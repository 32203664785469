import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingService {
  public preRoutingDataLoadedSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(private router: Router) { }

  public notifyPreRoutingDataLoaded(): void {
    this.preRoutingDataLoadedSubject.next(true);
    this.preRoutingDataLoadedSubject.complete();
  }

  public navigate(routes: string[]): void {
    if (!routes || !routes.length) {
      alert('Invalid route configuration');
      return;
    }
    let routeName = routes[0];
    let routePath = '';
    switch (routeName) {
      case 'ProjectMain': {
        routePath = 'mp';
        break;
      }
      case 'ProjectValuation': {
        routePath = 'pd';
        break;
      }
      case 'ReportDesigner': {
        routePath = 'rd';
        break;
      }
      case 'Comparables': {
        routePath = 'cmp';
        break;
      }
      case 'Geo': {
        routePath = 'geo';
        break;
      }
      case 'Settings': {
        routePath = 'set';
        break;
      }
      case 'AnalysisDashboard': {
        routePath = 'pad';
        break;
      }
      default: {
        alert('Warning: Misconfigured route: ' + routeName);
        routePath = 'pad';
      }
    }
    this.router.navigate([routePath]);
  }
}

@Injectable({
  providedIn: 'root'
})
export class PreRoutingDataLoadedResolver implements Resolve<Observable<boolean>> {
  constructor(private routingService: AppRoutingService) { }

  resolve(): Observable<boolean> {
    return this.routingService.preRoutingDataLoadedSubject.asObservable();
  }
}
