(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProjectStdSettingsJobTemplateDialog
   * @description
   * Dialog for project settings - job template choooser
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationProjectStdSettingsJobTemplateDialog", {
      template: require('html-loader!./project.valuation.projectstd.settings.job.template.dialog.tmpl.html'),
      bindings: {
        "templateTypeId": "=",
        "projectId": "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$q', 'ProjectsResourceCustom', 'reflistResource', '$compile', '$timeout', 'appSettings', '$translate', 'appStorage', 'adalAuthenticationService', 'fileDownloadService', projectValuationProjectStdSettingsJobTemplateDialogCtrl]
    });

  function projectValuationProjectStdSettingsJobTemplateDialogCtrl($scope, $rootScope, $q, ProjectsResourceCustom, reflistResource, $compile, $timeout, appSettings, $translate, appStorage, adalAuthenticationService, fileDownloadService) {
    var vm = this;
    vm.window = null;

    vm.templateTypeName = '';
    vm.showUpload = false;
    vm.uploadTemplateName = '';
    vm.dropDisabled = true;

    vm.setAsDefaultTemplate = setAsDefaultTemplate;
    vm.uploadTemplate = uploadTemplate;
    vm.downloadTemplate = downloadTemplate;
    vm.uploadTemplateNameChanged = uploadTemplateNameChanged;

    vm.$onDestroy = function () {
      $(".k-overlay").off('.overlaynamespace');
    };

    vm.selectedTemplateDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.availableTemplatesDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectOptionsSelectedTemplate = {
      dataTextField: "name",
      dataValueField: "oid",
      dataSource: vm.selectedTemplateDataSource,
      template: function (dataItem) {
        return `
<div class="item-row">
  <div class="item-text item-text-cyan">
    ${dataItem.name}
  </div>
  <span class="item-button-left" ng-click="vm.downloadTemplate(${dataItem.oid})">
    <img class="svg-icon-green project-settings-template-download-svg" src="download-outline.svg" alt="image" align="middle">
  </span>
  <span class="item-button"><!--placeholder--></span>
</div> 
`;
      },
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    vm.selectOptionsAvailableTemplates = {
      dataTextField: "name",
      dataValueField: "oid",
      dataSource: vm.availableTemplatesDataSource,
      template: function (dataItem) {
        return `
<div class="item-row">
  <div class="item-text item-text-cyan">
    ${dataItem.name}
  </div>
  <span class="item-button-left" ng-click="vm.downloadTemplate(${dataItem.oid})">
    <img class="svg-icon-green project-settings-template-download-svg" src="download-outline.svg" alt="image" align="middle">
  </span>
  <span class="item-button k-icon k-i-plus item-text-cyan" ng-click="vm.setAsDefaultTemplate(${dataItem.oid})"></span>
</div> 
`;
      },
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    vm.selectOptionsUploadTemplate = {
      dataTextField: "name",
      dataValueField: "oid",
      dataSource: new kendo.data.HierarchicalDataSource({ data: [{ oid: 0, name: ''}] }),//dummy
      template: function () {
        return `
<div class="item-row">
  <div class="item-text item-text-cyan">
    {{'projectUserSettings.uploadTemplate' | translate}}
  </div>
  <span class="item-button" ng-click="vm.uploadTemplate()">
    <img class="svg-icon-green project-settings-template-upload-svg" src="download-outline.svg" alt="image" align="middle">
  </span>
</div> 
`;
      },
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    function compileTemplate(e) {
      var listbox = this;
      $timeout(function () {
        var rows = listbox.items();
        rows.each(function (index, row) {
          if (!$(row).hasClass("ng-scope")) {
            $compile(row)($.extend($scope.$new(), listbox.dataItem(row)));
          }
        });
      });
    }

    const openCloseDuration = 200;

    function reset() {
      vm.loaded = true;
      vm.selectedTemplateDataSource.data([]);
      vm.availableTemplatesDataSource.data([]);

      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      ///https://docs.telerik.com/kendo-ui/controls/layout/window/how-to/window-as-sliding-pane

      const windowWidth = 500;
      vm.options = {
        title: false,
        width: windowWidth + "px",
        draggable: false,
        resizable: false,
        modal: true,
        position: {
          top: toolbar.height,
          left: 0,
        },
        animation: {
          open: {
            effects: "slideIn:left",
            duration: openCloseDuration
          },
          close: {
            effects: "slideIn:left",
            reverse: true,
            duration: openCloseDuration
          }
        },
        visible: false,
        close: function () {
          $timeout(function () {
            $rootScope.$broadcast('closeProjectStdSettingsJobTemplateDialog');
          }, openCloseDuration);
        },
        activate: function () {
          // Close window when clicked outside of window
          $(".k-overlay").one("click.overlaynamespace", vm.cancel);
        }
      };

      const winW = window.innerWidth;
      const winH = window.innerHeight;
      vm.options.position.left = winW - windowWidth;
      vm.options.height = winH - toolbar.height;
    }

    reset();

    vm.cancel = cancel;

    /**
    * @ngdoc function
    * @name confirm
    * @methodOf DXSPolluxApp.component:projectValuationProjectStdSettingsJobTemplateDialog
    * @description
    * Closes a dialog
    */
    function cancel() {
      vm.window && vm.window.close();
    }

    function loadData() {
      vm.showUpload = false;
      kendo.ui.progress(vm.window.element, true);
      $q.all([
        ProjectsResourceCustom.getDefaultProjectTemplates(vm.projectId, vm.templateTypeId),
        ProjectsResourceCustom.getAvailableProjectTemplates(vm.projectId, vm.templateTypeId),
        reflistResource.loadPollux.query({ reflistName: 'ref_valuation_project_type' }).$promise
      ]).then(
        function (results) {
          kendo.ui.progress(vm.window.element, false);

          const defaultTemplates = results[0];
          const defaultTemplate = defaultTemplates.find(t => t.jobTypeId === vm.templateTypeId);//there should always be one
          const availableTemplates = results[1].filter(t => t.jobTypeId === vm.templateTypeId && t.oid !== defaultTemplate.oid);

          const currentTemplate = results[2].find(t => t.oid === vm.templateTypeId);
          if (currentTemplate) {
            vm.templateTypeName = currentTemplate.description;
          }

          $timeout(function () {
            vm.selectedTemplateDataSource.data([defaultTemplate]);
            vm.availableTemplatesDataSource.data(availableTemplates);
          });

        }).catch((err) => {
          kendo.ui.progress(vm.window.element, false);
          alert("Failed to load templates!");
          console.error(err);
        });
    }

    function setAsDefaultTemplate(templateId) {
      const template = vm.availableTemplatesDataSource.data().find(t => t.oid === templateId);
      const oid = template.oid;
      kendo.ui.progress(vm.window.element, true);
      $q.all([
        ProjectsResourceCustom.setAsDefaultTemplate(vm.projectId, vm.templateTypeId, oid)
      ]).then(
        function (results) {
          kendo.ui.progress(vm.window.element, false);
          const gpr = results[0];
          if (gpr.success) {

            //inform parent (settings dialog)
            $rootScope.$broadcast('defaultTemplateChange', template);

            //switch templates in own dropdowns, for now => just reload
            loadData();
          } else {
            throw gpr.message;
          }
        }).catch((err) => {
          kendo.ui.progress(vm.window.element, false);
          alert(err);
          console.error(err);
        });
    }

    function uploadTemplate() {
      vm.showUpload = !vm.showUpload;
      vm.uploadTemplateName = '';
      vm.dropDisabled = true;
    }

    function downloadTemplate(templateId) {
      let template = vm.availableTemplatesDataSource.data().find(t => t.oid === templateId);
      if (!template) {
        template = vm.selectedTemplateDataSource.data().find(t => t.oid === templateId);
      }

      if (!template) return;

      exportTemplate(templateId, `${template.name.trim()}.xlsx`);
    }

    function exportTemplate(templateId, exportFileName) {
      var downloadUrl = `${appSettings.environment.DQS_API_URL}/import/calcmodel/v2/template/id/${templateId}`;
      fileDownloadService.initiateDownload(downloadUrl, exportFileName);
    }

    function uploadTemplateNameChanged() {
      if (vm.uploadTemplateName && vm.uploadTemplateName.trim().length > 0) {
        vm.dropDisabled = false;
        vm.upload.enable();
      } else {
        vm.dropDisabled = true;
        vm.upload.disable();
      }
    }

    //#region "EVENTS"

    $scope.$on('closeSidebar', function (event, params) {
      cancel();
    });

    $scope.$on("closeProjectStdSettingsDialog", function (event, params) {
      cancel();
    });

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      if (widget === vm.window) {
        $rootScope.$broadcast("closeInfoBar");
        $timeout(function() {
          vm.window.open();
          loadData();
        });
      }
    });

    //#endregion "EVENTS"

    //#region "UPLOAD"
    vm.upload = null;

    vm.uploadOptions = {
      multiple: false,
      enabled: false, //enabled in uploadTemplateNameChanged
      async: {
        saveUrl: appSettings.environment.POLLUX_PROJECTVALUATION_API_URL + '/project/templates/upload',
        autoUpload: false
      },
      validation: {
        allowedExtensions: ['.xlsx']
      },
      dropZone: '.dropZoneElement',
      localization: {
        select: $translate.instant('import.select'),
        cancel: $translate.instant('import.cancel'),
        clearSelectedFiles: $translate.instant('import.clearSelectedFiles'),
        dropFilesHere: "",
        headerStatusUploaded: $translate.instant('import.headerStatusUploaded'),
        headerStatusUploading: $translate.instant('import.headerStatusUploading'),
        invalidFileExtension: $translate.instant('import.invalidFileExtension'),
        invalidFiles: $translate.instant('import.invalidFiles'),
        invalidMaxFileSize: $translate.instant('import.invalidMaxFileSize'),
        invalidMinFileSize: $translate.instant('import.invalidMinFileSize'),
        remove: $translate.instant('import.remove'),
        retry: $translate.instant('import.retry'),
        statusFailed: $translate.instant('import.statusFailed'),
        statusUploaded: $translate.instant('import.statusUploaded'),
        uploadSelectedFiles: $translate.instant('import.uploadSelectedFiles')
      },
      upload: function (e) {

        if (!vm.uploadTemplateName) {
          e.preventDefault();
          alert("Please enter template name!");
          return;
        }

        var xhr = e.XMLHttpRequest;
        xhr.addEventListener("readystatechange", function (e) {
          if (xhr.readyState == 1 /* OPENED */) {
            try {
              var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
              if (resource) {
                xhr.withCredentials = true;
                xhr.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
              }
            } catch (e) {
              //Nothing, appears outside Azure only
            }
          }
        });

        const zeroGuidPattern = '/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/';
        const selectedProject = appStorage.getSelectedProject();
        const organizationGuid = appStorage.getOrganizationGuid();

        let url = appSettings.environment.POLLUX_PROJECTVALUATION_API_URL + '/project/templates/upload';
        if (selectedProject) {
          url = url.replace(zeroGuidPattern, `/api/v2/${organizationGuid}/${selectedProject.ProjectGuid}/`);
        }
        e.sender.options.async.saveUrl = url;

        e.data = {
          json: JSON.stringify({
            oid: 0,
            projectId: vm.projectId,
            jobTypeId: vm.templateTypeId,
            name: vm.uploadTemplateName
          })
        };
      },
      error: function (e) {
        alert(e.XMLHttpRequest.responseText);
      },
      success: function (e) {
        if (!e.response.success) {
          alert(e.response.message);
          e.preventDefault();
          $timeout(function () {
            angular.element('.k-upload-status-total').text($translate.instant('import.invalidFiles'));
          }, 10);
        }
        else {
          $scope.$apply();
          $timeout(function () {
            loadData();
          }, 10);
        }
      }
    };

    //#endregion "UPLOAD"
  }
}());

