(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('ValuationPropertiesResource',
      ['$resource', 'appServiceHelper', 'ValuationPropertiesResourceCustom', PropertiesResource]);

  function PropertiesResource($resource, appServiceHelper, customResource) {
    var api = "marvin.pollux.projectValuation.api";
    var baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties')),
      pagedResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties/list/paged'));

    function load(oid) {
      return baseResource.get({ oid: oid }).$promise;
    }

    function loadPagedList(take, skip, page, pageSize, filter) {
      return pagedResource.get({
        take: take || 50,
        skip: skip || 0,
        page: page || 1,
        pageSize: pageSize || 50,
        filter: filter || ""
      }).$promise;
    }

    function save(data) {
      return baseResource.save(data).$promise;
    }

    function remove(oid) {
      return baseResource.delete({ oid: oid }).$promise;
    }

    var obj = {
      itemResource: baseResource,
      pagedListResource: pagedResource,
      load: load,
      loadPagedList: loadPagedList,
      save: save,
      remove: remove
    };

    for (var prop in customResource) {
      obj[prop] = customResource[prop];
    }

    return obj;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:projectValuationPropertyResource
   * @description
   * This service is responsible for loading property data.
   * 
   * @requires $resource
   * @requires DXSPolluxApp.object:appServiceHelper
   */
  angular
    .module('DXSPolluxApp')
    .factory('projectValuationPropertyResource',
      ['$resource', 'appServiceHelper', projectValuationPropertyResource]);

  function projectValuationPropertyResource($resource, appServiceHelper) {
    return {
      /**
       * @ngdoc function
       * @name load
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Loads all available property data.
       * 
       * <b>Warning</b> This can be a lot of data. Better use <c>filter</c> instead
       * 
       * Route: api/v2/project/valuation/property/details
       * 
       * @returns {Array} All available property data
       */
      load: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/details'), {}),

      /**
       * @ngdoc function
       * @name filter
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Loads all available property data that match the given criteria.
       * 
       * Route: api/v2/project/valuation/tenant/filter
       * 
       * @param {Integer} parentProjectStd The parent project OID
       * @param {Integer} parentPortfolioId The parent valuation portfolio OID
       * @param {Integer} parentReportId The parent report OID
       * @param {Bool} includeZero If set to true, the 'Nulldatensatz' (OID) will be included (OID >= 0). If set to false, only OID >= 1 will be returned
       * @param {String} sortBy Name of the field that will be used for sorting
       * @param {String} sortDirection Sort diretion - 'ASC' or 'DESC'
       * 
       * @returns {Array} All available property data that match the given filter criteria
       */
      filter: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/filter'), { parentProjectStd: '@parentProjectStd', parentPortfolioId: '@parentPortfolioId', parentReportId: '@parentReportId', includeZero: '@includeZero', sortBy: '@sortBy', sortDirection: '@sortDirection' }),

      /**
       * @ngdoc function
       * @name remove
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Deletes a property.
       * 
       * Route: api/v2/project/valuation/property
       * 
       * @param {Integer} propertyId The OID of the property entry that will be deleted
       */
      remove: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property'), { propertyId: '@propertyId' }),

      /**
       * @ngdoc function
       * @name createreport
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Creates a report for the given property and assigns it to the specified valuation portfolio
       * 
       * Route: api/v2/project/valuation/property/createreport
       * 
       * @param {Integer} propertyId The OID of the property
       * @param {Integer} valuationProjectId The OID of the valuation portfolio
       */
      createreport: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/createreport'), { propertyId: '@propertyId', valuationProjectId: '@valuationProjectId' }),

      /**
       * @ngdoc function
       * @name createreports
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Checks if there are properties in the given valuation portfolio that don't have a report assigned to them.
       * After that, the reports for these properties are created automatically.
       * 
       * <b>Hint</b> This might take a while. Use some kind of busy indicator.
       * 
       * Route: api/v2/project/valuation/property/createreport
       * 
       * @param {Integer} valuationProjectId The OID of the valuation portfolio
       */
      createreports: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/createreports'), { valuationProjectId: '@valuationProjectId' }, { create: { method: 'POST', isArray: true } }),

      /**
       * @ngdoc function
       * @name setportfolioid
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Assigns or unassigns a property to/from a valuation portfolio
       * 
       * Route: api/v2/project/valuation/property/setportfolioid
       * 
       * @param {Integer} propertyId The OID of the property entry that will be assigned/unassigned to/from the given valuation portfolio
       * @param {Integer} portfolioId The OID of the valuation portfolio. To unassign, simply negate this value (e.g. -42 to unassign the property from the valuation portfolio OID 42)
       */
      setportfolioid: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/setportfolioid'), { propertyId: '@propertyId', portfolioId: '@portfolioId' }),

      /**
       * @ngdoc function
       * @name getpictures
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Gets a list of all pictures for this property
       * 
       * api/v2/picturesexport/pictures
       * 
       * @param {Integer} propertyId The OID of the property entry that will be checked for pictures
       * 
       * @returns {Integer} A list of all pictures for this property
       */
      getpictures: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'picturesexport/pictures'), { propertyId: '@propertyId' }, { get: { method: 'GET', isArray: true } }),

      /**
         * @ngdoc function
         * @name getpicturesV2
         * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
         * @description
         * Gets a list of all pictures for this property
         * 
         * api/v2/picturesexport/pictures
         * 
         * @param {Integer} propertyId The OID of the property entry that will be checked for pictures
         * 
         * @returns {Integer} A list of all pictures for this property
         */
      getpicturesV2: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'picturesexport/pictures/dataurl'), { propertyId: '@propertyId', pictureTypeId: '@pictureTypeId', width: '@width', height: '@height'}, { get: { method: 'GET', isArray: true } }),

      /**
       * @ngdoc function
       * @name deletepicture
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Deletes a property picture
       * 
       * api/v2/picturesexport/pictures/delete
       * 
       * @param {Integer} oid The OID of the picture that will be deleted
       */
      deletepicture: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'picturesexport/pictures/delete'), { oid: '@oid' }, { delete: { method: 'POST' } }),

      /**
       * @ngdoc function
       * @name savepicture
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Saves and attaches a picture to a property
       * 
       * api/v2/picturesexport/pictures/save
       */
      savepicture: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'picturesexport/pictures/save'), null, { save: { method: 'POST' } }),

      /**
       * @ngdoc function
       * @name attachments
       * @methodOf DXSPolluxApp.service:projectValuationPropertyResource
       * @description
       * Gets a list of all file attachments
       * 
       * api/v2/project/valuation/property/attachments
       * 
       * @param {Integer} propertyId The OID of the property
       * @param {Integer} valuationPortfolioId The OID of the valuation portfolio (attachments are per property AND valuation portfolio because a property can belong to multiple valuation portfolios)
       * 
       * @returns {Array} A list of all file attachments
       */
      attachments: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/attachments'), { propertyId: '@propertyId', valuationPortfolioId: '@valuationPortfolioId' })
    }
  }
}());
