(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:appImportExportResource
     * @description
     * This service is responsible for creating Excel import templates and export objects to Excel.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appSettings
     */
    angular
        .module('DXSPolluxApp')
        .factory('appImportExportResource',
        ['$resource', 'appServiceHelper', appImportExportResource]);

    function appImportExportResource($resource, appServiceHelper) {

        function getFileNameFromHeader(header) {
          if (!header) return null;
          var result = header.split(";")[1].trim().split("=")[1];
          return result.replace(/"/g, '');
        }

        return {
            /**
             * @ngdoc function
             * @name export
             * @methodOf DXSPolluxApp.service:appImportExportResource
             * @description
             * Exports the specified values to an Excel sheet. 
             * 
             * Route: api/v2/export
             * 
             * @param {String} mask The mask that should be exported
             * @param {Array} oidValues POST value. The OIDs of the values that need to be exported. Note: Some masks have a 'confidential' flag. These entries will not be exported.
             * 
             * @returns {Object} An ExportResponse indicating success/failure and where the resulting file is stored.
             */
            export: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'export'), { mask: '@mask' }, {
              'query': { method: 'POST', isArray: false },
              'download': {
                method: 'POST',
                headers: {
                  accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                responseType: 'arraybuffer',
                cache: false,
                transformResponse: function (data, headers) {
                  var blob = null;
                  if (data) {
                    blob = new Blob([data], {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });
                  }
                  var fileName = getFileNameFromHeader(headers('content-disposition'));
                  var result = {
                    blob: blob,
                    fileName: fileName
                  };
                  return {
                    response: result
                  };
                }
              }
            }),

            /**
             * @ngdoc function
             * @name template
             * @methodOf DXSPolluxApp.service:appImportExportResource
             * @description
             * Creates an import template for the desired mask.
             * 
             * Route: api/v2/import/template
             * 
             * @param {String} mask The mask that should be exported
             * 
             * @returns {Object} An ImportTemplateResponse indicating success/failure and the resulting template's path.
             */
            template: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'import/template'), { mask: '@mask' }, { 'query': { method: 'GET', isArray: false } })
        }
    }
}());
