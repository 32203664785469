(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxNumericFieldEdit
   * @description
   * This component provides the behavior and visual representation of a text field. The field is rendered as a Kendo UI input field.
   *
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * Internally, it handles a pollux-text-field, but it only displays it's edit field if the content has been clicked.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   */
  angular.module("DXSPolluxApp")
    .component("polluxNumericFieldEdit", {
      template: require('html-loader!./pollux.numeric.field.edit.tmpl.html'),
      bindings: {
        "ngMinValue": "=",
        "ngMaxValue": "=",
        "ngDecimals": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngThousandsSeparators": '=',
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxNumericFieldEditCtrl]
    });

  function polluxNumericFieldEditCtrl($timeout) {
    var vm = this;

    //#region variable declaration
    vm.editMode = false;
    vm.guid = null;
    //#endregion

    //#region function declaration
    vm.edit = edit;
    vm.disableEdit = disableEdit;
    //#endregion

    vm.$onInit = function () {
      vm.guid = kendo.guid();
      if (!vm.ngId) {
        vm.ngId = kendo.guid();
      }
    }

    vm.onNgChange = function () {
      if (vm.ngChange) {
        vm.ngChange();
      }
    }

    function edit(event) {
      event.stopPropagation();
      vm.editMode = true;
      focusEditableField();
    }

    function focusEditableField() {
      $timeout(function () {
        var editableField = angular.element('.' + vm.guid)[0].querySelector('pollux-reportdesigner-numeric-field').querySelector('input\:not([hidden])');

        if (editableField) {
          editableField.focus();
        } else {
          focusEditableField(); //without this editableField might not be defined yet
        }
      });
    }

    function disableEdit() {
      if (document.activeElement) {
        var root = angular.element('.' + vm.guid);
        if (root && root.length) {
          var field = root[0].querySelector('pollux-reportdesigner-numeric-field');
          if (field) {
            var input = field.querySelector('input\:not([hidden])');
            if (input && document.activeElement.parentElement.uniqueID !== input.parentElement.uniqueID) {
              vm.editMode = false;
            }
          }
        }
      }
    }
  }
}());

