(function () {
  'use strict';

  angular
    .module('DXSPolluxApp')
    .factory('appConfirmDialogService',
      ['$rootScope', appConfirmDialogService]);

  function appConfirmDialogService($rootScope) {
    function open(config, translationKey, title) {
      $rootScope.$broadcast('openConfirmDialog', config, translationKey, title);
    }

    return {
      open: open
    };
  }
}());
