import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HelpTextService } from '../_services/help-text.service';
import { Subscription } from 'rxjs';
import { Tour } from '../model/tour';

@Component({
  selector: 'help-text-overlay',
  templateUrl: './help-text-overlay.component.html',
  styleUrls: ['./help-text-overlay.component.scss']
})
export class HelpTextOverlayComponent implements OnInit {

  public visible = false;

  private visiblitySubscription: Subscription;
  private allTours: Tour[] = [];
  private visibleTours: Tour[] = [];

  private loading = true; 
  @Output() closed = new EventEmitter<void>();

  constructor(private helpTextService: HelpTextService) { }

  ngOnInit(): void {
    const self = this;
    
    //Visibility
    self.visiblitySubscription = self.helpTextService.visible$.subscribe((visibility: boolean) => {
      self.visible = visibility;
      self.visibleTours = [];
      setTimeout(async () => {
        if (visibility) {
          // get tours if it is empty 
          if (!self.allTours || self.allTours.length == 0) {
            await self.helpTextService.getAllTours().then((tours: Tour[]) => {
              self.allTours = [];
              if (tours) {
                self.allTours = tours;
              }
            });
          }
          //filtes only for the visible ids
          self.visibleTours = self.filterOnlyVisible(self.allTours);
          self.loading = false; 
        }
      });
    });
  }

  changeVisibile(): void {
    const self = this;
    self.visible = !self.visible;
    self.helpTextService.changeVisibility(false);
    self.closed.emit();
  }

  /**
   * Filters for a list of Tour if it is visibile. 
   * @param data
   */
  filterOnlyVisible(data: Tour[]): Tour[] {
    return data.filter(entry => {
      this.mapElementPosition(entry); 
      const element = document.getElementById(entry.tourId);
      if (element) {
        return window.getComputedStyle(element).display !== 'none';
      }
      return false;
    });
  }

  mapElementPosition(tour: Tour): void {
    const rootOverlay = document.getElementById("helpTextRootOverlay");
    const element = document.getElementById(tour.tourId);
    const values = element ? element.getBoundingClientRect() : null;
    if (values && rootOverlay) {
      tour.bottom = rootOverlay.clientHeight - values.bottom;
      tour.left = values.left;
      tour.right = rootOverlay.clientWidth - values.right;
      tour.top = values.top;
    }
  }

  ngOnDestroy(): void {
    if (this.visiblitySubscription) {
      this.visiblitySubscription.unsubscribe();
    }
  }
} 
