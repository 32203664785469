(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxNumericField
   * @description
   * This component provides the behavior and visual representation of a numeric input field. It is rendered as a Kendo UI kendo-numeric-text-box (without spinners).
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires common.services.service:appStorage
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Bool} isNullable Specifis if this field can contain null values. In such a case, an empty field will be saved as null. Otherwise, the field will be saved as 0.
   * @param {Float} ngMinValue If provided, the user can't enter less than <c>ngMinValue</c>
   * @param {Float} ngMaxValue If provided, the user can't enter more than <c>ngMaxValue</c>
   * @param {Bool} thousandsSeparators If set to <c>true</c>, this field uses thousands separators depending on the current locale.
   * @param {Integer} decimals Specifies how many decimal places can be used. The decimal separator is chosen depending on the current locale.
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxNumericField", {
      template: require('html-loader!./pollux.numeric.field.tmpl.html'),
      bindings: {
        "isNullable": "=",
        "ngMinValue": "=",
        "ngMaxValue": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": "<",
        "thousandsSeparators": "=",
        "decimals": "=",
        "ngId": "@",
        "format": "@",
        "skipChangeOnInit": "=",
      },
      controllerAs: "vm",
      controller: ['$scope', '$timeout', polluxNumericFieldCtrl]
    });

  function polluxNumericFieldCtrl($scope, $timeout) {
    var vm = this;

    //#region variable declaration
    vm.numericField = null;
    vm.displayModel = null;

    vm.options = {
      max: vm.ngMaxValue,
      min: vm.ngMinValue,
      change: vm.onNgChange,
      format: vm.format,
      spinners: false,
      decimals: vm.decimals || 0
    };
    //#endregion

    //#region function declaration
    vm.onNgChange = onNgChange;
    vm.onFocus = onFocus;
    vm.onBlur = onBlur;
    //#endregion

    //#region function implementation
    function onNgChange(e) {
      vm.options = {
        max: vm.ngMaxValue,
        min: vm.ngMinValue,
        change: onNgChange,
        format: vm.format ? vm.format : vm.options.format,
        spinners: false,
        decimals: vm.decimals || 0
      };

      $timeout(function () { //Potentially executed from outside of the Angular context
        if (!vm.isNullable && vm.ngModel == null) {
          if (vm.ngMinValue != null) {
            vm.ngModel = vm.ngMinValue;
          } else {
            vm.ngModel = 0;
          }
        }

        vm.displayModel = vm.numericField.element[0].value;
        //console.log("displayModel: " + vm.displayModel);

        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }

    //https://docs.telerik.com/kendo-ui/controls/editors/numerictextbox/how-to/select-all-on-focus
    function onFocus(e) {
      const input = angular.element(e.target);

      clearTimeout(input.data("selectTimeId")); //stop started time out if any

      var selectTimeId = setTimeout(function () {
        input.select();
        // To make this work on iOS, too, replace the above line with the following one. Discussed in https://stackoverflow.com/q/3272089
        // input[0].setSelectionRange(0, 9999);
      });

      input.data("selectTimeId", selectTimeId);
    }

    function onBlur(e) {
      const input = angular.element(e.target);

      clearTimeout(input.data("selectTimeId")); //stop started timeout
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      if (vm.format) {
        vm.options.format = vm.format;
      }
      if (vm.decimals) {
        vm.options.decimals = vm.decimals;
      }
      if (vm.ngMaxValue) {
        vm.options.max = vm.ngMaxValue;
      }
      if (vm.ngMinValue != null) {
        vm.options.min = vm.ngMinValue;
      }

      //console.log("NumericField: $onInit")
      if (!vm.isNullable && vm.ngModel == null) {
        if (vm.ngMinValue != null) {
          vm.ngModel = vm.ngMinValue;
        } else {
          vm.ngModel = 0;
        }
      }

      if (vm.ngMinValue > vm.ngMaxValue) {
        console.warn("Configuration error: The minimum value is larger than the maximum value!");
      }

      if (!vm.format) {
        if (vm.thousandsSeparators) {
          if (vm.decimals > 0) {
            vm.options.format = '#,##0.';
            for (var i = 0; i < vm.decimals; ++i) {
              vm.options.format += '0';
            }
          }
          else {
            vm.options.format = '#,###';
          }
        }
        else {
          if (vm.decimals > 0) {
            vm.options.format = '0.';
            for (var i = 0; i < vm.decimals; ++i) {
              vm.options.format += '0';
            }
          }
          else {
            vm.options.format = '#####';
          }
        }
      }

      $timeout(function () {
        vm.numericField.element.on("keyup", function (e) {
          //vm.numericField.value(vm.numericField.element.val());
          //vm.numericField.trigger("change");
          $timeout(function () {
            onNgChange(e);//kendo-numeric-text-box wont trigger change by itself in this version
          });
        });

        if (!vm.skipChangeOnInit) {
          onNgChange();
        }
      });
    };

    //vm.$onDestroy = function() {
    //    console.log("NumericField: $onDestroy");
    //};

    //$scope.$on("kendoRendered", function(e) {
    //    console.log("kendoRendered");
    //});

    $scope.$on('localeChanged', function (event, locale) {
      vm.onNgChange();
    });
    //#endregion
  }
}());

