import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import * as msalConfig from './config/msal.config';

@Injectable()
export class AppAuthGuard implements CanActivate {

  constructor(public router: Router, private http: HttpClient) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const self = this;
    console.log('Validating user data and licensing...');
    return new Observable<boolean>((observer) => {

      if (!environment.UseMsal) {
        self.checkLicense(observer);
      } else {
        const msalInstance = msalConfig.MSALInstanceFactory();
        // Wait for MSAL redirect to finish, so the token is attachedd
        msalInstance
          .handleRedirectPromise()
          .then((tokenResponse) => {
            self.checkLicense(observer);
          })
          .catch((error) => {
            // Handle redirect error
            console.log('Failed to load licensing information from the server!');
            self.router.navigate(['unauthorized']);
            observer.next(false);
            observer.complete();
          });
      }
    });
  }

  private checkLicense(observer): void {
    const self = this;
    self.http.get(`${environment.POLLUX_API_URL}/user/license/synapses`)
      .pipe(take(1))
      .subscribe(
        (data: any) => {
          if (data.success) {
            console.log('License is valid');
            observer.next(true);
          }
          else {
            console.log('License is invalid');
            self.router.navigate(['unauthorized']);
            observer.next(false);
          }

          observer.complete();
        },
        (error: any) => {
          console.log('Failed to load licensing information from the server!');
          self.router.navigate(['unauthorized']);
          observer.next(false);
          observer.complete();
        });
  }

}
