(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationMain
   * @description
   * This is the main component for the projectValuation (Valuation Portfolio) area. This area allows the management of report-based data:
   * - Project (main_projectstd)
   *    - Valuation Portfolio (main_valuation_project)
   *      - Report (main_report)
   *          - Property (main_property)
   *          - Tenant (support_report_rentroll)
   *          - Valuation MV (support_report_valuation) + Valuation VPV (support_report_valuation_vpv)
   *          - Competitor (support_report_competitor_analysis)
   * 
   * It divides the available area into 4 sections:
   * - Toolbar
   * - Search area
   * - Workplace
   * - Infobar
   * 
   * Each of these areas is represented by a dedicated component again.
   * Therefore, this component provides logic for controlling all contained areas, like showing, hiding or the link counter.
   * 
   * Furthermore, it also abstracts the import, export and template creation logic which is used by all of the Business Objects that are contained in the Valuation Portfolio area.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires $rootScope
   * @requires $http
   * @requires $timeout
   * @requires DXSPolluxApp.service:appImportExportResource
   * @requires $translate
   * @requires appRouting
   * 
   * @param {Integer} userid The user id (not used)
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationMain", {
      template: require('html-loader!./project.valuation.main.tmpl.html'),
      bindings: {
        userid: "<"
      },
      controllerAs: "vm",
      controller: ['$scope', 'appStorage', '$rootScope', '$http', '$timeout', 'appServiceHelper', '$translate', 'appRouting', 'fileDownloadService', 'angHelpTextService', projectValuationMainCtrl]
    });

  function projectValuationMainCtrl($scope, appStorage, $rootScope, $http, $timeout, appServiceHelper, $translate, appRouting, fileDownloadService, angHelpTextService) {
    var vm = this;

    //#region variables
    vm.storage = appStorage;
    vm.ApplicationOrientation = "vertical";
    vm.infobar = null;
    vm.workplace = null;
    vm.loading = false;

    vm.linkCounter = {};

    vm.searchMode = "projectstd"; //dummy

    vm.exportCommands = {};

    vm.selectedTabHierarchy = null;
    vm.selectedTabHierarchyItemType = null;
    vm.selectedTabHierarchyItem = null;

    vm.reportValues = [];
    vm.projectStdValues = [];
    vm.portfolioValues = [];

    vm.PVInfoBarVisibility = false;
    vm.PVSearchModeVisibility = false;

    vm.sidebarSplitter = null;
    vm.mainAreaSplitter = null;
    vm.toolBarSplitter = null;
    vm.showHelpTextWindow = false;

    vm.mainAreaSplitterOptions = {
      panes: [
        { collapsible: false, resizable: true },
        { collapsible: true, resizable: true, size: '400px', collapsed: true, name: 'project-valuation-infobar' }
      ],
      orientation: "horizontal"
    };

    vm.toolBarSplitterOptions = {
      panes: [
        { collapsible: false, resizable: false, size: '100px' },
        { collapsible: false, resizable: true }
      ],
      orientation: "vertical"
    };

    vm.sidebarSplitterOptions = {
      panes: [
        { collapsible: true, resizable: false, size: '139px', name: 'project-valuation-sidebar', collapsed: false },
        { collapsible: false, resizable: true }
      ],
      orientation: "horizontal"
    };

    vm.sidebarBig = true;

    //#endregion

    vm.getPVInfoBarVisibility = getPVInfoBarVisibility;
    vm.setPVInfoBarVisibility = setPVInfoBarVisibility;
    vm.setPVSearchModeVisibility = setPVSearchModeVisibility;
    vm.getPVSearchModeVisibility = getPVSearchModeVisibility;
    vm.closeSidebar = closeSidebar;
    vm.onElementSelected = onElementSelected;
    vm.onElementDeselected = onElementDeselected;
    vm.setSelectedImportArea = setSelectedImportArea;

    vm.setCounterValue = setCounterValue;
    vm.getCounterValue = getCounterValue;
    vm.onInfobarCreated = onInfobarCreated;
    vm.onWorkplaceCreated = onWorkplaceCreated;
    vm.onSearchReport = onSearchReport;
    vm.onSearchProjectStd = onSearchProjectStd;
    vm.onSearchPortfolio = onSearchPortfolio;

    vm.doExport = doExport;
    vm.createTemplate = createTemplate;
    vm.doImport = doImport;
    vm.resizePane = resizePane;
    vm.resizePaneSidebar = resizePaneSidebar;
    vm.doGridExcelExport = doGridExcelExport;
    vm.doCustomImport = doCustomImport;

    /**
     * @ngdoc function
     * @name onInfobarCreated
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called by the infobar when it's created. Allows accessing the toolbar programmatically within this component.
     * @param {Object} infobar The infobar's view model
     */
    function onInfobarCreated(infobar) {
      vm.infobar = infobar;
    }

    /**
     * @ngdoc function
     * @name onWorkplaceCreated
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called by the workplace when it's created. Allows accessing the workplace programmatically within this component.
     * @param {Object} workplace The workplace's view model
     */
    function onWorkplaceCreated(workplace) {
      vm.workplace = workplace;
    }

    /**
     * @ngdoc function
     * @name onSearchReport
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called if a report search is triggered
     * @param {Array} values The array (key -> value) containing the fields to be searched
     * @returns {Array} The updated values
     */
    function onSearchReport(values) {
      return vm.reportValues = values;
    }

    /**
     * @ngdoc function
     * @name onSearchProjectStd
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called if a project search is triggered
     * @param {Array} values The array (key -> value) containing the fields to be searched
     * @returns {Array} The updated values
     */
    function onSearchProjectStd(values) {
      return vm.projectStdValues = values;
    }

    /**
     * @ngdoc function
     * @name onSearchPortfolio
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called if a portfolio search is triggered
     * @param {Array} values The array (key -> value) containing the fields to be searched
     * @returns {Array} The updated values
     */
    function onSearchPortfolio(values) {
      return vm.portfolioValues = values;
    }

    /**
     * @ngdoc function
     * @name getPVInfoBarVisibility
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Gets the infobar's visibility
     * @returns {Boolean} The infobar's visibility
     */
    function getPVInfoBarVisibility() {
      return vm.PVInfoBarVisibility;
    }

    /**
     * @ngdoc function
     * @name setPVInfoBarVisibility
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Shows or hides the infobar (right side)
     * @param {String} mode The infobar's visibility
     * @param {Object} item The selected item (if any)
     * @param {String} sizeOverwrite Size as String (i.e. "400px")
     */
    function setPVInfoBarVisibility(mode, item, sizeOverwrite) {
      vm.PVInfoBarVisibility = mode;
      if (vm.mainAreaSplitter) {
        if (mode) {
          vm.mainAreaSplitter.expand('#project-valuation-infobar');
          sizeOverwrite && vm.resizePane('#project-valuation-infobar', sizeOverwrite);
          vm.infobar.setMode(mode, item);
        }
        else {
          try {
            vm.mainAreaSplitter.collapse('#project-valuation-infobar');
            vm.infobar.setMode(false);
          } catch (e) {
            //Nothing
          }
        }
      }
    }

    /**
     * @ngdoc function
     * @name getPVSearchModeVisibility
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Gets the search bar's visibility
     * @returns {Boolean} The search bar's visibility
     */
    function getPVSearchModeVisibility() {
      return vm.PVSearchModeVisibility;
    }

    /**
     * @ngdoc function
     * @name setPVSearchModeVisibility
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Shows or hides the search bar (left side)
     * @param {Boolean} mode The search bar's visibility
     */
    function setPVSearchModeVisibility(mode) {
      vm.PVSearchModeVisibility = mode;
      if (mode) {
        vm.sidebarSplitter.expand('#project-valuation-searchbar');
      }
      else {
        vm.sidebarSplitter.collapse('#project-valuation-searchbar');
      }
      $rootScope.$broadcast('search-bar-visibility-changed');
    }

    /**
     * @ngdoc function
     * @name setCounterValue
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Sets the counter value for the given tab
     * @param {String} tab The desired tab's name
     * @param {Integer} value The counter value (number of elements in the grid) for the given tab
     */
    function setCounterValue(tab, value) {
      $timeout(function () {
        vm.linkCounter[tab] = value;
      }, 0);
    }

    /**
     * @ngdoc function
     * @name getCounterValue
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Gets the counter value for the given tab
     * @param {String} tab The desired tab's name
     * @returns {Integer} The counter value (number of elements in the grid) for the given tab
     */
    function getCounterValue(tab) {
      return vm.linkCounter[tab];
    }

    /**
     * @ngdoc function
     * @name onElementSelected
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called when an item was selected in any tab
     * @param {Object} tabItem The tab in which the element was selected
     * @param {Object} item The item that was selected
     */
    function onElementSelected(tabItem, item) {
      if (!vm.exportCommands[tabItem.exportAreaName]) {
        vm.exportCommands[tabItem.exportAreaName] = [];
      }
      if (tabItem.exportAreaName === 'ReportValuation') {
        vm.exportCommands['ReportValuationVpv'] = [];
      }

      try {
        vm.exportCommands[tabItem.exportAreaName] = [];

        if (item.length > 0) {
          for (var i = 0; i < item.length; ++i) {
            if (item[i].HistoryOID) {
              if (tabItem.exportAreaName === 'ReportValuation') {
                if (item[i].Scenario === 'VPV') {
                  vm.exportCommands['ReportValuationVpv'].push(item[i].HistoryOID);
                }
                else {
                  vm.exportCommands['ReportValuation'].push(item[i].HistoryOID);
                }
              }
              else {
                vm.exportCommands[tabItem.exportAreaName].push(item[i].HistoryOID);
              }
            } else if (item[i].Oid) {
              vm.exportCommands[tabItem.exportAreaName].push(item[i].Oid);
            }
            else if (item[i].oid) {
              vm.exportCommands[tabItem.exportAreaName].push(item[i].oid);
            }
            else if (item[i].CompetitorAnalysisOID) {
              vm.exportCommands[tabItem.exportAreaName].push(item[i].CompetitorAnalysisOID);
            }
          }
        }
        else {
          if (item.HistoryOID) {
            if (tabItem.exportAreaName === 'ReportValuation') {
              if (item.Scenario === 'VPV') {
                vm.exportCommands['ReportValuationVpv'].push(item.HistoryOID);
              }
              else {
                vm.exportCommands['ReportValuation'].push(item.HistoryOID);
              }
            }
            else {
              vm.exportCommands[tabItem.exportAreaName].push(item.HistoryOID);
            }
          } else if (item.Oid) {
            vm.exportCommands[tabItem.exportAreaName].push(item.Oid);
          }
          else if (item.oid) {
            vm.exportCommands[tabItem.exportAreaName].push(item.oid);
          }
          else if (item.CompetitorAnalysisOID) {
            vm.exportCommands[tabItem.exportAreaName].push(item.CompetitorAnalysisOID);
          }
        }
      } catch (e) {
        //Nothing
      }
    }

    /**
     * @ngdoc function
     * @name onElementDeselected
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Called when an item was deselected in any tab
     * @param {Object} tabItem The tab in which the element was deselected
     * @param {Object} item The item that was deselected
     */
    function onElementDeselected(tabItem, item) {
      vm.exportCommands[tabItem.exportAreaName] = [];
    }

    /**
     * @ngdoc function
     * @name setSelectedImportArea
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Sets the selected area for the Excel import
     * @param {String} area The desired area for importing
     */
    function setSelectedImportArea(area) {
      vm.selectedImportArea = area;

      vm.exportCommands = {};
    }

    /**
     * @ngdoc function
     * @name doExport
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Initiates an Excel export if the currently selected area supports this
     *
     * @param {String} func The name of the function you want to call (Optional).
     */
    function doExport(func) {
      vm.workplace.doExport(func);
    }

    /**
     * @ngdoc function
     * @name doExport
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Initiates an Excel export if the currently selected area supports this, using the Kendo UI grid's functinalities
     */
    function doGridExcelExport() {
      vm.workplace.doGridExcelExport();
    }

    /**
     * @ngdoc function
     * @name createTemplate
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Initiates the creation of an Excel import template if the currently selected area supports this
     */
    function createTemplate() {
      if (!vm.selectedImportArea) {
        $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'noImportInThisSection', 'simpleMessage.titleNoImport');
        return;
      }

      var importAreas = [vm.selectedImportArea];
      if (vm.selectedImportArea === 'ReportValuation') {
        importAreas.push('ReportValuationVpv');
      }
      angular.forEach(importAreas, function (importArea) {
        var url = appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'import/template') + '?mask=' + importArea;
        fileDownloadService.initiateDownload(url);
      });
    }

    /**
     * @ngdoc function
     * @name doImport
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Initiates an Excel import if the currently selected area supports this
     */
    function doCustomImport(customArea) {
      $rootScope.$broadcast("startAppImport", { importArea: customArea, additionalData: null });
    }

    /**
     * @ngdoc function
     * @name doImport
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Initiates an Excel import if the currently selected area supports this
     */
    function doImport() {
      if (!vm.selectedImportArea) {
        $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'noImportInThisSection', 'simpleMessage.titleNoImport');
        return;
      }

      var additionalData = null;

      if (vm.selectedImportArea === "Objects") {
        var selectedProject = appStorage.getSelectedProject();
        if (!selectedProject) {
          return;
        } else {
          additionalData = { OverwriteColumns: [{ ImportColumnUIName: "PUID", Value: selectedProject.Oid }] };
        }
      }

      $rootScope.$broadcast("startAppImport", { importArea: vm.selectedImportArea, additionalData: additionalData });
    }

    /**
     * @ngdoc function
     * @name resizePane
     * @methodOf DXSPolluxApp.component:projectValuationMain
     * @description
     * Resizes a pane of the horizontal splitter.
     * @param {String} id The splitter pane that should be resized
     * @param {Integer} size The desired size
     */
    function resizePane(id, size) {
      vm.mainAreaSplitter.size(id, size);
    }

    /**
 * @ngdoc function
 * @name resizePaneSidebar
 * @methodOf DXSPolluxApp.component:projectValuationMain
 * @description
 * Resizes a pane of the sidebarSplitter.
 * @param {String} id The splitter pane that should be resized
 * @param {Integer} size The desired size
 */
    function resizePaneSidebar(id, size) {
      vm.sidebarSplitter.size(id, size);
    }

    vm.$onInit = function () {
      var module = document.getElementById('projectValuationModule');
      module.focus();
    };

    function closeSidebar(e) {
      $rootScope.$broadcast("closeSidebar");
      vm.setPVInfoBarVisibility(false);
    }

    vm.onKeyPressed = function (e) {
      //if (e.ctrlKey && e.altKey && e.keyCode === 80) { // STRG + ALT + P
      //  appRouting.navigate(["ProjectMain"]);
      //}
      //else if (e.ctrlKey && e.altKey && e.keyCode === 82) { // STRG + ALT + R
      //  appRouting.navigate(["ReportDesigner"]);
      //}
      //else if (e.ctrlKey && e.altKey && e.keyCode === 68) { // STRG + ALT + D
      //  appRouting.navigate(["Comparables"]);
      //}
      //else if (e.ctrlKey && e.altKey && e.keyCode === 83 && appStorage.userIsInRole("Admin")) { // STRG + ALT + S
      //  appRouting.navigate(["Settings"]);
      //}
    };

    //#region events

    $scope.$on('loadingIndicator', function (event, value) {
      vm.loading = value;
    });

    $scope.$on('helpText', function () {
      angHelpTextService.changeVisibility(true);
      vm.showHelpTextWindow = true;
    });

    $scope.$on('sidebar-big', function (event, value) {
      vm.sidebarBig = value;
    });

    $scope.$on('openUserProfile', function (event, params) {
      vm.setPVInfoBarVisibility("userProfile");
    });

    $scope.$on('closeInfoBar', function (event, params) {
      vm.setPVInfoBarVisibility(false);
    });

    $scope.$on('notficiations', function (event, opened) {
      if (opened) {
        vm.setPVInfoBarVisibility("notifications");
      } else {
        vm.mainAreaSplitter.collapse('#project-valuation-infobar');
        vm.infobar.setMode(false);
      }

    });
    //#endregion
  }
}());
