(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:appLocalization
   * @description
   * Manages application localization
   * 
   * @requires $rootScope
   * @requires tmhDynamicLocale
   * @requires $translate
   * @requires $q
   * @requires $timeout
   * @requires appLanguageSettingsResource
   * @requires DXSPolluxApp.object:appSettings
   * @requires projectValuationSettingsDialogRoleManagementResource
   * @requires appUserResource
   * @requires locale
   */
  angular
    .module('DXSPolluxApp')
    .factory('appLocalization',
      ['$rootScope', 'tmhDynamicLocale', '$translate', '$q', 'appLanguageSettingsResource', 'appSettings', 'angTranslateService', appLocalization]);

  function appLocalization($rootScope, tmhDynamicLocale, $translate, $q, appLanguageSettingsResource, appSettings, angTranslateService) {
    var self = this;

    var appLocale = appSettings.locale;

    /**
    * @ngdoc function
    * @name applyLocale
    * @methodOf DXSPolluxApp.service:appLocalization
    * @description
    * Applies the locale settings. Not available outside this service.
    * 
    * @param {String} [localeRegionDesignator] The locale in region designator notation, separated by '_'.
    * @param {String} [localeScriptDesignator] The locale in script designator notation, separated by '-'.
    * @param {Promise} [dfd] Promise that will be resolved once everything is ready
    */
    function applyLocale(localeRegionDesignator, localeScriptDesignator, dfd) {
      appLocale = localeScriptDesignator; //The application uses the script designator notation ('de-DE')

      //http://docs.telerik.com/kendo-ui/controls/editors/datepicker/how-to/AngularJS/localization-using-angular-translate
      $.getScript('./libraries/kendoui/message/kendo.messages.' + localeScriptDesignator + '.min.js', function () { //Kendo UI uses the script designator notation
        kendo.culture(localeScriptDesignator); //Kendo UI uses the script designator notation
        tmhDynamicLocale.set(localeRegionDesignator); //tmhDynamicLocale uses the region designator location
        angTranslateService.use(localeScriptDesignator);
        $translate.use(localeScriptDesignator).then(function () { //$translate uses the script designator notation
          $rootScope.$broadcast("localeChanged", localeScriptDesignator);
          kendo.ui.progress(angular.element('body'), false);
          dfd.resolve();
        });
      });
    }

    /**
     * @ngdoc function
     * @name setLocale
     * @methodOf DXSPolluxApp.service:appLocalization
     * @description
     * Sets the application locale for the currently logged in user. Please note that the Report Designer's document area is not affected by this setting because this depends on the report/template settings.
     * As this operation also sets the locale for all Kendo UI components, these should be used carefully in any Report Designer widgets if they use locale-specific templates.
     * 
     * <b>Warning</b> This is an asynchronous operation. Therefore, all further operations that might depend on the application's locale should only be performed when the returned promise was resolved or rejected.
     * 
     * @param {String} newLocale The new locale that will be set for the current user. Should be in script designator notation (separated by '-').
     * @param {bool} [suppressStore] If set to true, the value will not be written back to the database. Set it to false or leave it out to save the settings.
     * 
     * @returns {Promise} A promise because this is an asynchronous operation
     */
    function setLocale(newLocale, suppressStore) {
      var localeRegionDesignator = newLocale.split('-').join('_'); //separated by '_', so replace all occurrences of '-' by '_' -> de_DE
      var localeScriptDesignator = newLocale.split('_').join('-'); //separated by '-', so replace all occurrences of '_' by '-' -> de-DE

      kendo.ui.progress(angular.element('body'), true);
      var dfd = $q.defer();

      if (!suppressStore) {
        //Also store the value in the user's settings.
        appLanguageSettingsResource.locale.save(
          { locale: localeScriptDesignator },
          {},
          function (result) {
            applyLocale(localeRegionDesignator, localeScriptDesignator, dfd);
          },
          function (error) {
            alert(error.data.ExceptionMessage);
          });
      }
      else {
        applyLocale(localeRegionDesignator, localeScriptDesignator, dfd);
      }

      return dfd.promise;
    }

    function getLocalizationToolbarButtons() {
      var ret = [];

      angular.forEach(appSettings.availableLocales, function (locale) {
        var text = locale.name.substring(0, 2).toUpperCase();

        ret.push({ type: "button", id: "buttonToggleLocale", text: text, enable: true, hidden: appLocale !== locale.name });
      });

      return ret;
    }

    function toggleLocale() {
      var currentLocaleEntry = appSettings.availableLocales.filter(function (entry) {
        return entry.name == appLocale;
      });
      var currentLocaleIndex = 0;

      if (currentLocaleEntry && currentLocaleEntry.length) {
        currentLocaleIndex = appSettings.availableLocales.indexOf(currentLocaleEntry[0]) + 1;
      }
      currentLocaleIndex %= appSettings.availableLocales.length;

      setLocale(appSettings.availableLocales[currentLocaleIndex].name);

      $rootScope.$broadcast('localeChanged');
    }


    /**
     * @ngdoc function
     * @name getLocale
     * @methodOf DXSPolluxApp.service:appLocalization
     * @description
     * Gets the application locale for the currently logged in user.
     * @returns {String} The current locale string
     */
    function getLocale() {
      return appLocale;
    }

    return {
      setLocale: setLocale,
      getLocale: getLocale,

      getLocalizationToolbarButtons: getLocalizationToolbarButtons,
      toggleLocale: toggleLocale,
    };
  }
}());
