import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from "@angular/core";

import { AngularIntegrationService } from '../../services/AngularIntegrationService';
import { FilterPaneService } from './services/filter-pane-service.service';
import { FilterPaneItemConfig } from './models/filter-pane-item-config';

@Component({
  selector: "filter-pane",
  templateUrl: "./filter-pane.component.html",
  styleUrls: ["./filter-pane.component.scss"]
})
export class FilterPaneComponent implements OnInit, OnDestroy {

  @Input('area') area: string;

  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();

  private show: boolean = false;
  private onEvents: Array<any> = [];

  private filterName: string = "";
  private items: Array<FilterPaneItemConfig> = [];
  public reset: boolean = false;

  private loadingMessage: string;

  private connectedItem: any;
  private resetConnectedItemFn: Function;
  private filterFieldName: string;
  private filterOperator: string;

  constructor(private ais: AngularIntegrationService, private service: FilterPaneService) {
  }

  ngOnInit() {
    this.setupEvents();
  }

  ngOnDestroy() {
    this.onEvents.forEach(unregister => {
      unregister();
    });
  }

  public removeFiltersClicked() {
    this.service.resetFilters();
    this.service.undoResetFilters();
  }

  public async saveFiltersClicked() {
    let filters = this.items.filter(item => item.filters.length > 0)
      .map((item) => {
        return {
          field: item.field,
          operator: item.operator,
          values: item.filters.map((filter) => { return { oid: filter.oid, replacer: filter.replacer, description: filter.description }; })
        };
      });

    var params = {
      area: this.area,
      name: this.filterName,
      filters: filters
    };

    let result = await this.service.saveFilterSet(params, true);/*GenericPolluxResult*/
    if (result.success) {
      this.service.filtersChanged(params);
      this.filtersChanged.emit(params);//TODO: remove this event, not used by anything
      this.closePane();
    } else {
      alert("Error: " + result.message);
    }
  }

  private async loadData(reset: boolean = false): Promise<any> {
    try {
      this.loadingMessage = "Loading..."
      if (reset) { // set items filters empty //TODO PLEASE CHECK FOR ISSUES
        this.service.resetCache();
      }
      if (this.ais.appStorage.getSelectedProject()) {
        let self = this;
        Promise.all([
          self.service.getActiveFilterSet(self.area),
          self.service.loadDataForConfig(self.area)
        ]).then((data: any[]) => {
          let filterSet = data[0];
          self.items = data[1];

          self.filterName = filterSet.name;
          filterSet.filters.forEach((_filter) => {
            let item = self.items.find(o => o.field == _filter.field);
            if (item) {
              _filter.values = _filter.values.filter((o) => { return item.values.filter((i) => { return i.oid === o.oid; }).length > 0; });//ensure filter values exists
              item.filters = _filter.values.map(_item => {
                let result = item.values.find((_value) => { return _value.oid === _item.oid; });
                return result;
              });
            }
          });

        });
      }
      if (reset) { // set items filters empty
        this.service.resetCache();
        this.items.forEach((item) => {
          item.filters = [];
        });
        this.reset = false;
      }

      this.loadingMessage = null;
    } catch (e) {
      this.loadingMessage = null;
    }
  }

  private closePane() {
    this.items = [];
    this.show = false;
  }

  private setupEvents() {
    let unregisterToggleAreaFilters: any;
    let unregisterResetFiltersByProject: any;
    let $rootScope = this.ais.$rootScope;
    const that = this;

    unregisterToggleAreaFilters = $rootScope.$on('toggleAreaFilters', function (event, params) {
      if (params.forceClose) {
        that.show && setTimeout(() => that.closePane());
        return;
      }

      if (that.area === params.area) {
        that.show = !that.show;
        if (that.show) {
          if (that.reset) { // check if reset flag is set
            that.loadData(true);
          }
          else {
            that.loadData();
          }
        } else {
          that.closePane();
        }
      }
    });

    unregisterResetFiltersByProject = $rootScope.$on('resetFiltersByProject', function (event, params) {
      that.reset = true;
    });

    this.onEvents.push(unregisterToggleAreaFilters);
    this.onEvents.push(unregisterResetFiltersByProject);
  }

}
