import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AngularIntegrationService } from '../../../services/AngularIntegrationService';
import { FilterPaneItemConfig } from '../models/filter-pane-item-config';
import { FilterPaneItemValue } from '../models/filter-pane-item-value';

@Injectable()
export class FilterPaneConfigLoaderService {

  constructor(private ais: AngularIntegrationService, private http: HttpClient) { }

  public loadConfig(area: string): Array<FilterPaneItemConfig> {
    let items: Array<FilterPaneItemConfig> = [];

    let createdModifiedAtValues: Array<FilterPaneItemValue> = [
      new FilterPaneItemValue(1, 'last week', '#LAST_WEEK#'),
      new FilterPaneItemValue(2, 'last month', '#LAST_MONTH#'),
      new FilterPaneItemValue(3, 'last quarter', '#LAST_QUARTER#'),
      new FilterPaneItemValue(4, 'last year', '#LAST_YEAR#')
    ];

    let booleanValues: Array<FilterPaneItemValue> = [
      new FilterPaneItemValue(0, 'false'),
      new FilterPaneItemValue(1, 'true')
    ];

    switch (area) {
      case "valuationPortfolio":

        items.push(new FilterPaneItemConfig('LinkedPropertyCity', 'contains_stringInList', 'filters.city', true, 'marvin.pollux.api', 'filterPane/valuationPortfolio-qv/values/city', null, { "property": 'MunicipalManual' }));
        items.push(new FilterPaneItemConfig('PropertyTypeIdMask', 'contains_intInList', 'filters.propertyType', true, 'marvin.pollux.api', 'filterPane/valuationPortfolio-qv/values/propertyType', null, { "property": 'PropertyTypeIdMask' }));
        items.push(new FilterPaneItemConfig('JobStatusId', 'contains_intInList', 'filters.jobStatus', true, 'marvin.pollux.api', 'filterPane/valuationPortfolio-qv/values/jobStatus', null, { "property": 'JobStatusId' }));
        items.push(new FilterPaneItemConfig('LinkedPropertyOid', 'contains_intInList', 'filters.property', true, 'marvin.pollux.api', 'filterPane/valuationPortfolio-qv/values/property-qv', null, { "property": 'Oid' }));
        items.push(new FilterPaneItemConfig('JobTypeId', 'contains_intInList', 'filters.jobType', true, 'marvin.pollux.api', 'filterPane/valuationPortfolio-qv/values/jobType', null, { "property": 'JobTypeId' }));
        items.push(new FilterPaneItemConfig('AssignedUserId', 'contains_jobMemberInList_job', 'filters.assignedUsers', true, 'marvin.pollux.api', 'filterPane/valuationPortfolio-qv/values/assignedUsers', null, { "property": 'AssignedUserId' }));
        break;
      case "property":
        items.push(new FilterPaneItemConfig('MunicipalManual', 'contains_stringInList', 'filters.city', true, 'marvin.pollux.api', 'filterPane/property-qv/values/city', null, { "valuationPortfolio": 'LinkedPropertyCity' }));
        items.push(new FilterPaneItemConfig('PropertyTypeIdMask', 'contains_intInList', 'filters.propertyType', true, 'marvin.pollux.api', 'filterPane/property-qv/values/propertyType', null, { "valuationPortfolio": 'PropertyTypeIdMask' }));
        items.push(new FilterPaneItemConfig('JobStatusId', 'contains_intInArray', 'filters.jobStatus', true, 'marvin.pollux.api', 'filterPane/property-qv/values/jobStatus', null, { "valuationPortfolio": 'JobStatusId' }));
        items.push(new FilterPaneItemConfig('Oid', 'contains_intInList', 'filters.property', true, 'marvin.pollux.api', 'filterPane/property-qv/values/property-qv', null, { "valuationPortfolio": 'LinkedPropertyOid' }));
        items.push(new FilterPaneItemConfig('JobTypeId', 'contains_intInArray', 'filters.jobType', true, 'marvin.pollux.api', 'filterPane/property-qv/values/jobType', null, { "valuationPortfolio": 'JobTypeId' }));
        items.push(new FilterPaneItemConfig('AssignedUserId', 'contains_jobMemberInList_property', 'filters.assignedUsers', true, 'marvin.pollux.api', 'filterPane/property-qv/values/assignedUsers', null, { "valuationPortfolio": 'AssignedUserId' }));
        break;
    }

    return items;
  }

  public getServiceEndpoint(api: string, url: string) {
    return this.ais.appServiceHelper.getServiceEndpoint(api, url);
  }

}
