(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("appConfirmDialog", {
      template: require('html-loader!./app.confirm.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', 'appSettings', 'appStorage', '$translate', '$timeout', appConfirmDialogCtrl]
    });

  function appConfirmDialogCtrl($scope, appSettings, appStorage, $translate, $timeout) {
    var vm = this;

    vm.confirmWindow = null;
    vm.config = null;

    vm.confirm = confirm;
    vm.cancel = cancel;



    function confirm() {
      vm.confirmWindow.close();
      if (vm.config && vm.config.success) {
        vm.config.success();
      }
    }

    function cancel() {
      vm.confirmWindow.close();
      if (vm.config && vm.config.cancel) {
        vm.config.cancel();
      }
    }

    //#region "EVENTS"

    $scope.$on('openConfirmDialog', function (event, config, translationKey, title) {
      vm.config = config;
      vm.translationKeyTitle = "";
      vm.translationKeyMessage = "";
      vm.translationKeyConfirm = "";
      vm.translationKeyCancel = "";

      if (!vm.config.simpleMessage) {
        vm.translationKeyTitle = translationKey + '.title';
        vm.translationKeyMessage = translationKey + '.message';
        vm.translationKeyConfirm = translationKey + '.confirm';
        if (vm.config.cancel) {
          vm.translationKeyCancel = translationKey + '.cancel';
        }
      }
      else {
        vm.translationKeyTitle = title;
        vm.translationKeyMessage = translationKey;
        vm.translationKeyConfirm = 'confirmSimpleMessage.confirm';
      }

      if (!vm.config.replace) {
        vm.translatedMessage = $translate.instant(vm.translationKeyMessage);
      }
      else {
        vm.translatedMessage = vm.translationKeyMessage;
      }

      $timeout(function () {
        vm.confirmWindow.title($translate.instant(vm.translationKeyTitle));
        vm.confirmWindow.center();
        vm.confirmWindow.open();
      }, 10);
    });

    //#endregion "EVENTS"
  }
}());

