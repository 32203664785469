(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:projectValuationGridKpiSettingsResource
   * @description
   * Resource for persisting user specific column arrangements in the workspace grids.
   * 
   * @requires $resource
   * @requires DXSPolluxApp.object:appServiceHelper
   */
  angular
    .module('DXSPolluxApp')
    .factory('projectValuationGridKpiSettingsResource',
      ['$resource', 'appServiceHelper', projectValuationGridKpiSettingsResource]);

  function projectValuationGridKpiSettingsResource($resource, appServiceHelper) {
    return {
      /**
       * @ngdoc function
       * @name details
       * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsResource
       * @description
       * Gets a list of all kpi settings for the current user
       * 
       * Route: api/v2/KpiSettings/details
       * 
       * @returns {Array} A list of all kpi settings for the current user
       */
      userKpis: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'KpiSettings/userKpis'), {}),

      data: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'KpiSettings/data'), { area: '@area', oid: '@oid' }),
      /**
         * @ngdoc function
         * @name availableKpis
         * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsResource
         * @description
         * Gets a list of all kpis with area and sortorder
         * 
         * Route: api/v2/KpiSettings/availableKpis
         * 
         * @returns {Array} A list of all kpis with area and sortorder
         */
      availableKpis: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'KpiSettings/availableKpis'), {})
    };
  }
}());
