import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDate } from './localized-date';
import { LocalizedNumber } from './localized-number';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

registerLocaleData(localeDe);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    declarations: [
        LocalizedDate,
        LocalizedNumber
    ],
    exports: [
        TranslateModule,
        LocalizedDate,
        LocalizedNumber
    ]
})
export class TranslationModule { }
