(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .filter('reflistMultiple', [function () {
            return function (oids, reflist) {
                if (!reflist) {
                    return '';
                }

                var descriptions = "";
                for (var i = 0; i < oids.length; ++i) {
                    var result = reflist.filter(function (element) {
                        return parseInt(element.oid) === oids[i];
                    });
                    if (result && result.length) {
                        if (descriptions !== "") {
                            descriptions += result[0].description !== "" ? ", " + result[0].description : "";
                        }
                        else {
                            descriptions += result[0].description !== "" ? result[0].description : "";
                        }
                    }
                }
                if (descriptions !== "") {
                    return descriptions;
                }
                else {
                    return '';
                }

            };
        }]);
}());
