import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import * as zc from '@dvsl/zoomcharts';
import { WindowRefService } from '../../_qv/zoom-charts/window-ref';

@Component({
  selector: 'property-rentroll-distribution',
  templateUrl: './property-rentroll-distribution.component.html',
  styleUrls: ['./property-rentroll-distribution.component.css']
})
export class PropertyRentrollDistributionComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  public _chartID: string;
  public _data: any;
  public chartInstance: any;
  public width: number = 0;
  public height: number = 0;
  public zc: any = zc;
  public hasData = false;

  @ViewChild('chart') chartDiv: ElementRef;

  @Input() data: any[];
  @Output() result = new EventEmitter<number[]>();

  constructor(winRef: WindowRefService) {
    winRef.nativeWindow.ZoomChartsLicense = "ZCP-he5p70237:  ZoomCharts SDK license for 3 Chart Developers";
    winRef.nativeWindow.ZoomChartsLicenseKey = "33e5694b13267f9859017a44420f5959ee8cde847c63f7794e" +
      "8998a4a218d3dd4ba67e84474c0dedd90210668d98e97ef350b3dedfc4a64e466c6254d15db88" +
      "6b92f31adda725e1019af6570ea8d3b432ec7d28a7f5fff781a735fe72c5d336557b2308e8d22" +
      "1fa9f40043758cfab6acce1109c7d30868d31001add291c9fbb8f0fe8342d3fd8c9b2d02c3798" +
      "68a039756d173548950fc5986a320630a942fad209c8531b7749ffda661a87dad0ce621a6e357" +
      "e8a51763a256c35a1fa324fb6e5e190708234cf9d8de32d22f2f8020f4cd34677661d66aa0e7a" +
      "17cb79d53d09fb1b181bdfa81543569321a4cc20156c6955e30f6b935c576421524e3f9122100";
  }


  ngOnInit() {
    const self = this;

    self._chartID = 'chart-property-rentroll-distribution';

    self._data = {
      nodes: [],
      links: []
    };

    //Add the jobs
    for (let i = 0; i < self.data.length; ++i) {
      const jobData = self.data[i];
      const node: any = {
        id: jobData.oid,
        className: 'unselected',
        style: {
          label: jobData.oid + ': ' + jobData.jobName
        },
        extra: {
          parent: jobData.parentJobOid,
          updateRentRollFromParent: jobData.updateRentRollFromParent
        }
      };
      self._data.nodes.push(node);
    }

    //Add the links
    for (let i = 0; i < self.data.length; ++i) {
      const jobData = self.data[i];
      //Don't include the parent if it has a different RR
      if (jobData.parentJobOid && (!jobData.parentJobOid || self._data.nodes.filter((node) => { return node.id == jobData.parentJobOid; }).length)) {
        self._data.links.push({
          id: "inheritance_" + jobData.oid + "_" + jobData.parentJobOid,
          to: jobData.oid, //Set parent as 'from' because it's the root
          from: jobData.parentJobOid,
          className: "inheritance"
        });

        if (jobData.updateRentRollFromParent) {
          self._data.links.push({
            id: "rentroll_" + jobData.oid + "_" + jobData.parentJobOid,
            to: jobData.oid,
            from: jobData.parentJobOid,
            className: "rentroll"
          });
        }
      }
    }
  }

  private applyClassToChildren(currentNode: any, className: string): void {
    const self = this;
    const children = self._data.nodes.filter((entry) => {
      return entry.extra.parent == currentNode.id;
    });

    for (let i = 0; i < children.length; ++i) {
      if (children[i].extra.updateRentRollFromParent) {
        children[i].className = className;

        self.applyClassToChildren(children[i], className);
      }
    }
  }

  private onNodeChanged(node: any): void {
    const self = this;

    const nodeInData = self._data.nodes.filter((entry) => {
      return entry.id == node.data.id;
    });
    if (nodeInData.length) {
      const currentClass = nodeInData[0].className;

      if (currentClass === 'unselected') {
        nodeInData[0].className = 'selected';
      }
      else {
        nodeInData[0].className = 'unselected';
      }

      let rootParent = nodeInData[0];
      //Go up in the tree until either the root is found or rr inheritance is disabled
      while (rootParent.extra.parent && rootParent.extra.updateRentRollFromParent) {
        const newRootParent = self._data.nodes.filter((entry) => {
          return entry.id == rootParent.extra.parent;
        });
        if (newRootParent.length) {
          rootParent = newRootParent[0];
        } else {
          break;
        }
      }

      rootParent.className = nodeInData[0].className;

      self.applyClassToChildren(rootParent, nodeInData[0].className);
    }

    self.result.emit(self._data.nodes.filter((node) => { return node.className === 'selected'; }).map((node) => { return node.id; }));

    self.chartInstance.reloadData();
  }

  ngAfterViewInit() {
    const self = this;
    const NetChart = self.zc.NetChart;

    let config = {
      container: self.chartDiv.nativeElement,
      area: {
        style: { fillColor: "#ffffff" },
        height: 450
      },
      layout: {
        mode: "focusnodes"
      },
      style: {
        nodeClasses: [
          { className: "undefined", nameLegend: 'Unknown Job', showInLegend: false, style: { fillColor: "#FF0000" } },
          { className: "unselected", nameLegend: "Don't update Rent Roll", style: { fillColor: "#FF0000" } },
          { className: "selected", nameLegend: 'Update Rent Roll with current', style: { fillColor: "#00FF00" } },
        ],
        linkClasses: [
          { className: "inheritance", nameLegend: "Parent Job", style: { fillColor: "#D9306B", toDecoration: null, fromDecoration: "arrow" } },
          { className: "rentroll", nameLegend: "Update Job Rent Roll", style: { fillColor: "#FFD35C", toDecoration: "arrow" } }
        ]
      },
      data: {
        preloaded: self._data
      },
      legend: {
        height: 120,
        maxLineSymbols: 5,
        numberOfColumns: 5,
        lineSpacing: 0,
        numberOfRows: 1,
        padding: 6,
        margin: 0,
        enabled: true,
        mode: 'highlight',
        marker: {
          alinment: 'left',
          size: 10
        }
      },
      toolbar: {
        enabled: false
      },
      events: {
        onPointerUp: function (e, args) {
          if (args.clickNode) {
            self.onNodeChanged(args.clickNode);
          }
        }
      }
    };
    self.chartInstance = new NetChart(config);
  }

  ngOnDestroy() {
    this.chartInstance.remove();
    this.chartInstance = null;
  }

  ngAfterViewChecked() {
    if (this.chartDiv.nativeElement.clientWidth != this.width || this.chartDiv.nativeElement.clientHeight != this.height) {
      this.width = this.chartDiv.nativeElement.clientWidth;
      this.height = this.chartDiv.nativeElement.clientHeight;
      this.chartInstance.updateSize();
    }
  }
}
