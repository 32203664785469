import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SmartBarComponent } from './smart-bar.component';
import { SmartBarItemComponent } from './smart-bar-item/smart-bar-item.component';
import { TranslationModule } from '../../../main/extensions/translation.module';
import { SmartBarFiltersComponent } from './smart-bar-filters/smart-bar-filters.component';
import { MultiDashboardModule } from '../multi-dashboard/multi-dashboard.module';

export const routerModule = RouterModule.forChild([{
  path: '',
  component: SmartBarComponent
}]);

@NgModule({
  imports: [
    CommonModule,
    TranslationModule,
    routerModule,
    MultiDashboardModule
  ],
  declarations: [SmartBarComponent, SmartBarItemComponent, SmartBarFiltersComponent]
})
export class SmartBarModule { }
