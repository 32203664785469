
(function () {
  'use strict';

  /*
      *****COMPARABLES PROPERTIES
      this.componentName = 'comparablesPropertyDialog';
      this.templateUrl = 'modules/comparables/components/dialog/comparables.property.dialog.tmpl.html';
      *****VALUATION PROPERTIES
      this.componentName = 'projectValuationPropertyDialog';
      this.templateUrl = 'modules/project.valuation/components/dialog/project.valuation.property.dialog.tmpl.html';
      *****VALUATION REPORTS
      this.componentName = 'projectValuationReportDialog';
      this.templateUrl = 'modules/project.valuation/components/dialog/project.valuation.report.dialog.tmpl.html';
      *****VALUATION PROJECTS
      this.componentName = 'projectValuationProjectstdDialog';
      this.templateUrl = 'modules/project.valuation/components/dialog/project.valuation.projectstd.dialog.tmpl.html';
      *****VALUATION PORTFOLIOS
      this.componentName = 'projectValuationPortfolioDialog';
      this.templateUrl = 'modules/project.valuation/components/dialog/project.valuation.portfolio.dialog.tmpl.html';
  */

  function getConfig() {
    //required on component declaration
    //TODO: next two lines should be adjusted probably (until some config)
    this.componentName = 'projectValuationPortfolioDialog';
    this.templateUrl = 'modules/project.valuation/components/dialog/project.valuation.portfolio.dialog.tmpl.html';


    this.angularModule = 'DXSPolluxApp';
    this.runtimeComponentName = this.componentName + 'Runtime';
    this.entityResourceName = 'ValuationPortfoliosResource';

    this.JobTypeId = 1;//Investment

    //used in component function(projectValuationPortfolioDialogCtrl)
    this.entityName = 'ValuationPortfolios';
    this.editprojectValuationPortfolioDialogBroadcastMessage = 'editValuationPortfoliosData:jobType:' + this.JobTypeId;
    this.newprojectValuationPortfolioDialogBroadcastMessage = 'createValuationPortfoliosData:jobType:' + this.JobTypeId;
    this.projectValuationPortfolioDialogBroadcastSavedMessage = 'valuationPortfoliosDataSaved';
    this.saveprojectValuationPortfolioDialogBroadcastMessage = 'savevaluationPortfoliosData:jobType:' + this.JobTypeId;
    this.editprojectValuationPortfolioBroadcastMessage = 'editValuationPortfolioData:jobType:' + this.JobTypeId;
    this.editprojectValuationPortfolioBroadcastMessageFromMask = 'editValuationPortfolioDataFromMask:jobType:' + this.JobTypeId;

    this.getDialogTitle = function (vm, $translate) {
      return 'Job Details';
    };

    this.resetSelectFields = function (vm) {
      //f.e. vm.choicesForProjectPhaseValuationID = [];
      vm.choicesForRecordStatusId = [];
      vm.choicesForBusinessLineNrId = [];
      vm.choicesForLiabilityTypeId = [];
      vm.choicesForLanguageId = [];
      vm.choicesForPeriodOfValuationID = [];
      vm.choicesForDerivationsFromInstructionLetterId = [];
      vm.choicesForPortfolioStatusId = [];
      vm.choicesForJobStatusId = [];
      vm.choicesForMarketRentUsageId = [];
      vm.choicesForJobTypeId = [];
    };
    this.refListsToLoad = ['ref_recordstatus', 'ref_valuation_businesslinenr', 'ref_l2_liability_type', 'ref_template_language', 'ref_valuation_project_periodofvaluation', 'ref_l2_deviations_from_instruction', 'ref_valuation_portfolio_scenario', 'ref_job_status', 'ref_unit_type', 'ref_valuation_project_type'];

    //////////////////////////////////////////////////////////////////////
    //SOME DEV HELPERS (TODO: remove after testing)
    //////////////////////////////////////////////////////////////////////
    this.fakeResource = function (resource) {
      if (!resource.load && resource.details && resource.details.get) {
        resource.load = function (oid) {
          return resource.details.get({ oid: oid }).$promise;
        };
      }
    };

    return this;
  }

  /****************************************************
  ****************************************************
  
  CODE BELOW SHOULD NOT BE MODIFIED (IN THEORY)
  
  ****************************************************
  ****************************************************/

  var CONFIG = new getConfig();

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationPortfolioDialog
   * @description
   * Dialog that allows user to edit Entity data
   * 
   * @requires $scope
   * @requires $q
   * @requires $timeout
   * @requires $translate
   * @requires DXSPolluxApp.service:reflistResource
   * @requires DXSPolluxApp.service:EntityResource
   */
  angular.module(CONFIG.angularModule)
    .component(CONFIG.componentName, {
      templateUrl: CONFIG.templateUrl,
      bindings: {
        "oid": "=",
        "isDialog": "="
      },
      controllerAs: 'vm',
      controller: ['$scope', '$q', '$timeout', '$translate', 'reflistResource', CONFIG.entityResourceName, CONFIG.runtimeComponentName, '$rootScope', 'appSettings', 'appCurrencyResource', 'windowHelper', '$element', projectValuationPortfolioDialogCtrl]
    });

  function projectValuationPortfolioDialogCtrl($scope, $q, $timeout, $translate, reflistResource, EntityResource, runtimeComponent, $rootScope, appSettings, appCurrencyResource, windowHelper, $element) {
    var vm = this;
    CONFIG.fakeResource && CONFIG.fakeResource(EntityResource);

    var RUNTIME = runtimeComponent;

    //#region variable declaration
    vm.window = null;
    vm.width = 1500;
    vm.minWidth = 425;
    vm.height = 820;
    vm.minHeight = 200;
    vm.displayProjectCategory = false;
    vm.displayRoleCategory = false;
    vm.displayPortfolioCategory = true;
    vm.displayAssumptionsCategory = true;
    vm.displayStrategyCategory = true;
    vm.displayDataLinkingCategory = false;
    vm.dialogTitle = CONFIG.getDialogTitle(vm, $translate);
    vm.editableFieldsShown = false;
    vm.disableRentRollDate = false;
    vm.availableTemplates = [];
    vm.currencies = null;
    vm.rentrollsDates = [];

    vm.getGeneralInformationSectionWidth = function () {
      const sectionsCount = vm.displayPortfolioCategory + vm.displayAssumptionsCategory + vm.displayStrategyCategory + vm.displayDataLinkingCategory;
      const width = (sectionsCount === 4) ? '20%' : (sectionsCount === 3) ? '25%' : (sectionsCount === 2) ? '33.33%' : (sectionsCount === 1) ? '50%' : '100%';
      return width;
    }

    vm.getMainSectionWidth = function () {
      const sectionsCount = vm.displayPortfolioCategory + vm.displayAssumptionsCategory + vm.displayStrategyCategory + vm.displayDataLinkingCategory;
      const width = (sectionsCount === 4) ? '80%' : (sectionsCount === 3) ? '75%' : (sectionsCount === 2) ? '66.66%' : (sectionsCount === 1) ? '50%' : '0%';
      return width;
    }

    vm.getSectionWidth = function () {
      const sectionsCount = vm.displayPortfolioCategory + vm.displayAssumptionsCategory + vm.displayStrategyCategory + vm.displayDataLinkingCategory;
      const width = (sectionsCount === 4) ? '25%' : (sectionsCount == 3) ? '33.33%' : (sectionsCount == 2) ? '50%' : '100%';
      return width;
    }

    //Dropdown possibilities should be declared in the vm
    //Remark: this is done in CONFIG.resetSelectFields, so probably nothing to do

    function resetComponent() {
      vm.formInvalid = true;
      vm.suppressFormInvalidMessage = true;
      vm.isNewMode = false;
      vm.dataLoaded = false;
      vm.dataLoadedTimeout = false;
      vm.formReadOnly = false;
      vm.showWindow = false;
      vm.data = null;
      vm.displayProjectCategory = false;
      vm.displayRoleCategory = false;
      vm.displayPortfolioCategory = true;
      vm.displayAssumptionsCategory = true;
      vm.displayStrategyCategory = true;
      vm.displayDataLinkingCategory = false;
      vm.editableFieldsShown = false;

      CONFIG.resetSelectFields && CONFIG.resetSelectFields(vm);
    }

    //init and reset
    resetComponent();

    //#endregion

    //#region function declaration
    vm.initWindow = initWindow;
    vm.loadData = loadData;
    vm.onClose = onClose;
    vm.cancel = cancel;
    vm.save = save;
    vm.displayProject = displayProject;
    vm.displayRole = displayRole;
    vm.displayPortfolio = displayPortfolio;
    vm.displayAssumptions = displayAssumptions;
    vm.displayStrategy = displayStrategy;
    vm.displayDataLinking = displayDataLinking;
    vm.filterTemplatesByLanguage = filterTemplatesByLanguage;
    vm.currencyChanged = currencyChanged;
    vm.showEditableFields = showEditableFields;
    //#endregion

    //#region function implementation

    function showProgress(show) {
      if (vm.window && vm.window.element) {
        kendo.ui.progress(vm.window.element, show);
      } else {
        kendo.ui.progress($element, show);
      }
    }

    /**
     * @ngdoc function
     * @name initWindow
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description
     * Initializes the Kendo UI window that hosts this dialog.
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function initWindow() {
      var deferred = $q.defer();
      $timeout(function () { //Potentially executed from outside of the Angular context
        resetComponent();
        $timeout(function () { //Timeout to destroy the window

          vm.width = 1500;
          vm.window && vm.window.setOptions({ width: vm.width, height: vm.height });
          //Show the window
          vm.showWindow = true;
          $timeout(function () { //Timeout to create the window
            vm.window && windowHelper.open(vm.window, 'generatedHTMLWindow');
            deferred.resolve();
          });
        });
      });
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name loadReflists
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description
     * Loads all ref lists that are required in this dialog.
     * @returns {Array<Promise>} An array of promises pointing to the ref list loading operations. If no ref lists are required, please create a dummy promise, resolve it and return an array containing only this promise.
     */
    function loadReflists() {
      //Always return a list of promises.
      var promises = [];

      if (CONFIG.refListsToLoad.length === 0) {
        // If no reflists are required, create a blank promise, resolve it and add it to the list
        var deferred = $q.defer();
        deferred.resolve([]);
        promises.push(deferred.promise);
      }
      else {
        for (var idx = 0, len = CONFIG.refListsToLoad.length; idx < len; idx++) {
          var refListName = CONFIG.refListsToLoad[idx];
          promises.push(reflistResource.loadPollux.query({ reflistName: refListName }).$promise);
        }
      }
      promises.push(appCurrencyResource.load.query({ locale: 'en-US' }).$promise);
      return promises;
    }

    /**
     * @ngdoc function
     * @name processReflistResults
     * @param { Array<Array<any>> } result The results for the requests sent in loadReflists (and also in this order).
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description Processes all ref list results (used for std/mstd lists). Leave this method empty if no ref lists are needed.
     */
    function processReflistResults(result) {
      //f.e. vm.choicesForProjectPhaseValuationID = result[0];
      vm.choicesForRecordStatusId = result[0];
      vm.choicesForBusinessLineNrId = result[1];
      vm.choicesForLiabilityTypeId = result[2];

      var rdLocales = result[3];
      vm.choicesForLanguageId = rdLocales;

      vm.choicesForPeriodOfValuationID = result[4];
      vm.choicesForDerivationsFromInstructionLetterId = result[5];
      vm.choicesForPortfolioStatusId = result[6];
      vm.choicesForJobStatusId = result[7];
      vm.choicesForMarketRentUsageId = result[8];
      vm.choicesForJobTypeId = result[9];
      vm.currencies = result[10];
    }

    /**
     * @ngdoc function
     * @name postProcessData
     * @param { any } data The data
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description
     * Post-processes the data stored in vm.data.
     * Typically, this is about performing some initial calculations and also applying data from the parent element if the dialog is in new mode (vm.isNewMode === true)
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function postProcessData(data, parent) {
      showProgress(true);
      var deferred = $q.defer();
      if (vm.isNewMode) {
        data.JobTypeId = CONFIG.JobTypeId;
      }

      RUNTIME.postProcessData(deferred, { vm: vm, data: data, parent: parent });

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name preProcessDataBeforeSave
     * @param { any } data The data
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description
     * Pre-processes the data stored in vm.data before saving.
     * Typically, this is about performing some initial calculations and also applying data from the parent element if the dialog is in new mode (vm.isNewMode === true)
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function preProcessDataBeforeSave(data, parent) {
      showProgress(true);
      var deferred = $q.defer();

      RUNTIME.preProcessDataBeforeSave(deferred, { vm: vm, data: data, parent: parent });

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name loadData
     * @param { number } oid The oid of the element or -1 for new mode
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description Processes all ref list results (used for std/mstd lists). Leave this method empty if no ref lists are needed.
     * @returns {Promise} A promise that is resolved once all async (loading) operations are completed.
     */
    function loadData(oid, parent) {
      showProgress(true);


      vm.isNewMode = oid <= 0;
      vm.data = null;
      var deferred = $q.defer();
      var promises = loadReflists();
      $q.all(promises)
        .then(function (results) {
          //Ref lists loading has finished
          var reflistResult = results;
          processReflistResults(reflistResult);
          vm.availableTemplates = [];
          return EntityResource.load(oid);
        }).then(function (dataResult) {
          //Data loading has finished
          vm.data = dataResult;
          return postProcessData(vm.data, parent);
        }).then(function () {
          //Post-processing has finished
          appCurrencyResource.setCurrencyData({
            CurrencyId: vm.data.CurrencyId,
            CurrencyIdDescription: vm.data.CurrencyIdDescription,
            CurrencySymbolUnicode: vm.data.CurrencySymbolUnicode
          });

          vm.dataLoaded = true;
          $timeout(function () {
            showProgress(false);
            vm.dataLoadedTimeout = true;

            deferred.resolve(); //All done!
          }, 100); //Give the components some time to render and prepare them in the background to prevent the UI from displaying incomplete markup                    
        }).catch(function (error) {
          showProgress(false);
          deferred.reject(error);
        });
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name onClose
     * @param { Event } e Optional event that triggered the close action. Set it to null/undefined to close the window programmatically. 
     * If it is not null and user triggered, the user is asked if he really wants to close the dialog before it's actually closed (or left open if the user chose not to close the dialog).
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description Closes the dialog. If the event is not null and user triggered, the user is asked if he really wants to close the dialog before it's actually closed (or left open if the user chose not to close the dialog).
     */
    function onClose(e) {
      $timeout(function () { //Timeout to destroy the window
        resetComponent();
        vm.window && vm.window.close();
      });
    }

    /**
     * @ngdoc function
     * @name save
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description Saves the data.
     * @returns {Promise} A promise that is resolved once all async (saving) operations are completed.
     */
    function save() {
      //if (!vm.roleManager.isValid(true)) {
      //  return;
      //}

      showProgress(true);

      preProcessDataBeforeSave(vm.data, parent)
        .then(function () {
          if (vm.formInvalid) {
            showProgress(false);
            return $q.reject('The form contains invalid data');
          } else {
            return EntityResource.save(vm.data);
          }
        }).then(function (result) {
          showProgress(false);
          if (result && result.m_Item1) {
            //save roles too
            var id = result.m_Item2;
            //vm.roleManager.save(id);

            $timeout(function () {
              showProgress(false);
              onClose();
              $rootScope.$broadcast(CONFIG.projectValuationPortfolioDialogBroadcastSavedMessage, result);
            }, 100); //Give the components some time to render and prepare them in the background to prevent the UI from displaying incomplete markup
          }
          else if (result && !result.m_Item1) {
            alert(result.m_Item2);
          }
          else {
            alert('Unspecified error saving ' + CONFIG.entityName + ' data!');
          }
        }).catch(function (error) {
          showProgress(false);

          if (error === 'The form contains invalid data') {
            if (!vm.suppressFormInvalidMessage) {
              $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'formInvalid', 'simpleMessage.titleSave');
            }
          } else {
            var msg = error.message || JSON.stringify(error);

            console.error(msg);
            alert(msg);
          }
        });
    }

    /**
     * @ngdoc function
     * @name cancel
     * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description Closes the dialog if the user clicked the 'close' button. The user is asked if he really wants to close the dialog before it's actually closed (or left open if the user chose not to close the dialog).
     */
    function cancel() {
      $rootScope.$broadcast("openConfirmDialog", {
        success: function () {
          save();
        },
        cancel: function () {
          $timeout(function () { //Timeout to destroy the window
            resetComponent();
            vm.window && vm.window.close();
            $rootScope.$broadcast(CONFIG.projectValuationPortfolioDialogBroadcastCanceledMessage);
          });
        }
      }, 'confirmDialogForm');
    }

    /**
    * @ngdoc function
    * @name showEditableFields
    * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
    * @description Show editable feilds.
    */
    function showEditableFields() {
      vm.editableFieldsShown = true;

      $rootScope.$broadcast(CONFIG.editprojectValuationPortfolioBroadcastMessageFromMask, vm.editableFieldsShown);
    }

    /**
    * @ngdoc function
    * @name calculateDialogSize
    * @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
    * @description Calculates Dialog Size using vm properties
    * @returns {object} {object} Calculated width of Dialog
    */
    function calculateDialogSize() {
      var width = 1500;
      var areasSum = vm.displayPortfolioCategory + vm.displayAssumptionsCategory + vm.displayStrategyCategory + vm.displayDataLinkingCategory;

      if (areasSum >= 3) {
        width = 1500;
      }
      else if (areasSum === 2) {
        width = 1400;
      }
      else if (areasSum === 1) {
        width = 1300;
      }
      else {
        width = 900;
      }

      return { width: width };
    }

    /**
* @ngdoc function
* @name displayPortfolio
* @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description Shows or hides the portfolio category.
*/
    function displayPortfolio() {
      vm.displayPortfolioCategory = !vm.displayPortfolioCategory;
      windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
* @ngdoc function
* @name displayProject
* @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description Shows or hides the project category.
*/
    function displayProject() {
      vm.displayProjectCategory = !vm.displayProjectCategory;
      windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
* @ngdoc function
* @name displayRole
* @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description Shows or hides the role category.
*/
    function displayRole() {
      vm.displayRoleCategory = !vm.displayRoleCategory;
      windowHelper.center(vm.window, calculateDialogSize());
    }
    /**
* @ngdoc function
* @name displayAssumptions
* @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description Shows or hides the assumptions category.
*/
    function displayAssumptions() {
      vm.displayAssumptionsCategory = !vm.displayAssumptionsCategory;
      windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
* @ngdoc function
* @name displayStrategy
* @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description Shows or hides the streategy category.
*/
    function displayStrategy() {
      vm.displayStrategyCategory = !vm.displayStrategyCategory;
      windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
* @ngdoc function
* @name displayStrategy
* @methodOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description Shows or hides the streategy category.
*/
    function displayDataLinking() {
      vm.displayDataLinkingCategory = !vm.displayDataLinkingCategory;
      windowHelper.center(vm.window, calculateDialogSize());
    }

    function filterTemplatesByLanguage(item, choices, index) {
      return choices.filter(function (template) {
        return vm.data.LanguageId === template.LanguageId;
      });
    }

    function currencyChanged(manually) {
      if (!vm.dataLoadedTimeout || !manually) {
        //Don't react to the initial change events
        return;
      }
      $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'projectValuation.currencyChangedDescription', 'projectValuation.currencyChangedTitle');
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      CONFIG.init && CONFIG.init();
      vm.init && vm.init();
      vm.RUNTIME = RUNTIME;

      if (!vm.isDialog && vm.oid) {
        vm.height = 820;
        vm.loadData(vm.oid, null);
      }
    };

    /**
     * @ngdoc event
     * @name editEntityData
     * @eventOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description
     * Use this event to open the DXSPolluxApp.component:projectValuationPortfolioDialog dialog in edit mode.
     * 
     * @param {Object} event The event - not used
     * @param {number} oid The oid of the Entity that shall be opened
     */
    $scope.$on(CONFIG.editprojectValuationPortfolioDialogBroadcastMessage, function (event, oid) {
      if (!vm.isDialog) return;

      vm.initWindow().then(function () {
        return vm.loadData(oid, null);
      }).catch(function (error) {
        onClose();
        var msg = error.message || JSON.stringify(error);

        console.error(msg);
        alert(msg);
      });
    });

    /**
     * @ngdoc event
     * @name newEntityData
     * @eventOf DXSPolluxApp.component:projectValuationPortfolioDialog
     * @description
     * Use this event to open the DXSPolluxApp.component:projectValuationPortfolioDialog dialog in new mode.
     * 
     * @param {Event} event The event - not used
     * @param {any} parent The parent element to fill data from in new mode
     */
    $scope.$on(CONFIG.newprojectValuationPortfolioDialogBroadcastMessage, function (event, parent) {
      if (!vm.isDialog) return;

      vm.initWindow().then(function () {
        return vm.loadData(-1, parent);//Load the zero entry DTO from the service and adapt it in the dialog. Pass any parent information to loadData so it can be processed there.
      }).catch(function (error) {
        onClose();
        var msg = error.message || JSON.stringify(error);

        console.error(msg);
        alert(msg);
      });
    });

    /**
* @ngdoc event
* @name savetEntityData
* @eventOf DXSPolluxApp.component:projectValuationPortfolioDialog
* @description
* Use this event to save the DXSPolluxApp.component:projectValuationPortfolioDialog
*/
    $scope.$on(CONFIG.saveprojectValuationPortfolioDialogBroadcastMessage, function (event) {
      if (vm.isDialog) return;//dialog (saving new job, calls directly save() - it has "Save" button in dialog)

      save();
    });

    $scope.$on(CONFIG.editprojectValuationPortfolioBroadcastMessage, function (event) {
      vm.editableFieldsShown = true;
    });
    //#endregion
  }
}());
