(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:appToolbarLink
   * @description
   * This component represents an area link in a toolbar. It can be accessed by clicking the small arrow in the top right corner.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of all toolbar area links.
   * 
   * Each toolbar simply reads app/config/AreaDefinitions.json and then creates the links (only those that don't match the current area, of course)
   * 
   * <b>WARNING</b> Areas can be hidden from non-admin users by setting <c>requiresAdminRole</c> to true. However, this doesn't guarantee that users can't bypass this by guessing/knowing the route to the target area.
   * Therefore, it is <b>absolutely required</b> that the targeted areas implement a protection mechanism themselves if they contain confidential/restricted data! <b>Failing to do so might result in the undesired exposure of protected data!</b>
   * 
   * @requires appRouting
   * @requires $translate
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @param {String} target The target area (please refer to the <c>name</c> fields in app.router.component for more details) 
   * @param {String} image Path to the image that should be displayed. Either <c>image</c> or <c>icon</c> can be specified, not both.
   * @param {String} icon Icon CSS class (for example from Kendo UI icons) that should be displayed. Either <c>image</c> or <c>icon</c> can be specified, not both.
   * @param {String} title The title. Should be translated by passing in translated strings, like <c>{{ 'toolbar.reportDesigner' | translate}}</c>
   * @param {String} keyCode Key code description that switches to this area. Does not have any functionality. If it's used, it needs to be implemented in the current main component.
   * @param {String} subtitle1 Subtitle (1st row). Should be translated by passing in translated strings, like <c>{{ 'toolbar.reportDesigner' | translate}}</c>
   * @param {String} subtitle2 Subtitle (2nd row). Should be translated by passing in translated strings, like <c>{{ 'toolbar.reportDesigner' | translate}}</c>
   * @param {String} requiresAdminRole If set to <c>true</c>, this link is only displayed if the current user has admin rule. <b>Please refer to the warning above for more information about security</b>
   */
  angular.module("DXSPolluxApp")
    .component("appToolbarLink", {
      template: require('html-loader!./app.toolbar.link.tmpl.html'),
      bindings: {
        "target": "@",
        "image": "@",
        "icon": "@",
        "title": "@",
        "keyCode": "@",
        "subtitle1": "@",
        "subtitle2": "@",
        "requiresRole": "@"
      },
      controllerAs: "vm",
      controller: ['appRouting', '$translate', 'appStorage', '$rootScope', appToolbarLinkCtrl]
    });

  function appToolbarLinkCtrl(appRouting, $translate, appStorage, $rootScope) {
    var vm = this;

    vm.show = true;

    vm.$onInit = function () {
      vm.titleLabel = $translate.instant(vm.title);
      vm.subtitle1Label = $translate.instant(vm.subtitle1);
      vm.subtitle2Label = $translate.instant(vm.subtitle2);

      if (vm.requiresRole) {
        vm.show = false;
        var roles = vm.requiresRole.split(',');
        for (var i = 0; i < roles.length; ++i) {
          if (appStorage.userIsInRole(roles[i])) {
            vm.show = true;
            break;
          }
        }
      }
    }

    vm.click = function () {
      appRouting.navigate([vm.target]);
    }

    $rootScope.$on('app.navigateToArea', function (event, item) {
      appRouting.navigate([item]);
    });
  }
}());
