import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';

import { environment } from './../../environments/environment';

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v2-samples/angular11-b2c-sample/src/app/b2c-config.ts
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v2-samples/angular11-b2c-sample/src/app/app.module.ts
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.AzureClientId,
      authority: environment.AzureAuthority,
      redirectUri: environment.AzureRedirectUri,
      postLogoutRedirectUri: environment.AzurePostLogoutRedirectUri,
      knownAuthorities: [environment.AzureAuthorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: environment.AzureProtectedResourceMap,
    //authRequest: {
    //    prompt: 'select_account',
    //    extraQueryParameters: {
    //        domain_hint: 'organizations'
    //        //loginHint: 'ct-user'
    //    }
    //}
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.AzureScopes,
      //prompt: 'select_account',
      //extraQueryParameters: {
      //    domain_hint: 'organizations'
      //    //loginHint: 'ct-user'
      //}
    }
  };
}
