import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {forkJoin} from "rxjs";

import { HttpClient } from '@angular/common/http';

import { AngularIntegrationService } from '../../../services/AngularIntegrationService';
import { FilterPaneConfigLoaderService } from './filter-pane-config-loader.service';
import { FilterPaneItemConfig } from './../models/filter-pane-item-config';

@Injectable()
export class FilterPaneService {

  private resetFiltersSubject = new BehaviorSubject(false);
  public resetFilters$ = this.resetFiltersSubject.asObservable();

  private filtersChangedSubject = new BehaviorSubject(null);
  public filtersChanged$ = this.filtersChangedSubject.asObservable();

  private _areaCache: any = {};

  constructor(private ais: AngularIntegrationService, private http: HttpClient, private configLoader: FilterPaneConfigLoaderService) {
  }

  public resetFilters() {
    this.resetFiltersSubject.next(true);
  }

  public undoResetFilters() {
    this.resetFiltersSubject.next(false); //reset flag (important, TODO: do it smarter)
  }

  public filtersChanged(filters) {
    this.filtersChangedSubject.next(filters);
  }

  public resetCache() {
    this._areaCache = {};
  }

  public loadConfig(area: string) {
    return this.configLoader.loadConfig(area);
  }

  public async loadDataForConfig(area: string): Promise<Array<FilterPaneItemConfig>> {
    if (this._areaCache[area]) {
      return Promise.resolve(this._areaCache[area]);
    }

    let items = this.configLoader.loadConfig(area);

    let fetchItems = items.filter(item => item.values === null);

    let calls = fetchItems.map(item => this.http.get(this.ais.getServiceEndpoint(item.api, item.valuesUrl)));

    return new Promise<any>((resolve, reject) => {
      forkJoin(calls)
      .subscribe((results: any) => {
        for (let i = 0; i < results.length; i++) {
          fetchItems[i].values = results[i];
        }

        this._areaCache[area] = items;
        resolve(items);
        
      },
      error => {
        console.log("Failed to load data! (2)", error);
        reject();
      });
    });
   }

  public async getActiveFilterSet(area: string): Promise<any> {
    let calls = [
      this.http.get(this.ais.getServiceEndpoint("marvin.pollux.api", `filterPane/${area}/filterset/active`))
    ];

    return new Promise<any>((resolve, reject) => {
      forkJoin(calls)
      .subscribe((results: any) => {

        resolve(results[0]);
        
      },
      error => {
        console.log("Failed to load data! (2)", error);
        reject();
      });
    });
  }

  public async saveFilterSet(filterSetObj: any, setActive: boolean): Promise<any> {

    const area = filterSetObj.area;
    const postObj = JSON.parse(JSON.stringify(filterSetObj));//clone
    postObj.setActive = setActive;
    let calls = [
      this.http.post(this.ais.getServiceEndpoint("marvin.pollux.api", `filterPane/${area}/filterset/save`), postObj)
    ];

    return new Promise<any>((resolve, reject) => {
      forkJoin(calls)
      .subscribe((results: any) => {

        resolve(results[0]);
        
      },
      error => {
        console.log("Failed to save data! (2)", error);
        reject();
      });
    });
  }

  public appendFilters(kendoFilterObject, filters): number {
    if (!filters) return 0;

    var _filters = [];

    filters.forEach((filterField) => {
      let operator: string = filterField.operator;
      let filterValue: string;

      switch (operator) {
        case "contains_intInArray":
          operator = "contains";
          filterValue = "intInArray:" + filterField.values.map(v => v.oid);
          break;
        case "contains_intInList":
          operator = "contains";
          filterValue = "intInList:" + filterField.values.map(v => v.oid);
          break;
        case "gte_transform":
          filterValue = filterField.values[0].replacer || filterField.values[0].oid;
          break;
        case "eq":
          filterValue = filterField.values[0].oid;
          break;
        case "contains_stringInList":
          operator = "contains";
          filterValue = "stringInList:" + filterField.values.map(v => v.description)
          break;
        case "contains_jobMemberInList_job":
          operator = "contains";
          filterValue = "jobMemberInList:job:" + filterField.values.map(v => v.oid);
          break;
        case "contains_jobMemberInList_property":
          operator = "contains";
          filterValue = "jobMemberInList:property:" + filterField.values.map(v => v.oid);
          break;
        default:
          alert("Operator " + operator + " not supported by filterPane!");
      }

      _filters.push({ field: filterField.field, operator: operator, value: filterValue });
    });

    kendoFilterObject.filters = kendoFilterObject.filters.concat(_filters);
    return _filters.length;
  }

  public async addAndSaveFilter(filterSetObj: any, filterObj) {
    //filterObj like {"field":"LinkedPropertyOid","operator":"contains_intInList","values":[{"oid":"102577","replacer":null}]}

    let filterChanged = false;

    const filters = filterSetObj.filters || [];
    let existingFilter = filters.find(f => f.field === filterObj.field);
    if (!existingFilter) {
      filters.push(filterObj);
      filterChanged = true;
    } else {
      switch (filterObj.operator) {
        case 'contains_intInList':
          /*BB_20210618
           * if same filter already exists (maybe with some different value, f.e. some other property), we add filterObj to values array
           * BUT, as no specification and this woulld probably be "bug" for now we will just replace this filter with passed filter
           * SO: change replaceExistingFilter to false if bug come for this approach (no spec, so everything could be a bug)
          */
          const replaceExistingFilter = true;
          if (replaceExistingFilter) {
            existingFilter.values = filterObj.values;
            filterChanged = true;
          } else {
            for (let idx = 0; idx < filterObj.values.length; idx++) {
              let valueObj = filterObj.values[idx];
              const existingValue = existingFilter.values.find(v => v.oid == valueObj.oid);
              if (!existingValue) {
                existingFilter.values.push(valueObj);
                filterChanged = true;
              }
            }
          }
          break;
        default:
          alert(`addAndSaveFilter: operator ${filterObj.operator} not supported!`);
          return;
      }
    }

    if (filterChanged) {
      let clonedFilterSetObj = JSON.parse(JSON.stringify(filterSetObj));//cloned,so it triggers reload
      let result = await this.saveFilterSet(clonedFilterSetObj, true);/*GenericPolluxResult*/
      if (result.success) {
        this.filtersChanged(clonedFilterSetObj);
      } else {
        alert("Error: " + result.message);
      }
    }
  }

  public async removeAndSaveFilter(filterSetObj: any, filterObj) {
    //filterObj like {"field":"LinkedPropertyOid","operator":"contains_intInList","values":[{"oid":"102577","replacer":null}]}

    let filterChanged = false;

    const filters = filterSetObj.filters || [];
    let existingFilter = filters.find(f => f.field === filterObj.field);
    if (!existingFilter) {
      return;//not in current filters => do nothing
    } else {
      switch (filterObj.operator) {
        case 'contains_intInList':
          /*BB_20211103
            * as in addAndSaveFilter we will just change filter for that field => in this case just remove
          */
          const idx = filters.indexOf(existingFilter);
          filterSetObj.filters.splice(idx, 1);
          filterChanged = true;
          break;
        default:
          alert(`removeAndSaveFilter: operator ${filterObj.operator} not supported!`);
          return;
      }
    }

    if (filterChanged) {
      let clonedFilterSetObj = JSON.parse(JSON.stringify(filterSetObj));//cloned,so it triggers reload
      let result = await this.saveFilterSet(clonedFilterSetObj, true);/*GenericPolluxResult*/
      if (result.success) {
        this.filtersChanged(clonedFilterSetObj);
      } else {
        alert("Error: " + result.message);
      }
    }
  }

}
