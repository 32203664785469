(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProperty
   * @description
   * This component lists Properties in a grid. If an entry is double-clicked, an edit dialog for this entry is shown where the element can be edited and saved.
   * Please note that there is no difference between the properties in the Property Database and the properties in the Valuation Portfolio area. They are just presented in a different way.
   * 
   * @requires DXSPolluxApp.component:projectValuationWorkplace
   * 
   * @param {Object} tabElement Tab element from the workplace
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationProperty", {
      template: require('html-loader!./project.valuation.property.tmpl.html'),
      require: {
        "workplace": "^projectValuationWorkplace"
      },
      bindings: {
        "tabElement": "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$timeout', '$q', 'appLocalization', 'adalAuthenticationService', 'appStorage', 'filterPaneService', 'appServiceHelper', 'reflistResource'
        , projectValuationPropertyCtrl]
    });

  function projectValuationPropertyCtrl($scope, $rootScope, $timeout, $q, appLocalization, adalAuthenticationService, appStorage, filterPaneService, appServiceHelper, reflistResource) {
    var vm = this;

    //#region variable declaration
    vm.grid = null;
    vm.filterByValuationPortfolioId = null;
    vm.areaFilterSettings = [];
    vm.filtersActive = false;
    vm.filterPaneFilters = {};
    vm.isInitialLoad = true;
    vm.choicesForPropertyTypeID = [];

    let filtersChangedSub = filterPaneService.filtersChanged$.subscribe((filters) => {
      if (filters && filters.area === vm.tabElement.searchMode && filters !== vm.filterPaneFilters) {
        vm.filterPaneFilters = filters;
        loadData();
      }
    });

    function processFilterPaneFilters(data) {
      data.filter = data.filter || {
        logic: "and",
        filters: []
      };

      let filtersAddedCount = filterPaneService.appendFilters(data.filter, vm.filterPaneFilters.filters);
      vm.filtersActive = filtersAddedCount > 0;
      broadcastFiltersActiveState();
    }


    vm.gridOptionsDataSource = new kendo.data.DataSource({
      serverPaging: true,
      serverFiltering: true,
      page: 1,
      pageSize: 5,
      transport: {
        read: {
          url: function (context) {
            return appServiceHelper.getServiceEndpointWithProjectGuid('marvin.pollux.projectValuation.api', 'Properties/list/paged', appStorage.getSelectedProject());
          }, beforeSend: function (request, xhr) {
            var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
            if (resource !== null) {
              xhr.withCredentials = true;
              request.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
            }
          },
          xhrFields: {
            withCredentials: true
          }
        },
        parameterMap: function (data, type) {

          processFilterPaneFilters(data);

          if (data.filter) {
            var filterClone = JSON.parse(JSON.stringify(data.filter));
            if (vm.isInitialLoad) {
              vm.isInitialLoad = false;
              filterClone.filters = [{ field: "Oid", operator: "lt", value: 0 }];
            }

            data.filter = kendo.stringify(filterClone);
          }

          return data;
        }
      },
      requestEnd: function (e) {
        var response = e.response;
        var type = e.type;
        if (type === "read") {

          if (response.details) {
            for (var i = 0; i < response.details.length; ++i) {
              if (vm.filterByValuationPortfolioId) {
                var propertyContainedInPortfolio = response.details[i].OccursInValuationPortfolios != null && response.details[i].OccursInValuationPortfolios.indexOf(vm.filterByValuationPortfolioId) >= 0;
                response.details[i].assignedToSelectedPortfolio = propertyContainedInPortfolio;
              }
              else {
                response.details[i].assignedToSelectedPortfolio = false;
              }
            }
          }
          
          vm.workplace.setCounterValue('property', response.total);
          appStorage.setSelectedProperty(null);
        }
      },
      schema: {
        total: "total", // total is returned in the "totalPropertyCount" field of the response
        data: "details" // Grid data is returned in the "details" field of the response
      }
    });

    vm.gridOptions = {
      dataSource: vm.gridOptionsDataSource,
      selectable: "row",
      locale: appLocalization.getLocale(),
      rowTemplate: function (context) {
        return getRowTemplate(context, '');
      },
      altRowTemplate: function (context) {
        return getRowTemplate(context, 'k-alt');
      },
      scrollable: true,
      pageable: {
        refresh: true,
        pageSize: 5,
        pageSizes: [5, 10, 15],
        numeric: false
      },
      columns: getColumns(),
      columnMenu: true,
      filterable: true,
      change: function (e) {
        var grid = e.sender;
        var item = grid.dataItem(grid.select());

        angular.forEach(vm.gridOptionsDataSource.data(), function (element) {
          element.checkbox = false;
        });

        $timeout(function () {

          $rootScope.$broadcast("selectTasksForProperty", item);
          if (item) {
            item.checkbox = true;

            vm.workplace.setCounterValue('property', 1);
          }

          $timeout(function () {
            appStorage.setSelectedProperty(item);
            if (item) {
              vm.workplace.onElementSelected(vm.tabElement, item);
            }
            else {
              vm.workplace.onElementDeselected(vm.tabElement);
            }
          }, 10);
        }, 10);
      }
    };
    //#endregion

    //#region function declaration
    vm.rowClicked = rowClicked;
    vm.filter = filter;
    //#endregion

    //#region function implementation

    /**
    * @ngdoc function
    * @name $onInit
    * @methodOf DXSPolluxApp.component:projectValuationProperty
    * @description
    * Initializes the grid, loads data
    */
    vm.$onInit = function () {
      vm.workplace.onTabCreated(this);
    };

    function initialize(postInitializeFn) {
      vm.initialized = true;
      $q.all([
        filterPaneService.getActiveFilterSet(vm.tabElement.searchMode)
      ]).then(function (results) {
        vm.filterPaneFilters = results[0];
        //vm.areaFilterSettings = results[0];

        loadData();
        vm.enableGrid = true;

        postInitializeFn && postInitializeFn();
      });
    }

    /**
    * @ngdoc function
    * @name $onDestroy
    * @methodOf DXSPolluxApp.component:projectValuationProperty
    * @description
    * Cleanup
    */
    vm.$onDestroy = function () {
      filtersChangedSub && filtersChangedSub.unsubscribe();
    }

    function getColumns() {
      var columnIndex = 0;
      return [
        {
          title: '',
          field: '',
          disableDoubleClick: true,
          template: function (context) {
            var matchingChoices = vm.choicesForPropertyTypeID.filter((entry) => { return entry.oid === context.propertyTypeIdMask; });
            if (matchingChoices && matchingChoices.length) {
              return matchingChoices[0].description;
            }
            return '';
          },
          index: columnIndex++
        },
        {
          title: '',
          field: '',
          disableDoubleClick: true,
          template: function () {
            return '<project-valuation-grid-images area="property" grid-data="dataItem"></project-valuation-grid-images>';
          },
          /*          width: 200,//Determines the ratio in which the components are scaled*/
          index: columnIndex++
        },
        {
          title: '',
          field: '',
          template: function () {
            return '<project-valuation-grid-property-details grid-data="dataItem"></project-valuation-grid-property-details>';
          },
          /*          width: 200,//Determines the ratio in which the components are scaled*/
          index: columnIndex++
        },
        {
          title: '',
          field: '',
          template: function () {
            return '<project-valuation-grid-kpi area="property" grid-data="dataItem"></project-valuation-grid-kpi>';
          },
          /*          width: 100,//Determines the ratio in which the components are scaled*/
          index: columnIndex++
        }
      ];
    }

    /**
     * @ngdoc function
     * @name loadData
     * @methodOf DXSPolluxApp.component:comparablesProperty
     * @description
     * Loads the data by triggering a read operation on the grid's data source
     * 
     * @param {Function} callback Callback that is called once the first page is loaded
     */
    function loadData(callback) {

      if (!appStorage.getSelectedProject()) {
        callback && callback();
        return;
      }
      $q.all([
        reflistResource.loadPollux.query({ reflistName: 'ref_property_type_mask' }).$promise
      ]).then(function (result) {
        vm.choicesForPropertyTypeID = result[0];
        vm.gridOptionsDataSource.read().then(
          function (data) {
            vm.gridOptionsDataSource.page(1);

            if (callback) {
              callback();
            }
          },
          function (error) {
            vm.err = error;
            alert(JSON.stringify(error));
          }
        );
      })
    }

    /**
     * @ngdoc function
     * @name getRowTemplate
     * @methodOf DXSPolluxApp.component:comparablesProperty
     * @description
     * Generates an HTML template for a single row in the grid
     * 
     * @param {Object} context The project that will be displayed in this row
     * @param {String} cssClass CSS class for the entire row. Used to display even and odd row numbers in different colors.
     * @returns {String} The template HTML
     */
    function getRowTemplate(context, cssClass) {
      var ret = '<tr id="comparablesProperty' + context.Oid + '" class="' + cssClass + ' propertyRow">';

      angular.forEach(vm.grid.columns, function (element, index) {
        var doubleClickHandler = element.disableDoubleClick ? '' : 'ng-dblclick="vm.rowClicked($event)"';

        if (!element.hidden) {
          if (typeof element.template === "function") {
            if (element.index === 0) {
              ret += '<td class="grid-first-column"' + doubleClickHandler + '><div class="grid-first-column-text">' + element.template(context) + '</div></td>';
            }
            else if (element.index === 2) {
              ret += '<td class="col-6"' + doubleClickHandler + '>' + element.template(context) + '</td>';
            }
            else {
              ret += '<td class="col-3"' + doubleClickHandler + '>' + element.template(context) + '</td>';
            }
          }
          else {
            ret += '<td ' + doubleClickHandler + '>' + context[element.field] + '</td>';
          }
        }
      });

      ret += '</tr>';
      return ret;
    }

    /**
     * @ngdoc function
     * @name rowClicked
     * @methodOf DXSPolluxApp.component:comparablesProperty
     * @description
     * Called if a row was double-clicked.
     * 
     */
    function rowClicked() {
      var selectedItem = vm.grid.dataItem(vm.grid.select());
      $rootScope.$broadcast("editPropertiesData.inline", { id: selectedItem.Oid, data: selectedItem });
    }

    /**
     * @ngdoc function
     * @name findDataItem
     * @methodOf DXSPolluxApp.component:comparablesProperty
     * @description
     * Highlights the property with the specified OID in the grid.
     * 
     * @param {Integer} desiredOid The property's OID
     */
    function findDataItem(desiredOid) {
      //Determine the page and select the item!
      var ds = vm.grid.dataSource;

      var view = kendo.data.Query.process(ds.data(), {
        filter: ds.filter(),
        sort: ds.sort()
      }).data;

      var index = -1;
      for (var x = 0; x < view.length; x++) {
        if (view[x].Oid == desiredOid) {
          index = x;
          break;
        }
      }

      if (index === -1) {
        return;
      }

      var page = Math.floor(index / vm.grid.dataSource.pageSize());
      //page is 1-based index    
      vm.grid.dataSource.page(++page);

      vm.grid.select("#comparablesProperty" + desiredOid);
    }

    /**
     * @ngdoc function
     * @name filter
     * @methodOf DXSPolluxApp.component:comparablesValuation
     * @description
     * Called if a BO was selected in any tab of the current area. If it's a property, only the valuations for this property will be displayed.
     * 
     * @param {Object} item The item that was selected
     * @param {Integer} selectedHierarchy The tab hierarchy of the tab where the item was selected
     * @param {Array} tabConfig The current area's tab configuration containing all tabs. selectedHierarchy can be used to filter the current config.
     * @param {Bool} reset true if the 'show all' action was chosen
     */
    function filter(item, selectedHierarchy, tabConfig, reset) {
      vm.filterByValuationPortfolioId = null;
      const noFilter = {
        logic: "and",
        filters: []
      };

      if (reset || selectedHierarchy == null) {
        vm.gridOptionsDataSource.filter(noFilter);
      } else {
        var filteredTab = tabConfig[selectedHierarchy];
        switch (filteredTab.searchMode) {
          case 'projectstd': {
            const projectSelectedFn = () => {
              vm.gridOptionsDataSource.data([]);
              vm.gridOptionsDataSource.filter(noFilter);
            };

            if (!vm.initialized) {
              initialize();
            } else {
              projectSelectedFn();
            }
            break;
          }
          case 'valuationPortfolio': {
            vm.filterByValuationPortfolioId = item.Oid;
            filterPaneService.addAndSaveFilter(vm.filterPaneFilters, { field: "Oid", operator: "contains_intInList", values: [{ oid: item.LinkedPropertyOid }] });
            break;
          }
        }
      }
    }


    function broadcastFiltersActiveState() {
      $rootScope.$broadcast("filtersActiveState", { area: vm.tabElement.searchMode, active: vm.filtersActive });
    }

    //#region events   

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      // the event is emitted for every widget; if we have multiple
      // widgets in this controller, we need to check that the event
      // is for the one we're interested in.
      if (widget === vm.grid) {
        //grid is created
        angular.element("#tabProperty-component .k-pager-refresh").on('click', function (e) {
          loadData();
        });
      }
    });

    $scope.$on('valuationProjectReportItemSaved', function (e, item) {
      loadData();
    });

    $scope.$on('valuationPropertyItemSaved', function (e, item) {
      var savedPropertyId = item.m_Item2;
      loadData(function () {
        $timeout(function () {
          findDataItem(savedPropertyId);
        }, 10);
      });
    });

    $scope.$on('buttonDeleteProperty', function (e, item) {
      var selectedItem = vm.grid.dataItem(vm.grid.select());
      if (selectedItem) {
        $rootScope.$broadcast('deleteProperty', selectedItem);
      }
      else {
        $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'errorMessage.chooseProperty', 'simpleMessage.titleDelete');
      }
    });

    $scope.$on('propertiesDataSaved', function (e, item) {
      var savedPropertyId = item.m_Item2;
      loadData(function () {
        $timeout(function () {
          findDataItem(savedPropertyId);
        }, 10);
      });
    });

    $scope.$on('PropertyLoadData', function (event, item) {
      loadData();
    });

    $scope.$on('project.valuation.property.search', function (event, item) {
      vm.gridOptionsDataSource.filter(item);
    });

    $scope.$on('broadcastFiltersActiveState', function (event, params) {
      if (params.area === vm.tabElement.searchMode) {
        broadcastFiltersActiveState();
      }
    });

    $scope.$on('filterPane:clearGridSelection', function (e) {
      vm.grid.clearSelection();
    });

  }
}());
