import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Tour } from '../model/tour';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HelpTextService {

  /**
   * Handles the visiblity of the Help text window
   *
   * */
  private _visible = new BehaviorSubject<boolean>(false);

  public visible$ = this._visible.asObservable();

  /**
   * The current Tour
   *
   * */
 // private _tour = new BehaviorSubject<Tour[]>(null);

 // public tour$ = this._tour.asObservable();

  constructor(private http: HttpClient) { }

  private genericHttpGet(url: string): Promise<any> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      self.http.get(url)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * Return All available tours. 
   * @return  Tour[] 
   */
  public getAllTours(): Promise<Tour[]> {
    const url = `${environment.DMS_HELPTEXT_API_URL}/tour/list`;
    return this.genericHttpGet(url);
     
  }

  /**
   * Sets the given visiblity. 
   * @param visiblity
   */
  public changeVisibility(visiblity: boolean) {
    this._visible.next(visiblity);
  }

  /**
   * Filters for a list of Tour if it is visibile. 
   * @param data
   */
  public filterOnlyVisible(data: Tour[]): Tour[] {
    return data.filter(entry => {
      const element = document.getElementById(entry.tourId);
      if (element) {
        return window.getComputedStyle(element).display !== 'none';
      }
      return false;
    });
  }
}
