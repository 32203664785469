import { Component, Input, AfterViewInit, ElementRef, SimpleChanges } from '@angular/core';
import * as zc from '@dvsl/zoomcharts';
import { WindowRefService } from "../window-ref";

@Component({
  selector: 'app-geocharts',
  templateUrl: './geo-charts.component.html',
  styleUrls: ['./geo-charts.component.scss']
})

export class GeochartsComponent implements AfterViewInit {

  // for optimal operation: ref to zc instance
  private zc: any = zc;
  public _chartID: string;
  private chartOptions: any;
  private chartInstance: any;

  //leaflet instance
  private L: any = window["L"];

  private initialized: boolean = false;

  @Input() id: string;
  @Input() area: string;
  @Input() description: string;
  @Input() latitude: number;
  @Input() longitude: number;

  @Input() enteredarea: string;

  static counter: number = 1;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.initialized && changes.enteredarea && this.area === changes.enteredarea.currentValue) {
      this.tryInitWithVisibleElem(this);
    }
  }

  constructor(winRef: WindowRefService, private elRef: ElementRef) {
    winRef.nativeWindow.ZoomChartsLicense = "ZCP-he5p70237:  ZoomCharts SDK license for 3 Chart Developers";
    winRef.nativeWindow.ZoomChartsLicenseKey = "33e5694b13267f9859017a44420f5959ee8cde847c63f7794e" +
      "8998a4a218d3dd4ba67e84474c0dedd90210668d98e97ef350b3dedfc4a64e466c6254d15db88" +
      "6b92f31adda725e1019af6570ea8d3b432ec7d28a7f5fff781a735fe72c5d336557b2308e8d22" +
      "1fa9f40043758cfab6acce1109c7d30868d31001add291c9fbb8f0fe8342d3fd8c9b2d02c3798" +
      "68a039756d173548950fc5986a320630a942fad209c8531b7749ffda661a87dad0ce621a6e357" +
      "e8a51763a256c35a1fa324fb6e5e190708234cf9d8de32d22f2f8020f4cd34677661d66aa0e7a" +
      "17cb79d53d09fb1b181bdfa81543569321a4cc20156c6955e30f6b935c576421524e3f9122100";
  }

  ngOnInit() {
    this._chartID = 'geo-chart-' + this.area + '-' + this.id + '-' + GeochartsComponent.counter++;
    this.elRef.nativeElement.addEventListener('wheel', (e) => this.onWheel(e), true);
  }

  ngOnDestroy() {
    this.elRef.nativeElement.removeEventListener('wheel', this.onWheel);
  }

  ngAfterViewInit() {
    this.initWithVisibleElem();
  }

  private tryInitWithVisibleElem(self: any) {
    self.initWithVisibleElem();
    if (!self.initialized) {
      setTimeout(() => self.tryInitWithVisibleElem(self), 50);
    }
  }

  private initWithVisibleElem() {
    var elem = document.getElementById(this._chartID);
    if (elem.clientHeight === 0) {
      return;
    }

    this.initialized = true;

    const GeoChart = this.zc.GeoChart;
    let self = this;

    this.chartOptions = {
      area: {
        height: 340
      },
      container: elem,
      data: [
        {
          id: "defaults",
          preloaded: {
            nodes: [
              {
                id: this.description,
                coordinates: [
                  this.longitude,
                  this.latitude
                ]
              }
            ]
          }
        }
      ],
      layers: [
        {
          id: "defaults",
          type: "items",
          data: {
            id: "defaults"
          },
          style: {
            node: {
              fillColor: "#007288",
              lineColor: "#007288",
              display: "droplet",
              radius: 10
            }
          }
        }
      ],
      navigation: {
        initialLat: this.latitude,
        initialLng: this.longitude,
        initialZoom: 10,
        minZoom: 4
      },
      // setting background.enabled=false will disable the default built-in map layer.
      // the background will be rendered using the custom plugins initialized below.
      background: {
        enabled: false
      }
    };

    this.chartInstance = new GeoChart(this.chartOptions);
    self.createGoogleLayer(null, self);

    window.setTimeout(() => this.chartInstance.updateSize(), 0); //BUG 3030
  }

  private onWheel(e) {
    var ctrlKeyPressed = e.ctrlKey;
    if (!ctrlKeyPressed) {
      e.stopPropagation();
    }
  }

  private googleLayerCreated = false;

  private createGoogleLayer(evt: any, self: any) {
    if (!self.googleLayerCreated && self.chartInstance) {
      // note that .leaflet() is an experimental API that is subject to change.
      // retrieve the Leaflet map object used internally by GeoChart
      var map = self.chartInstance.leaflet();

      // the chart is still in progress of loading Leaflet.
      if (!map) return;

      self.googleLayerCreated = true;

      let L: any = self.L;

      var gglTerrain = new L.gridLayer.googleMutant({ type: "terrain" });
      var gglSatellite = new L.gridLayer.googleMutant({ type: "satellite" });

      map.addLayer(gglTerrain);

      // add the default Leaflet control that allows switching maps
      map.addControl(new L.Control.Layers({
        'Google Terrain': gglTerrain,
        'Google Satellite': gglSatellite
      }, {}));
    }
  }

}
