import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { MultiDashboardComponent } from './multi-dashboard.component';
import { MultiDashboardService } from './services/multi-dashboard-service.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SynapsesDashboardModule } from 'qv-dashboard-lib/projects/qv-dashboard-lib/src/public_api';

export const routerModule = RouterModule.forChild([{
  path: '',
  component: MultiDashboardComponent
}]);

@NgModule({
  declarations: [MultiDashboardComponent, DashboardComponent],
  imports: [
    CommonModule,
    FormsModule,
    SynapsesDashboardModule,
    routerModule
  ],
  providers: [
    MultiDashboardService
  ]
})
export class MultiDashboardModule { }
