import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'date',
    pure: false
})
export class LocalizedDate implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    public transform(value: any, pattern: string = 'shortDate'): any {
        let ngPipe = new DatePipe(this.translate.currentLang || this.translate.defaultLang);
        return ngPipe.transform(value, pattern);
    }
}
