(function () {
  'use strict';

  angular
    .module('DXSPolluxApp')
    .factory('appBusyIndicatorService', [appBusyIndicatorService]);

  function appBusyIndicatorService() {
    function setVisible(visible, domElement) {
      kendo.ui.progress($(domElement) || angular.element('body'), visible);
    }

    return {
      setVisible: setVisible
    };
  }
}());
