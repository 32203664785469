(function () {
  'use strict';

  //Doc common.services index page
  /**
    * @ngdoc overview
    * @name common.services
    * @description The common.services module contains all 
    */
  angular
    .module('common.services',
      ['ngResource']);

  /**
   * @ngdoc object
   * @name common.services.object:appSettings
   * @description
   * Stores the application configuration. The settings are loaded from app/config/AppConfig.json when the application is started
   */
  angular
    .module('common.services')
    .constant('appSettings',
      {
        /**
         * @ngdoc property
         * @name common.services.object:appSettings.#version
         * @propertyOf common.services.object:appSettings
         * @description The application version string
         */
        version: "",

        /**
        * @ngdoc property
        * @name common.services.object:appSettings.#useMockup
        * @propertyOf common.services.object:appSettings
        * @description Enables the use of mockup data. Disables in productive use.
        */
        useMockup: false,

        /**
        * @ngdoc property
        * @name common.services.object:appSettings.#serverPath
        * @propertyOf common.services.object:appSettings
        * @description Path to the Pollux API service
        */
        serverPath: '',

        polluxApiUrl: '',
        settingsApiUrl: '',
        comparablesApiUrl: '',
        projectMainApiUrl: '',
        projectValuationApiUrl: '',
        dmsIoApiUrl: '',

        environment: {}
      })
}());
