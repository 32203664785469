(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridPropertyDetails
   * @description
   * Displays the property details
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridPropertyDetails", {
      template: require('html-loader!./project.valuation.grid.property.details.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "gridData": "<"
      },
      controllerAs: "vm",
      controller: [projectValuationGridPropertyDetailsCtrl]
    });

  function projectValuationGridPropertyDetailsCtrl() {
    var vm = this;
    //#region variables 

    //#endregion

    //#region function declaration
    //#endregion

    //#region function implementation
    
    //#endregion

    //#region events

    //#endregion
  }
}());

