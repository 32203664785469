(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationImportResource
     * @description
     * This service is responsible for importing ARGUS files.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationImportResource',
        ['$resource', 'appServiceHelper', projectValuationImportResource]);

    function projectValuationImportResource($resource, appServiceHelper) {
        return {

            /**
             * @ngdoc function
             * @name importSetup
             * @methodOf DXSPolluxApp.service:projectValuationImportResource
             * @description
             * Performs the launcher setup. This creates a copy of the original AE Tool and creates directories where the MV and VPV directories will be uploaded.
             * 
             * Route: api/v2/argusImport/SetupLauncher
             * 
             * @returns {Object} A SetupLauncherResult that contains the paths of the copied AE tool and the MV and VPV file upload directories
             */
            importSetup: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'argusImport/SetupLauncher'), {}),

            /**
            * @ngdoc function
            * @name uploadFile
            * @methodOf DXSPolluxApp.service:projectValuationImportResource
            * @description
            * Uploads one or more files into the directory specified by the path parameter. This method is called separately for the MV and VPV file(s)
            * 
            * Route: api/v2/argusImport/UploadFile
            *
            * @param {String} path The path where all the files will be uploaded
            * 
            * @returns {String} A blank string
            */
            uploadFile: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'argusImport/UploadFile'), { path: '@path' }, {
                save: {
                    method: 'POST',
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                }
            }),

           /**
            * @ngdoc function
            * @name processImportFile
            * @methodOf DXSPolluxApp.service:projectValuationImportResource
            * @description
            * This method is called after the AE tool was run on the client side. Now, the values provided by the AE Tool are read and put into regular import templates. These templates are not imported yet.
            * 
            * Route: api/v2/argusImport/ProcessImportFile
            * 
            * @returns {Object} A ProcessImportFileResult that contains the processed values and the paths to the resulting files.
            */
            processImportFile: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'argusImport/ProcessImportFile'), {}),

            /**
            * @ngdoc function
            * @name importArgusData
            * @methodOf DXSPolluxApp.service:projectValuationImportResource
            * @description
            * Puts the values from the request into import templates and imports these. Before this, a rights check (BU) is performed and it's also impossible to import into finalized reports.
            * 
            * Route: api/v2/argusImport/ImportArgusData
            * 
            * @returns {Object} A Tuple indicating success or failure. If Item1 is false, Item2 contains a more detailed error message.
            */
            importArgusData: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'argusImport/ImportArgusData'), {})

            
        }
    }
}());
