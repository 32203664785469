import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import * as zc from '@dvsl/zoomcharts';
import { WindowRefService } from '../../_qv/zoom-charts/window-ref';

@Component({
  selector: 'job-data-linking',
  templateUrl: './job-data-linking.component.html',
  styleUrls: ['./job-data-linking.component.css']
})
export class JobDataLinkingComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  public _chartID: string;
  public _data: any;
  public chartInstance: any;
  public width: number = 0;
  public height: number = 0;
  public zc: any = zc;
  public hasData = false;

  @ViewChild('chart') chartDiv: ElementRef;

  @Input() data: any[];

  constructor(winRef: WindowRefService) {
    winRef.nativeWindow.ZoomChartsLicense = "ZCP-he5p70237:  ZoomCharts SDK license for 3 Chart Developers";
    winRef.nativeWindow.ZoomChartsLicenseKey = "33e5694b13267f9859017a44420f5959ee8cde847c63f7794e" +
      "8998a4a218d3dd4ba67e84474c0dedd90210668d98e97ef350b3dedfc4a64e466c6254d15db88" +
      "6b92f31adda725e1019af6570ea8d3b432ec7d28a7f5fff781a735fe72c5d336557b2308e8d22" +
      "1fa9f40043758cfab6acce1109c7d30868d31001add291c9fbb8f0fe8342d3fd8c9b2d02c3798" +
      "68a039756d173548950fc5986a320630a942fad209c8531b7749ffda661a87dad0ce621a6e357" +
      "e8a51763a256c35a1fa324fb6e5e190708234cf9d8de32d22f2f8020f4cd34677661d66aa0e7a" +
      "17cb79d53d09fb1b181bdfa81543569321a4cc20156c6955e30f6b935c576421524e3f9122100";
  }


  ngOnInit() {
    const self = this;

    self._chartID = 'chart-JobDataLinking';

    self._data = {
      nodes: [],
      links: []
    };

    //Add the jobs
    for (let i = 0; i < self.data.length; ++i) {
      const jobData = self.data[i];
      let className = 'undefined';
      switch (jobData.Type) {
        case 1: {
          className = 'cre';
          break;
        }
        case 2: {
          className = 'val';
          break;
        }
      }
      const node: any = {
        id: jobData.Oid,
        className: className,
        hierarchyLevel: i,
        style: {
          label: jobData.Name
        }
      };
      if (jobData.IsCurrent != 0) {
        node.className += '_current';
      }
      self._data.nodes.push(node);
    }

    //Add the links
    for (let i = 0; i < self.data.length; ++i) {
      const jobData = self.data[i];
      if (jobData.ParentOid) {
        self._data.links.push({
          id: "inheritance_" + jobData.Oid + "_" + jobData.ParentOid,
          to: jobData.Oid, //Set parent as 'from' because it's the root
          from: jobData.ParentOid,
          className: "inheritance"
        });

        if (jobData.UpdateResultsFromParent) {
          self._data.links.push({
            id: "results_" + jobData.Oid + "_" + jobData.ParentOid,
            to: jobData.Oid,
            from: jobData.ParentOid,
            className: "results"
          });
        }

        if (jobData.UpdateRentRollFromParent) {
          self._data.links.push({
            id: "rentroll_" + jobData.Oid + "_" + jobData.ParentOid,
            to: jobData.Oid,
            from: jobData.ParentOid,
            className: "rentroll"
          });
        }
      }
    }

  }

  ngAfterViewInit() {
    const self = this;
    const NetChart = self.zc.NetChart;

    let config = {
      container: self.chartDiv.nativeElement,
      area: {
        style: { fillColor: "#ffffff" },
        height: 450
      },
      layout: {
        mode: "hierarchy",
        nodeSpacing: 60, // horizontal spacing between nodes
        rowSpacing: 200 // vertical spacing between node rows in the hierarchy layout
      },
      style: {
        nodeClasses: [
          { className: "undefined", nameLegend: 'Unknown Job', showInLegend: false, style: { fillColor: "#FF0000" } },
          { className: "cre", nameLegend: 'CRE Job', style: { fillColor: "#F67599" } },
          { className: "cre_current", nameLegend: 'CRE Job (current)', showInLegend: false, style: { fillColor: "#F67599", shadowBlur: 5, shadowColor: 'black' } },
          { className: "val", nameLegend: 'VAL Job', style: { fillColor: "#A8D4B7" } },
          { className: "val_current", nameLegend: 'VAL Job (current)', showInLegend: false, style: { fillColor: "#A8D4B7", shadowBlur: 5, shadowColor: 'black' } }
        ],
        linkClasses: [
          { className: "inheritance", nameLegend: "Parent Job", style: { fillColor: "#D9306B", toDecoration: null, fromDecoration: "arrow" } },
          { className: "rentroll", nameLegend: "Update Job Rent Roll", style: { fillColor: "#FFD35C", toDecoration: "arrow" } },
          { className: "results", nameLegend: "Update Job Results", style: { fillColor: "#22A498", toDecoration: "arrow" } }
        ]
      },
      data: {
        preloaded: self._data
      },
      legend: {
        height: 120,
        maxLineSymbols: 5,
        numberOfColumns: 5,
        lineSpacing: 0,
        numberOfRows: 1,
        padding: 6,
        margin: 0,
        enabled: true,
        mode: 'highlight',
        marker: {
          alinment: 'left',
          size: 10
        }
      },
      toolbar: {
        enabled: false
      }
    };
    self.chartInstance = new NetChart(config);
  }

  ngOnDestroy() {
    this.chartInstance.remove();
    this.chartInstance = null;
  }

  ngAfterViewChecked() {
    if (this.chartDiv.nativeElement.clientWidth != this.width || this.chartDiv.nativeElement.clientHeight != this.height) {
      this.width = this.chartDiv.nativeElement.clientWidth;
      this.height = this.chartDiv.nativeElement.clientHeight;
      this.chartInstance.updateSize();
    }
  }
}
