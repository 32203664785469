import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable()
export class QvPropertyImagesService {

  constructor(private http: HttpClient) { }

  public clear() {
    //Nothing
  }

  public loadInfo(propertyId: number): Observable<any> {
    const subject = new Subject<any[]>();
    const actionUrl = `${environment.DMS_IO_URL}/pictures/info?propertyId=${propertyId}`;
    this.http
      .get(actionUrl).subscribe((response: any) => {
        subject.next(response);
      });

    return subject.asObservable();
  }

  public load(propertyId: number, width: number, height: number): Observable<any> {
    const subject = new Subject<any[]>();
    const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/dataurl?propertyId=${propertyId}&width=${width}&height=${height}`;
    this.http
      .get(actionUrl).subscribe((response: any) => {
        subject.next(response);
      });

    return subject.asObservable();
  }

  public loadImageDataUrl(pictureId: number, width: number, height: number): Observable<any> {
    const subject = new Subject<any>();
    const actionUrl = `${environment.DMS_IO_URL}/pictures?oid=${pictureId}&width=${width}&height=${height}`;
    this.http
      .get(actionUrl, { responseType: 'blob' }).subscribe((response: any) => {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          const result = reader.result;
          subject.next(result);
        }, false);
        if (response) {
          reader.readAsDataURL(response);
        }
      });

    return subject.asObservable();
  }

  public loadImage(propertyId: number, pictureId: number, width: number, height: number, setQualityWarnings: boolean): Observable<any> {
    const subject = new Subject<any[]>();
    const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/dataurl?propertyId=${propertyId}&width=${width}&height=${height}&pictureId=${pictureId}&setQualityWarnings=${setQualityWarnings}`;
    this.http
      .get(actionUrl).subscribe((response: any) => {
        subject.next(response);
      });

    return subject.asObservable();
  }

  public loadOriginalSizeImage(propertyId: number, pictureId: number): Observable<any> {
    const subject = new Subject<any[]>();
    this.http
      .get(`${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/dataurl?propertyId=${propertyId}&pictureId=${pictureId}`).subscribe((response: any) => {
        subject.next(response);
      });

    return subject.asObservable();
  }

  public save(picture: any) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = environment.POLLUX_PROJECTVALUATION_API_URL + '/picturesexport/pictures/save';

      self.http.post(actionUrl, picture)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          resolve(res);
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public delete(oids: Array<number>) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = environment.POLLUX_PROJECTVALUATION_API_URL + '/picturesexport/pictures/delete/multiple';

      self.http.post(actionUrl, oids)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          resolve(res);//array of deleted images
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public reindex(propertyId: number, oids: Array<number>) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/reindex/${propertyId}`;

      self.http.post(actionUrl, oids)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          resolve(res);//GenericPolluxResult
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public updateType(oid: number, type: number) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/update/type?oid=${oid}`;

      self.http.post(actionUrl, type)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public updateCustomType(oid: number, type: string) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/update/type/custom?oid=${oid}`;

      self.http.post(actionUrl, { value: type })
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public updateDescription(oid: number, description: string) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/update/description?oid=${oid}`;
      const postObj = {
        description: description
      }

      self.http.post(actionUrl, postObj)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public updateComment(oid: number, comment: string) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/update/comment?oid=${oid}`;
      const postObj = {
        comment: comment
      }

      self.http.post(actionUrl, postObj)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public getReflist(reflistName: string, locale: string): Promise<any[]> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_API_URL}/reflist/pollux?reflistName=${reflistName}&localeOverride=${locale}`;

      self.http.get(actionUrl)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public disableQualityWarnings(pictureId: number) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/disableQualityWarnings/${pictureId}`;

      self.http.post(actionUrl, {})
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          resolve(res);//GenericPolluxResult
        },
          error => {
            reject(error);
          }
        );
    });
  }

}
