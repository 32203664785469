(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxPostcodeField
   * @description
   * This component provides the behavior and visual representation of a single select field. It is rendered as a Kendo UI kendo-drop-down-list.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxStreetnoField", {
      template: require('html-loader!./pollux.streetno.field.tmpl.html'),
      bindings: {
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "countryId": '<',
        "city": '<',
        "street": '<',
        "ngId": "@",
        "updatePostalCode": "<",
        "postalCodeTarget": "="
      },
      controllerAs: "vm",
      controller: ['$timeout', 'appServiceHelper', 'adalAuthenticationService', 'appStorage', 'appLocalization', polluxStreetnoFieldCtrl]
    });

  function polluxStreetnoFieldCtrl($timeout, appServiceHelper, adalAuthenticationService, appStorage, appLocalization) {
    var vm = this;

    //#region variable declaration
    vm.initialCall = true;

    vm.dataSource = new kendo.data.DataSource({
      serverFiltering: true,
      transport: {
        read: {
          url: function (context) {
            const selectedProject = appStorage.getSelectedProject();
            const organizationGuid = appStorage.getOrganizationGuid();
            const zeroGuid = '00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000';
            let projectGuid;
            if (selectedProject) {
              projectGuid = selectedProject.ProjectGuid;
            }
            else {
              projectGuid = zeroGuid;
            }

            var api = "marvin.pollux.api";
            let ret;

            if (!vm.ngModel && (!context.filter || !context.filter.filters || !context.filter.filters.length || !context.filter.filters[0].value)) {
              ret = appServiceHelper.getServiceEndpoint(api, 'location/autocomplete/streetno');
            }
            if (context.filter.filters.length > 0) {
              ret = appServiceHelper.getServiceEndpoint(api, 'location/autocomplete/streetno?searchTerm=' + context.filter.filters[0].value + '&countryId=' + (vm.countryId || 0) + '&city=' + (vm.city || '') + '&street=' + (vm.street || ''));
            }
            if (ret) {
              ret = ret.split(zeroGuid).join(`${organizationGuid}/${projectGuid}`);
            }
            return ret;
          }, beforeSend: function (request, xhr) {
            var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
            if (resource !== null) {
              xhr.withCredentials = true;
              request.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
            }
            request.setRequestHeader('X-PolluxLanguage', appLocalization.getLocale());
          },
          xhrFields: {
            withCredentials: true
          },
          parameterMap: function (data, type) {
            return "";
          }
        },
        parameterMap: function (data, type) {
          return "";
        }
      }
    });

    vm.options = {
      dataTextField: "streetNo",
      dataValueField: "streetNo",
      filter: "contains",
      valuePrimitive: true,
      delay: 500,
      autoBind: false,
      minLength: 1,
      change: onNgChange,
      template: function (data) {
        let ret = '<span class="k-state-default">';
        if (data.streetNo) {
          if (data.warnNotFound) {
            ret += `<h3>${data.street} <span style="color: orange;">${data.streetNo}</span></h3>`;
          }
          else {
            ret += `<h3>${data.street} ${data.streetNo}</h3>`;
          }
        }
        else {
          ret += `<h3>${data.street}</h3>`;
        }

        if (data.postalCode) {
          ret += `<p>${data.postalCode} ${data.city}, ${data.country}</p>`;
        }
        else {
          ret += `<p>${data.city}, ${data.country}</p>`;
        }
        ret += '</span>';
        return ret;
      },
      select: function (e) {
        if (vm.updatePostalCode && e.dataItem && e.dataItem.postalCode) {
          vm.postalCodeTarget = e.dataItem.postalCode;
        }
      }
    };

    //#endregion

    //#region function declaration

    //#endregion

    //#region function implementation
    function onNgChange() {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.initialCall && vm.filter && vm.filter()) {
          vm.initialCall = false;
          return;
        }

        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.options.enabled = !vm.ngReadonly;

      if (vm.ngModel) {
        vm.dataSource.read().then(function () {
          vm.element.value(vm.ngModel);
          vm.element.refresh();
          onNgChange();
        });
      }
      else {
        onNgChange();
      }
    }
    //#endregion
  }
}());

