(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationRoleManagement
   * @description
   * Displays a counter card that shows the amount of projects, valuation portfolios, reports and properties that are currently displayed in all grids.
   * 
   * @requires $scope
   * @requires $rootScope
   * 
   * @param {Object} linkCounter Contains the counter values. This object comes from the projectValuationMain component. It must have this format:
   * 
   *     {
   *         property: 1,
   *         report: 2,
   *         valuationPortfolio: 3,
   *         project: 4
   *     }
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationRoleManagement", {
      template: require('html-loader!./project.valuation.role.management.tmpl.html'),
      bindings: {
        "roleManager": "@"
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$translate', '$q', '$timeout', 'ValuationPortfoliosResource', 'reflistResource', 'adalAuthenticationService', projectValuationRoleManagementCtrl]
    });

  function projectValuationRoleManagementCtrl($scope, $rootScope, $translate, $q, $timeout, ValuationPortfoliosResource, reflistResource, adalAuthenticationService) {
    var vm = this;

    vm.$onInit = function () {
    }

    vm.availableUsers = [];
    vm.availableRoles = [];
    vm.gridRoleManagement = null;
    vm.teamSelector = null;
    vm.originalRoleAssignmentData = [];

    vm.valuationProjectId = 0;
    vm.selectedUserId = 0;
    vm.userWorkload = null;

    vm.$onInit = function () {
      $scope.$parent.vm[vm.roleManager] = {
        isValid: isValid,
        load: load,
        save: save
      };
    }

    vm.availableUsersDataSource = new kendo.data.DataSource({
      data: []
    });

    vm.teamOptions = {
      dataTextField: "description",
      dataValueField: "oid",
      dataSource: vm.availableUsersDataSource,
      filter: "contains",
      change: onTeamSelectionChanged
    };

    vm.teamUsers = [];

    vm.gridOptionsRoleManagementDataSource = new kendo.data.DataSource({
      data: [],
      page: 1,
      pageSize: 25,
      transport: {
        read: function (e) {
          e.success(vm.gridOptionsRoleManagementDataSource.data());
        },
        update: function (e) {
          e.success();
        },
        create: function (e) {
          e.success(e.data);
        }, beforeSend: function (request, xhr) {
          var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
          if (resource !== null) {
            xhr.withCredentials = true;
            request.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
          }
        }
      }
    });

    vm.gridOptionsRoleManagement = {
      dataSource: vm.gridOptionsRoleManagementDataSource,
      selectable: "row",
      resizable: true,
      editable: true,
      toolbar: ["create"],
      columnMenu: {
        messages: {
          sortAscending: $translate.instant('projectValuation.sortAsc'),
          sortDescending: $translate.instant('projectValuation.sortDesc'),
          columns: $translate.instant('projectValuation.columns'),
          filter: $translate.instant('projectValuation.filter'),
        }
      },
      pageable: {
        pageSize: 25,
        pageSizes: [10, 25],
        numeric: false,
        messages: {
          display: $translate.instant('projectValuation.total') + "{2}",
          empty: $translate.instant('projectValuation.noData'),
          itemsPerPage: $translate.instant('projectValuation.itemsPerPage'),
        }
      },
      filterable: {
        messages: {
          and: $translate.instant('projectValuation.and'),
          clear: $translate.instant('projectValuation.cancel'),
          filter: $translate.instant('projectValuation.apply'),
          info: $translate.instant('projectValuation.displayedDataset'),
          isFalse: $translate.instant('projectValuation.no'),
          isTrue: $translate.instant('projectValuation.yes'),
          or: $translate.instant('projectValuation.or'),
          cancel: $translate.instant('projectValuation.cancel'),
          operator: $translate.instant('projectValuation.searchOperator')
        },
        operators: {
          string: {
            eq: $translate.instant('projectValuation.isEqual'),
            neq: $translate.instant('projectValuation.IsnotEqual'),
            isnull: $translate.instant('projectValuation.isNull'),
            isnotnull: $translate.instant('projectValuation.isNotNull'),
            isnotempty: $translate.instant('projectValuation.isnotempty'),
            startswith: $translate.instant('projectValuation.startsWith'),
            contains: $translate.instant('projectValuation.contain'),
            doesnotcontain: $translate.instant('projectValuation.notContain'),
            endswith: $translate.instant('projectValuation.endsWith')
          },
          number: {
            eq: $translate.instant('projectValuation.isEqual'),
            neq: $translate.instant('projectValuation.IsnotEqual'),
            isnull: $translate.instant('projectValuation.isNull'),
            isnotnull: $translate.instant('projectValuation.isNotNull'),
            gte: $translate.instant('projectValuation.isBiggerEqual'),
            gt: $translate.instant('projectValuation.isBigger'),
            lte: $translate.instant('projectValuation.isSmallerEqual'),
            lt: $translate.instant('projectValuation.isSmaller')
          },
          date: {
            eq: $translate.instant('projectValuation.isEqual'),
            neq: $translate.instant('projectValuation.IsnotEqual'),
            isnull: $translate.instant('projectValuation.isNull'),
            isnotnull: $translate.instant('projectValuation.isNotNull'),
            gte: $translate.instant('projectValuation.isAfterOrOn'),
            gt: $translate.instant('projectValuation.isAfter'),
            lte: $translate.instant('projectValuation.isBeforeOrAfter'),
            lt: $translate.instant('projectValuation.isBefore')
          }
        }
      },
      change: function (e) {
        var grid = e.sender;
        var item = grid.dataItem(grid.select());

        var selectedUserId = item && item.userId ? item.userId : 0;
        //load selected user's workload
        vm.userWorkload = null;
        if (selectedUserId !== vm.selectedUserId) {
          vm.selectedUserId = selectedUserId;
          vm.selectedUserId && loadUserWorkload(item.userId);
        }
      },
      columns: [
        {
          title: $translate.instant('settingsDialog.name'),
          field: "userId",
          editor: customUserEditor,
          template: function (context) {
            if (context.userId) {
              var user = vm.availableUsers.filter(function (elem) {
                return elem.oid == context.userId;
              });
              if (user.length) {
                return user[0].description;
              }
            }
            else {
              return "";
            }
          }
        },
        {
          title: $translate.instant('settingsDialog.role'),
          field: "roleId",
          editor: customRoleEditor,
          template: function (context) {
            if (context.roleId) {
              var user = vm.availableRoles.filter(function (elem) {
                return elem.oid == context.roleId;
              });
              if (user.length) {
                return user[0].name;
              }
            }
            else {
              return "";
            }
          }
        },
        {
          command: ["destroy"],
          title: " ",
          width: 140
        }
      ],
      edit: function (e) {
        $timeout(function () {
          if (!e.model.oid) {
            e.model.oid = -1;

            //Clear the roles
            e.model.roleId = 4;

            //Select the first user
            e.model.userId = Number.MAX_VALUE;
            for (var i = 0; i < vm.availableUsers.length; ++i) {
              e.model.userId = Math.min(vm.availableUsers[i].oid, e.model.userId);
            }

            vm.selectedUserId = e.model.userId;
            loadUserWorkload(vm.selectedUserId);
          } else {
            if (vm.selectedUserId !== e.model.userId) {
              loadUserWorkload(vm.selectedUserId);
            }
          }
        });
      },
      remove: function (e) {
        vm.selectedUserId = 0;
        loadUserWorkload(vm.selectedUserId);
      }
    };

    function onTeamSelectionChanged() {
      $timeout(function () {
        var dataSource = vm.gridOptionsRoleManagementDataSource;

        var entriesToRemove = dataSource.data().filter(function (entry) {
          var existingUser = vm.teamUsers.filter(function (user) {
            return user.oid == entry.userId;
          });
          return entry.roleId == 4 && !existingUser.length;
        });

        var entriesToAdd = vm.teamUsers.filter(function (user) {
          var existingEntry = dataSource.data().filter(function (entry) {
            return entry.roleId == 4 && user.oid == entry.userId;
          });

          return existingEntry.length == 0;
        });

        var promises = [];
        angular.forEach(entriesToRemove, function (entry) {
          dataSource.remove(entry);
        });

        angular.forEach(entriesToAdd, function (entry) {
          dataSource.add({
            "oid": -1,
            "roleId": 4,
            "roleDescription": "Projektteam",
            "userId": entry.oid,
            "userDescription": entry.description
          });
        });
      });
    }

    function customRoleEditor(container, options) {
      var value = null;
      if (options.model.oid != null) {
        value = options.model.roleId;
      }
      else {
        value = 4;
        options.model.roleId = 4;
      }
      $('<input name="' + options.field + '" id="editor-' + options.field + '" />')
        .appendTo(container)
        .kendoDropDownList({
          dataTextField: "name",
          dataValueField: "oid",
          valuePrimitive: true,
          dataSource: {
            data: vm.availableRoles
          },
          value: value
        });
    }

    function customUserEditor(container, options) {
      var value = null;
      if (options.model.oid != null) {
        value = options.model.userId;
      }
      else {
        options.model.userId = Number.MAX_VALUE;
        for (var i = 0; i < vm.availableUsers.length; ++i) {
          options.model.userId = Math.min(vm.availableUsers[i].oid, options.model.userId);
        }
        value = options.model.userId;
      }
      $('<input required name="' + options.field + '" id="editor' + options.field + '" />')
        .appendTo(container)
        .kendoDropDownList({
          dataTextField: "description",
          dataValueField: "oid",
          valuePrimitive: true,
          dataSource: {
            data: vm.availableUsers
          },
          value: value,
          select: function (e) {
            vm.selectedUserId = e.dataItem.oid;
            loadUserWorkload(vm.selectedUserId);
          }
        });
    }

    function load(valuationPortfolioId) {
      var deferred = $q.defer();

      //reset
      vm.teamUsers.length = 0;
      vm.gridOptionsRoleManagementDataSource.data([]);
      vm.userWorkload = null;
      vm.selectedUserId = 0;

      vm.valuationProjectId = valuationPortfolioId;

      $q.all([
        ValuationPortfoliosResource.loadUsers(),
        ValuationPortfoliosResource.loadRoles(),
        ValuationPortfoliosResource.loadRoleAssignments(valuationPortfolioId),
        reflistResource.loadUsers.query({}).$promise

      ]).then(function (result) {
        var users = result[0];
        var roles = result[1];
        var roleAssignments = result[2];
        var availableUsers = result[3];

        users.sort(function compare(a, b) {
          if ((a.description || '').toUpperCase() < (b.description || '').toUpperCase())
            return -1;
          if ((a.description || '').toUpperCase() > (b.description || '').toUpperCase())
            return 1;
          return 0;
        });

        vm.availableUsersDataSource.data(availableUsers);
        vm.availableUsers = users;
        vm.availableRoles = roles;

        vm.gridOptionsRoleManagementDataSource.data(roleAssignments);
        vm.originalRoleAssignmentData = roleAssignments;

        vm.teamUsers = vm.availableUsersDataSource.data().filter(function (user) {
          return roleAssignments.filter(function (assignment) {
            return assignment.roleId == 4 && assignment.userId == user.oid;
          }).length > 0;
        });

        deferred.resolve(true);
      });

      return deferred.promise;
    }

    function isValid(interactive) {
      var assignments = vm.gridOptionsRoleManagementDataSource.data();
      if (assignments.filter(function (element) {
        return element.roleId == 1;
      }).length > 1) {
        interactive && $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'settingsDialog.valuationPortfolioRoleManagement.duplicateEntry');
        return false;
      }

      if (assignments.filter(function (element) {
        return element.roleId == 2;
      }).length > 1) {
        interactive && $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'settingsDialog.valuationPortfolioRoleManagement.duplicateEntry');
        return false;
      }

      if (assignments.filter(function (element) {
        return element.roleId == 3;
      }).length > 1) {
        interactive && $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'settingsDialog.valuationPortfolioRoleManagement.duplicateEntry');
        return false;
      }

      return true;
    }

    function save(valuationPortfolioId) {
      var deferred = $q.defer();

      var entriesToAddBySelection = vm.gridOptionsRoleManagementDataSource.data().filter(function (newEntry) {
        var existingEntry = vm.originalRoleAssignmentData.filter(function (entry) {
          return entry.roleId == newEntry.roleId && entry.userId == newEntry.userId;
        });
        return existingEntry.length == 0;
      });

      var entriesToRemoveBySelection = vm.originalRoleAssignmentData.filter(function (originalEntry) {
        var existingEntry = vm.gridOptionsRoleManagementDataSource.data().filter(function (entry) {
          return entry.roleId == originalEntry.roleId && entry.userId == originalEntry.userId;
        });

        return existingEntry.length == 0;
      });

      var promises = [];

      angular.forEach(entriesToRemoveBySelection, function (entry) {
        promises.push(ValuationPortfoliosResource.deleteRoleAssignment(entry.oid));
      });

      angular.forEach(entriesToAddBySelection, function (entry) {
        entry.oid = -1;
        promises.push(ValuationPortfoliosResource.addRoleAssignment(valuationPortfolioId, entry));
      });

      $q.all(promises).then(function () {
        //TODO: check for errors
        deferred.resolve();
      });

      return deferred.promise;
    }

    function loadUserWorkload(userId) {
      vm.userWorkload = null;
      if (userId !== 0) {
        $q.all([
          ValuationPortfoliosResource.loadUserWorkload(vm.valuationProjectId, userId)
        ]).then(function (results) {
          if (vm.selectedUserId === userId) {
            vm.userWorkload = results[0];
          }
        });
      }
    }

  }
}());
