
export class QvPropertyImageDataUriDto {
  public Oid: number = 0;
  public Type: number = 0;
  public Data: string = null;
  public Description: string = '';
  public ImageTypeDescription: string = '';
  public Index: number = 0;
  public Comment: string = '';
  public QualityWarnings: Array<string> = new Array<string>();

  //helpers
  public checked: boolean = false;
  public isPlaceholder: boolean = false;
  public fileDragOver: boolean = false;
  public fileDragOverAction: string = '';
  public commentActive: boolean = false;
  public uploadPlaceholder: boolean;
}
