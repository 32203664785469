import { Component, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { concat, delay, retryWhen, take } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { SetupPolluxBeforeBootstrap } from './app.module.ajs';
import { SynapsesDashboardService } from 'qv-dashboard-lib/projects/qv-dashboard-lib/src/public_api';
import { UpgradeModule } from '@angular/upgrade/static';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private upgrade: UpgradeModule, private http: HttpClient, private dashboardService: SynapsesDashboardService) {
    this.dashboardService.setEnvironment(environment);
    //acquireTokenSilent cause page refresh #526
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/526
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/9fa581aa01e5314dc765ea33161b431b6f3e6c04/lib/msal-angular/samples/MSALAngularDemoApp/src/app/app.component.ts
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/1a7b3c5fdb67d160a216eabe09c4c004e3310649/lib/msal-angular/samples/MSALAngularDemoApp/src/app/app.component.html

    this.isIframe = window !== window.parent && !window.opener;
  }

  public angularJsInitialized = false;
  public isIframe: boolean;

  private apiReadySubject = new Subject<any>();

  private waitForApiReady(appConfigResult) {
    setTimeout(() => {
      const zeroGuid = '00000000-0000-0000-0000-000000000000';
      const languageUrl = appConfigResult.polluxApiUrl + '/api/'
        + (appConfigResult.serverApiVersion ? (appConfigResult.serverApiVersion + '/') : '')
        + `${zeroGuid}/`  //organization guid
        + (appConfigResult.useProjectGuidInUrl ? (zeroGuid + '/') : '')
        + 'startup/info';

      this.http.get(languageUrl, { observe: 'response' }).pipe(retryWhen(errors => errors.pipe(delay(10000), take(9), concat(throwError("Giving up Retry.!"))))).subscribe((response) => {
        //Wait for DB to become ready: Retry every 10sec, retry 9 times (=1.5min)
        this.apiReadySubject.next(response);
      }, (error) => {
        this.apiReadySubject.next(null);
      });
    });
  }

  ngOnInit() {
    if (!this.isIframe) {
      this.http.get('config/AppConfig.json').subscribe((appConfigResult: any) => {
        //this.http.get(appConfigResult['polluxApiUrl'] + '/api/v2/language').subscribe((localeResult) => {

        this.waitForApiReady(appConfigResult);

        this.apiReadySubject.asObservable().subscribe((result) => {
          if (result) {
            const startupInfo = result.body;

            SetupPolluxBeforeBootstrap(appConfigResult, startupInfo, () => {
              this.upgrade.bootstrap(document.body, ['DXSPolluxApp'], { debugInfoEnabled: true });
              this.angularJsInitialized = true;
            });

            if (environment.UseMsal) {
              const zeroGuid = '00000000-0000-0000-0000-000000000000';
              const tokenPingUrl = appConfigResult.polluxApiUrl + '/api/'
                + (appConfigResult.serverApiVersion ? (appConfigResult.serverApiVersion + '/') : '')
                + (zeroGuid + '/')//organization
                + (appConfigResult.useProjectGuidInUrl ? (zeroGuid + '/') : '')
                + 'api/v2/status/ping';//double /api/v2 is not error.. it is defined like that in API..TODO

              const pingInterval = 3 * 60 * 1000;//every 3 minutes
              this.pingTokenUrl(tokenPingUrl, pingInterval);
            }
            //});
          }
          else {
            alert("Couldn't load the application. Please make sure that you have an active and valid user account and/or retry it again in a minute. If you're still experiencing any issues, please contact your ISPINIT support.");
          }
        });        
      });
    }
  }

  private pingTokenUrl(tokenPingUrl, pingInterval) {
    const self = this;
    window.setTimeout(function () {
      self.http.get(tokenPingUrl).subscribe((result: any) => {
        //ping again
        self.pingTokenUrl(tokenPingUrl, pingInterval);
      }, error => {
        //ping again
        self.pingTokenUrl(tokenPingUrl, pingInterval);
      });
    }, pingInterval);
  }
}
