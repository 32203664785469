(function () {

  "use strict";

  //https://stackoverflow.com/questions/20146713/ng-change-on-input-type-file
  angular.module("DXSPolluxApp")
    .directive('dxsFileInputChange', function () {
      return {
        scope: {
          dxsFileInputChange: "&",
          item: '=item'
        },
        link: function ($scope, $element, $attrs) {
          $element.on("change", function (event) {
            $scope.dxsFileInputChange({ $event: event, item: $scope.item });
          });

          $scope.$on("$destroy", function () {
            $element.off();
          });
        }
      };
    });
}());
