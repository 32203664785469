import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { TourStep } from '../model/tourStep';

@Component({
  selector: 'tour-step',
  templateUrl: './tour-step-element.component.html',
  styleUrls: ['./tour-step-element.component.scss']
})
export class TourStepElementComponent implements OnInit {

  @Input() tourStep: TourStep;
  public joyrideStep = "";
  public header = "";
  public description = "";
  @Output() closedTour = new EventEmitter<void>();

  ngOnInit() {
    const self = this;
    if (self.tourStep) {
      self.joyrideStep = self.tourStep.tourStepId;
      self.header = self.tourStep.header;
      self.description = self.tourStep.description;
    }
  }
}
