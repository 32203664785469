import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ValuationPortfoliosList } from './models/valuation-portofolio-list.model';
import { MultiDashboardService } from './services/multi-dashboard-service.service';


@Component({
    selector: 'ct-multi-dashboard',
    templateUrl: './multi-dashboard.component.html',
    styleUrls: ['./multi-dashboard.component.scss'],
})
export class MultiDashboardComponent implements OnInit, OnDestroy {
  @Input() job: ValuationPortfoliosList;

  readonly widgetSize = '443px';

  comparasionJobs: ValuationPortfoliosList[] = [];
  scrollTop: number = 0;
  displayAssumtionWidgets: boolean = false;
  subscriptions: Subscription = new Subscription();

  constructor(private mds: MultiDashboardService) {
  }

  ngOnInit(): void {

    this.subscriptions.add(this.mds.comparisionJobs$.subscribe((jobs) => {
      this.comparasionJobs = jobs;
    }));

    this.subscriptions.add(this.mds.showDashboardAssumptions$.subscribe((show) => {
      this.displayAssumtionWidgets = show;
    }));

    if(!this.mds.comparasionJobsExists()){
        this.mds.initWithJob(this.job);
    }
  }

  removeJob(jobId: number) {
    const job = this.comparasionJobs.find((j) => j.Oid === jobId);
    if (job) {
      this.mds.removeJob(job);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getClass(index: number, j: ValuationPortfoliosList) {
    if (j.Oid === -1) {
      return 'ct-mask default';
    }

    switch (index) {
      case 1:
        return 'ct-mask first';
      case 2:
        return 'ct-mask second';
      default:
        return '';
    }
  }
}
