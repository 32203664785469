(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxDateField
   * @description
   * This component provides the behavior and visual representation of a date picker field. The field is rendered as a Kendo UI kendo-date-picker.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires $timeout
   * @requires DXSPolluxApp.service:dateHelper
   * 
   * @param {String} ngModel The property that is bound to this field. Please note that this is a string because this eliminates the date time offset in different time zones. Only the date itself is saved, no time.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Bool} isNullable Specifis if this field can contain null values
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxDateField", {
      template: require('html-loader!./pollux.date.field.tmpl.html'),
      bindings: {
        "isNullable": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngId": "@",
        "format": "@",
        "onlyMonthSelection": '<'
      },
      controllerAs: "vm",
      controller: ['$scope', 'dateHelper', '$timeout', polluxDateFieldCtrl]
    });

  function polluxDateFieldCtrl($scope, dateHelper, $timeout) {
    var vm = this;

    //#region variable declaration
    vm.datePicker = null;
    vm.displayModel = null;
    vm.ngModelDeepCopy = null;

    vm.options = {
      change: onNgChange,
      open: function (e) {
        if (vm.ngModel === '1900-01-01') {
          vm.datePicker.value(new Date());
          vm.ngModel = dateHelper.toISOString(new Date());
        }
        if (vm.onlyMonthSelection) {
          vm.ngModelDeepCopy = JSON.parse(JSON.stringify(vm.ngModel));
        }
      },
      close: function (e) {
        if (vm.onlyMonthSelection) {
          if (new Date(vm.ngModel) !== new Date(vm.ngModelDeepCopy)) {
            var date = new Date(vm.ngModel);
            var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            vm.ngModel = dateHelper.toISOString(lastDayOfMonth);
          }
        }
      }
    };
    //#endregion

    //#region function declaration

    //#endregion

    //#region function implementation
    function onNgChange(e) {
      $timeout(function () { //Potentially executed from outside of the Angular context
        vm.displayModel = vm.datePicker.element[0].value;

        if (!(vm.isNullable && vm.ngModel === null)) {
          vm.ngModel = dateHelper.toISOString(vm.ngModel);
        }

        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }

    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.ngModelDeepCopy = null;
      if (vm.isNullable && vm.ngModel == null) {
        vm.ngModel = null;
      }
      else {
        vm.ngModel = dateHelper.toISOString(vm.ngModel);
      }
      if (vm.format) {
        vm.options.format = vm.format;
      }
      if (vm.onlyMonthSelection) {
        vm.options.depth = "year";
        vm.options.start = "year";
      }
      onNgChange();
    }

    $scope.$on('localeChanged', function (event, locale) {
      onNgChange();
    });
    //#endregion    
  }
}());

