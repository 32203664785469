import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { SynapsesDqsValidationResult } from '../_data/synapses-dqs-validation-result';
import * as GC from '@grapecity/spread-sheets';
import * as GCLP from '@grapecity/spread-sheets-languagepackages';
import * as Excel from '@grapecity/spread-excelio';
import { SynapsesDqsService } from '../_services/synapses-dqs-service.service';
import { SynapsesDqs2ValidationEntry } from '../_data/synapses-dqs2-validation-entry';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AngularIntegrationService } from '../../../../services/AngularIntegrationService';

@Component({
  selector: 'synapses-dqs-validation',
  templateUrl: './synapses-dqs-validation.component.html',
  styleUrls: ['./synapses-dqs-validation.component.scss']
})
export class SynapsesDqsValidationComponent implements OnInit, OnDestroy {
  public vm = this;
  @Input() businessObjectType: string;
  @Input() oid: number;
  @Input() importTemplate: any;

  @Output() close = new EventEmitter<boolean>();
  @ViewChild('container') container: ElementRef;

  /*
   * The spreadsheet
   */
  private spreadsheet: GC.Spread.Sheets.Workbook;

  public hasErrors = false;
  public hasWarnings = false;
  public ignoreErrors = false;

  public resultsWarning: Array<SynapsesDqs2ValidationEntry> = [];
  public resultsError: Array<SynapsesDqs2ValidationEntry> = [];
  public resultsWarningDeepCopy: Array<SynapsesDqs2ValidationEntry> = [];
  public resultsErrorDeepCopy: Array<SynapsesDqs2ValidationEntry> = [];
  public errorMessage: string;

  public availableDates: any[] = [];
  public selectedDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  public initialTemplateLoad = true;

  public categories: any[] = [];
  public currentMappings: any[] = [];

  public columnCount = 0;
  public rowCount = 0;
  public columnWidth = 200.0;
  public rowHeight = 50.0;

  public rowErrorsWarnings: any[] = [];

  public currentReflistMapperEntry = null;
  public currentReflistMapperValues = null;

  @ViewChild("dqsValidationScrollContainer") private dqsValidationScrollContainer: ElementRef<HTMLElement>;

  /**
   * Host style for the spreadsheet
   */
  public hostStyle = {
    width: '100%',
    height: '100%'
  };

  public fileJson: any;

  private _data: SynapsesDqsValidationResult;

  get data(): SynapsesDqsValidationResult {
    return this._data;
  }

  @Input() set data(value: SynapsesDqsValidationResult) {
    const self = this;

    self._data = null;
    self.hasErrors = false;
    self.hasWarnings = false;
    self.ignoreErrors = false;

    self.service.getAvailableDates(self.businessObjectType, self.oid).then((availableDates) => {
      self.availableDates = availableDates;

      const file = value.rawFile;

      if (file) {
        const excelIO = new Excel.IO();
        excelIO.open(file, (json: any) => {
          self.fileJson = json;
          self._data = value;
        }, (e) => {
          alert("Failed to load excel file!");
        });
      } else {
        self._data = value;
      }
    });
  }

  @Output() importSuccessful = new EventEmitter<SynapsesDqsValidationResult>();

  constructor(private service: SynapsesDqsService, @Inject('appBusyIndicatorService') private busyIndicator: any, @Inject('appConfirmDialogService') private confirmDialog: any
    , private translate: TranslateService, @Inject('appSidebarActionsService') private sidebarActionsService: any, private ais: AngularIntegrationService) {
    const self = this;

    if (self.translate.currentLang === 'de-DE') {
      //https://www.grapecity.com/forums/spreadjs/custom-culture-not-applyin
      const enToDeFormatMap = {};
      enToDeFormatMap['mm/dd/yyyy'] = new GC.Spread.Formatter.GeneralFormatter('dd.MM.yyyy', 'de');
      enToDeFormatMap['m/d/yyyy'] = new GC.Spread.Formatter.GeneralFormatter('d.M.yyyy', 'de');
      // add more mapping here as needed.

      const g_getFormatter = (GC.Spread.Formatter.GeneralFormatter as any).prototype.getFormatter;
      (GC.Spread.Formatter.GeneralFormatter as any).prototype.getFormatter = function (index) {
        const f = g_getFormatter.call(this, index);
        const formatString = f && f.formatString();
        if (formatString && formatString.length > 0 && enToDeFormatMap[formatString]) {
          return enToDeFormatMap[formatString];
        }
        return f;
      }

      const myCulture = new GC.Spread.Common.CultureInfo();
      myCulture.NumberFormat.currencySymbol = "\u20ac"
      myCulture.NumberFormat.numberDecimalSeparator = ",";
      myCulture.NumberFormat.numberGroupSeparator = ".";
      myCulture.NumberFormat.arrayGroupSeparator = ";";
      myCulture.NumberFormat.arrayListSeparator = "\\";
      myCulture.NumberFormat.listSeparator = ";";
      myCulture.DateTimeFormat.amDesignator = "";
      myCulture.DateTimeFormat.amDesignator = "";
      myCulture.DateTimeFormat.pmDesignator = "";
      myCulture.DateTimeFormat.abbreviatedMonthNames = ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez", ""];
      myCulture.DateTimeFormat.abbreviatedDayNames = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
      myCulture.DateTimeFormat.abbreviatedMonthGenitiveNames = ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez", ""];
      myCulture.DateTimeFormat.dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
      myCulture.DateTimeFormat.fullDateTimePattern = "dddd, d. MMMM yyyy HH:mm:ss";
      myCulture.DateTimeFormat.longDatePattern = "dddd, d. MMMM yyyy";
      myCulture.DateTimeFormat.longTimePattern = "HH:mm:ss";
      myCulture.DateTimeFormat.monthDayPattern = "dd MMMM";
      myCulture.DateTimeFormat.monthNames = ["Januar", "Februar", "M\xe4rz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember", ""];
      myCulture.DateTimeFormat.monthGenitiveNames = ["Januar", "Februar", "M\xe4rz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember", ""];
      myCulture.DateTimeFormat.shortDatePattern = "dd.MM.yyyy";
      myCulture.DateTimeFormat.shortTimePattern = "HH:mm";
      myCulture.DateTimeFormat.yearMonthPattern = "MMMM yyyy";

      //add one culture
      GC.Spread.Common.CultureManager.addCultureInfo("de-DE", myCulture);
      //switch to "de-DE" culture
      GC.Spread.Common.CultureManager.culture("de-DE");
    }
    GC.Spread.Sheets.LicenseKey = environment.SpreadJsLicenseKey;
    (Excel as any).LicenseKey = environment.SpreadJsLicenseKey; //See last post from https://www.grapecity.com/forums/spread-sheets/export-to-excel---license-
  }

  ngOnInit() {
    const self = this;
    self.updateSidebarActions();

    self.busyIndicator.setVisible(true);

    for (let i = 0; i < 8; i++) {
      this.currentMappings.push({ fieldName: '', errorCount: 0, warningCount: 0 });
    }
  }

  public updateSidebarActions() {
    const self = this;

    const sidebarActions = [
      {
        imgUrl: '/assets/images/ionicons/create-outline.svg', translationKey: 'projectValuation.edit', callback: function () {
          //Nothing
        }
      },
      {
        imgUrl: '/assets/images/SidebarIcons/save-solid.svg', translationKey: 'dqs.import', callback: function () {
          self.import();
        }
      },
      {
        imgUrl: self.ignoreErrors ? '/assets/images/SidebarIcons/eye-slash-solid.svg' : '/assets/images/SidebarIcons/eye-solid.svg', translationKey: 'dqs.ignoreErrors', callback: function () {
          self.ignoreErrors = !self.ignoreErrors;
          self.updateSidebarActions();
        }
      },
      {
        imgUrl: '/assets/images/SidebarIcons/window-close-solid.svg', translationKey: 'dqs.close', callback: function () {
          self.doClose();
        }
      }
    ];
    self.sidebarActionsService.setActions(sidebarActions);
  }

  ngOnDestroy() {
    this.sidebarActionsService.clear();
  }


  public doClose(): void {
    const self = this;

    self.busyIndicator.setVisible(false);
    self.close.emit(true);
  }

  /**
   * Callback: The SpreadJS spreadsheet has been initialized
   * @param args
   */
  public workbookInit(args) {
    const self = this;

    self.spreadsheet = args.spread;

    self.spreadsheet.options.tabStripVisible = false;
    self.spreadsheet.options.allowUserZoom = false;
    if (self.fileJson) {
      const json = self.fileJson;
      let removedCount = 0; for (const p in json.sheets) p.indexOf("Evaluation") !== -1 && ++removedCount && delete json.sheets[p];
      if (removedCount > 0) {
        json.sheetCount = 0; for (const p in json.sheets) ++json.sheetCount;

        const arr = [];
        for (const p in json.sheets) arr.push({ name: p, index: json.sheets[p].index });
        arr.sort(function (a, b) { return a.index - b.index });
        arr.forEach((item, index) => { json.sheets[item.name].index = index; });
        json.activeSheetIndex = 0;
      }

      self.spreadsheet.fromJSON(self.fileJson);
    }

    self.errorMessage = "";
    for (let i = 0; i < self.spreadsheet.sheets.length; ++i) {
      const sheet = self.spreadsheet.sheets[i];
      sheet.zoom(1.0);

      if (i > 0) {
        sheet.visible(false);
        continue;
      }

      const sheetName = sheet.name();
      if (self.initialTemplateLoad && self.importTemplate.id === 1 && sheetName === 'Rent Roll' && self.fileJson.sheets["Calc Model Information"] && self.fileJson.sheets["Rent Roll"]) {
        if ((sheet.getCell(0, 0).value() || '').toString() !== 'Unit') {
          sheet.deleteColumns(0, 1);
          sheet.deleteColumns(16, 10);
          sheet.deleteRows(0, 18);
        }
        self.initialTemplateLoad = false;
      }

      //Suspend events and paint
      sheet.suspendPaint();
      sheet.suspendEvent();

      //Freeze the 1st row so it's always visible
      //sheet.frozenRowCount(1);

      //Freeze all rows
      var rc = sheet.getRowCount();
      sheet.frozenRowCount(rc);

      //Freeze all cols
      var cc = sheet.getColumnCount();
      self.columnCount = cc;
      sheet.frozenColumnCount(cc);

      //Set the colors for the headers (1st row)
      const headerRange = sheet.getRange(0, 0, 1, cc);

      //headerRange.locked(true);
      headerRange.backColor("#008AAC");
      headerRange.foreColor("#FFFFFF");

      sheet.getRange(0, -1, 1, -1).visible(false);

      //Unlock the data cells
      //const cellRange = sheet.getRange(1, 0, sheet.getRowCount() - 1, sheet.getColumnCount());
      // cellRange.locked(false);

      //Set a different color if a cell was changed by the user
      sheet.bind(GC.Spread.Sheets.Events.CellChanged, function (e, info) {
        if (info.propertyName === "value") {
          //self.resultsWarning = self.resultsWarning.filter(err => err.rowIndex !== info.row || err.colIndex !== info.col);
          //self.resultsError = self.resultsError.filter(err => err.rowIndex !== info.row || err.colIndex !== info.col);
          info.sheet.getCell(info.row, info.col).backColor('#CEE4E8');

          self.fixDateFormat(info.sheet, info.row, info.col);
        }
      });

      sheet.bind(GC.Spread.Sheets.Events.EnterCell, function (e, info) {
        self.errorMessage = "";
        const error = self.resultsError.find(err => err.rowIndex === info.row && err.colIndex === info.col);
        if (error) {
          self.errorMessage = `ERROR: ${error.fieldName}: ${error.message}`;
          setTimeout(() => self.handleCellClicked(error, info));
        } else {
          const warning = self.resultsWarning.find(err => err.rowIndex === info.row && err.colIndex === info.col);
          if (warning) {
            self.errorMessage = `WARNING: ${error.fieldName}: ${error.message}`;
          }

          setTimeout(() => self.handleCellClicked(null, null));
        }

        self.ensureCellInViewPort(info);
      });

      //Set the protection options
      sheet.options.isProtected = false;
      sheet.options.protectionOptions.allowDeleteRows = false;
      sheet.options.protectionOptions.allowDeleteColumns = false;
      sheet.options.protectionOptions.allowInsertRows = false;
      sheet.options.protectionOptions.allowInsertColumns = false;
      sheet.options.protectionOptions.allowDragInsertColumns = false;
      sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.valuesAndFormatting;

      const headerStyle = new GC.Spread.Sheets.Style();
      headerStyle.font = 'bold 14px Segoe UI';
      headerStyle.foreColor = '#808080';
      headerStyle.backColor = '#FAFAFA';
      headerStyle.borderLeft = null;
      headerStyle.borderRight = null;
      headerStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      headerStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      headerStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
      headerStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

      const evenRowStyle = new GC.Spread.Sheets.Style();
      evenRowStyle.font = '14px Segoe UI';
      evenRowStyle.foreColor = '#808080';
      evenRowStyle.backColor = '#FAFAFA';
      evenRowStyle.borderLeft = null;
      evenRowStyle.borderRight = null;
      evenRowStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      evenRowStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      evenRowStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
      evenRowStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

      const oddRowStyle = new GC.Spread.Sheets.Style();
      oddRowStyle.font = '14px Segoe UI';
      oddRowStyle.foreColor = '#808080';
      oddRowStyle.backColor = '#FFFFFF';
      oddRowStyle.borderLeft = null;
      oddRowStyle.borderRight = null;
      oddRowStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      oddRowStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      oddRowStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
      oddRowStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

      const errorStyle = new GC.Spread.Sheets.Style();
      errorStyle.font = 'bold 14px Segoe UI';
      errorStyle.foreColor = '#808080';
      errorStyle.backColor = '#EF796F';
      errorStyle.borderLeft = null;
      errorStyle.borderRight = null;
      errorStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      errorStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      errorStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
      errorStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

      const warningStyle = new GC.Spread.Sheets.Style();
      warningStyle.font = 'bold 14px Segoe UI';
      warningStyle.foreColor = '#808080';
      warningStyle.backColor = '#FFE8B1';
      warningStyle.borderLeft = null;
      warningStyle.borderRight = null;
      warningStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      warningStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
      warningStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
      warningStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

      sheet.setColumnWidth(i, self.columnWidth, GC.Spread.Sheets.SheetArea.viewport);

      for (let i = 0; i < sheet.getColumnCount(); ++i) {
        sheet.setColumnWidth(i, self.columnWidth, GC.Spread.Sheets.SheetArea.viewport);

        // disable col resizing in the row headers
        sheet.setColumnResizable(i, false, GC.Spread.Sheets.SheetArea.viewport);
      }

      sheet.zoom(1.0);
      sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader).resizable(false);
      sheet.getRange(-1, 0, -1, 1, GC.Spread.Sheets.SheetArea.rowHeader).resizable(false);

      sheet.options.colHeaderVisible = false;
      sheet.options.rowHeaderVisible = false;

      for (let row = 0; row < sheet.getRowCount(); ++row) {
        sheet.setRowHeight(row, self.rowHeight, GC.Spread.Sheets.SheetArea.viewport);
        for (let col = 0; col < sheet.getColumnCount(); ++col) {
          let replacementStyle = null;

          if (row === 0) {
            replacementStyle = headerStyle;
          }
          else if (row % 2 === 0) {
            replacementStyle = evenRowStyle;
          }
          else {
            replacementStyle = oddRowStyle;
          }

          self.mergeStyles(sheet, row, col, replacementStyle);
        }
      }

      if (typeof (self.data.entries) !== 'undefined') {
        self.resultsWarning = self.data.entries.filter(e => !e.isError);
        self.resultsError = self.data.entries.filter(e => e.isError);

        self.resultsWarning.forEach(o => self.mergeStyles(sheet, o.rowIndex, o.colIndex, warningStyle));
        self.resultsError.forEach(o => self.mergeStyles(sheet, o.rowIndex, o.colIndex, errorStyle));
        self.hasWarnings = self.resultsWarning.length > 0;
        self.hasErrors = self.resultsError.length > 0;
      }

      self.categories = [];
      self.resultsWarningDeepCopy = JSON.parse(JSON.stringify(self.resultsWarning));
      self.resultsErrorDeepCopy = JSON.parse(JSON.stringify(self.resultsError));

      if (self.hasErrors) {
        self.categories.push({ title: "errors", fields: [] });
        // errors
        for (let error of self.resultsErrorDeepCopy) {
          const index = self.categories.findIndex(item => item.title === 'errors');
          let sameErrors = self.categories[index].fields.filter(e => e.fieldName === error.fieldName && e.message === error.message); //filter fields by error fieldname and message
          if (sameErrors.length === 0) {
            self.categories[index].fields.push(error);
          }
          else {
            if (self.categories[index].fields.find(e => e.fieldName === sameErrors[0].fieldName && e.message === sameErrors[0].message).count) {
              self.categories[index].fields.find(e => e.fieldName === sameErrors[0].fieldName && e.message === sameErrors[0].message).count += 1;
            }
            else {
              self.categories[index].fields.find(e => e.fieldName === sameErrors[0].fieldName && e.message === sameErrors[0].message).count = 2;
            }
          }
        }
      }
      if (self.hasWarnings) {
        self.categories.push({ title: "warnings", fields: [] });
        // warnings
        for (let warning of self.resultsWarningDeepCopy) {
          const index = self.categories.findIndex(item => item.title === 'warnings');
          let sameWarnings = self.categories[index].fields.filter(e => e.fieldName === warning.fieldName && e.message === warning.message); //filter fields by error fieldname and message
          if (sameWarnings.length === 0) {
            self.categories[index].fields.push(warning);
          }
          else {
            if (self.categories[index].fields.find(e => e.fieldName === sameWarnings[0].fieldName && e.message === sameWarnings[0].message).count) {
              self.categories[index].fields.find(e => e.fieldName === sameWarnings[0].fieldName && e.message === sameWarnings[0].message).count += 1;
            }
            else {
              self.categories[index].fields.find(e => e.fieldName === sameWarnings[0].fieldName && e.message === sameWarnings[0].message).count = 2;
            }
          }
        }
      }
      self.rowErrorsWarnings = [];
      //get column errors and warnings count
      self.currentMappings = [];
      for (let i = 0; i < sheet.getColumnCount(); i++) {
        self.currentMappings.push({ fieldName: '', errorCount: 0, warningCount: 0 });
      }
      for (let col = 0; col < sheet.getColumnCount(); ++col) {
        const columnErrors = self.resultsError.filter(err => err.colIndex === col);
        const columnWarnings = self.resultsWarning.filter(err => err.colIndex === col);
        try {
          self.currentMappings[col].fieldName = (sheet.getCell(0, col).value() || '').toString();
          self.currentMappings[col].errorCount = columnErrors.length;
          self.currentMappings[col].warningCount = columnWarnings.length;
        } catch (e) { }
      }

      //get row errors and warnings count
      for (let row = 1; row < sheet.getRowCount(); ++row) {
        const rowErrors = self.resultsError.filter(err => err.rowIndex === row);
        const rowWarnings = self.resultsWarning.filter(err => err.rowIndex === row);
        self.rowErrorsWarnings.push({ errorCount: rowErrors.length, warningCount: rowWarnings.length });
      }

      for (let row = 0; row < sheet.getRowCount(); ++row) {
        for (let col = 0; col < sheet.getColumnCount(); ++col) {
          self.fixDateFormat(sheet, row, col);
        }
      }

      //Resume events and paint - never forget!
      sheet.resumePaint();
      sheet.resumeEvent();

      sheet.setActiveCell(0, 0);
    }

    self.busyIndicator.setVisible(false);
    //Disable some extended user interactions soit's not possible to change the spreadsheet structure
    self.spreadsheet.options.allowUserEditFormula = false;
    self.spreadsheet.options.allowSheetReorder = false;
    self.spreadsheet.options.newTabVisible = false;
    self.spreadsheet.options.tabStripVisible = false;
    self.spreadsheet.options.allowContextMenu = false;
    self.spreadsheet.options.showVerticalScrollbar = false;

    //resize canvas
    self.hostStyle = {
      width: self.columnCount * self.columnWidth + 'px',
      height: (1 + self.rowErrorsWarnings.length) * self.rowHeight/*needs to be adjusted if when have rows without checks*/ + 18/*Toolbar*/ + 'px'
    };

    setTimeout(() => {
      self.spreadsheet.refresh();
    });
  }

  private mergeStyles(sheet: GC.Spread.Sheets.Worksheet, row: number, col: number, replacementStyle: GC.Spread.Sheets.Style) {
    const originalStyle = sheet.getStyle(row, col, GC.Spread.Sheets.SheetArea.viewport);

    let newStyle;
    if (originalStyle) {
      newStyle = originalStyle.clone();

      if (replacementStyle.font) {
        newStyle.font = replacementStyle.font;
      }

      if (replacementStyle.foreColor) {
        newStyle.foreColor = replacementStyle.foreColor;
      }

      if (replacementStyle.backColor) {
        newStyle.backColor = replacementStyle.backColor;
      }

      if (replacementStyle.borderLeft) {
        newStyle.borderLeft = replacementStyle.borderLeft;
      }
      else {
        newStyle.borderLeft = null;
      }

      if (replacementStyle.borderRight) {
        newStyle.borderRight = replacementStyle.borderRight;
      }
      else {
        newStyle.borderRight = null;
      }

      if (replacementStyle.borderTop) {
        newStyle.borderTop = replacementStyle.borderTop;
      }
      else {
        newStyle.borderTop = null;
      }

      if (replacementStyle.borderBottom) {
        newStyle.borderBottom = replacementStyle.borderBottom;
      }
      else {
        newStyle.borderBottom = null;
      }

      if (replacementStyle.hAlign) {
        newStyle.hAlign = replacementStyle.hAlign;
      }

      if (replacementStyle.vAlign) {
        newStyle.vAlign = replacementStyle.vAlign;
      }
    }
    else {
      newStyle = replacementStyle;
    }

    sheet.setStyle(row, col, newStyle, GC.Spread.Sheets.SheetArea.viewport);
  }

  private fixDateFormat(sheet: GC.Spread.Sheets.Worksheet, row: number, col: number) {
    const value: string = (sheet.getCell(row, col).value() || '').toString();
    let dateValue;
    if (value.match(/^[0-3][0-9]\.[0-1][0-9]\.[0-9]{4}$/)) {
      //German date format dd.MM.yyyy
      dateValue = moment(value, 'DD.MM.YYYY').toDate();
    } else if (value.match(/^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}$/)) {
      //English date format dd/MM/yyyy
      dateValue = moment(value, 'DD/MM/YYYY').toDate();
    }
    else if (value.match(/^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/)) {
      //English date format yyyy-MM-dddd
      dateValue = moment(value, 'YYYY-MM-DD').toDate();
    }

    if (dateValue) {
      sheet.getCell(row, col).formatter("General");
      //to avoid error: The string '44561' is not a valid Date value. Should be in YYYY-MM-DD format (if entered manually).
      //set cell value to YYYY-MM-DD format
      //sheet.setValue(row, col, dateValue);
      const formattedDate = moment(dateValue).format('YYYY-MM-DD');
      sheet.setValue(row, col, formattedDate);
    }
  }

  public hasDataInMonth(date: Date) {
    const self = this;

    const matchingRentRollDate = self.availableDates.filter((entry) => {
      const entryDate = new Date(entry);
      return entryDate.getFullYear() === date.getFullYear() && entryDate.getMonth() === date.getMonth() && entryDate.getDate() === date.getDate();
    });

    return matchingRentRollDate.length ? 'hasRentRoll' : '';
  }

  public hasDataInYear(year: any) {
    const self = this;

    const matchingRentRollDate = self.availableDates.filter((entry) => {
      const entryDate = new Date(entry);
      return entryDate.getFullYear() === parseInt(year);
    });

    return matchingRentRollDate.length ? 'hasRentRoll' : '';
  }

  public import() {
    const self = this;

    self.busyIndicator.setVisible(true, self.container.nativeElement);

    const matchingRentRollDate = self.availableDates.filter((entry) => {
      const entryDate = new Date(entry);
      return entryDate.getFullYear() === self.selectedDate.getFullYear() && entryDate.getMonth() === self.selectedDate.getMonth() && entryDate.getDate() === self.selectedDate.getDate();
    });

    function proceedImport() {
      const templateId: number = self.importTemplate.id;
      const worksheetName: string = self.importTemplate.worksheetName;

      const currentSheetName = self.spreadsheet.getActiveSheet().name();

      //In case there are more sheets: Remove!
      const sheetNamesToDelete = [];
      for (let i = 0; i < self.spreadsheet.getSheetCount(); ++i) {
        const currentName = self.spreadsheet.sheets[i].name();

        if (currentName !== currentSheetName) {
          sheetNamesToDelete.push(currentName);
        }
      }

      for (let name of sheetNamesToDelete) {
        const index = self.spreadsheet.getSheetIndex(name);
        self.spreadsheet.removeSheet(index);
      }

      //remember file
      self.fileJson = self.spreadsheet.toJSON({ includeBindingSource: true });

      //Rename the sheet
      const currentSheetJson = JSON.parse(JSON.stringify(self.fileJson.sheets[currentSheetName]));
      currentSheetJson.name = worksheetName;
      delete self.fileJson.sheets[currentSheetName];
      self.fileJson.sheets[worksheetName] = currentSheetJson;

      const dto = JSON.stringify(self.fileJson);

      self.service.validate(dto, templateId, true).then((data: SynapsesDqsValidationResult) => {
        const errors = data.entries.filter((entry) => { return entry.isError });

        if (errors.length && !self.ignoreErrors) {
          self.busyIndicator.setVisible(false, self.container.nativeElement);
          self._data = null;
          setTimeout(() => {
            self._data = data;
            self.hasErrors = true;
          });
        }
        else {
          self.service.import(dto, templateId, self.oid, self.availableDates, self.selectedDate, self.ignoreErrors).then((data) => {
            self.busyIndicator.setVisible(false, self.container.nativeElement);
            if (data.success) {
              const validationResult = data.validationResult as SynapsesDqsValidationResult;

              if (self.businessObjectType === "PropertyRentRoll") {
                self.ais.$rootScope.$broadcast('reloadKpi', { type: 'property', oid: self.oid });
              }

              self.importSuccessful.emit(validationResult);
            }
            else {
              self.confirmDialog.open({ simpleMessage: true }, 'dqs.importFailed', 'simpleMessage.titleImport');
            }
          }).catch(() => {
            self.confirmDialog.open({ simpleMessage: true }, 'dqs.importFailed', 'simpleMessage.titleImport');
          });
        }
      }).catch(() => {
        self.busyIndicator.setVisible(false, self.container.nativeElement);
        self.confirmDialog.open({ simpleMessage: true }, 'dqs.importFailed', 'simpleMessage.titleImport');
      });
    }

    if (matchingRentRollDate.length) {
      self.confirmDialog.open({
        success: function () {
          proceedImport();

        },
        cancel: function () {
          self.busyIndicator.setVisible(false, self.container.nativeElement);
          //Nothing
        }
      }, 'importRentRoll');
    }
    else {
      proceedImport();
    }
  }

  public getCategoryColor(category: any): string {
    const self = this;

    const colors = ['#EF796F', '#FFE8B1'];

    const index = self.categories.map(e => e.title).indexOf(category.title);
    if (index >= colors.length) {
      return '#FF0000;';
    }
    return colors[index];
  }

  public fieldClicked(field: any, category: any): void {
    const self = this;
    const sheet = self.spreadsheet.getActiveSheet();

    const errorAndWarningStyle = new GC.Spread.Sheets.Style();
    errorAndWarningStyle.borderLeft = null;
    errorAndWarningStyle.borderRight = null;
    errorAndWarningStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    errorAndWarningStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);

    //set all hints not selected (remove selected border)
    self.resultsError.forEach(o => self.mergeStyles(sheet, o.rowIndex, o.colIndex, errorAndWarningStyle));
    self.resultsWarning.forEach(o => self.mergeStyles(sheet, o.rowIndex, o.colIndex, errorAndWarningStyle));

    let sameHints = [];
    if (field.count) {
      if (category.title === "errors") {
        sameHints = self.resultsError.filter(e => e.fieldName === field.fieldName && e.message === field.message);
      }
      else if (category.title === "warnings") {
        sameHints = self.resultsWarning.filter(e => e.fieldName === field.fieldName && e.message === field.message);
      }

      //set border of selected hints
      for (let sameHint of sameHints) {
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderLeft(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderRight(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderTop(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderBottom(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
      }
    }
    else {
      //set border of selected hint
      sheet.getCell(field.rowIndex, field.colIndex).borderLeft(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
      sheet.getCell(field.rowIndex, field.colIndex).borderRight(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
      sheet.getCell(field.rowIndex, field.colIndex).borderTop(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
      sheet.getCell(field.rowIndex, field.colIndex).borderBottom(new GC.Spread.Sheets.LineBorder("blue", GC.Spread.Sheets.LineStyle.thick));
    }
  }

  public hintChecked(event, field: any, category: any): void {
    event.preventDefault();
    event.stopPropagation();

    const self = this;
    const sheet = self.spreadsheet.getActiveSheet();

    const index = self.categories.findIndex(item => item.title === category.title);
    let hintToDeleteIndex = self.categories[index].fields.findIndex(e => e.fieldName === field.fieldName && e.message === field.message);
    self.categories[index].fields.splice(hintToDeleteIndex, 1);

    let sameHints = [];
    if (field.count) {
      if (category.title === "errors") {
        sameHints = self.resultsError.filter(e => e.fieldName === field.fieldName && e.message === field.message);
      }
      else if (category.title === "warnings") {
        sameHints = self.resultsWarning.filter(e => e.fieldName === field.fieldName && e.message === field.message);
      }

      for (let sameHint of sameHints) {
        //reset backColor of selected hints to default
        if (sameHint.rowIndex % 2 === 0) {
          sheet.getCell(sameHint.rowIndex, sameHint.colIndex).backColor('#FAFAFA');
        }
        else {
          sheet.getCell(sameHint.rowIndex, sameHint.colIndex).backColor('#FFFFFF');
        }
        //reset border of selected hints to default
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderLeft(null);
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderRight(null);
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderTop(new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin));
        sheet.getCell(sameHint.rowIndex, sameHint.colIndex).borderBottom(new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin));
      }
    }
    else {
      //reset backColor of selected hint to default
      if (field.rowIndex % 2 === 0) {
        sheet.getCell(field.rowIndex, field.colIndex).backColor('#FAFAFA');
      }
      else {
        sheet.getCell(field.rowIndex, field.colIndex).backColor('#FFFFFF');
      }
      //reset border of selected hint to default
      sheet.getCell(field.rowIndex, field.colIndex).borderLeft(null);
      sheet.getCell(field.rowIndex, field.colIndex).borderRight(null);
      sheet.getCell(field.rowIndex, field.colIndex).borderTop(new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin));
      sheet.getCell(field.rowIndex, field.colIndex).borderBottom(new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin));
    }

    // asjust row errors or warnings count
    let rowErrorsToDelete = self.resultsError.filter(e => e.fieldName === field.fieldName && e.message === field.message);
    let rowWarningsToDelete = self.resultsWarning.filter(e => e.fieldName === field.fieldName && e.message === field.message);
    for (let row = 1; row < sheet.getRowCount() - 1; ++row) {
      for (let i = 0; i < rowErrorsToDelete.length; ++i) {
        if (rowErrorsToDelete[i].rowIndex === row) {
          self.rowErrorsWarnings[row - 1].errorCount -= 1;
        }
      }

      for (let i = 0; i < rowWarningsToDelete.length; ++i) {
        if (rowWarningsToDelete[i].rowIndex === row) {
          self.rowErrorsWarnings[row - 1].warningCount -= 1;
        }
      }
    }

    // asjust column errors or warnings count
    let columnErrorsToDelete = self.resultsError.filter(e => e.fieldName === field.fieldName && e.message === field.message);
    let columnWarningsToDelete = self.resultsWarning.filter(e => e.fieldName === field.fieldName && e.message === field.message);
    for (let col = 0; col < sheet.getColumnCount() - 1; ++col) {
      for (let i = 0; i < columnErrorsToDelete.length; ++i) {
        if (columnErrorsToDelete[i].colIndex === col) {
          self.currentMappings[col].errorCount -= 1;
        }
      }

      for (let i = 0; i < columnWarningsToDelete.length; ++i) {
        if (columnWarningsToDelete[i].colIndex === col) {
          self.currentMappings[col].warningCount -= 1;
        }
      }
    }
  }

  public ignoreWarnings(event, field: any, category: any): void {
    event.preventDefault();
    event.stopPropagation();
    alert('TODO');
  }

  private handleCellClicked(error: any, info: any) {
    const self = this;
    if (error && error.fieldName === 'Usage') {
      const columnIndex = info.col;
      const templateId = self.importTemplate.id;
      /*let entry: any = {
        action: 1,
        displayStyle: 1,
        canSelectImport: true,
        addedCellTransformation: 0,
        reflistName: 'ref_unit_type',
        reflistMappings: []
      };*/

      self.service.loadMappings(templateId).then((savedMappings) => {
        let entry = savedMappings.find(m => m.column === columnIndex);
        if (entry) {
          self.openReflistMapper(entry, columnIndex);
        }
      });
    } else {
      self.closeReflistMapper();
    }
    
  }

  private getAllColumnValues(columnIndex: number): Array<any> {
    const self = this;
    const values = [];
    for (let i = 1; i < self.spreadsheet.getActiveSheet().getRowCount(); ++i) {
      const value: string = (self.spreadsheet.getActiveSheet().getCell(i, columnIndex).value() || '').toString();
      if (values.indexOf(value) < 0 && value !== '') {
        values.push(value);
      }
    }

    return values;
  }

  private openReflistMapper(entry: any, columnIndex: number): void {
    const self = this;
    const values = self.getAllColumnValues(columnIndex);

    self.currentReflistMapperValues = values;
    self.currentReflistMapperEntry = entry;
  }

  private closeReflistMapper(): void {
    const self = this;
    self.currentReflistMapperValues = null;
    self.currentReflistMapperEntry = null;
  }

  public reflistMappingEditorClosed(state: string) {
    const self = this;
    if (state === 'yes') {
      const templateId = self.importTemplate.id;
      const mapperEntry = JSON.parse(JSON.stringify(self.currentReflistMapperEntry));
      //save and revalidate
      self.service.saveSingleReflistMapping(templateId, mapperEntry).then((gpr: any) => {
        if (gpr.success) {
          setTimeout(() => {
            //revalidate
            self.fileJson = self.spreadsheet.toJSON({ includeBindingSource: true });
            const dto = JSON.stringify(self.fileJson);

            self.service.validate(dto, templateId, true).then((data: SynapsesDqsValidationResult) => {
              self._data = null;
              setTimeout(() => {
                self._data = data;
              });
            }).catch(() => {
              //TODO
            });
          });
        } else {
          alert("Failed to save mapping!");
        }
      }).catch(() => {
        alert("Failed to save mapping!");
      });
    }

    self.closeReflistMapper();
  }

  private ensureCellInViewPort(cellInfo): void {
    
    //header above sheet = 200px
    //left side (validation result) = 50px width
    //TODO: get real values from DOM
    const headerHeight = 200;
    const leftSideWidth = 50;

    const sheet = cellInfo.sheet;
    const row = cellInfo.row;
    const col = cellInfo.col;

    const containerEl = this.dqsValidationScrollContainer.nativeElement;

    const scrollLeft = containerEl.scrollLeft;
    const scrollTop = containerEl.scrollTop;

    const visibleWidth = containerEl.clientWidth;//containerEl.offsetWidth;
    const visibleHeight = containerEl.clientHeight;//containerEl.offsetHeight;
    
    const cellRect = sheet.getCellRect(row, col);
    const cellWidth = cellRect.width;
    const cellHeight = cellRect.height;

    //calc where is cell at the moment
    const cellX = cellRect.x + leftSideWidth;//col * cellWidth + leftSideWidth;
    const cellY = cellRect.y + headerHeight;//row * cellHeight + headerHeight;

    //is it in viewport?
    const viewportX = scrollLeft;
    const viewportY = scrollTop;

    const isInViewPortX = cellX > viewportX && (cellX + cellWidth) < (viewportX + visibleWidth);
    const isInViewPortY = cellY > viewportY && (cellY + cellHeight) < (viewportY + visibleHeight);

    if (!isInViewPortX) {
      //console.log("X not in viewport");
      const directionRight = cellX > viewportX;
      const scrollAmount = cellWidth * 1.1;
      const scrollToX = scrollLeft + (directionRight ? scrollAmount : - scrollAmount);
      containerEl.scrollTo(scrollToX, scrollTop);
    }

    if (!isInViewPortY) {
      //console.log("Y not in viewport");
      const directionDown = cellY > viewportY;
      const scrollAmount = cellHeight * 1.1;
      const scrollToY = scrollTop + (directionDown ? scrollAmount : - scrollAmount);
      containerEl.scrollTo(scrollLeft, scrollToY);
    }

    //edge cases adjustments
    if (col === 0 && containerEl.scrollLeft !== 0) {
      containerEl.scrollTo(0, scrollTop);
    }

    if (row === 1 && containerEl.scrollTop !== 0) {
      containerEl.scrollTo(scrollLeft, 0);
    }
  }
}
