import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'smart-bar-item',
  templateUrl: './smart-bar-item.component.html',
  styleUrls: ['./smart-bar-item.component.css']
})
export class SmartBarItemComponent implements OnInit {
  @Input() filterItem: any;
  @Input() allValues: any[];
  @Input() area: string;
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  isItemClickedSet: boolean = false;

  ngOnInit(): void {
    this.isItemClickedSet = this.itemClicked.observers.length > 0;
  }

  public getFieldValue(oid: string) {
    const self = this;

    if (self.filterItem) {
      const fieldValues = self.allValues.filter((entry) => { return entry.field === self.filterItem.field; });
      if (fieldValues.length) {
        const availableValues = fieldValues[0].values;

        const currentValue = availableValues.filter((entry) => { return entry.oid == oid });
        if (currentValue.length) {
          return currentValue[0].description;
        }
      }
    }
    return "N/A!";
  }

  public smartBarItemClicked() {
    this.itemClicked.emit();
  }
}
