import { Pipe, PipeTransform } from '@angular/core';

import { FilterPaneItemValue } from '../models/filter-pane-item-value';

@Pipe({
    name: 'valueNotInFilter',
    pure: false
})
export class ValueNotInFilterPipe implements PipeTransform {
    transform(items: FilterPaneItemValue[], filters: FilterPaneItemValue[]): any {
        if (!items || !filters) {
            return items;
        }

        let availableValues = (items || []).filter((item : FilterPaneItemValue) => {
          return filters.indexOf(item) === -1;
        });

      return availableValues;
    }
}
