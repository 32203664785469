import { Component, Input, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import * as zc from '@dvsl/zoomcharts';
import { WindowRefService } from "../window-ref";

@Component({
  selector: 'app-zoomcharts',
  templateUrl: './zoom-charts.component.html',
  styleUrls: ['./zoom-charts.component.scss']
})

export class ZoomchartsComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() id: string;
  @Input() data: any;
  @Input() grid: boolean;

  @Output() onChartClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChartFullscreenModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('chart') chartDiv: ElementRef;

  // for optimal operation: ref to zc instance
  private zc: any = zc;
  public _chartID: string;
  private _data: any;
  private chartInstance: any;
  private lastFullscreenMode: boolean = false;
  private width: number = 0;
  private height: number = 0;

  constructor(winRef: WindowRefService) {
    winRef.nativeWindow.ZoomChartsLicense = "ZCP-he5p70237:  ZoomCharts SDK license for 3 Chart Developers";
    winRef.nativeWindow.ZoomChartsLicenseKey = "33e5694b13267f9859017a44420f5959ee8cde847c63f7794e" +
      "8998a4a218d3dd4ba67e84474c0dedd90210668d98e97ef350b3dedfc4a64e466c6254d15db88" +
      "6b92f31adda725e1019af6570ea8d3b432ec7d28a7f5fff781a735fe72c5d336557b2308e8d22" +
      "1fa9f40043758cfab6acce1109c7d30868d31001add291c9fbb8f0fe8342d3fd8c9b2d02c3798" +
      "68a039756d173548950fc5986a320630a942fad209c8531b7749ffda661a87dad0ce621a6e357" +
      "e8a51763a256c35a1fa324fb6e5e190708234cf9d8de32d22f2f8020f4cd34677661d66aa0e7a" +
      "17cb79d53d09fb1b181bdfa81543569321a4cc20156c6955e30f6b935c576421524e3f9122100";
  }

  ngOnDestroy() {
    this.chartInstance.remove();
    this.chartInstance = null;
  }

  ngAfterViewChecked() {
    if (this.chartDiv.nativeElement.clientWidth != this.width || this.chartDiv.nativeElement.clientHeight != this.height) {
      this.width = this.chartDiv.nativeElement.clientWidth;
      this.height = this.chartDiv.nativeElement.clientHeight;
      this.chartInstance.updateSize();
    }
  }

  ngOnInit() {
    this._chartID = 'chart' + this.id;
    this._data = this.data;
  }

  ngAfterViewInit() {
    var self = this;
    const FacetChart = this.zc.FacetChart;
    self.onChartFullscreenModeChanged.emit(false);
    if (self.grid) {
      self.chartInstance = new FacetChart({
        container: document.getElementById(self._chartID),
        area: {
          style: { fillColor: "#ffffff" }
        },
        labels: { enabled: false },
        data: {
          preloaded: self._data.data.Values
        },
        series: [{
          data: { field: "value" },
          style: { lineColor: "#ef796f", lineWidth: "2" }, //F9BE02,00acab,ef796f

          type: "line"
        }
        ],
        facetAxis: {
          enabled: false,
          defaultUnitWidth: 4
        },
        valueAxisDefault: {
          enabled: false
        },
        toolbar: {
          enabled: false
        },
        interaction: { resizing: { enabled: false }, scrolling: { enabled: false }, selection: { enabled: true }, zooming: { enabled: false }, mode: "select" },
        events: {
          onClick: function (event) {
            self.onChartClicked.emit(self.id);
          }
        }
      });
    }
    else {
      self.chartInstance = new FacetChart({
        container: document.getElementById(self._chartID),
        area: {
          style: { fillColor: "#ffffff" }
        },
        labels: { enabled: true },
        data: {
          preloaded: self._data.data.Values
        },
        series: [{
          data: { field: "value" },
          style: { fillColor: "#ef796f" } //F9BE02,00acab,ef796f
        }
        ],
        facetAxis: {
          enabled: true,
          defaultUnitWidth: 4
        },
        valueAxisDefault: {
          enabled: true
        },
        toolbar: {
          enabled: true, export: false, logScale: false, showLabels: false, fullscreen: true, side: "right", align: "left", location: "outside",
          items: [{
            item: "fullscreen",
            onClick: function (event) {
              if (self.chartInstance.fullscreen() !== self.lastFullscreenMode) {
                self.lastFullscreenMode = self.chartInstance.fullscreen();
                self.onChartFullscreenModeChanged.emit(self.lastFullscreenMode);
              }
            }
          }, {
            item: "back"
          }, {
            item: "zoomOut"
          }
          ]
        },
        interaction: { resizing: { enabled: false }, scrolling: { enabled: false }, selection: { enabled: true }, zooming: { enabled: true }, mode: "drilldown" },
        events: {
          onClick: function (event, args) {
            self.onChartClicked.emit(self.id);
          }
        }
      });
    }
  }
}
