import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularIntegrationService } from '../../../../services/AngularIntegrationService';
import { FilterPaneService } from '../../../filter-pane/services/filter-pane-service.service';

@Component({
  selector: 'smart-bar-filters',
  templateUrl: './smart-bar-filters.component.html',
  styleUrls: ['./smart-bar-filters.component.css']
})
export class SmartBarFiltersComponent implements OnInit, OnDestroy {
  @Input() main: any;

  public area: string;
  public filters: any[];
  public allValues: any[];

  public expanded = false;
  private onEvents: Array<any> = [];
  constructor(private ais: AngularIntegrationService, private filterService: FilterPaneService) { }

  ngOnInit() {
    const self = this;
    self.setupEvents();

    self.area = self.main.workplace.searchMode;

    self.reload();
  }

  ngOnDestroy() {
    const self = this;
    self.onEvents.forEach(unregister => {
      try {
        unregister();
      } catch (e) {
        //Nothing
      }
    });
  }
  private reload() {
    const self = this;
    if (this.ais.appStorage.getSelectedProject()) {
      Promise.all([
        self.filterService.getActiveFilterSet(self.area),
        self.filterService.loadDataForConfig(self.area)
      ]).then((data: any[]) => {
        self.evaluateFilters(data[0], data[1]);
      });
    } else {
      self.allValues = [];
      self.filters = [];
    }
  }

  private evaluateFilters(filters: any, allValues: any[]) {
    const self = this;
    if (filters && filters.area === self.area) {
      self.allValues = allValues;
      self.filters = filters.filters;
    }
  }

  private setupEvents() {
    const self = this;

    let $rootScope = self.ais.$rootScope;

    self.onEvents.push($rootScope.$on('toolbarSetArea', function (event, params) {
      if (self.area != params) {
        self.area = params;
        self.reload();
      }
    }));

    self.onEvents.push(self.filterService.filtersChanged$.subscribe((filters) => {
      if (filters && filters.area === self.area) {
        self.filterService.loadDataForConfig(self.area).then((allValues: any) => {
          self.evaluateFilters(filters, allValues);
        });
      }
    }));
  }

  public toggleFilterVisiblity() {
    if (!this.filters) {
      this.expanded = false;
      return;
    }
    this.expanded = !this.expanded;
  }
}
