(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationConfirmDeletePropertyDialog
   * @description
   * Dialog that, after confirmation, deletes property
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires DXSPolluxApp.service:ValuationPropertiesResource
   * @requires DXSPolluxApp.service:applicationUserInfoResource
   * @requires $translate
   * @requires DXSPolluxApp.service:appStorage
   *
   */

  angular.module("DXSPolluxApp")
    .component("projectValuationConfirmDeletePropertyDialog", {
      template: require('html-loader!./project.valuation.confirm.delete.property.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', 'ValuationPropertiesResource', 'applicationUserInfoResource', '$translate', 'appStorage', '$timeout', projectValuationConfirmDeletePropertyDialogCtrl]
    });

  function projectValuationConfirmDeletePropertyDialogCtrl($scope, $rootScope, ValuationPropertiesResource, applicationUserInfoResource, $translate, appStorage, $timeout) {
    var vm = this;

    vm.appStorage = appStorage;
    vm.confirmWindow = null;

    vm.confirm = confirm;
    vm.cancel = cancel;

    vm.userInfo = {};
    applicationUserInfoResource.userinfo.get({},
      function (data) { vm.userInfo = data; },
      function (err) { vm.err = err; alert("Error loading UserInfo:" + JSON.stringify(err)); });

    /**
    * @ngdoc function
    * @name confirm
    * @methodOf DXSPolluxApp.component:projectValuationConfirmDeletePropertyDialog
    * @description
    * Deletes property and closes a dialog
    */
    function confirm() {
      vm.confirmWindow.close();

      //if (vm.userInfo.Roles && (vm.userInfo.Roles.indexOf('PowerUser') >= 0 || vm.userInfo.Roles.indexOf('Administrator') >= 0)) {
      ValuationPropertiesResource.remove(vm.propertyId).then(
        function (result) {
          if (result.m_Item1) {
            $rootScope.$broadcast('PropertyLoadData', vm);
            $rootScope.$broadcast('property.archived', { oid: vm.propertyId });
          } else {
            alert(result.m_Item2);
          }
        },
        function (error) {
          vm.err = error;
          alert(error.data.ExceptionMessage);
        });
      //}
      //else
      //    alert($translate.instant('projectValuationConfirmDeletePropertyDialog.InsufficientRights'));
    }

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationConfirmDeletePropertyDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      vm.confirmWindow.close();

    }

    //#region "EVENTS"

    $scope.$on('deleteProperty', function (event, selectedItem) {
      vm.propertyId = selectedItem.Oid;
      vm.confirmWindow.title($translate.instant('projectValuationConfirmDeletePropertyDialog.title'));
      $timeout(function () {
        vm.confirmWindow.center();
        vm.confirmWindow.open();
      });
    });

    //#endregion "EVENTS"
  }
}());

