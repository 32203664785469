(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('appRetrieverResource',
      ['$resource', 'appServiceHelper', appRetrieverResource]);

  function appRetrieverResource($resource, appServiceHelper) {
    return {
      User: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'dxsRetriever/User'), { searchTerm: '@searchTerm' }),

      Property: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'dxsRetriever/Property'), { searchTerm: '@searchTerm' }, { 'query': { method: 'POST', isArray: false } }),
      ValuationPortfolioParentCandidates: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'dxsRetriever/ValuationPortfolioParentCandidates'), { searchTerm: '@searchTerm', jobTypeId: '@jobTypeId', propertyId: '@propertyId' }),
      ValuationPortfolio: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'dxsRetriever/ValuationPortfolio'), { searchTerm: '@searchTerm', propertyId: '@propertyId' })
    }
  }
}());

