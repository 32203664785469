import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { JobDataLinkingComponent } from './job-data-linking.component';

export const routerModule = RouterModule.forChild([{
  path: '',
  component: JobDataLinkingComponent
}]);

@NgModule({
  imports: [
    CommonModule,
    routerModule
  ],
  declarations: [JobDataLinkingComponent]
})
export class JobDataLinkingModule { }
