(function () {
  'use strict';

  angular
    .module('DXSPolluxApp')
    .factory('appUserAvatarService', ['$q', '$timeout', appUserAvatarService]);

  function appUserAvatarService($q, $timeout) {

    let avatarCache = {};

    function cache_exists(userId) {
      const key = `key_${userId}`;
      return typeof(avatarCache[key]) !== 'undefined';
    }

    function cache_get(userId) {
      const key = `key_${userId}`;
      return avatarCache[key] ? avatarCache[key] : null;
    }

    function cache_set(userId, avatarUrl) {
      const key = `key_${userId}`;
      avatarCache[key] = avatarUrl;
    }

    function getUserAvatarUrlAsync(userId) {
      if (cache_exists(userId)) {
        let avatarUrl = cache_get(userId);
        return $q.when(avatarUrl);
      }

      var deferred = $q.defer();
      //TODO: this is just mockup until we define where user images/avatars are stored
      $timeout(function () {
        let avatarUrl = null;

        //if (userId === 14) {
        //  avatarUrl = "https://ionicframework.com/img/docs/mcfly.jpg";
        //}

        cache_set(userId, avatarUrl);
        deferred.resolve(avatarUrl);

      }, 250);

      return deferred.promise;
    }

    function getInitials(userDescription) {
      var list = userDescription ? userDescription.split(',') : '';
      if (list.length > 1) {
        return list[1].trim().substring(0, 1) + list[0].trim().substring(0, 1);
      }
      return userDescription ? userDescription.trim().substring(0, 1) : '';
    }

    return {
      getUserAvatarUrlAsync: getUserAvatarUrlAsync,
      getInitials: getInitials
    };
  }
}());
