(function () {

  "use strict";

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:projectValuationGridKpiSettingsService
   * @description
   * This service persists user specific column arrangements in the workspace grids.
   * 
   * @requires $resource
   */
  angular
    .module('DXSPolluxApp')
    .factory('projectValuationGridKpiSettingsService',
      ['projectValuationGridKpiSettingsResource', '$q', '$timeout', 'reflistResource', projectValuationGridKpiSettingsService]);

  function projectValuationGridKpiSettingsService(projectValuationGridKpiSettingsResource, $q, $timeout, reflistResource) {

    var loadStartedDfd = null; //Prevents from double-loading when called while loading

    var availableKpis = [];
    var userKpiSettings = [];
    var userKpiTopByArea = {};

    function loadData(area, oid) {
      return projectValuationGridKpiSettingsResource.data.get({ area: area, oid: oid }).$promise;
    }

    /**
     * @ngdoc function
     * @name load
     * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsService
     * @description
     * Loads the kpi settings for the current user
     */
    function load(reload) {
      var dfd = $q.defer();

      if (reload) {
        loadStartedDfd = null;
      }

      if (loadStartedDfd) {
        //Loading is already in progress - wait for it to terminate and return
        $q.all([
          loadStartedDfd
        ]).then(function () {
          //Loading is done - resolve
          dfd.resolve();
        });

        return loadStartedDfd.promise;
      }

      loadStartedDfd = $q.defer(); //Loading is in progress

      projectValuationGridKpiSettingsResource.userKpis.query(
        {},
        function (dataUserKpiSettings) {

          userKpiSettings = dataUserKpiSettings;

          projectValuationGridKpiSettingsResource.availableKpis.query(
            {},
            function (dataAvailableKpis) {
              availableKpis = dataAvailableKpis; //if the user has never before saved kpi settings use the default

              generateUserKpiTopByArea();

              dfd.resolve();
              loadStartedDfd.resolve();
            },
            function (error) {
              dfd.reject(error);
              vm.err = error;
              alert(error.data.ExceptionMessage);
            }
          );
        },
        function (error) {
          dfd.reject(error);
          vm.err = error;
          alert(error.data.ExceptionMessage);
        }
      );
      //});

      return dfd.promise;
    }

    //function insertKpiNames(kpis) {
    //  debugger;
    //  for (var i = 0; i < kpis.length; ++i) {
    //    kpis[i].name = refKpiSettings.filter((kpi) => kpi.oid === kpis[i].id)[0].description; // there should be no conflicts here
    //  }
    //  return kpis;
    //}

    /**
     * @ngdoc function
     * @name updateKpiSettings
     * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsService
     * @description
     * Saves the kpi settings for the current user.
     * 
     * @param {Object} data The new kpi settings
     */
    function updateKpiSettings(area, areaTypeId, data) {
      userKpiSettings = userKpiSettings.filter((kpi) => { return kpi.area !== area || kpi.areaTypeId !== areaTypeId; });//remove old entries (the dialog gives us the selected area only)
      userKpiSettings = userKpiSettings.concat(data);

      generateUserKpiTopByArea();

      projectValuationGridKpiSettingsResource.userKpis.save({}, userKpiSettings,
        function (result) {
          if (result.success) {
            //success
          }
          else {
            alert("An error occurred while saving the kpi settings:\r\n" + result.message);
          }
        },
        function (error) {
          alert("An error occurred while saving the kpi settings:\r\n" + JSON.stringify(error));
        });
    }

    function getAreaTypeKey(areaTypeId) {
      return 'areaTypeId_' + areaTypeId;
    }

    function generateUserKpiTopByArea() {
      userKpiTopByArea = {
        "job": {},
        "property": {}
      };
      $timeout(function () {
        var kpis = userKpiSettings.filter((kpi) => kpi.sortOrder < 6).sort((kpi1, kpi2) => kpi1.sortOrder - kpi2.sortOrder);
        for (var i = 0; i < kpis.length; ++i) {
          const kpi = kpis[i];
          const area = kpi.area;
          const areaKey = getAreaTypeKey(kpi.areaTypeId);

          if (typeof userKpiTopByArea[area][areaKey] === 'undefined') {
            userKpiTopByArea[area][areaKey] = [];
          }

          userKpiTopByArea[area][areaKey].push(kpi);
        }
      });
    }

    /**
    * @ngdoc function
    * @name getAvailableKpis
    * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsService
    * @description
    * Gets the available kpi categories.
    */
    function getAvailableKpis() {
      return availableKpis;
    }

    /**
     * @ngdoc function
     * @name getUserKpiSettings
     * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsService
     * @description
     * Gets the kpi settings for the current user.
     */
    function getUserKpiSettings() {
      return userKpiSettings;
    }

    /**
     * @ngdoc function
     * @name getUserKpiTopByArea
     * @methodOf DXSPolluxApp.service:projectValuationGridKpiSettingsService
     * @description
     * Gets users top kpi categories.
     * 
     * @param {string} area The desired area
     */
    function getUserKpiTopByArea(area, _areaTypeId) {
      const areaTypeId = _areaTypeId || 1;//fallback (f.e. on property grid)
      if (area) {
        return userKpiTopByArea[area][getAreaTypeKey(areaTypeId)];
      }

      alert("getUserKpiTopByArea: area not defined!")
      return [];//userKpiTopByArea;
    }

    return {
      load: load,
      loadData: loadData,
      updateKpiSettings: updateKpiSettings,
      getAvailableKpis: getAvailableKpis,
      getUserKpiSettings: getUserKpiSettings,
      getUserKpiTopByArea: getUserKpiTopByArea
    };
  }
}());
