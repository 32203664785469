(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxSingleSelectFieldEdit
   * @description
   * This component provides the behavior and visual representation of a text field. The field is rendered as a Kendo UI drop down list
   *
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * Internally, it handles a pollux-single-select-field, but it only displays it's edit field if the content has been clicked.
   *
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $timeout
   *
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Array} choices An array of elements that can be selected
   * @param {String} valueFieldName The name of the value field. Must be present in <c>choices</c>.
   * @param {String} germanFieldName The name of the German display field. Must be present in <c>choices</c>.
   * @param {String} englishFieldName The name of the English display field. Must be present in <c>choices</c>.
   * @param {Object} item The object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {Function} filter Filter function. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, the function will be called on every change.
   * @param {Integer} index The row index of the current object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {Bool} useComboBox If <c>true</c>, a Kendo UI kendo-combo-box is used instead of the kendo-drop-down-list. This allows the user to enter a filter string.
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxSingleSelectFieldEdit", {
      template: require('html-loader!./pollux.single.select.field.edit.tmpl.html'),
      bindings: {
        "choices": "=",
        "valueFieldName": "@",
        "fieldName": "@",
        "item": "=",
        "filter": "&",
        "index": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "useComboBox": '=',
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxSingleSelectFieldEditCtrl]
    });

  function polluxSingleSelectFieldEditCtrl($timeout) {
    var vm = this;

    //#region variable declaration
    vm.editMode = false;
    vm.guid = null;
    //#endregion

    //#region function declaration
    vm.edit = edit;
    //#endregion

    vm.$onInit = function () {
      vm.guid = kendo.guid();
      if (!vm.ngId) {
        vm.ngId = kendo.guid();
      }

      $timeout(function () {
        angular.element('.' + vm.guid).focusout(function () {
          vm.editMode = false;
        });
      });

    }

    vm.onNgChange = function () {
      if (vm.ngChange) {
        vm.ngChange();
      }
    }

    function edit() {
      vm.editMode = true;
      $timeout(function () {
        angular.element('.' + vm.guid).find('.k-dropdown').focus();
      });
    }
  }
}());

