import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, ViewEncapsulation, Inject, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SortableService, SortableComponent } from '@progress/kendo-angular-sortable';
import { AngularIntegrationService } from '../../../services/AngularIntegrationService';

import { QvPropertyImagesService } from './_services/qv-property-images.service';
import { QvPropertyImageDataUriDto } from './_services/qv-property-image-datauri-dto';
import { QvPropertySaveImageDto } from './_services/qv-property-save-image-dto';
import { TranslateService } from '@ngx-translate/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({
  selector: 'qv-property-image-management',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './qv-property-image-management.component.html',
  styleUrls: ['./qv-property-image-management.component.scss']
})
export class QvPropertyImageManagementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() oid: any;
  @Input() area: string;

  @ViewChild('container') container: ElementRef;
  @ViewChild('sortable') public sortable: SortableComponent;
  @ViewChildren('imageTypeList') public imageTypeLists: QueryList<SortableComponent>;
  @ViewChild("kendoUpload", { static: true }) kendoUpload: any;


  public images: Array<QvPropertyImageDataUriDto> = [];
  public imageTypes: any = [];
  public newImagePlaceholder: QvPropertyImageDataUriDto;
  public categories: any[] = null;

  private sidebarActions: Array<any>;

  constructor(private ais: AngularIntegrationService
    , private imageService: QvPropertyImagesService
    , private sortableService: SortableService
    , @Inject('appBusyIndicatorService') private busyIndicator: any
    , @Inject('appConfirmDialogService') private confirmDialog: any
    , @Inject('appSidebarActionsService') private sidebarActionsService: any
    , @Inject('fileDownloadService') private fileDownloadService: any
    , private translator: TranslateService
  ) {

    this.sidebarActions = [
      { imgUrl: '/assets/images/arrow-undo-outline.svg', translationKey: 'imageManagement.toggleComments', callback: this.toggleAllComments.bind(this) }

    ];
  }

  private setBusy(visible: boolean) {
    this.busyIndicator.setVisible(visible, this.container.nativeElement);
  }

  public doClose() {
    this.ais.$rootScope.$broadcast('propertiesData.canceled');
  }

  ngOnInit() {
    this.sidebarActionsService.setActions(this.sidebarActions);

    this.translator.onLangChange.subscribe(() => {
      this.getImageTypes();
    });
  }

  ngAfterViewInit() {
    if (this.oid) {

      this.categories = [{ name: "noImageType", imageTypes: [] }, { name: "predefined", imageTypes: [] }, { name: "custom", imageTypes: [] }];
      this.getImageTypes();

      this.reloadImages();
    }
  }

  private getImageTypes(): void {
    const self = this;

    const locale = self.translator.currentLang;

    this.imageService.getReflist("ref_property_imagetype", locale).then(result => {
      this.imageTypes = result;

      /*
        Task 7299: Remove image type
        For some (uknown "kendo") reason, types can't be dropped on image (on right side) which has greater index than count
        of items in imageType category (f.e. noImageType originaly has one item and can be dropped only on image with index 0)
        So.. we fill categories (see fillWithDummies) with dummy items (up to MAX_IMAGES_COUNT) that are hidden (see dummyItem: true) in left imageTypes area
      */
      const MAX_IMAGES_COUNT: number = 100;

      //category no Image type
      const noImageType = this.categories.find(entry => entry.name === 'noImageType');
      const imageTypesforNoImageType = this.imageTypes.filter(type => type.oid === 0);
      noImageType.imageTypes = imageTypesforNoImageType;
      this.fillWithDummies(noImageType.imageTypes, MAX_IMAGES_COUNT);

      //category property Images
      const propertyImages = this.categories.find(entry => entry.name === 'predefined');
      const imageTypesforPropertyImages = this.imageTypes.filter(type => type.oid >= 1 && type.oid <= 9);
      propertyImages.imageTypes = imageTypesforPropertyImages;
      this.sortArray(propertyImages.imageTypes, "description");
      this.fillWithDummies(propertyImages.imageTypes, MAX_IMAGES_COUNT);

      //category custom Images
      const customImages = this.categories.find(entry => entry.name === 'custom');
      const imageTypesforCustomImages = this.imageTypes.filter(type => type.oid === 10);
      customImages.imageTypes = imageTypesforCustomImages;
      this.sortArray(customImages.imageTypes, "description");
      this.fillWithDummies(customImages.imageTypes, MAX_IMAGES_COUNT);
    });
  }

  private fillWithDummies(imageTypesArray: Array<any>, MAX_IMAGES_COUNT: number) {
    const currentLength = imageTypesArray.length;
    let addDummiesCount = MAX_IMAGES_COUNT - currentLength;
    while (addDummiesCount > 0) {
      imageTypesArray.push({ oid: -1, description: 'DUMMY', dummyItem: true });
      addDummiesCount--;
    }
  }

  ngOnDestroy() {
    this.sidebarActionsService.clear();
  }

  public reloadImages(fullReloadIds: Array<number> = []): void {
    this.setBusy(true);

    let imagesCache: Array<QvPropertyImageDataUriDto> = [];
    if (fullReloadIds.length > 0) {
      //remember data that didn't change
      imagesCache = this.images.filter(image => !image.isPlaceholder && fullReloadIds.indexOf(image.Oid) === -1);
    }

    const uploadPlaceholderImage = new QvPropertyImageDataUriDto();
    uploadPlaceholderImage.uploadPlaceholder = true;

    this.images = [uploadPlaceholderImage];
    this.imageService.loadInfo(this.oid).subscribe(data => {
      if (data && data.length) {
        data.sort((a, b) => a.Index - b.Index);//sort by Index
        this.images = data.concat(this.images);//placeholder at the end
      }

      const imagesInCache = this.images.filter(image => imagesCache.map(ci => ci.Oid).indexOf(image.Oid) !== -1 && !image.uploadPlaceholder);
      const imagesNotInCache = this.images.filter(image => imagesCache.map(ci => ci.Oid).indexOf(image.Oid) === -1 && !image.uploadPlaceholder);

      imagesInCache.forEach(image => {
        const cached = imagesCache.find(ci => ci.Oid === image.Oid);
        Object.assign(image, cached);
      });

      imagesNotInCache.forEach(image => {
        setTimeout(() => {
          //load native size, so we get warning about size (f.e. less than 800x600)
          this.imageService.loadImage(this.oid, image.Oid, -1, -1, true).subscribe(data => {
            if (data && data.length) {
              data = data[0];
              Object.assign(image, data);
            }
          });
        }, 20);
      });

      this.refreshImagesUILocal();
      this.setBusy(false);
    });
  }

  public isTypeInUse(item: any) {
    return this.images.filter(image => image.Type === item.oid && !image.isPlaceholder).length > 0;
  }

  private refreshImagesUILocal() {
    this.images = [...this.images];//refresh sortable (new array)
  }

  public deleteImage(item: QvPropertyImageDataUriDto) {
    let deleteItems = [];
    if (item) {
      //remove this image and also all checked
      deleteItems = this.images.filter(img => img.checked && img !== item);//all but clicked
      deleteItems.push(item);//plus clicked
    }
    else {
      deleteItems = this.images.filter(img => img.checked);//all checked
    }

    const self = this;
    self.setBusy(true);
    this.imageService.delete(deleteItems.map(item => item.Oid))
      .then(async function (results: Array<any>) {
        self.setBusy(false);
        var hasErrors: boolean = false;
        results.forEach(result => {
          if (!result.errorMessage) {
            const image: any = self.images.find(img => img.Oid === result.oid);
            const idx: number = self.images.indexOf(image);
            self.images.splice(idx, 1);
          } else {
            hasErrors = true;
          }
        });

        if (hasErrors) {
          alert(`Some image failed to be deleted!`);
        }

        await self.reindexImages();
        self.refreshImagesUILocal();

        self.loadData();
      });
  }

  public downloadImage(item: QvPropertyImageDataUriDto) {
    const self = this;

    this.setBusy(true);
    this.imageService.loadOriginalSizeImage(self.oid, item.Oid).subscribe(data => {
      this.setBusy(false);
      if (data && data.length) {
        let image = data[0];

        const url = image.Data;
        const filename = image.Description != "" ? image.Description : (image.ImageTypeDescription != "" ? image.ImageTypeDescription : "image");
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      }
    });
  }

  public updateSidebarActions() {
    const self = this;
    const checkedItems = this.images.filter(img => img.checked);//all checked
    const sidebarActions = [
      { imgUrl: '/assets/images/arrow-undo-outline.svg', translationKey: 'imageManagement.toggleComments', callback: this.toggleAllComments.bind(this) }
    ];

    if (checkedItems.length) {
      sidebarActions.push(
        {
          imgUrl: '/assets/images/trash-outline.svg', translationKey: 'imageManagement.deleteSelectedImages', callback: function () {
            self.confirmDialog.open({
              success: function () {
                self.deleteSelectedImages();
              },
              cancel: function () {
                //Nothing
              }
            }, 'imageManagement.confirmDialogDelete');
          }
        },
        { imgUrl: '/assets/images/download-outline.svg', translationKey: 'imageManagement.downloadSelectedImages', callback: this.downloadSelectedImages.bind(this) })

    }
    self.sidebarActionsService.setActions(sidebarActions);
  }

  public updateDescription(item: QvPropertyImageDataUriDto) {
    this.imageService.updateDescription(item.Oid, item.Description);
  }

  //#region Image types
  public dragTypeInProgress: boolean = false;

  public imageTypeDragStart(e) {
    this.dragTypeInProgress = true;
  }

  public imageTypeDragOver(e) {
    console.log(e);
    e.preventDefault();
  }

  public imageTypeDragEnd(e, category) {
    this.dragTypeInProgress = false;
    let draggedToItself = this.sortableService.target && this.sortableService.target.wrapper.classList.contains("imageTypeSortable");
    if (draggedToItself || e.index < 0/*dropped somewhere outside of sortables*/) {
      //wasn't dropped on images sortable
      e.preventDefault();
      return;
    }

    const activeiImageTypeList = this.imageTypeLists.toArray().find(entry => entry.itemClass.toString().includes(category.name)); // find the SortableComponent with the category name 
    const item = activeiImageTypeList.getActiveItem();
    const image = this.images[e.index];
    if (image.Type !== item.oid) {
      //type changed
      this.imageService.updateType(image.Oid, item.oid).then((result) => {
        image.Type = item.oid;
        image.ImageTypeDescription = item.description;// for frontend => that the view is correct
      });
    }
  }

  public onCustomImageTypeChanged(item, e) {
    this.imageService.updateCustomType(item.Oid, item.CustomImageType).then((result) => {
    });
  }

  //#endregion Image types

  //#region Images
  public setAllowImageDrag(flag) {
    this.allowImageDrag = flag;
  }

  //https://stackoverflow.com/questions/44663562/kendo-ui-angular-2-sortable-drag-handle
  private allowImageDrag: boolean = false;

  public sortableDragStart(e): void {
    if (!this.allowImageDrag) {
      e.preventDefault();
      return;
    }
  }

  public sortableDragEnd(e): void {
    this.resetItemsFileDragState();
    this.resetGlobalFileDragState();
    if (e.index !== -1 && e.oldIndex !== -1 && e.index !== e.oldIndex) {
      //position changed
      this.reindexImages();
    }
  }

  private async reindexImages(): Promise<any> {
    const self = this;
    const items = this.images.filter(i => !i.isPlaceholder && !i.uploadPlaceholder);

    return new Promise<void>((resolve, reject) => {

      if (items.length === 0) {
        //nothing to reindex
        resolve();
        return;
      }

      self.setBusy(true);
      return this.imageService.reindex(this.oid, items.map(i => i.Oid)).then(result => {
        self.setBusy(false);
        if (result.success) {
          items.forEach((item, idx) => { item.Index = idx; });//on success reindex loccally to
          self.setBusy(false);
          setTimeout(() => {
            self.ais.$rootScope.$broadcast('property.images.reindex', { images: items });
          });
          resolve();
        } else {
          alert("Re-index images error occured!");
          self.setBusy(false);
          reject();
        }
      }).catch(() => {
        self.setBusy(false);
      });
    });
  }

  //#endregion Images

  //#region File drop

  public fileDragOver: boolean = false;
  public dragOver: boolean = false;
  private disableFileDragOverHandler: boolean = false;
  private resetTimeoutId = null;

  private resetGlobalFileDragState() {
    this.dragOver = false;
    this.fileDragOver = false;
  }

  private resetItemsFileDragState() {
    this.images.forEach(function (o) {
      o.fileDragOver = false;
      o.fileDragOverAction = null;
    });
  }

  private async uploadImages(files: FileList, imageItem: QvPropertyImageDataUriDto, dropIdxOverride: number = -1) {
    let dropIdx: number = imageItem !== null ? this.images.indexOf(imageItem) : 0;
    if (dropIdxOverride >= 0) {
      dropIdx = dropIdxOverride;
    }

    const droppedOnPlaceholder: boolean = imageItem !== null ? !!imageItem.isPlaceholder : false;

    console.log(`${files.length} file(s) dropped on position ${dropIdx}.. on placeholder = ${droppedOnPlaceholder}`);

    if (!droppedOnPlaceholder && dropIdxOverride < 0) {
      dropIdx = this.images.filter(i => !i.isPlaceholder).indexOf(imageItem);
    }

    imageItem = imageItem || new QvPropertyImageDataUriDto();
    let errors: Array<any> = [];
    let uploadedIds: Array<any> = [];

    for (let idx = 0; idx < files.length; idx++) {
      try {
        let uploadResult: any = await this.uploadImage(idx, files[idx], imageItem, dropIdx, droppedOnPlaceholder);
        if (uploadResult.err) {
          errors.push(uploadResult.err);
        } else {
          let saveObj: QvPropertySaveImageDto = uploadResult.saveObj;
          uploadedIds.push(saveObj.oid);
        }
      } catch (err) {
        errors.push(err);
      }
    }

    if (errors.length > 0) {
      alert(`${errors.length} image(s) upload failed`);
    }

    this.reloadImages(uploadedIds);

    this.loadData();
  }

  private async uploadImage(idx: number, file: File, imageItem: QvPropertyImageDataUriDto, dropIdx: number, droppedOnPlaceholder: boolean): Promise<any> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        let saveObj: QvPropertySaveImageDto = new QvPropertySaveImageDto();
        saveObj.propertyId = self.oid;
        saveObj.oid = 0;
        saveObj.pictureTypeId = !droppedOnPlaceholder ? imageItem.Type : 0;
        saveObj.description = !droppedOnPlaceholder ? imageItem.Description : "";
        saveObj.index = dropIdx + idx;
        saveObj.dataUrl = reader.result as string;

        self.setBusy(true);
        self.imageService.save(saveObj).then(function (result) {
          self.setBusy(false);
          saveObj.oid = result.oid;
          resolve({ saveObj: saveObj, err: result.errorMessage });
        }).catch((e) => {
          reject('Failed to upload image!!');
        });
      }
    });
  }

  public fileDropHandler(e, imageItem, action) {
    if (!e.dataTransfer.types.length || e.dataTransfer.types[0] !== 'Files') return;//let it bubble if not file(s)
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    e.stopPropagation();

    this.resetItemsFileDragState();
    this.resetGlobalFileDragState();

    if (!e.dataTransfer || !e.dataTransfer.items) return;

    const files = e.dataTransfer.files;

    setTimeout(() => {
      this.uploadImages(files, imageItem)
    });
  }

  public dragOverHandler(e: any, imageItem: QvPropertyImageDataUriDto, action: string) {
    this.dragOver = true;
    const draggingFiles = (e.dataTransfer && e.dataTransfer.types.length && e.dataTransfer.types[0] === 'Files');

    this.resetItemsFileDragState();

    if (!draggingFiles) return;
    this.fileDragOver = true;
    if (this.disableFileDragOverHandler) return;

    //FILE DRAG
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "move";

    if (imageItem === null) {
      if (e.currentTarget && e.currentTarget.classList && e.currentTarget.classList.contains("imageManagementRight")) {
        e.dataTransfer.dropEffect = "move";//allow drop anywhere in "images area"
      } else {
        e.dataTransfer.dropEffect = "none";
      }

      return;
    }

    clearTimeout(this.resetTimeoutId);
    //if user didn't dropped (dragged files back in File Explorer, etc) => reset state (for files there is no dragEnd event)
    this.resetTimeoutId = setTimeout(() => {
      this.resetItemsFileDragState();
      this.resetGlobalFileDragState();
    }, 500);

    const usePlaceHolders = false;

    if (usePlaceHolders && imageItem) {
      imageItem.fileDragOver = true;
      imageItem.fileDragOverAction = action;

      if (!imageItem.isPlaceholder) {
        if (action === 'replace') {
          if (e.dataTransfer.items.length > 1) {
            e.dataTransfer.dropEffect = "none";
          }
        } else {
          const items = this.images;

          const placeholderFromIdx: number = items.indexOf(this.newImagePlaceholder);
          let placeholderToIdx: number = placeholderFromIdx;

          let itemIdx = items.filter(i => !i.isPlaceholder).indexOf(imageItem);

          if (action === 'before') {
            placeholderToIdx = itemIdx;//on current item place
          } else if (action === 'after') {
            placeholderToIdx = Math.min(itemIdx + 1, items.length);
          }

          if (placeholderFromIdx !== placeholderToIdx) {
            console.log(`Action: ${action}, placeholderFromIdx: ${placeholderFromIdx}, placeholderToIdx: ${placeholderToIdx}, itemIdx: ${itemIdx}`);
            this.disableFileDragOverHandler = true;
            setTimeout(() => {
              this.disableFileDragOverHandler = false;//let Kendo do its stuff
            }, 50);

            this.sortable.moveItem(placeholderFromIdx, placeholderToIdx);
            console.log(`placeholder moved..`);
          }
        }
      }
    }
  }

  //#endregion File drop

  private deleteSelectedImages(): void {
    this.deleteImage(null);
  }

  private downloadSelectedImages(): void {
    const self = this;
    const checkedItems = self.images.filter(img => img.checked).map((entry) => { return entry.Oid; });//all checked
    self.fileDownloadService.initiateDownloadPost(`${environment.POLLUX_PROJECTVALUATION_API_URL}/picturesexport/pictures/zip?propertyId=${self.oid}`, checkedItems);
  }

  public reviewCheckedItems(): void {
    setTimeout(() => {
      this.updateSidebarActions();
    });
  }

  //#region Comments handling

  public commentsOn: boolean = false;

  public toggleComment(item: QvPropertyImageDataUriDto) {
    item.commentActive = !item.commentActive;
  }

  private toggleAllComments(): void {
    const self = this;
    self.commentsOn = !this.commentsOn;
    this.images.filter(i => !i.isPlaceholder).forEach(i => { i.commentActive = self.commentsOn; });
  }

  public updateComment(item: QvPropertyImageDataUriDto) {
    this.imageService.updateComment(item.Oid, item.Comment);
  }

  //#endregion Comments handling


  //#region category field color
  public getCategoryColor(category: string): string {
    const self = this;

    const colors = ['transparent', '#D9306B', '#F39B13', '#22A498', '#A8D4B7', '#F67599'];

    const index = self.categories.indexOf(category);
    if (index >= colors.length) {
      return '#FF0000;';
    }
    return colors[index];
  }
  //#endregion category field color

  //#region sort array
  public sortArray(array: any, property: string): any {
    array.sort(function (a, b) {
      if (a[property] < b[property])
        return -1;
      if (a[property] > b[property])
        return 1;
      return 0;
    });

    return array;
  }
  //#endregion sort array

  getQualityWarningDescription(qualityWarning): string {
    if (qualityWarning.indexOf("QW1:") === 0) {//QW2: Aspect ratio != 4:3
      return this.translator.instant("imageManagement.qualityWarning1");
    } else if (qualityWarning.indexOf("QW2:") === 0) {//QW2: Aspect ratio != 4:3
      return this.translator.instant("imageManagement.qualityWarning2");
    }

    return qualityWarning;
  }

  ignoreQualityWarningErrorClicked(item) {
    const pictureId = item.Oid;
    const self = this;
    self.setBusy(true);
    this.imageService.disableQualityWarnings(pictureId).then(result => {
      self.setBusy(false);
      if (result.success) {
        item.QualityWarnings = [];
      } else {
        alert("Error occured!");
        self.setBusy(false);
      }
    }).catch(() => {
      self.setBusy(false);
    });

  }

  //#region Kendo (button) upload
  public imageUploadUrl: string = "TODO";

  public fileUploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpg'],
    maxFileSize: 8388608 //8MB
  };

  public uploadProgressEventHandler(e) {
    //do nothing
  }

  public loadData() {
    this.ais.$rootScope.$broadcast('PropertyLoadData');
  }

  public onFileSelectOrDropped(e) {
    e.prevented = true;//we wont use default kendo upload, so prevent
    if (e.files && e.files.length) {
      const extensions = [...new Set(e.files.map(f => f.extension.toLowerCase()))] as Array<string>;
      const extensionChecks = extensions.map(ext => this.fileUploadRestrictions.allowedExtensions.indexOf(ext) !== -1);
      const unsupportedExtension = extensionChecks.find(check => check === false) === false;//we found (at least) one false value
      if (unsupportedExtension) {
        alert("Please upload just .png or .jpg images!");
        return;
      }

      const rawFiles = e.files.map(f => f.rawFile);
      setTimeout(() => {
        const dropIdx = this.images.length - 1;//position on the end
        this.uploadImages(rawFiles, null, dropIdx);
      })
    }
  }

  public onUploadEvent(e) {
    //do nothing: shouold not be called at all because prevented
  }

  public successEventHandler(e) {
    if (e.response.ok) {
         //nothing
    }
  }

  public errorEventHandler(e) {
    alert("Upload image error!");
  }

  //#endregion Kendo (button) upload
}
