(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationCopyValuationPortfolioResource
     * @description
     * This service is responsible for copying of a valuation portfolio.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */

    angular
        .module('DXSPolluxApp')
        .factory('projectValuationCopyValuationPortfolioResource',
            ['$resource', 'appServiceHelper', projectValuationCopyValuationPortfolioResource]);

    function projectValuationCopyValuationPortfolioResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name copy
             * @methodOf DXSPolluxApp.service:projectValuationCompetitorDialogResource
             * @description
             * Copies a valuation portfolio and all linked data (like properties, reports,...)
             * 
             * Route: api/v2/project/valuation/portfolio/copy
             * 
             * @param {Object} portfolioCopyDetails A ValuationPortfolioDetails object to be copied
             * 
             * @returns {Object} Copied valuation portfolio
             */
            copy: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/copy'), { portfolioCopyDetails: '@portfolioCopyDetails' }),
            copyL2: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'ValuationPortfolios/copy'), { portfolioCopyDetails: '@portfolioCopyDetails' }),
            valuationPeriods: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/valuationPeriods'), { projectId: '@projectId' })
        }
    }
}());
