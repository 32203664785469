(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:reflistResource
     * @description
     * This service is responsible for retrieving reflists (ref_... tables) from the service.
     * 
     * The schema of a reflist is as following:
     * 
     *     [
     *         {
     *             "oid": 0,
     *             "description": "",
     *             "parentNo": null
     *         }
     *     ]
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .filter('reflist', [function () {
            return function (oid, reflist) {
                if (!reflist) {
                    return '';
                }
                var result = reflist.filter(function (element) {
                    return element.oid == oid;
                });
                if (result && result.length) {
                    return result[0].description;
                }
                return '';
            };
        }]);
}());
