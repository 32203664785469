(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationInfobarProfile
   * @description
   * Show User Profile and Language selection
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationInfobarProfile", {
      template: require('html-loader!./project.valuation.infobar.profile.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "data": "<"
      },
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$timeout', 'appSettings', 'appLocalization', 'appStorage', 'adalAuthenticationService', projectValuationInfobarProfileCtrl]
    });

  function projectValuationInfobarProfileCtrl($rootScope, $scope, $timeout, appSettings, appLocalization, appStorage, adalAuthenticationService) {
    var vm = this;
    //#region variables

    vm.loaded = false;

    vm.languages = [];
    vm.userLanguageId = null;
    vm.userDescription = "";
    vm.bigSidebar = false;
    vm.Initials = "";
    //#endregion

    //#region function declaration
    vm.getLocale = getLocale;
    vm.languageClicked = languageClicked;
    vm.accountClicked = accountClicked;
    vm.logoutClicked = logoutClicked;
    vm.navigationClicked = navigationClicked;
    //#endregion

    //#region function implementation

    vm.$onInit = function () {
      vm.loaded = false;

      vm.languages = appSettings.availableLocales;
      vm.userLocale = appLocalization.getLocale();

      const userDetails = appStorage.getCurrentUserDetails() || { User_Firstname: '', User_Lastname: ''};
      vm.userDescription = `${userDetails.User_Firstname} ${userDetails.User_Lastname}`;
      if (userDetails.User_Firstname) {
          vm.Initials = userDetails.User_Firstname.substring(0, 1);
      }
      if (userDetails.User_Lastname) {
          vm.Initials += userDetails.User_Lastname.substring(0, 1);
      }
      vm.bigSidebar = appStorage.getSidebarLocked();

      $timeout(function () {
        loadData(true, false);
      });
    };

    function languageClicked(item) {
      appLocalization.toggleLocale();
    }

    function accountClicked() {
      //do nothing
    }

    function logoutClicked() {
      adalAuthenticationService.logout();
    }

    function navigationClicked(size) {
      const shouldBeBig = (size === 'wide');
      if (vm.bigSidebar !== shouldBeBig) {
        vm.bigSidebar = shouldBeBig;
        appStorage.setSidebarLocked(vm.bigSidebar);
        $rootScope.$broadcast(vm.bigSidebar ? 'maximizeSidebar' : 'minimizeSidebar');
      }
    }

    function getLocale() {
      for (var i = 0; i < appSettings.availableLocales.length; ++i) {
        var locale = appSettings.availableLocales[i];
        if (appLocalization.getLocale() === locale.name) {
          return locale.name.substring(0, 2).toUpperCase();
        }
      }
      return 'N/A';
    }

    /**
    * @ngdoc function
    * @name confirm
    * @methodOf DXSPolluxApp.component:projectValuationGridKpiSettingsDialog
    * @description
    * Updates the KPI Settings and closes the dialog
    */
    function confirm() {
      vm.main.setPVInfoBarVisibility(false);
    }
    
    function loadData(openDialog, forceReload, callback) {
      vm.loaded = true;
    }

    //#endregion

    //#region events

    $scope.$on("localeChanged", function (event, params) {
      vm.languages = appSettings.availableLocales;
      vm.userLocale = appLocalization.getLocale();
    });

    //#endregion
  }
}());
