(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationOrganization", {
      template: require('html-loader!./project.valuation.organization.tmpl.html'),
      require: {
        "workplace": "^projectValuationWorkplace"
      },
      bindings: {
        "tabElement": "="
      },
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', 'appStorage', 'appSettings', 'projectValuationOrganizationResource', '$q', projectValuationOrganizationCtrl]
    });

  function projectValuationOrganizationCtrl($rootScope, $scope, appStorage, appSettings, projectValuationOrganizationResource, $q) {
    var vm = this;

    // #region variables
    vm.data = [];
    vm.showOrganizationSettingsDialog = false;
    vm.organization = null;
    // #endregion variables

    //#region function declaration
    vm.filter = filter;
    vm.navigateTo = navigateTo;
    vm.getUiNumber = getUiNumber;
    vm.openOrganizationSettingsClicked = openOrganizationSettingsClicked;
    //#endregion

    //region function implementation
    vm.$onInit = function () {
      vm.workplace.onTabCreated(this);

      const data = appSettings.startupInfo.organizations;

      vm.data = data;
    };

    function filter() {
      //Nothing
    }

    function navigateTo(organization) {
      appStorage.setOrganizationGuid(organization.organizationGuid);
      appStorage.setSelectedProject(null);

      vm.workplace.onElementSelected(vm.tabElement, organization);
      $rootScope.$broadcast('resetFiltersByProject');

      $rootScope.$broadcast('selectedOrganizationChanged');
      $rootScope.$broadcast('valuationsidebar.activateArea', 'tabProjectstd');
    }

    function getUiNumber(organization, section, type) {
      switch (section.toLowerCase()) {
        case 'qv':
          if (type === 'projects') return uiFormatNumber(organization.projectsCount);
          if (type === 'properties') return uiFormatNumber(organization.propertiesCount);
          if (type === 'jobs') return uiFormatNumber(organization.jobsCountQv);
          break;
        case 'ct':
          if (type === 'projects') return uiFormatNumber(organization.projectsCount);
          if (type === 'properties') return uiFormatNumber(organization.propertiesCount);
          if (type === 'jobs') return uiFormatNumber(organization.jobsCountCt);
          break;
        default:
          return uiFormatNumber(0);
      }

      return uiFormatNumber(0);
    }

    function uiFormatNumber(_num) {
      const num = _num || 0;
      return num < 10 ? `0${num}` : String(num);
    }

    function openOrganizationSettingsClicked($event, organization) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();

      vm.showOrganizationSettingsDialog = true;
      vm.organization = organization;
    }

    //#endregion

    //#region events    
    $scope.$on('closeOrganizationSettingsDialog', function () {
      vm.showOrganizationSettingsDialog = false;
      vm.organization = null;
    });
    //#endregion
  }
}());
