(function () {
    'use strict';

    angular
        .module('DXSPolluxApp')
        .factory('projectValuationOrganizationResource',
        ['$resource', 'appServiceHelper', projectValuationOrganizationResource]);

    function projectValuationOrganizationResource($resource, appServiceHelper) {
        return {
          invite: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/organizations/invite'), { mail: '@mail', organizationId: '@organizationId'}),
        }
    }
}());
