import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CashflowChartsService {
  constructor(private http: HttpClient) {
  }

  public load(jobId: number): Promise<any> {
    let promise = new Promise((resolve, reject) => {
    this.http
      .get(environment.POLLUX_PROJECTVALUATION_API_URL + '/ValuationPortfolios/cashflowcharts?jobId=' + jobId).subscribe((response: any[]) => {
        resolve(response);
      });

    });
    return promise;
  }
}
