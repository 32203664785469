(function () {

  "use strict";

  /**
   * @ngdoc directive
   * @name DXSPolluxApp.directive:appWorkplaceTab
   * @description
   * This directive can be used to dynamically generate the tab pane in an area's workplace. The workplace reads a config file (for example app/config/ProjectValuationTabs.json) and then the tab is generated.
   * It also provides features for hierachical filtering, showing/hiding the search bar and configuring import and export.
   * 
   * @requires $compile
   * 
   * @param {Object} component An entry from a tab config file (for example app/config/ProjectValuationTabs.json)
   */
  angular.module("DXSPolluxApp")
    .directive('appWorkplaceTab', ['$compile',
      function ($compile) {
        return {
          template: require('html-loader!./app.workplace.tab.tmpl.html'),
          link: function (scope, element, attrs) {
            // create a new angular element from the resource in the
            // inherited scope object so it can compile the element 
            // the item element represents the custom widgets
            var newElement = angular.element(scope.component.template);
            // using jQuery after new element creation, to append element
            element.append(newElement);
            // returns a function that is looking for scope
            // use angular compile service to instanitate a new widget element
            $compile(newElement)(scope);
          },
          scope: {
            component: '=component',
            workplace: '=workplace'
          }
        }
      }
    ]);
}());

