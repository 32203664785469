(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridJobShareDialog
   * @description
   * Allows to share/unshare job too CT app
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridJobShareDialog", {
      template: require('html-loader!./project.valuation.grid.job.share.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$timeout', '$rootScope', '$q', 'ValuationPortfoliosResourceCustom', '$translate', 'appConfirmDialogService', 'notificationsService', projectValuationGridJobShareDialog]
    });

  function projectValuationGridJobShareDialog($scope, $timeout, $rootScope, $q, ValuationPortfoliosResourceCustom, $translate, appConfirmDialogService, notificationsService) {
    var vm = this;
    //#region variables
    vm.window = null;
    function reset() {
      vm.share = null;
      vm.jobEntity = null;
      vm.jobId = null;
      vm.successCallback = null;
      vm.failCallback = null;
      vm.users = [];
      vm.selectedUsers = [];
    }
    //#endregion

    reset();

    //#region function declaration
    vm.onWindowClose = onWindowClose;
    vm.onOk = onOk;
    vm.cancel = cancel;
    vm.onChange = onChange;
    //#endregion

    //#region function implementation

    function onChange() {
    }

    function prepareMultiselect() {
      vm.selectOptions = {
        dataTextField: "description",
        dataValueField: "oid",
        //itemTemplate: $templateCache.get('itemTemplate.html'),
        //tagTemplate: $templateCache.get('tagTemplate.html'),
        autoBind: false,
        dataSource: new kendo.data.DataSource({
          data: vm.users
        })
      };
    }

    function openDialog() {
      const promises = [
        ValuationPortfoliosResourceCustom.loadPossibleTeamMembers(vm.jobId)
      ]

      $q.all(promises).then(function (results) {
        vm.users = results[0];

        prepareMultiselect();
        vm.window.center();
        vm.window.open();
      })
    }

    function onWindowClose() {
      reset();
    }

    function onOk() {
      if (vm.selectedUsers.length > 0) {
        shareJob();
      }
    }

    function shareJob() {
      const request = {
        jobId: vm.jobId,
        users: vm.selectedUsers.map(user => user.oid)
      };

      kendo.ui.progress(angular.element('body'), true);
      ValuationPortfoliosResourceCustom.shareJob(request)
          .then((gpr) => {
            kendo.ui.progress(angular.element('body'), false);
            if (gpr) {
              if (gpr.success) {
                notificationsService.notifyPublishChange({ jobId: vm.jobId, state: true });
                vm.successCallback && vm.successCallback();
                vm.cancel();
              } else {
                vm.failCallback(gpr.message);
              }
            } else {
              vm.failCallback("Failed to share job!");
            }
          }).catch(function (error) {
            kendo.ui.progress(angular.element('body'), false);
            vm.failCallback("Failed to share job!");
        });
    }

    function unshareJob() {
      kendo.ui.progress(angular.element('body'), true);
      ValuationPortfoliosResourceCustom.unshareJob({ jobId: vm.jobId })
          .then((gpr) => {
            kendo.ui.progress(angular.element('body'), false);
            if (gpr) {
              if (gpr.success) {
                notificationsService.notifyPublishChange({ jobId: vm.jobId, state: false });
                vm.successCallback && vm.successCallback();
              } else {
                vm.failCallback(gpr.message);
              }
            } else {
              vm.failCallback("Failed to unpublish job!");
            }
          }).catch(function (error) {
            kendo.ui.progress(angular.element('body'), false);
            vm.failCallback("Failed to unpublish job!");
        });
    }

    function turnSyncronization(syncronizationOn) {
      const request = {
        jobId: vm.jobId,
        state: syncronizationOn
      };

      kendo.ui.progress(angular.element('body'), true);
      ValuationPortfoliosResourceCustom.setJobSyncronizationState(request)
        .then((gpr) => {
          kendo.ui.progress(angular.element('body'), false);
          if (gpr) {
            if (gpr.success) {
              notificationsService.notifySyncronizationChange(request);
              vm.successCallback && vm.successCallback();
            } else {
              vm.failCallback(gpr.message);
            }
          } else {
            vm.failCallback("Failed to change syncronization state!");
          }
        }).catch(function (error) {
          kendo.ui.progress(angular.element('body'), false);
          vm.failCallback("Failed to change syncronization state!");
        });
    }

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationGridJobShareDialog
    * @description
    * Closes the dialog 
    */
    function cancel() {
      reset();
      vm.window.close();
    }

    //#endregion

    //#region events

    function noAction() {
      //no action fallback
    }

    $scope.$on('CTShareJob', function (e, params) {

      reset();
      vm.share = params.share;
      vm.jobEntity = params.jobEntity;
      vm.jobId = params.jobEntity.Oid;
      vm.successCallback = params.successCallback || noAction;
      vm.failCallback = params.failCallback || noAction;

      if (vm.jobEntity.PublishedCopyJobId > 0 && vm.share) {
        //job is unpublished, can't be published again
        vm.failCallback("Job is unpublished. It can't be published again.");
        return;
      }

      if (!vm.share) {

        const confirmConfig = {
          success: function() {
            unshareJob();
          },
          cancel: function () {}
        };

        appConfirmDialogService.open(confirmConfig, 'unshareJobConfirmDialog', '');

      } else {
         $timeout(function () {
          openDialog();
        });
      }
    });

    $scope.$on('CTShareJobSyncronization', function (e, params) {

      reset();
      vm.jobEntity = params.jobEntity;
      vm.jobId = params.jobEntity.Oid;
      vm.successCallback = params.successCallback || noAction;
      vm.failCallback = params.failCallback || noAction;

      if (vm.jobEntity.PublishedCopyJobId > 0 && !vm.jobEntity.IsCTShared) {
        //job is unpublished, no Syncronization change
        vm.failCallback("Job is unpublished. Syncronization can't be changed.");
        return;
      }

      turnSyncronization(params.syncronizationOn);
    });

    //#endregion
  }
}());
