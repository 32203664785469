(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('ValuationPortfolioTeamResource',
      ['$resource', 'appServiceHelper', ValuationPortfolioTeamResource]);

  function ValuationPortfolioTeamResource($resource, appServiceHelper) {
    var api = 'marvin.pollux.projectValuation.api';

    var /*baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'team')),*/
      currentTeamUsersResource = $resource(appServiceHelper.getServiceEndpoint(api, 'team/current')),
      notAssignedTeamUsersResource = $resource(appServiceHelper.getServiceEndpoint(api, 'team/getnotassignedusers')),
      addUserToTeamResource = $resource(appServiceHelper.getServiceEndpoint(api, 'team/add')),
      removeUserFromTeamResource = $resource(appServiceHelper.getServiceEndpoint(api, 'team/remove'));

    function loadJobCurrentTeamUsers(jobId) {
      return currentTeamUsersResource.query({ jobId: jobId }).$promise;
    }

    function loadJobNotAssignedTeamUsers(projectId, jobId) {
      return notAssignedTeamUsersResource.query({ projectId: projectId, jobId: jobId }).$promise;
    }

    function addUserToTeam(jobId, userId) {
      return addUserToTeamResource.save({ jobId: jobId, userId: userId }, {}).$promise;
    }

    function removeUserFromTeam(jobId, userId) {
      return removeUserFromTeamResource.save({ jobId: jobId, userId: userId }, {}).$promise;
    }

    return {
      loadJobCurrentTeamUsers: loadJobCurrentTeamUsers,
      loadJobNotAssignedTeamUsers: loadJobNotAssignedTeamUsers,
      addUserToTeam: addUserToTeam,
      removeUserFromTeam: removeUserFromTeam,
    };
  }
}());
