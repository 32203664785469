import { DqsValidationModule } from "./dqs-validation/dqs-validation-module";
import { TranslationModule } from 'src/app/services/translation/translation.module';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { UploadModule } from "@progress/kendo-angular-upload";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslationModule,
    DropDownsModule,
    UploadModule,
    DqsValidationModule
  ],
  declarations: [
  ],
  providers: [
  ],
  exports: [
  ]
})
export class DqsModule { }
