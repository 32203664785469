(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationInfobar
   * @description
   * Displays an infobar. Depending on the current BO section, the following items are displayed:
   * - The settings pane is not displayed here any more in Pollux Valuation.
   * - File attachment list: Valuation portfolios and reports
   * - Image attachment list: Properties
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires $translate
   * @requires $timeout
   * @requires $http
   * @requires DXSPolluxApp.service:appSettings
   * @requires $sce
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationInfobar", {
      template: require('html-loader!./project.valuation.infobar.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      controllerAs: "vm",
      controller: ['$rootScope', 'appStorage', projectValuationInfobarCtrl]
    });


  function projectValuationInfobarCtrl($rootScope, appStorage) {
    var vm = this;
    vm.isProjectSelected = () => !!appStorage.getSelectedProject();

    //#region variables

    vm.appStorage = appStorage;
    vm.infobarItem = [];

    //#endregion

    //#region function declarations

    vm.closeButtonClicked = closeButtonClicked;
    vm.setMode = setMode;

    //#endregion

    function closeButtonClicked() {
      $rootScope.$broadcast('projectValuationInfobarClosed');
      vm.main.setPVInfoBarVisibility(false);
    }

    /**
     * @ngdoc function
     * @name setMode
     * @methodOf DXSPolluxApp.component:projectValuationInfobar
     * @description
     * Sets the infobar mode and selected item
     * 
     * @param {String} mode The mode (e.g. 'property', 'report', 'valuationPortfolio', 'valuationProject')
     * @param {Object} item The currently selected item
     */
    function setMode(mode, item) {
      vm.infobarItem[mode] = item;
      vm.mode = mode;
    }

    /**
     * @ngdoc function
     * @name $onInit
     * @methodOf DXSPolluxApp.component:projectValuationInfobar
     * @description
     * Initializes everything and hides the infobar
     */
    vm.$onInit = function () {
      vm.main.onInfobarCreated(this);
      //upon creation, infobar should be hidden
      vm.main.setPVInfoBarVisibility(false);

    };

    //#region "EVENTS"



    //#endregion "EVENTS"
  }
}());

