(function() {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('ValuationPropertiesResourceCustom',
            ['$resource', 'appServiceHelper', resourceCustom]);

    function resourceCustom($resource, appServiceHelper) {
        var api = "marvin.pollux.projectValuation.api";
        var filteredResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties/setPortfolioId'));

        function setPortfolioId(propertyId, portfolioId) {
            return filteredResource.save({
                propertyId: propertyId || 0,
                portfolioId: portfolioId || 0
            }, {}).$promise;
        }

        return {
            setPortfolioId: setPortfolioId
        };
    }
}());
