import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from "@angular/core";
import { Subscription } from 'rxjs';

import { FilterPaneService } from '../services/filter-pane-service.service';
import { FilterPaneItemConfig } from '../models/filter-pane-item-config';
import { FilterPaneItemValue } from '../models/filter-pane-item-value';

@Component({
  selector: "filter-pane-item",
  templateUrl: "./filter-pane-item.component.html",
  styleUrls: ["./filter-pane-item.component.scss"]
})
export class FilterPaneItemComponent implements OnInit, OnDestroy {

  @Input('config') config: FilterPaneItemConfig;

  @Input('active') showOnlyActive: boolean = false;

  private sub: Subscription;

  public showAllEnabled: boolean = false;
  public showAllEnabledCount: number = 5;

  constructor(private service: FilterPaneService) {
  }

  ngOnInit() {
    this.sub = this.service.resetFilters$.subscribe((reset: boolean) => {
      if (reset) {
        this.config.filters = [];
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public availableValues(values: Array<FilterPaneItemValue>): Array<FilterPaneItemValue> {
    return (this.config.values || []).filter((item : FilterPaneItemValue) => {
      return this.config.filters.indexOf(item) !== -1;
    });
  }

  public valueClicked(item: FilterPaneItemValue) {

    if (this.config.multi) {
      this.config.filters.push(item);
      this.config.filters = [...this.config.filters];//force new array
    } else {
      this.config.filters = [ item ];
    }
  }

  public filterClicked(item: FilterPaneItemValue) {
    this.config.filters = this.config.filters.filter(o => o.oid != item.oid);
  }

  public shouldShowMoreOrLess(): boolean {
    //return (this.config.values || []).length - (this.config.filters || []).length > this.showAllEnabledCount;
    return !this.showOnlyActive
      ? ((this.config.values || []).length - (this.config.filters || []).length) > this.showAllEnabledCount
      : (this.config.filters || []).length > this.showAllEnabledCount;
  }

  public showMore(): void {
    this.showAllEnabled = true;
  }

  public showLess(): void {
    this.showAllEnabled = false;
  }

}
