import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "pollux-month-picker-field",
  templateUrl: "./pollux-month-picker-field.component.html",
  styleUrls: ["./pollux-month-picker-field.component.scss"]
})
export class PolluxMonthPickerField implements OnInit {

  @Input() model: any;
  @Input() fieldName: any;
  @Input() value: any;
  @Input() hasMonthClass: any;
  @Input() availableDates: any;
  @Input() disabled: any;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();

  public dateValue: any;

  ngOnInit() {
    this.dateValue = this.value ? new Date(this.value) : null;
    this.hasMonthClass = this.hasMonthClass || 'hasMatchingDate';
  }

  public changed() {
    var dateString = null;
    if (this.dateValue) {
      //handle time zone
      dateString = new Date(this.dateValue.getTime() - (this.dateValue.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
    }

    this.model[this.fieldName] = dateString;
    this.valueChanged.emit(this.dateValue);
  }

  public disabledDates = (date: Date): boolean => {
    if (this.availableDates === "") return false;

    let matching = this.availableDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === date.getFullYear() && entryDate.getMonth() === date.getMonth();
    });

    return matching.length === 0;
  }

  public hasDateInMonth(date: Date) {
    if (this.availableDates === "") return this.hasMonthClass;

    let matching = this.availableDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === date.getFullYear() && entryDate.getMonth() === date.getMonth();
    });

    return matching.length ? this.hasMonthClass : '';
  }

  public hasDateInYear(year: any) {
    if (this.availableDates === "") return this.hasMonthClass;

    let matching = this.availableDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === parseInt(year);
    });

    return matching.length ? this.hasMonthClass : '';
  }

}
