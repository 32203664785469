(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxClientField
   * @description
   * This component provides the behavior and visual representation of a single select field. It is rendered as a Kendo UI kendo-drop-down-list.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Integer} index The row index of the current object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxClientField", {
      template: require('html-loader!./pollux.client.field.tmpl.html'),
      bindings: {
        "valueFieldName": "@",
        "fieldName": "@",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngId": "@",
        "ngDisplayedModel": '=',
      },
      controllerAs: "vm",
      controller: ['$timeout', 'appServiceHelper', 'adalAuthenticationService', polluxClientFieldCtrl]
    });

  function polluxClientFieldCtrl($timeout, appServiceHelper, adalAuthenticationService) {
    var vm = this;

    //#region variable declaration
    vm.initialCall = true;

    vm.dataSource = new kendo.data.DataSource({
      serverFiltering: true,
      transport: {
        read: {
          url: function (context) {
            var api = "marvin.pollux.api";
            if (!vm.ngModel && (!context.filter || !context.filter.filters || !context.filter.filters.length || !context.filter.filters[0].value)) {
              var searchTerm = encodeURI('%%%');
              return appServiceHelper.getServiceEndpoint(api, 'dxsRetriever/Address?searchTerm=' + searchTerm);
            }
            if (!context.filter) {
              return appServiceHelper.getServiceEndpoint(api, 'dxsRetriever/Address?searchId=' + vm.ngModel + '&searchTerm=');
            } else if (context.filter.filters.length > 0) {
              var searchTerm = encodeURI(context.filter.filters[0].value);
              return appServiceHelper.getServiceEndpoint(api, 'dxsRetriever/Address?searchTerm=' + searchTerm);
            }
          }, beforeSend: function (request, xhr) {
            var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
            if (resource !== null) {
              xhr.withCredentials = true;
              request.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
            }
          }
        }
      },
      schema: {
        data: function (response) {
          var choice = response.results.filter(function (item) {
            return item.OID == vm.ngModel;
          });
          if (choice && choice.length) {
            vm.ngDisplayedModel = choice[0].officeName;
          }
          else {
            vm.ngDisplayedModel = '';
          }
          return response.results || [];
        }
      }
    });

    vm.dropDownOptions = {
      dataTextField: vm.fieldName || "officeName",
      dataValueField: vm.valueFieldName || "OID",
      filter: "contains",
      valuePrimitive: true,
      autoBind: false,
      minLength: 3,
      change: onNgChange
    };

    //#endregion

    //#region function declaration

    //#endregion

    //#region function implementation
    function onNgChange() {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.initialCall || vm.filter && vm.filter()) {
          vm.initialCall = false;
          return;
        }
        var choice = vm.dataSource.data().filter(function (item) {
          return item.OID == vm.ngModel;
        });
        if (choice && choice.length) {
          vm.ngDisplayedModel = choice[0].officeName;
        }
        else {
          vm.ngDisplayedModel = '';
        }
        $timeout(function () {
          if (vm.ngChange) {
            vm.ngChange();
          }
        });
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.dropDownOptions.enabled = !vm.ngReadonly;

      if (vm.ngModel) {
        vm.dataSource.read().then(function () {
          vm.element.value(vm.ngModel);
          vm.element.refresh();
          onNgChange();
        });
      }
      else {
        onNgChange();
      }
    }
    //#endregion
  }
}());

