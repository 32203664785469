(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('projectValuationPortfolioTypeValuationDialogRuntime',
      ['ValuationPortfoliosResource', '$timeout', 'PropertiesResource', runtime]);

  function runtime(ValuationPortfoliosResource, $timeout, PropertiesResource) {
    this.getDialogWidth = function () {
      return 1600;
    };

    this.postProcessData = function (deferred, params) {
      //Perform any post-processing operations, including async. Resolve the promise once they are done or immediately if no async operations are required.
      //params = { vm: vm, data: data, parent: parent }
      if (params.data.Oid < 0) {
        params.data.LanguageId = 2;
        params.data.PeriodOfValuationID = 2;
      }

      params.vm.parentJobFilters = [{ name: 'jobTypeId', value: params.data.JobTypeId }, { name: 'propertyId', value: params.data.LinkedPropertyOid }];

      if (params.parent) {
        params.data.ProjectID = params.parent.ProjectStdID;
        params.data.ProjectIDDescription = params.parent.ProjectStdIDDescription;

        params.data.Client = 0;
        params.data.ClientIdDescription = '';

        params.data.CurrencyId = 1;
        params.data.CurrencyIdDescription = "Euro";
        params.data.CurrencySymbolUnicode = "8364";

        params.data.ValuationPortfolioName = 'New job for property ' + params.parent.Oid;

        params.data.LinkedPropertyOid = params.parent.Oid;
        params.data.LinkedPropertyOidDescription = params.parent.PropertyAddress;

        params.data.AcquisitionDate = params.parent.acquisitionDate;
        params.data.Opex = params.parent.opex;

        params.data.PropertyManagementFee = params.parent.pmFee;
        params.data.AssetManagementFee = params.parent.amFee;

        params.data.PlotSize = params.parent.areaAccToLandRegister;
      }

      ValuationPortfoliosResource.loadLinkingData(params.data.Oid).then(function (linkingData) {
        params.vm.linkingData = linkingData;
        if (params.data.LinkedPropertyOid) {
          ValuationPortfoliosResource.loadRentRollsDates(params.data.LinkedPropertyOid).then(function (dates) {
            params.vm.rentrollsDates = dates.sort().reverse();
            deferred.resolve();
          });
        } else {
          params.vm.rentrollsDates = [];
          deferred.resolve();
        }
      });
    };

    this.preProcessDataBeforeSave = function (deferred, params) {
      var vm = params.vm, data = params.data, parent = params.parent;

      if (!vm.data.LinkedPropertyOid) {
        vm.formInvalid = true;
        vm.suppressFormInvalidMessage = false; //Use this variable to suppress the 'invalid' message if you wish to display your own messages
      }
      else {
        vm.formInvalid = false;
        vm.suppressFormInvalidMessage = false; //Use this variable to suppress the 'invalid' message if you wish to display your own messages
      }


      deferred.resolve();
    };

    this.applyProjectName = function (vm, field) {
      if (!vm.dataLoadedTimeout && field != 'ApplyFieldsFromProjectstd') {
        //Don't react to the initial change events
        return;
      }

      var projectNameWithId = String(vm.data.ProjectIDDescription);
      if (projectNameWithId.lastIndexOf(',') >= 0) {
        vm.data.ProjectName = projectNameWithId.substring(0, projectNameWithId.lastIndexOf(',')).trim();
      }
      else {
        vm.data.ProjectName = projectNameWithId;
      }
      this.buildValuationPortfolioName(vm, 'ApplyFieldsFromProjectstd');
    };

    this.buildValuationPortfolioName = function (vm, field) {
      if (!vm.dataLoadedTimeout && field != 'ApplyFieldsFromProjectstd') {
        //Don't react to the initial change events
        return;
      }

      if (vm.data.Oid) {
        var name = '';
        if (vm.data.ValuationDate && vm.data.ValuationDate != '1900-01-01') {
          if (vm.data.ValuationDate instanceof Date) {
            name = kendo.toString(new Date(vm.data.ValuationDate), "yyyyMMdd");
          }
          else {
            var dateString = String(vm.data.ValuationDate);
            if (dateString.indexOf('T') >= 0) {
              dateString = dateString.substring(0, dateString.indexOf('T'))
            }
            if (dateString) {
              var valuationDate = Date(dateString);
              name = kendo.toString(new Date(valuationDate), "yyyyMMdd");
            }
          }
        }
      }

      if (vm.data.Client) {
        name += '_';
        name += vm.data.ClientIdDescription;
      }

      if (vm.data.ProjectName) {
        name += '_';
        name += vm.data.ProjectName;
      }

      if (vm.data.Remark) {
        name += '_';
        name += vm.data.Remark;
      }

      vm.data.ValuationPortfolioName = name;
    };

    this.datasetStatusUpdated = function (vm) {
      vm.formReadOnly = vm.data.RecordStatusId == 2;
    };

    this.getMarketRentsAndUsages = function (vm) {
      ValuationPortfoliosResource.loadMarketRentsAndUsages(vm.data.LinkedPropertyOid, vm.data.RentRollDate).then(function (result) {
        vm.data.MarketRentUsageType1 = result.MarketRentUsageType1;
        vm.data.MarketRentUsageType2 = result.MarketRentUsageType2;
        vm.data.MarketRentUsageType3 = result.MarketRentUsageType3;
        vm.data.MarketRentUsageType4 = result.MarketRentUsageType4;
        vm.data.MarketRentUsageType5 = result.MarketRentUsageType5;

        vm.data.MarketRentUsage1 = result.MarketRentUsage1;
        vm.data.MarketRentUsage2 = result.MarketRentUsage2;
        vm.data.MarketRentUsage3 = result.MarketRentUsage3;
        vm.data.MarketRentUsage4 = result.MarketRentUsage4;
        vm.data.MarketRentUsage5 = result.MarketRentUsage5;
      });
    }

    this.rentRollDateChanged = function (vm) {
      const self = this;
      if (vm.data.Oid > 0) {
        return;//no RR date change on existing jobs
      }

      self.getMarketRentsAndUsages(vm);
    }
    this.linkedPropertyChanged = function (vm) {
      const self = this;
      if (vm.data.Oid > 0) {
        return;//no property change on existing jobs
      }

      //reset parent job (can be just job with same property)
      vm.data.ParentJobOid = 0;
      vm.data.ParentJobOidDescription = "";

      vm.parentJobFilters = [{ name: 'jobTypeId', value: vm.data.JobTypeId }, { name: 'propertyId', value: vm.data.LinkedPropertyOid }];

      if (vm.data.LinkedPropertyOid) {
        vm.rentrollsDates = null;//force dropdown update

        ValuationPortfoliosResource.loadRentRollsDates(vm.data.LinkedPropertyOid).then(function (dates) {
          vm.rentrollsDates = dates.sort().reverse();
          if (vm.rentrollsDates.length) {
            vm.data.RentRollDate = vm.rentrollsDates[0];
            self.getMarketRentsAndUsages(vm);
          }
        });

        PropertiesResource.load(vm.data.LinkedPropertyOid).then(function (propertyData) {
          vm.data.Opex = propertyData.opex;
          vm.data.PlotSize = propertyData.areaAccToLandRegister;
        });

      } else {
        vm.rentrollsDates = null;//force dropdown update
        $timeout(function () {
          vm.rentrollsDates = [];
          vm.data.RentRollDate = null;
        });
      }
    };

    this.parentJobChanged = function (vm) {
      if (vm.data.ParentJobOid) {
        ValuationPortfoliosResource.load(vm.data.ParentJobOid).then((result) => {
          vm.data.MarketRentUsage1 = result.MarketRentUsage1;
          vm.data.MarketRentUsage2 = result.MarketRentUsage2;
          vm.data.MarketRentUsage3 = result.MarketRentUsage3;
          vm.data.MarketRentUsage4 = result.MarketRentUsage4;
          vm.data.MarketRentUsage5 = result.MarketRentUsage5;
        });
      }
    };

    this.updateRentrollFromParentClicked = function (vm) {
      const self = this;
      if (vm.data.UpdateRentrollFromParent) {
        vm.rentrollsDates = null;//force dropdown update
        ValuationPortfoliosResource.load(vm.data.ParentJobOid).then(function (result) {
          if (result) {
            vm.rentrollsDates = [result.RentRollDate];
            vm.data.RentRollDate = vm.rentrollsDates[0];
            vm.disableRentRollDate = true;
          }
        });
      }
      else {
        vm.rentrollsDates = null;//force dropdown update
        vm.disableRentRollDate = false;
        $timeout(function () {
          if (vm.data.LinkedPropertyOid) {
            vm.rentrollsDates = null;//force dropdown update
            ValuationPortfoliosResource.loadRentRollsDates(vm.data.LinkedPropertyOid).then(function (dates) {
              vm.rentrollsDates = [];
              vm.rentrollsDates = dates.sort().reverse();
              vm.data.RentRollDate = null;
              if (vm.rentrollsDates.length) {
                vm.data.RentRollDate = vm.rentrollsDates[0];
                self.getMarketRentsAndUsages(vm);
              }
            });
          } else {
            vm.rentrollsDates = null;//force dropdown update
            $timeout(function () {
              vm.rentrollsDates = [];
              vm.data.RentRollDate = null;
            });
          }
        });
      }
    };

    return this;
  }
}());
