import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { QvPropertyImagesService } from './_services/qv-property-images.service';
import { QvPropertyImageManagementComponent } from './qv-property-image-management.component';
import { TranslationModule } from 'src/app/services/translation/translation.module';
import { UploadModule } from '@progress/kendo-angular-upload';
import { SynapsesImagePipe } from './_services/SynapsesImagePipe';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AfterValueChangedDirective } from './_services/AfterValueChangedDirective';

export const routerModule = RouterModule.forChild([{
  path: '',
  component: QvPropertyImageManagementComponent
}]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule,
    TranslationModule,
    SortableModule,
    UploadModule,
    InputsModule
  ],
  declarations: [
    QvPropertyImageManagementComponent,
    SynapsesImagePipe,
    AfterValueChangedDirective
  ],
  providers: [
    QvPropertyImagesService,
    SynapsesImagePipe
  ],
  exports: [
    QvPropertyImageManagementComponent,
    SynapsesImagePipe
  ],
  entryComponents: [
    QvPropertyImageManagementComponent
  ]
})
export class QvPropertyImageManagementModule { }
