(function() {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('PropertiesResource',
            ['$resource', 'appServiceHelper', 'PropertiesResourceCustom', PropertiesResource]);

    function PropertiesResource($resource, appServiceHelper, customResource) {
        var api = "marvin.pollux.comparables.api";
        var baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties')),
            pagedResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties/list/paged'));
        function load(oid) {
            return baseResource.get({ oid: oid }).$promise;
        }

        function loadPagedList(take, skip, page, pageSize, filter) {
            return pagedResource.get({
                take: take || 50,
                skip: skip || 0,
                page: page || 1,
                pageSize: pageSize || 50,
                filter: filter || ""
            }).$promise;
        }

        function save(data) {
            return baseResource.save(data).$promise;
        }

        function remove(oid) {
            return baseResource.delete({ oid: oid }).$promise;
        }

        var obj = {
            itemResource: baseResource,
            pagedListResource: pagedResource,
            load: load,
            loadPagedList: loadPagedList,
            save: save,
            remove: remove
        };

        for(var prop in customResource) {
            obj[prop] = customResource[prop];
        }

        return obj;
    }
}());


(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('comparablesPropertyResource',
        ['$resource', 'appServiceHelper', comparablesPropertyResource]);

    function comparablesPropertyResource($resource, appServiceHelper) {
        return {
            load: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'comparables/property/details'), {}),
            details: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'comparables/property/details/single'), { oid: '@oid' }),
            getpictures: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'picturesexport/pictures'), { propertyId: '@propertyId' }, { get: { method: 'GET', isArray: true } }),
            deletepicture: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'picturesexport/pictures/delete'), { oid: '@oid' }, { delete: { method: 'POST' } }),
            savepicture: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'picturesexport/pictures/save'), null, { save: { method: 'POST' } }),
            remove: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'Properties/deleteArray'), {}, { save: { method: 'POST' } }),
            recreateImages: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.comparables.api', 'comparables/property/recreateImages'), {}, { save: { method: 'POST' } })
        }
    }
}());
