(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationOrganizationSettingsDialog", {
      template: require('html-loader!./project.valuation.organization.settings.dialog.tmpl.html'),
      bindings: {
        "organization": "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$q', 'projectValuationOrganizationResource', '$timeout', projectValuationOrganizationSettingsDialogCtrl]
    });

  function projectValuationOrganizationSettingsDialogCtrl($scope, $rootScope, $q, projectValuationOrganizationResource, $timeout) {
    var vm = this;

    //#region variable declaration 
    vm.window = null;
    vm.inviteMail = '';
    const openCloseDuration = 500;
    //#endregion


    function reset() {
      vm.inviteMail = '';

      const sidebar = document.getElementById("project-valuation-searchbar");
      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      ///https://docs.telerik.com/kendo-ui/controls/layout/window/how-to/window-as-sliding-pane
      vm.options = {
        title: false,
        draggable: false,
        resizable: false,
        modal: false,
        position: {
          top: toolbar.height,
          left: sidebar.clientWidth
        },
        animation: {
          open: {
            effects: "slideIn:left",
            duration: openCloseDuration
          },
          close: {
            effects: "slideIn:left",
            reverse: true,
            duration: openCloseDuration
          }
        },
        visible: false,
        close: function () {
          $timeout(function () {
            $rootScope.$broadcast('closeOrganizationSettingsDialog');
          }, openCloseDuration);
        }
      };

      const winW = window.innerWidth;
      const winH = window.innerHeight;
      vm.options.width = winW - sidebar.clientWidth;
      vm.options.height = winH - toolbar.height;
    }

    reset();

    //#region function declaration 
    vm.cancel = cancel;
    vm.invite = invite;
    //#endregion

    //#region function implementation
    
    function cancel() {
      vm.window.close();
    }

    $scope.$on('openOrganizationSettingsDialog', function (event, params) {
      reset();

      setTimeout(() => {
        vm.window.open();
      });
    });



    function invite(organization) {
      if (vm.inviteMail) {
        $q.all([projectValuationOrganizationResource.invite.save({ mail: vm.inviteMail, organizationId: vm.organization.oid }, {}).$promise]).then(
          () => {
            alert("Invitation queued!");
          });
      }
    }

    $scope.$on('closeSidebar', function (event, params) {
      cancel();
    });

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      if (widget === vm.window) {
        const params = {
          organization: vm.organization
        };
        $rootScope.$broadcast('openOrganizationSettingsDialog', params);
      }
    });

    //#endregion "EVENTS"
  }
}());

