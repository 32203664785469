(function () {

    "use strict";

    /**
    * @ngdoc service
    * @name DXSPolluxApp.service:dateHelper
    * @description
    * This service eliminates the timezone offset for datetime fields and theredore simplifies and unifies the handling of dates in the application.
    * <b>It is highly recommended to use this service instead of creating many custom solutions for this problem in different components</b>
    */
    angular
        .module('DXSPolluxApp')
        .factory('dateHelper',
        [dateHelper]);

    function dateHelper() {
        var self = this;

        /**
         * @ngdoc function
         * @name toDateTime
         * @methodOf DXSPolluxApp.service:dateHelper
         * @description
         * Converts an object to a datetime:
         * - if it is null, null is returned back
         * - If it is a Date already, the time zone is eliminated
         * - If it is a string, it can be parsed from an ISO-8601 string as well as from t´he German and English notation.
         * 
         * @param {Object} date The value that will be converted into a date 
         * 
         * @returns {Date} The converted value
         */
        function toDateTime(date) {
            if (!date) {
                //Empty or null - return null
                return null;
            }

            if (date instanceof Date) {
                //DateTime - remove the timezone info first and convert it to an ISO-like string without time(zone) info
                date = kendo.toString(date, 'yyyy-MM-dd');
            }

            if (String(date).indexOf('T') >= 0) {
                //ISO string -> cut off the time
                date = String(date).substring(0, String(date).indexOf('T'));
            }

            return kendo.parseDate(date, ['yyyy-MM-dd', 'dd.MM.yyyy', 'dd/MM/yyyy']);
        }

        /**
         * @ngdoc function
         * @name toISOString
         * @methodOf DXSPolluxApp.service:dateHelper
         * @description
         * Converts an object to a short ISO string (e.g. '2018-12-31'), which is useful for sending the value back to the server without any local timezone information:
         * - if it is null, '1900-01-01' is returned back
         * - If it is a Date, the time zone is eliminated
         * - If it is a string, it can be parsed from an ISO-8601 string as well as from t´he German and English notation.
         * 
         * @param {Object} date The value that will be converted into a string 
         * 
         * @returns {String} The converted value
         */
        function toISOString(date) {
            if (!date) {
                //Empty or null
                return '1900-01-01';
            }

            date = toDateTime(date);

            if (!date) {
                return '';
            }
            if (date instanceof Date) {
                return kendo.toString(date, "yyyy-MM-dd");
            }

            return '';
        }

        

        /**
         * @ngdoc function
         * @name fixTimeZoneOffsetForSave
         * @methodOf DXSPolluxApp.service:dateHelper
         * @description
         * Eliminates the timezone offset for a Date
         * 
         * @param {Date} date The value whose timezone offset will be removed
         * 
         * @returns {String} The value with it's timezone offset removed
         */
        function fixTimeZoneOffsetForSave(date) {
            try {
                if (date) {
                    if (!(date instanceof Date)) {
                        date = new Date(date);
                    }

                    if (date instanceof Date) {
                        var n = date.getTimezoneOffset();
                        date.setHours(date.getHours() - n / 60);
                        var dateString = date.toISOString();
                        date = dateString.substring(0, dateString.indexOf('T'));
                    }
                }
                return date;
            } catch (e) {
                return null;
            }
        }

        /**
         * @ngdoc function
         * @name fixTimeZoneOffsetForSave
         * @methodOf DXSPolluxApp.service:dateHelper
         * @description
         * Creates a formatted string that contains the provided date value in the specified format
         * 
         * @param {Object} date The value that will be formatted
         * @param {String} [format] The date format. If this value is not provided, the default format is 'dd.MM.yyyy'
         * 
         * @returns {String} The value with it's timezone offset removed
         */
        function toDisplayableString(date, format) {
            if (!date) {
                //Empty or null - return an empty string
                return '';
            }

            if (!format) {
                format = 'dd.MM.yyyy';
            }

            date = toDateTime(date);

            if (!date) {
                return '';
            }


            var ret = kendo.toString(new Date(date), format);
            if (ret == kendo.toString(new Date(1900, 0, 1), format)) {
                return "";
            }

            return ret;
        }

        return {
            toDisplayableString: toDisplayableString,
            toISOString: toISOString,
            fixTimeZoneOffsetForSave: fixTimeZoneOffsetForSave
        };
    }
}());
