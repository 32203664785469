import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SynapsesDqsV2MainComponent } from './synapses-dqs-main/synapses-dqs-main.component';
import { SynapsesDqsService } from './_services/synapses-dqs-service.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UploadModule, FileSelectModule } from '@progress/kendo-angular-upload';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { SynapsesDqsFileUploadComponent } from './synapses-dqs-file-upload/synapses-dqs-file-upload.component';
import { SynapsesDqsValidationComponent } from './synapses-dqs-validation/synapses-dqs-validation.component';
import { SynapsesDqsSummaryComponent } from './synapses-dqs-summary/synapses-dqs-summary.component';
import { SpreadSheetsModule } from 'src/app/components/spreadjs/gc.spread.sheets.angular'; //https://www.grapecity.com/forums/spread-sheets/angular-error-on-build-ple#helloyou-can-copy-the-angu_1
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { SynapsesDqsUpdateJobRentrollComponent } from './synapses-dqs-update-job-rentroll/synapses-dqs-update-job-rentroll.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { SynapsesDqsMappingEditorComponent } from './synapses-dqs-mapping-editor/synapses-dqs-mapping-editor.component';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { SynapsesDqsReflistMapperComponent } from './synapses-dqs-reflist-mapper/synapses-dqs-reflist-mapper.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { Ng1ProjectValuationRentrollDistributionPaneComponentWrapper } from './synapses-dqs-summary/project-valuation-rentroll-distribution-pane.ajs';

@NgModule({
  imports: [
    CommonModule,
    InputsModule,
    FormsModule,
    TranslateModule,
    DropDownsModule,
    UploadModule,
    ButtonsModule,
    SortableModule,
    FileSelectModule,
    DatePickerModule,
    SpreadSheetsModule,
    GridModule,
    DialogsModule,
    ScrollViewModule
  ],
  declarations: [
    SynapsesDqsV2MainComponent,
    SynapsesDqsFileUploadComponent,
    SynapsesDqsValidationComponent,
    SynapsesDqsSummaryComponent,
    SynapsesDqsUpdateJobRentrollComponent,
    SynapsesDqsMappingEditorComponent,
    SynapsesDqsReflistMapperComponent,
    Ng1ProjectValuationRentrollDistributionPaneComponentWrapper
  ],
  providers: [
    SynapsesDqsService
  ],
  entryComponents: [
    SynapsesDqsV2MainComponent
  ]
})
export class SynapsesDqsV2Module { }
