import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, Inject, ViewChild, ElementRef } from '@angular/core';
import { SynapsesDqsService } from '../_services/synapses-dqs-service.service';
import * as GC from '@grapecity/spread-sheets';
import * as Excel from '@grapecity/spread-excelio';
import { environment } from 'src/environments/environment';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SortableService } from '@progress/kendo-angular-sortable';
import { SynapsesDqsValidationResult } from '../_data/synapses-dqs-validation-result';
import { SynapsesDqs2ValidationEntry } from '../_data/synapses-dqs2-validation-entry';

@Component({
  selector: 'synapses-dqs-mapping-editor',
  templateUrl: './synapses-dqs-mapping-editor.component.html',
  styleUrls: ['./synapses-dqs-mapping-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SynapsesDqsMappingEditorComponent implements OnInit {
  public vm = this;

  private _importInfo: any;

  get importInfo(): any {
    return this._importInfo;
  }

  @Input() set importInfo(value: any) {
    this._importInfo = value;
    this.reset();
  }

  get importId(): number {
    return this._importInfo.id;
  }

  @Input() oid: number;

  @Output() close = new EventEmitter<boolean>();


  public ignoreErrors = false;
  public categories: any[] = null;
  public mappableFields: any[] = null;
  public mappableFieldsReflists: any = null;
  public currentMappings: any[] = null;
  public currentMappingsNestedArray: any[] = null;
  public availableMappings: any[] = null;
  public importSheetData: any = null;
  public hiddenMappings: any[] = [];
  public selectedHiddenMappings: any[] = null;
  private isShowFinal = false;
  public showHiddenMappingsList = false;
  public columnCount = 0;
  public columnWidth = 200.0;
  public rowHeight = 50.0;
  public templateName: string;
  private spreadsheet: GC.Spread.Sheets.Workbook;
  private importSheetName: string;
  public addedTransformationColumnIndices: number[] = [];
  private templateList: any[] = null;
  private selectedTemplateId = 0;
  public currentReflistMapperEntry = null;
  public currentReflistMapperValues = null;
  public categorySortableFields: any = {};

  public propertyPictures: any = [];
  public propertyPicture: any;
  public validated: boolean;

  public resultsWarning: Array<SynapsesDqs2ValidationEntry> = [];
  public resultsError: Array<SynapsesDqs2ValidationEntry> = [];
  public errorMessage: string;

  public hostStyle = {
    width: '100%',
    height:'100vh'
  };

  public fileRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx'],
    maxFileSize: 8388608 //8MB
  };

  @ViewChild('container') container: ElementRef;
  @ViewChild('mappingEditorContainer') private mappingEditorContainer: ElementRef<HTMLElement>;


  constructor(private service: SynapsesDqsService, @Inject('appBusyIndicatorService') private busyIndicator: any, @Inject('appConfirmDialogService') private confirmDialog: any
    , private translate: TranslateService, @Inject('appSidebarActionsService') private sidebarActionsService: any, private http: HttpClient, private sortableService: SortableService) {
    const self = this;

    if (self.translate.currentLang === 'de-DE') {
      //https://www.grapecity.com/forums/spreadjs/custom-culture-not-applyin
      const enToDeFormatMap = {};
      enToDeFormatMap['mm/dd/yyyy'] = new GC.Spread.Formatter.GeneralFormatter('dd.MM.yyyy', 'de');
      enToDeFormatMap['m/d/yyyy'] = new GC.Spread.Formatter.GeneralFormatter('d.M.yyyy', 'de');
      // add more mapping here as needed.

      const g_getFormatter = (GC.Spread.Formatter.GeneralFormatter as any).prototype.getFormatter;
      (GC.Spread.Formatter.GeneralFormatter as any).prototype.getFormatter = function (index) {
        const f = g_getFormatter.call(this, index);
        const formatString = f && f.formatString();
        if (formatString && formatString.length > 0 && enToDeFormatMap[formatString]) {
          return enToDeFormatMap[formatString];
        }
        return f;
      }

      const myCulture = new GC.Spread.Common.CultureInfo();
      myCulture.NumberFormat.currencySymbol = "\u20ac"
      myCulture.NumberFormat.numberDecimalSeparator = ",";
      myCulture.NumberFormat.numberGroupSeparator = ".";
      myCulture.NumberFormat.arrayGroupSeparator = ";";
      myCulture.NumberFormat.arrayListSeparator = "\\";
      myCulture.NumberFormat.listSeparator = ";";
      myCulture.DateTimeFormat.amDesignator = "";
      myCulture.DateTimeFormat.amDesignator = "";
      myCulture.DateTimeFormat.pmDesignator = "";
      myCulture.DateTimeFormat.abbreviatedMonthNames = ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez", ""];
      myCulture.DateTimeFormat.abbreviatedDayNames = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
      myCulture.DateTimeFormat.abbreviatedMonthGenitiveNames = ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez", ""];
      myCulture.DateTimeFormat.dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
      myCulture.DateTimeFormat.fullDateTimePattern = "dddd, d. MMMM yyyy HH:mm:ss";
      myCulture.DateTimeFormat.longDatePattern = "dddd, d. MMMM yyyy";
      myCulture.DateTimeFormat.longTimePattern = "HH:mm:ss";
      myCulture.DateTimeFormat.monthDayPattern = "dd MMMM";
      myCulture.DateTimeFormat.monthNames = ["Januar", "Februar", "M\xe4rz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember", ""];
      myCulture.DateTimeFormat.monthGenitiveNames = ["Januar", "Februar", "M\xe4rz", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember", ""];
      myCulture.DateTimeFormat.shortDatePattern = "dd.MM.yyyy";
      myCulture.DateTimeFormat.shortTimePattern = "HH:mm";
      myCulture.DateTimeFormat.yearMonthPattern = "MMMM yyyy";

      //add one culture
      GC.Spread.Common.CultureManager.addCultureInfo("de-DE", myCulture);
      //switch to "de-DE" culture
      GC.Spread.Common.CultureManager.culture("de-DE");
    }

    GC.Spread.Sheets.LicenseKey = environment.SpreadJsLicenseKey;
    (Excel as any).LicenseKey = environment.SpreadJsLicenseKey; //See last post from https://www.grapecity.com/forums/spread-sheets/export-to-excel---license-
  }

  ngOnInit() {
    const self = this;
    setTimeout(() => {
      const width = this.container.nativeElement.offsetWidth;
      const height = this.container.nativeElement.offsetHeight;

      this.http
        .get(environment.POLLUX_PROJECTVALUATION_API_URL + '/picturesexport/pictures/dataurl?propertyId=' + this.oid + '&width=' + width + '&height=' + height).subscribe((data: any) => {
          if (data && data.length) {
            this.propertyPictures = data;
            this.propertyPicture = this.propertyPictures[0];
          } else {
            this.propertyPicture = { Data: '/assets/images/demoPropertyImage.jpg' };
          }
        });
    });

    const sidebarActions = [
      {
        imgUrl: '/assets/images/SidebarIcons/window-close-solid.svg', translationKey: 'dqs.close', callback: function () {
          self.doClose();
        }
      }
    ];
    self.sidebarActionsService.setActions(sidebarActions);

    self.busyIndicator.setVisible(true);
    Promise.all([
      self.service.getMappableFields(self.importId),
      self.service.getTemplates('PropertyRentRoll')
    ]).then((values: any[]) => {
      self.busyIndicator.setVisible(false);
      self.mappableFields = values[0];

      self.categories = [];
      for (let field of self.mappableFields) {
        if (self.categories.indexOf(field.category) < 0) {
          self.categories.push(field.category);
        }
      }

      self.categories.sort();
      //setup fields per category
      self.setupCategoryFields();

      self.templateList = values[1].filter((template) => { return template.id > 1; });
      self.selectedTemplateId = self.templateList.length > 0 ? self.templateList[0].id : 0;

      const fieldReflistsPromises = [];
      const reflistsToLoad = self.mappableFields.filter((field) => {
        return (field.reflistName || '') !== '';
      }).map((field) => {
        return field.reflistName;
      });

      for (const reflist of reflistsToLoad) {
        fieldReflistsPromises.push(self.service.getReflist(reflist));
      }

      if (fieldReflistsPromises.length) {
        Promise.all(fieldReflistsPromises).then((fieldReflistsResult: any[]) => {
          const mappableFieldsReflists = {};
          for (let i = 0; i < reflistsToLoad.length; ++i) {
            mappableFieldsReflists[reflistsToLoad[i]] = fieldReflistsResult[i];
          }
          self.mappableFieldsReflists = mappableFieldsReflists;
        });
      }
      else {
        self.mappableFieldsReflists = {};
      }
    });
  }

  ngOnDestroy() {
    this.sidebarActionsService.clear();
  }

  public entryDragEnd(e: any, columnIndex: number) {
    const self = this;
    if (e.index < 0) {
      //An entry was dragged outside, so it got an index < 0. Remove it from the selection and make it available again

      //Make a copy of the available list and selected list
      const newMappingsNestedArray = JSON.parse(JSON.stringify(self.currentMappingsNestedArray));
      const newAvailableMappings = JSON.parse(JSON.stringify(self.availableMappings));

      //Get the entry that is removed. Because we don't use setTimeout, sorting wasn't applied and it's still at e.oldIndex
      const removedEntry = newMappingsNestedArray[columnIndex][e.oldIndex];

      //Clear the selection list
      newMappingsNestedArray[columnIndex] = [];

      //Reset the field
      removedEntry.action = 1;
      removedEntry.displayStyle = 1;
      removedEntry.canSelectImport = true;
      removedEntry.addedCellTransformation = 0;
      removedEntry.formula = null;

      //Add it to the available list
      newAvailableMappings.push(removedEntry);

      //Re-render the lists: Set to 0, ste timeout and apply
      self.currentMappingsNestedArray = null;
      self.availableMappings = null;

      setTimeout(() => {
        self.currentMappingsNestedArray = newMappingsNestedArray;
        self.availableMappings = newAvailableMappings;
        self.setupCategoryFields();
      });
    }
    else {
      setTimeout(() => {
        const newMappingsNestedArray = JSON.parse(JSON.stringify(self.currentMappingsNestedArray));
        const newAvailableMappings = JSON.parse(JSON.stringify(self.availableMappings));

        for (let i = 0; i < newMappingsNestedArray.length; ++i) {
          //First, push old entries to the available list
          if (newMappingsNestedArray[i].length > 1) {
            const oldEntry = newMappingsNestedArray[i][e.index];

            newAvailableMappings.push(oldEntry);
            newMappingsNestedArray[i].splice(e.index, 1);
          }
        }

        const entry = newMappingsNestedArray[columnIndex];
        if (self.addedTransformationColumnIndices.indexOf(columnIndex) >= 0) {
          entry[0].action = 0;
          entry[0].displayStyle = 1;
          entry[0].canSelectImport = false;
          entry[0].addedCellTransformation = 1;
        }
        else {
          entry[0].action = 1;
          entry[0].displayStyle = 1;
          entry[0].canSelectImport = true;
          entry[0].addedCellTransformation = 0;
          if (entry[0].reflistName) {
            self.openReflistMapper(entry[0], columnIndex);
          }
        }

        //Re-render the lists: Set to 0, ste timeout and apply
        self.currentMappingsNestedArray = null;
        self.availableMappings = null;

        setTimeout(() => {
          self.currentMappingsNestedArray = newMappingsNestedArray;
          self.availableMappings = newAvailableMappings;
          self.setupCategoryFields();
        });
      });
    }
  }

  public openReflistMapper(entry: any, col: number) {
    const self = this;
    const values = [];

    const validator = self.spreadsheet.getActiveSheet().getDataValidator(3, col, GC.Spread.Sheets.SheetArea.viewport);
    if (validator) {
      const validList = validator.getValidList(self.spreadsheet.getActiveSheet(), 3, 1);
      for (let i = 0; i < validList.length; ++i) {
        if (values.indexOf(validList[i]) < 0 && validList[i] !== '') {
          values.push(validList[i]);
        }
      }
    }

    for (let i = 1; i < self.spreadsheet.getActiveSheet().getRowCount(); ++i) {
      const value: string = (self.spreadsheet.getActiveSheet().getCell(i, col).value() || '').toString();
      if (values.indexOf(value) < 0 && value !== '') {
        values.push(value);
      }
    }

    self.currentReflistMapperValues = values;
    self.currentReflistMapperEntry = entry;
  }

  public reflistMappingEditorClosed(state: string) {
    this.currentReflistMapperValues = null;
    this.currentReflistMapperEntry = null;
  }

  public entryAddedToChoosable(e: any) {
    const self = this;
    e.reflistMappings = null;
  }

  private createDefaultMappings(): void {
    const self = this;

    const mappings = [];

    for (let i = 0; i < self.mappableFields.length; ++i) {
      const field = self.mappableFields[i];
      const mapping = {
        sortOrder: i,
        displayStyle: 1,
        fieldName: field.fieldName,
        fieldType: field.fieldType,
        reflistName: field.reflistName,
        sizeConstraints: field.sizeConstraints,
        action: 1,
        valid: true
      };

      mappings.push(mapping);
    }
    self.hiddenMappings = [];
    self.selectedHiddenMappings = [];

    self.currentMappings = mappings;

    // creating arrays with one item each for kendo-sortable
    self.currentMappingsNestedArray = [];
    let columnCount = 0;
    const sheetNames = Object.keys(self.importSheetData.sheets);
    for (let i = 0; i < sheetNames.length; ++i) {
      try {
        const sheetName = self.importSheetData.sheets[sheetNames[i]].name;
        if (sheetName !== 'Evaluation version') {
          self.importSheetName = sheetName;
          columnCount = Object.keys(self.importSheetData.sheets[sheetNames[i]].data.dataTable[0]).length;
          break;
        }
      } catch (e) {
        //Ignore and continue
      }
    }

    for (let i = 0; i < columnCount; ++i) {
      self.currentMappingsNestedArray[i] = [];
    }

    self.availableMappings = mappings;
  }

  public onMappingChanged(item: any): void {
    const self = this;

    item.valid = item.displayStyle && item.action;
  }

  public onDisplayStyleChanged(item: any, index: number): void {
    const self = this;

    //Checkbox to stdlist
    if (!item.displayStyle) {
      item.displayStyle = 0;
    }
    else {
      item.displayStyle = 1;
    }

    if (item.displayStyle === 0) { // hide
      self.hiddenMappings.push(item);
    }
    else {
      self.hiddenMappings = self.hiddenMappings.filter((elem) => {
        return item.fieldName !== elem.fieldName;
      });
    }
  }

  private reset(): void {
    const self = this;

    self.addedTransformationColumnIndices = [];
    self.sidebarActionsService.clear();
    self.validated = false;

    self.errorMessage = null;
    self.resultsError = null;
    self.resultsWarning = null;

    self.isShowFinal = false;
    self.busyIndicator.setVisible(false);
    self.ignoreErrors = false;
    self.updateSidebarActions();
  }

  public doClose(): void {
    const self = this;

    self.busyIndicator.setVisible(false);
    self.close.emit(true);
  }

  public insertTransformation(): void {
    const self = this;
    let index = 0;
    try {
      const sheet = self.spreadsheet.getActiveSheet();
      const selectedRanges = self.spreadsheet.getActiveSheet().getSelections();

      for (let i = 0; i < selectedRanges.length; i++) {
        index = selectedRanges[i].col;
      }

      self.hostStyle = null;
      setTimeout(() => {
        //resize canvas, so last column is visible after transformation cell is added (#10864)
        self.hostStyle = {
          width: ((sheet.getColumnCount() + 1) * 200 + 18) + 'px',
          height: '100vh'
        };
        setTimeout(() => {
          self.spreadsheet.refresh();//!!refresh
          self.insertTransformationAtIndex(index);
        });
      });
      

    } catch (e) {
      return;
    }
  }

  private insertTransformationAtIndex(index: number): void {
    const self = this;

    const sheet = self.spreadsheet.getActiveSheet();
    sheet.addColumns(index + 1, 1);

    //Push the other indices one further
    for (let i = 0; i < self.addedTransformationColumnIndices.length; ++i) {
      if (self.addedTransformationColumnIndices[i] >= index + 1) {
        ++self.addedTransformationColumnIndices[i];
      }
    }

    //Add the column to the added transformation columns
    self.addedTransformationColumnIndices.push(index + 1);

    sheet.setValue(0, index + 1, "Transformation");
    sheet.setColumnWidth(index + 1, 200.0, GC.Spread.Sheets.SheetArea.viewport);

    const headerStyle = new GC.Spread.Sheets.Style();
    headerStyle.font = 'bold 14px Segoe UI';
    headerStyle.foreColor = '#808080';
    headerStyle.backColor = '#FAFAFA';
    headerStyle.borderLeft = null;
    headerStyle.borderRight = null;
    headerStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    headerStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    headerStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
    headerStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    sheet.setStyle(0, index + 1, headerStyle);

    const firstRowRange = sheet.getRange(1, 0, 1, sheet.getColumnCount());
    firstRowRange.locked(false);

    for (let i = 1; i <= sheet.getRowCount(); ++i) {
      sheet.getCell(i, index + 1).validator(null);
      const prevCellStyle = sheet.getStyle(i, index);
      sheet.setStyle(i, index + 1, prevCellStyle);
    }

    ++self.columnCount;

    const newMappingsNestedArray = JSON.parse(JSON.stringify(self.currentMappingsNestedArray));

    newMappingsNestedArray.splice(index, 1, []);
    if (self.currentMappingsNestedArray[index].length) {

      const entry = self.currentMappingsNestedArray[index];

      entry[0].action = 0;
      entry[0].displayStyle = 1;
      entry[0].addedCellTransformation = 1;
      entry[0].canSelectImport = false;

      newMappingsNestedArray.splice(index + 1, 0, entry);
    }
    else {
      newMappingsNestedArray.splice(index + 1, 0, []);
    }

    self.currentMappingsNestedArray = null;
    setTimeout(() => {
      self.currentMappingsNestedArray = newMappingsNestedArray;
    });
  }

  public removeTransformation(): void {
    const self = this;


    const sheet = self.spreadsheet.getActiveSheet();
    let index = 0;
    try {
      const selectedRanges = sheet.getSelections();

      for (let i = 0; i < selectedRanges.length; i++) {
        index = selectedRanges[i].col;
      }
    } catch (e) {
      return;
    }

    if(self.addedTransformationColumnIndices.indexOf(index) < 0) {
      return;
    }

    sheet.deleteColumns(index, 1);

    //Add the column to the added transformation columns
    self.addedTransformationColumnIndices = self.addedTransformationColumnIndices.filter((idx) => { return idx !== index; });

    //Push the other indices one back
    for (let i = 0; i < self.addedTransformationColumnIndices.length; ++i) {
      if (self.addedTransformationColumnIndices[i] >= index) {
        --self.addedTransformationColumnIndices[i];
      }
    }

    --self.columnCount;

    const newMappingsNestedArray = JSON.parse(JSON.stringify(self.currentMappingsNestedArray));
    const newAvailableMappings = JSON.parse(JSON.stringify(self.availableMappings));

    //Get the entry that is removed. Because we don't use setTimeout, sorting wasn't applied and it's still at e.oldIndex
    const removedEntry = newMappingsNestedArray[index][0];

    //Clear the selection list
    newMappingsNestedArray.splice(index, 1);

    if (removedEntry) {
      //Reset the field
      removedEntry.action = 1;
      removedEntry.displayStyle = 1;
      removedEntry.canSelectImport = true;
      removedEntry.addedCellTransformation = 0;
      removedEntry.formula = null;


      //Add it to the available list
      newAvailableMappings.push(removedEntry);
    }

    //Re-render the lists: Set to 0, ste timeout and apply
    self.currentMappingsNestedArray = null;
    self.availableMappings = null;

    setTimeout(() => {
      self.currentMappingsNestedArray = newMappingsNestedArray;
      self.availableMappings = newAvailableMappings;
      self.setupCategoryFields();
    });
  }


  public showFinal(): void {
    const self = this;

    self.isShowFinal = !self.isShowFinal;
    if (self.isShowFinal) {
      for (let i = 0; i < self.currentMappingsNestedArray.length; ++i) {
        if (!self.currentMappingsNestedArray[i].length || self.currentMappingsNestedArray[i][0].displayStyle === 0) {
          self.spreadsheet.getActiveSheet().getRange(-1, i, -1, 1).visible(false); // hide column
        }
      }
    }
    else {
      for (let i = 0; i < self.spreadsheet.getActiveSheet().getColumnCount(); ++i) {
        self.spreadsheet.getActiveSheet().getRange(-1, i, -1, 1).visible(true); // show column
      }
    }

    self.spreadsheet.getActiveSheet().showCell(0, 0, 1, 1);
    self.updateSidebarActions();
  }

  public saveMapping(): void {
    const self = this;

    const mappings = self.currentMappingsNestedArray.map(item => item.length ? item[0] : null);

    //Check if there are any unmapped/invalid mapped transformation columns
    const emptyTransformationsToRemove: number[] = [];

    for (const addedTransformationColumn of self.addedTransformationColumnIndices) {
      if (!mappings[addedTransformationColumn] || mappings[addedTransformationColumn].action !== 0) {
        emptyTransformationsToRemove.push(addedTransformationColumn);
      }
    }

    //Sort descending
    emptyTransformationsToRemove.sort(function (a, b) { return b - a });

    if (emptyTransformationsToRemove.length) {
      //There are such columns - ask the user if they should be auto-removed
      self.confirmDialog.open({
        success: function () {
          //User said yes -> Remove these columns and fix mapping indices
          for (let i = 0; i < emptyTransformationsToRemove.length; ++i) {
            const transformationColumnToRemove = emptyTransformationsToRemove[i];

            self.addedTransformationColumnIndices = self.addedTransformationColumnIndices.filter((index) => { return index !== transformationColumnToRemove; });
            self.currentMappingsNestedArray.splice(transformationColumnToRemove, 1);
            self.spreadsheet.getActiveSheet().deleteColumns(transformationColumnToRemove, 1);

            for (let j = 0; j < self.addedTransformationColumnIndices.length; ++j) {
              if (self.addedTransformationColumnIndices[j] >= transformationColumnToRemove + 1) {
                --self.addedTransformationColumnIndices[j];
              }
            }
          }

          //Trigger save
          self.validateAndSaveMapping();
        },
        cancel: function () {
          //Nothing
        }
      }, 'dqsRemoveTransformations');
    }
    else {
      //There aren't any incorrectly mapped columns -> trigger save
      self.validateAndSaveMapping();
    }
  }

  public validateAndSaveMapping(): void {
    const self = this;

    const mappings = self.currentMappingsNestedArray.map(item => item.length ? item[0] : null);
    let formulaErrors = "";
    mappings.filter(item => item && item.action === 0).forEach(item => {
      const idx = mappings.indexOf(item);
      //formulas are entered in row 2: A2, B2, etc..
      const formula = self.spreadsheet.getActiveSheet().getFormula(1, idx);
      if (formula != null) {
        //formula is without starting equal sign (=)
        item.formula = '=' + formula;
      } else {
        formulaErrors += ('Formula not set for Transformation: cell ' + String.fromCharCode(65 + idx) + '2' + '\n');
      }
    });

    if (formulaErrors) {
      self.confirmDialog.open({ simpleMessage: true }, 'dqs.formulaErrors', 'simpleMessage.titleSave');
      return;
    }

    const fileJson: any = self.spreadsheet.toJSON({ includeBindingSource: true });
    self.busyIndicator.setVisible(true);

    self.errorMessage = null;
    self.resultsError = null;
    self.resultsWarning = null;

    self.service.saveMappings(self.importId, self.importSheetName, self.templateName, mappings, false).then((result) => {
      const templateId = parseInt(result.message);
      self.service.uploadSheet(templateId, fileJson).then((result) => {
        self.service.validate(JSON.stringify(fileJson), templateId, false).then((data: SynapsesDqsValidationResult) => {
          self.resultsWarning = data.entries.filter(e => !e.isError);
          self.resultsError = data.entries.filter(e => e.isError);

          const errorStyle = new GC.Spread.Sheets.Style();
          errorStyle.font = 'bold 14px Segoe UI';
          errorStyle.foreColor = '#808080';
          errorStyle.backColor = '#EF796F';
          errorStyle.borderLeft = null;
          errorStyle.borderRight = null;
          errorStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
          errorStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
          errorStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
          errorStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

          const warningStyle = new GC.Spread.Sheets.Style();
          warningStyle.font = 'bold 14px Segoe UI';
          warningStyle.foreColor = '#808080';
          warningStyle.backColor = '#FFE8B1';
          warningStyle.borderLeft = null;
          warningStyle.borderRight = null;
          warningStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
          warningStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
          warningStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
          warningStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

          self.resultsWarning.forEach(o => self.spreadsheet.getActiveSheet().setStyle(o.rowIndex, o.colIndex, warningStyle, GC.Spread.Sheets.SheetArea.viewport));
          self.resultsError.forEach(o => self.spreadsheet.getActiveSheet().setStyle(o.rowIndex, o.colIndex, errorStyle, GC.Spread.Sheets.SheetArea.viewport));

          if ((self.resultsError.length || self.resultsWarning.length) && !self.ignoreErrors) {
            self.busyIndicator.setVisible(false);

            self.confirmDialog.open({ simpleMessage: true }, 'dqs.mappingsWithError', 'simpleMessage.titleSave');
          }
          else {
            self.service.saveMappings(self.importId, self.importSheetName, self.templateName, mappings, true).then(() => {
              self.busyIndicator.setVisible(false);
              self.confirmDialog.open({ simpleMessage: true }, 'dqs.mappingsSaved', 'simpleMessage.titleSave');
            });
          }

        });

      }).catch(() => {
        self.busyIndicator.setVisible(false);
        alert("Failed to save mappings!");
      });
    }).catch(() => {
      self.busyIndicator.setVisible(false);
      alert("Failed to save mappings!");
    });
  }

  public unhide(): void {
    const self = this;
    for (let i = 0; i < self.selectedHiddenMappings.length; ++i) {
      const item = self.selectedHiddenMappings[i];
      self.hiddenMappings = self.hiddenMappings.filter((elem) => {
        return elem.fieldName !== item.fieldName;
      });
      for (let i = 0; i < self.currentMappingsNestedArray.length; ++i) {
        if (self.currentMappingsNestedArray[i].length && self.currentMappingsNestedArray[i][0].fieldName === item.fieldName) {
          self.currentMappingsNestedArray[i][0].displayStyle = 1;
        }
      }
    }
    self.showHiddenMappingsList = false;
  }

  public selectHiddenMapping(item): void {
    const self = this;

    self.selectedHiddenMappings.push(item);
  }

  public showHiddenList(): void {
    const self = this;

    self.showHiddenMappingsList = !self.showHiddenMappingsList;
  }

  public getProgressStatusClass(item: string) {
    switch (item) {
      case 'map':
        return 'active-status';
    }

    return '';
  }

  private dragFormulaIfNeeded(row: number, col: number, formula: string) {
    const self = this;

    if (row === 1 && formula && formula.indexOf("=") === 0) {
      const sheet = self.spreadsheet.getActiveSheet();

      let firstEmptyRowIndex: number = self.getFirstEmptyRowIndex(sheet, 2);
      let fillRowsNumber: number = firstEmptyRowIndex < 2 ? sheet.getRowCount() - 1 : (firstEmptyRowIndex - 1);

      //Apply the formula to the following rows and increment the formula
      //https://www.grapecity.com/spreadjs/docs/v13/online/dragfill.html
      const start = new GC.Spread.Sheets.Range(row, col, 1, 1);

      const range = new GC.Spread.Sheets.Range(row, col, fillRowsNumber, 1);
      sheet.fillAuto(start, range,
        {
          fillType: GC.Spread.Sheets.Fill.FillType.auto,
          series: GC.Spread.Sheets.Fill.FillSeries.column,
        });
    }
  }

  private getFirstEmptyRowIndex(sheet: any, startRowIdx: number = 0): number {
    for (let row = startRowIdx; row < sheet.getRowCount(); row++) {
      let rowHasValues: any = false;
      for (let col = 0; col < sheet.getColumnCount(); col++) {
        let val: any = sheet.getCell(row, col).value();
        rowHasValues |= ((!!val) as any);
        if (rowHasValues) break;
      }

      if (!rowHasValues) {
        return row;
      }
    }

    return -1;//no empty rows
  }

  public workbookInit(args) {
    const self = this;

    self.spreadsheet = args.spread;

    self.spreadsheet.bind(GC.Spread.Sheets.Events.EditEnded, function (sender, args) {
      self.dragFormulaIfNeeded(args.row, args.col, args.editingText);
    });

    self.spreadsheet.bind(GC.Spread.Sheets.Events.EnterCell, function (e, info) {
      self.errorMessage = "";
      if (self.resultsError) {
        const error = self.resultsError.find(err => err.rowIndex === info.row && err.colIndex === info.col);
        if (error) {
          self.errorMessage = `ERROR: ${error.fieldName}: ${error.message}`;
        } else {
          if (self.resultsWarning) {
            const warning = self.resultsWarning.find(err => err.rowIndex === info.row && err.colIndex === info.col);
            if (warning) {
              self.errorMessage = `WARNING: ${error.fieldName}: ${error.message}`;
            }
          }
        }
      }
      
      self.ensureCellInViewPort(info);
    });

    self.spreadsheet.fromJSON(self.importSheetData);
    self.spreadsheet.options.allowUserZoom = false;

    //Remove the evil version sheet
    for (let i = 0; i < self.spreadsheet.sheets.length; ++i) {
      if (self.spreadsheet.sheets[i].name() === 'Evaluation Version') {
        self.spreadsheet.removeSheet(i);
      }
    }

    //Now remove all other sheets but the first one
    for (let i = self.spreadsheet.sheets.length - 1; i >= 1; --i) {
      self.spreadsheet.sheets[i].visible(false);
    }

    const headerStyle = new GC.Spread.Sheets.Style();
    headerStyle.font = 'bold 14px Segoe UI';
    headerStyle.foreColor = '#808080';
    headerStyle.backColor = '#FAFAFA';
    headerStyle.borderLeft = null;
    headerStyle.borderRight = null;
    headerStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    headerStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    headerStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
    headerStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

    const evenRowStyle = new GC.Spread.Sheets.Style();
    evenRowStyle.font = '14px Segoe UI';
    evenRowStyle.foreColor = '#808080';
    evenRowStyle.backColor = '#FAFAFA';
    evenRowStyle.borderLeft = null;
    evenRowStyle.borderRight = null;
    evenRowStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    evenRowStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    evenRowStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
    evenRowStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

    const oddRowStyle = new GC.Spread.Sheets.Style();
    oddRowStyle.font = '14px Segoe UI';
    oddRowStyle.foreColor = '#808080';
    oddRowStyle.backColor = '#FFFFFF';
    oddRowStyle.borderLeft = null;
    oddRowStyle.borderRight = null;
    oddRowStyle.borderTop = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    oddRowStyle.borderBottom = new GC.Spread.Sheets.LineBorder("#F5F5F5", GC.Spread.Sheets.LineStyle.thin);
    oddRowStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
    oddRowStyle.vAlign = GC.Spread.Sheets.VerticalAlign.center;

    if (self.spreadsheet.sheets.length) {
      const sheet = self.spreadsheet.sheets[0];

      for (let i = 0; i < sheet.getColumnCount(); ++i) {
        sheet.setColumnWidth(i, 200.0, GC.Spread.Sheets.SheetArea.viewport);

        // disable col resizing in the row headers
        sheet.setColumnResizable(i, false, GC.Spread.Sheets.SheetArea.viewport);
      }
      sheet.zoom(1.0);
      sheet.getRange(0, -1, 1, -1, GC.Spread.Sheets.SheetArea.colHeader).resizable(false);
      sheet.getRange(-1, 0, -1, 1, GC.Spread.Sheets.SheetArea.rowHeader).resizable(false);

      //Show header + first 50 columns
      if (sheet.getRowCount() > 51) {
        sheet.deleteRows(52, sheet.getRowCount() - 52);
      }

      const headerRange = sheet.getRange(0, 0, 1, sheet.getColumnCount());
      headerRange.locked(false);

      //Unlock the first data row
      const firstRowRange = sheet.getRange(1, 0, 1, sheet.getColumnCount());
      firstRowRange.locked(false);

      //Lock all other ranges
      const dataRange = sheet.getRange(2, 0, sheet.getRowCount(), sheet.getColumnCount());
      dataRange.locked(true);

      self.columnCount = sheet.getColumnCount();

      sheet.options.isProtected = true;
      sheet.options.protectionOptions.allowDeleteRows = false;
      sheet.options.protectionOptions.allowDeleteColumns = false;
      sheet.options.protectionOptions.allowInsertRows = false;
      sheet.options.protectionOptions.allowInsertColumns = false;
      sheet.options.protectionOptions.allowDragInsertColumns = false;

      sheet.options.colHeaderVisible = false;
      sheet.options.rowHeaderVisible = false;

      for (let row = 0; row < sheet.getRowCount(); ++row) {
        sheet.setRowHeight(row, 50.0, GC.Spread.Sheets.SheetArea.viewport);
        for (let col = 0; col < sheet.getColumnCount(); ++col) {
          let replacementStyle = null;

          if (row === 0) {
            replacementStyle = headerStyle;
          }
          else if (row % 2 === 0) {
            replacementStyle = evenRowStyle;
          }
          else {
            replacementStyle = oddRowStyle;
          }

          self.mergeStyles(sheet, row, col, replacementStyle);
        }
      }
      //resize canvas
      self.hostStyle = {
        width: (sheet.getColumnCount() * self.columnWidth + 18) + 'px',
        height: '100vh'
      };
    }

    //Disable some extended user interactions so it's not possible to change the spreadsheet structure
    self.spreadsheet.options.allowUserEditFormula = true;
    self.spreadsheet.options.allowUserDeselect = false;
    self.spreadsheet.options.allowSheetReorder = false;
    self.spreadsheet.options.newTabVisible = false;
    self.spreadsheet.options.tabStripVisible = false;
    self.spreadsheet.options.allowContextMenu = false;

    self.updateSidebarActions();
      
    setTimeout(() => {
      self.spreadsheet.refresh();
    });

    self.busyIndicator.setVisible(false);
  }

  private mergeStyles(sheet: GC.Spread.Sheets.Worksheet, row: number, col: number, replacementStyle: GC.Spread.Sheets.Style) {
    const originalStyle = sheet.getStyle(row, col, GC.Spread.Sheets.SheetArea.viewport);

    let newStyle;
    if (originalStyle) {
      newStyle = originalStyle.clone();

      if (replacementStyle.font) {
        newStyle.font = replacementStyle.font;
      }

      if (replacementStyle.foreColor) {
        newStyle.foreColor = replacementStyle.foreColor;
      }

      if (replacementStyle.backColor) {
        newStyle.backColor = replacementStyle.backColor;
      }

      if (replacementStyle.borderLeft) {
        newStyle.borderLeft = replacementStyle.borderLeft;
      }
      else {
        newStyle.borderLeft = null;
      }

      if (replacementStyle.borderRight) {
        newStyle.borderRight = replacementStyle.borderRight;
      }
      else {
        newStyle.borderRight = null;
      }

      if (replacementStyle.borderTop) {
        newStyle.borderTop = replacementStyle.borderTop;
      }
      else {
        newStyle.borderTop = null;
      }

      if (replacementStyle.borderBottom) {
        newStyle.borderBottom = replacementStyle.borderBottom;
      }
      else {
        newStyle.borderBottom = null;
      }

      if (replacementStyle.hAlign) {
        newStyle.hAlign = replacementStyle.hAlign;
      }

      if (replacementStyle.vAlign) {
        newStyle.vAlign = replacementStyle.vAlign;
      }
    }
    else {
      newStyle = replacementStyle;
    }

    sheet.setStyle(row, col, newStyle, GC.Spread.Sheets.SheetArea.viewport);
  }

  public updateSidebarActions() {
    const self = this;

    const sidebarActions = [
      {
        imgUrl: '/assets/images/ionicons/create-outline.svg', translationKey: 'Edit', callback: function () {
          ;
        }
      },
      {
        imgUrl: '/assets/images/SidebarIcons/columns-solid.svg', translationKey: 'dqs.insertTransformation', callback: function () {
          self.insertTransformation();
        }
      },
      {
        imgUrl: '/assets/images/SidebarIcons/eraser-solid.svg', translationKey: 'dqs.removeTransformation', callback: function () {
          self.removeTransformation();
        }
      },
      {
        imgUrl: self.isShowFinal ? '/assets/images/SidebarIcons/eye-slash-solid.svg' : '/assets/images/SidebarIcons/eye-solid.svg', translationKey: 'dqs.showFinal', callback: function () { //TODO: Toggle icon strikethrough
          self.showFinal();
        }
      },
      {
        imgUrl: self.ignoreErrors ? '/assets/images/SidebarIcons/eye-slash-solid.svg' : '/assets/images/SidebarIcons/eye-solid.svg', translationKey: 'dqs.ignoreErrors', callback: function () {
          self.ignoreErrors = !self.ignoreErrors;
          self.updateSidebarActions();
        }
      },
      {
        imgUrl: '/assets/images/SidebarIcons/save-solid.svg', translationKey: 'dqs.saveMapping', callback: function () {
          if (self.templateName) {
            self.saveMapping();
          }
          else {
            self.confirmDialog.open({ simpleMessage: true }, 'dqs.noTemplateName', 'simpleMessage.titleSave');
          }
        }
      },
      {
        imgUrl: '/assets/images/SidebarIcons/window-close-solid.svg', translationKey: 'dqs.close', callback: function () {
          self.doClose();
        }
      }
    ];
    self.sidebarActionsService.setActions(sidebarActions);
  }

  public loadMapping() {
    const self = this;

    self.busyIndicator.setVisible(true);

    const template = self.templateList.filter((map => { return map.id === self.selectedTemplateId; }));
    if (!template.length) {
      return;
    }
    self.templateName = template[0].description;

    self.service.loadMappings(self.selectedTemplateId).then((savedMappings) => {
      self.service.loadMappingFile(self.selectedTemplateId).then((json) => {

        self.importSheetData = json;

        const mappings = [];
        for (let i = 0; i < self.mappableFields.length; ++i) {
          const field = self.mappableFields[i];

          if (!savedMappings.filter((entry) => { return entry.fieldName === field.fieldName; }).length) {

            const mapping = {
              sortOrder: i,
              displayStyle: 1,
              fieldName: field.fieldName,
              fieldType: field.fieldType,
              reflistName: field.reflistName,
              sizeConstraints: field.sizeConstraints,
              action: 1,
              valid: true
            };

            mappings.push(mapping);
          }
        }
        self.hiddenMappings = [];
        self.selectedHiddenMappings = [];

        self.currentMappings = mappings;

        ///creating arrays with one item each for kendo-sortable
        self.currentMappingsNestedArray = [];
        let columnCount = 0;
        const sheetNames = Object.keys(self.importSheetData.sheets);
        for (let i = 0; i < sheetNames.length; ++i) {
          try {
            const sheetName = self.importSheetData.sheets[sheetNames[i]].name;
            if (sheetName !== 'Evaluation version') {
              self.importSheetName = sheetName;
              columnCount = Object.keys(self.importSheetData.sheets[sheetNames[i]].data.dataTable[0]).length;
              break;
            }
          } catch (e) {
            //Ignore and continue
          }
        }

        self.addedTransformationColumnIndices = [];
        for (let i = 0; i < columnCount; ++i) {
          const mappingForColumn = savedMappings.filter((entry) => { return entry.column === i; });
          if (mappingForColumn.length) {
            self.currentMappingsNestedArray[i] = mappingForColumn; //Array assignment is correct & desired here
            if (mappingForColumn[0].addedCellTransformation) {
              self.addedTransformationColumnIndices.push(i);
            }
          }
          else {
            self.currentMappingsNestedArray[i] = [];
          }
        }

        self.availableMappings = mappings;

        self.busyIndicator.setVisible(true);
      }).catch((e) => {
        self.busyIndicator.setVisible(false);
        alert('Failed to load the mapping file');
      });
    }).catch((e) => {
      self.busyIndicator.setVisible(false);
      alert('Failed to load the mapping');
    });
  }

  public deleteMapping() {
    const self = this;

    self.confirmDialog.open({
      success: function () {
        self.busyIndicator.setVisible(true);

        self.service.deleteTemplate(self.selectedTemplateId).then(() => {
          self.templateList = null;
          self.selectedTemplateId = null;
          self.service.getTemplates('PropertyRentRoll').then((result) => {
            self.templateList = result.filter((template) => { return template.id > 1; });
            self.selectedTemplateId = self.templateList.length > 0 ? self.templateList[0].id : 0;
            self.busyIndicator.setVisible(false);
          }).catch((e) => {
            self.busyIndicator.setVisible(false);
            alert('Failed to load the template list');
          });
        }).catch((e) => {
          self.busyIndicator.setVisible(false);
          alert('Failed to delete the template');
        });
      },
      cancel: function () {
        //Nothing
      }
    }, 'dqsDeleteMapping');
  }

  public onFileSelected(e) {
    const self = this;

    self.busyIndicator.setVisible(true);
    if (e.files && e.files.length) {
      const file = e.files[0].rawFile;
      const excelIO = new Excel.IO();
      excelIO.open(file, (json: any) => {
        self.importSheetData = json;
        self.createDefaultMappings();
      }, (e) => {
        debugger;
      });
    }
  }

  public getCategoryColorForField(item: any): string {
    const self = this;

    const field = self.mappableFields.filter((en) => {
      return en.fieldName === item.fieldName;
    });

    return self.getCategoryColor(field[0].category);
  }

  public getCategoryColor(category: string): string {
    const self = this;

    const colors = ['#D9306B', '#F39B13', '#22A498', '#A8D4B7', '#FFD35C'];

    const index = self.categories.indexOf(category);
    if (index >= colors.length) {
      return '#FF0000;';
    }
    return colors[index];
  }

  private setupCategoryFields() {
    const self = this;

    for (let category of self.categories) {
      self.categorySortableFields[category] = [];
    }

    let alreadyMappedFields = [].concat(...self.currentMappingsNestedArray || []).map(f => f.fieldName);

    for (let field of self.mappableFields) {
      if (alreadyMappedFields.indexOf(field.fieldName) === -1) {
        self.categorySortableFields[field.category].push(field);
      }
    }
  }

  public entryAddedToCategorySortable(e: any) {
    let fromMapping = this.sortableService.getSource().wrapper.classList.contains("mappingSortable");
    let toCategory = this.sortableService.target.wrapper.classList.contains("categorySortable");
    if (!fromMapping || !toCategory) {
      e.preventDefault();
      return;
    }

    var sortableCssClassAllowed = e.dataItem.category;//it is set in html
    if (!this.sortableService.target.wrapper.classList.contains(sortableCssClassAllowed)) {
      e.preventDefault();
      return;
    }
  }

  private ensureCellInViewPort(cellInfo): void {

    //this should probably be little adjusted, as any excel file can be uploaded
    //but for now it seems to work (mostly) ok

    //header above sheet = 200px
    //TODO: get real values from DOM
    const headerHeight = 200;
    const leftSideWidth = 0;

    const sheet = cellInfo.sheet;
    const row = cellInfo.row;
    const col = cellInfo.col;

    const containerEl = this.mappingEditorContainer.nativeElement;

    const scrollLeft = containerEl.scrollLeft;
    const scrollTop = containerEl.scrollTop;

    const visibleWidth = containerEl.clientWidth;
    const visibleHeight = containerEl.clientHeight;

    const cellRect = sheet.getCellRect(row, col);
    const cellWidth = cellRect.width;
    const cellHeight = cellRect.height;

    //calc where is cell at the moment
    const cellX = cellRect.x + leftSideWidth;//col * cellWidth + leftSideWidth;
    const cellY = cellRect.y + headerHeight;//row * cellHeight + headerHeight;

    //is it in viewport?
    const viewportX = scrollLeft;
    const viewportY = scrollTop;

    const isInViewPortX = cellX > viewportX && (cellX + cellWidth) < (viewportX + visibleWidth);
    const isInViewPortY = cellY > viewportY && (cellY + cellHeight) < (viewportY + visibleHeight);

    if (!isInViewPortX) {
      //console.log("X not in viewport");
      const directionRight = cellX > viewportX;
      const scrollAmount = cellWidth * 1.1;
      const scrollToX = scrollLeft + (directionRight ? scrollAmount : - scrollAmount);
      containerEl.scrollTo(scrollToX, scrollTop);
    }

    if (!isInViewPortY) {
      //console.log("Y not in viewport");
      const directionDown = cellY > viewportY;
      const scrollAmount = cellHeight * 1.1;
      const scrollToY = scrollTop + (directionDown ? scrollAmount : - scrollAmount);
      containerEl.scrollTo(scrollLeft, scrollToY);
    }

    //edge cases adjustments
    if (col === 0 && containerEl.scrollLeft !== 0) {
      containerEl.scrollTo(0, scrollTop);
    }

    if (row === 0 && containerEl.scrollTop !== 0) {
      containerEl.scrollTo(scrollLeft, 0);
    }
  }
}
