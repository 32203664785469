(function () {

  "use strict";

  /**
  * @ngdoc service
  * @name DXSPolluxApp.service:appServiceHelper
  * @description
  * Service that helps with REST API interfacing
  * 
  * @requires $resource
  * @requires DXSPolluxApp.object:appSettings
  */
  angular
    .module('DXSPolluxApp')
    .factory('appServiceHelper',
      ['appSettings', appServiceHelper]);

  function appServiceHelper(appSettings) {
    const zeroGuid = '00000000-0000-0000-0000-000000000000';
    const zeroGuidPattern = '/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/';

    var organizationGuid = '00000000-0000-0000-0000-000000000000';

    /**
     * @ngdoc function
     * @name getServiceEndpoint
     * @methodOf DXSPolluxApp.service:appServiceHelper
     * @description
     * Builds the route to the service endpoint
     */
    function getBaseServiceEndpoint(service) {
      let ret = appSettings.serverBasePath;
      let apiRequiresProjectGuid = false;

      switch (service) {
        case 'marvin.pollux.api': {
          ret = appSettings.polluxApiUrl;
          apiRequiresProjectGuid = true;
          break;
        }
        case 'marvin.pollux.settings.api': {
          ret = appSettings.settingsApiUrl;
          apiRequiresProjectGuid = true;
          break;
        }
        case 'marvin.pollux.comparables.api': {
          ret = appSettings.comparablesApiUrl;
          apiRequiresProjectGuid = true;
          break;
        }
        case 'marvin.pollux.projectMain.api': {
          ret = appSettings.projectMainApiUrl;
          apiRequiresProjectGuid = true;
          break;
        }
        case 'marvin.pollux.projectValuation.api': {
          ret = appSettings.projectValuationApiUrl;
          apiRequiresProjectGuid = true;
          break;
        }
        case 'marvin.dqs.api': {
          ret = appSettings.dqsApiUrl;
          apiRequiresProjectGuid = true;
          break;
        }
        default: {
          alert('Invalid API configuration: Service endpoint for ' + service + ' is not defined!');
        }
      }

      ret += '/api/';

      if (appSettings.serverApiVersion) {
        ret += appSettings.serverApiVersion + '/';
      }

      if (appSettings.useProjectGuidInUrl && apiRequiresProjectGuid) {
        ret += `${zeroGuid}/${zeroGuid}/`;
      }

      return ret;
    }

    function getServiceEndpoint(service, route) {
      let url = getBaseServiceEndpoint(service);
      url += route;
      return url;
    }

    function getServiceEndpointWithProjectGuid(service, route, selectedProject) {
      let url = getBaseServiceEndpoint(service);
      if(selectedProject) {
        url = url.replace(zeroGuidPattern, `/api/v2/${organizationGuid}/${selectedProject.ProjectGuid}/`);
      }

      url += route;
      return url;
    }

    function setOrganizationGuid(guid) {
      organizationGuid = guid;
    }

    return {
      getServiceEndpoint: getServiceEndpoint,
      getServiceEndpointWithProjectGuid: getServiceEndpointWithProjectGuid,
      setOrganizationGuid: setOrganizationGuid
    };
  }
}());
