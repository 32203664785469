(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridImages
   * @description
   * Displays the images and an interactive map for either jobs or properties. The area is defined via the area parameter
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridImages", {
      template: require('html-loader!./project.valuation.grid.images.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "area": "@",
        "gridData": "<"
      },
      controllerAs: "vm",
      controller: ['$scope', '$timeout', '$rootScope', projectValuationGridImagesCtrl]
    });

  function projectValuationGridImagesCtrl($scope, $timeout, $rootScope) {
    var vm = this;
    //#region variables
    vm.mode = 'images';
    vm.fullscreen = 0;
    vm.enteredArea = '';
    vm.show = true;
    vm.hasCoordinates = false;
    //#endregion

    //#region function declaration
    vm.switchMode = switchMode;
    vm.openSlideshow = openSlideshow;
    //#endregion

    //#region function implementation
    vm.$onInit = function () {
      $scope.$watch("vm.gridData", function () {
        if (vm.area === 'property') {
          vm.hasCoordinates = vm.gridData.Latitude && vm.gridData.Longitude;

          if (vm.hasCoordinates && vm.gridData.ImagesCount) {
            vm.mode = 'images';
          } else if (vm.hasCoordinates && !vm.gridData.ImagesCount) {
            vm.mode = 'map';
          } else if (!vm.hasCoordinates && vm.gridData.ImagesCount) {
            vm.mode = 'images';
          } else {
            vm.mode = 'images';//show at least PropertyNoImage
          }
        } else if (vm.area === 'job') {
          vm.hasCoordinates = vm.gridData.LinkedPropertyLatitude && vm.gridData.LinkedPropertyLongitude;

          if (vm.gridData.LinkedPropertyOid) {
            if (vm.hasCoordinates && vm.gridData.LinkedPropertyImagesCount && !vm.gridData.HasImportedData) {
              vm.mode = 'map';
            } else if (vm.hasCoordinates && vm.gridData.LinkedPropertyImagesCount && vm.gridData.HasImportedData) {
              vm.mode = 'chart';
            } else if (vm.hasCoordinates && !vm.gridData.LinkedPropertyImagesCount && vm.gridData.HasImportedData) {
              vm.mode = 'chart';
            } else if (!vm.hasCoordinates && vm.gridData.LinkedPropertyImagesCount && vm.gridData.HasImportedData) {
              vm.mode = 'chart';
            }
            else {
              vm.mode = null;
            }
          }
          else {
            vm.mode = null;
          }
        }
      });
    };

    function switchMode(mode) {
      vm.mode = mode;
      vm.fullscreen = 0;
    }

    function openSlideshow() {
      if (vm.gridData.ImagesCount > 0) {
        ++vm.fullscreen;
      } else if (vm.area === 'property') {
        openImageManagement();
      }
    }

    function openImageManagement() {
      $rootScope.$broadcast("editPropertiesData.inline", { id: vm.gridData.Oid, data: vm.gridData, mode: 'imageManagement' });
    }
    //#endregion

    //#region events

    $scope.$on("valuationTabSelect", function (ev, param) {
      var newArea = (param === "tabProperty" ? "property"
        : param === "tabValuationPortfolio" ? "job" : "");

      $timeout(function () {
        vm.enteredArea = newArea;
      });
    });

    $scope.$on('localeChanged', function () {
      vm.show = false;
      setTimeout(function () {
        vm.show = true;
      });
    });
    //#endregion
  }
}());

