import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AngularIntegrationService } from '../../../services/AngularIntegrationService';
import { QvPropertyImagesService } from '../qv-property-image-management/_services/qv-property-images.service';

@Component({
  selector: 'qv-property-images-slider',
  templateUrl: './qv-property-images-slider.component.html',
  styleUrls: ['./qv-property-images-slider.component.scss']
})
export class QvPropertyImagesSliderComponent implements OnInit, OnDestroy {
  @Input() oid: any;
  @Input() area: string;
  @Input() data: any;

  @Input('fullscreen') set fullscreen(value: number) {
    //fullscreen is incremented externally. On each increment, we switch to fullscreen
    if (value) {
      this.enableFullscreen();
    }
  }

  @ViewChild('container') container: ElementRef;
  @ViewChild('scrollview') scrollview;
  @ViewChild('thumbsScrollview') thumbsScrollview;

  @HostListener('window:resize', ['$event.target'])
  public onResize(event) {
    if (!this.showThumbs) return;

    //recalc thumbs count and thumbs pages count on resize
    this.setupThumbs();
  }

  public model: Array<any> = [];
  public propertyData: any = {};
  public show = true;
  public _fullscreen = false;
  private firstFullscreen = true;

  public scrollviewWidth = '100%';
  public scrollviewHeight = '100%';

  private nonFullscreenWidth = 440;//367;
  private nonFullscreenHeight = 340;//275;

  private showThumbs = false;
  public showNoImageOverlay = false;

  private onEvents: Array<any> = [];

  constructor(private http: HttpClient, private ais: AngularIntegrationService, private imageService: QvPropertyImagesService) { }

  ngOnInit() {
    if (this.oid) {
      this.showNoImageOverlay = false;
      //loadInfo load images info without Data or Bytes
      this.imageService.loadInfo(this.oid).subscribe(data => {
        if (data && data.length) {
          this.model = (data || []).sort((a, b) => a.Index - b.Index);
          this.loadImages(this.nonFullscreenWidth, this.nonFullscreenHeight);
        } else if (this.area === 'property') {
          this.model = [{ Data: '/assets/images/PropertyNoImage.jpg' }];
          this.showNoImageOverlay = true;
        }
      });
    }

    this.setupEvents();
  }

  ngOnDestroy() {
    const self = this;
    self.onEvents.forEach(unregister => {
      try {
        unregister();
      } catch (e) {
        //Nothing
      }
    });
  }

  private loadImages(width: number, height: number) {
    this.model.forEach(image => {
      image.Data = null;
      this.imageService.loadImageDataUrl(image.Oid, width, height).subscribe(data => {
        if (data && data.length) {
          image.Data = data;
        }
      }, error => {
        //alert("Failed to load image!");
      });
    });
  }

  /**
   * Enables fullscreen mode:
   * 1. If done for the first time, load all images in their original (resized if larger than the screen) size instead of just the 640x480 property picture
   * 2. By setting _fullscreen, the class 'fullscreen' is applied to the 'property-image-outer-container' element, which causes it to be displayed in a fixed fullscreen overlay mode
   */
  public enableFullscreen() {
    this._fullscreen = true;
    if (this.firstFullscreen) {
      this.model = [...this.model];//new instance (same images objects as in "non fullscreen" => just reload with new sizes)
      this.model.forEach(image => { image.Data = null; });
      //Load all images in the maximum available size
      setTimeout(() => {
        let width = this.container.nativeElement.offsetWidth;
        let height = this.container.nativeElement.offsetHeight;
        this.loadImages(width, height);
        this.setupThumbs();
        this.firstFullscreen = false;
        //Get property data
        this.http
          .get(environment.POLLUX_PROJECTVALUATION_API_URL + '/Properties?oid=' + this.oid).subscribe((data: any) => {
            this.propertyData = data;
          });
      });
    } else {
      this.showThumbs = true;
    }
  }

  public disableFullscreen() {
    this._fullscreen = false;
    this.showThumbs = false;
  }

  public onClickEvent(event) {
    if (this._fullscreen) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public openFullscreenMode(item) {
    if (!this._fullscreen && item.Data !== '/assets/images/PropertyNoImage.jpg') {
      this.enableFullscreen();
    }
  }

  public switchToImageManagement() {
    const self = this;
    self.disableFullscreen();
    setTimeout(() => {
      self.ais.$rootScope.$broadcast("editPropertiesData.inline", { id: self.oid, data: self.data, mode: 'imageManagement' });
    });
  }

  /**
   * Close fullscreen if the ESC key is pressed
   * @param event
   */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this._fullscreen) {
      this.disableFullscreen();
    }
  }

  //#region thumbs handling
  public thumbsPages: Array<any> = [];
  public thumbsImages: Array<any> = [];
  public thumbsImagesPerPage: number = 0;
  public thumbsImagesCurrentPage: number = 0;
  public thumbIndex: number = 0;
  public pictureIndex: number = 0;
  public picNumber: number = 1;
  public picCount: number = 0;

  private setupThumbs(): void {
    const images = this.model;
    let width = this.container.nativeElement.offsetWidth;
    const thumbsContainerWidth = width - 120;//10px = 2 arrows each 60px
    this.thumbsImagesPerPage = Math.trunc(thumbsContainerWidth / 170);//each thumb 150px wide + margins 10 + 10

    const thumbsPagesCount: number = Math.round(images.length / this.thumbsImagesPerPage + 0.5);
    this.thumbsPages = new Array<any>(thumbsPagesCount);
    this.thumbsImagesCurrentPage = Math.trunc((this.scrollview ? this.scrollview.activeIndex : 0) / this.thumbsImagesPerPage);
    this.setupThumbsPage(this.thumbsImagesCurrentPage);

    this.showThumbs = true;
  }

  private setupThumbsPage(pageIdx: number): void {
    this.thumbsImages = [];
    const imageFromIdx = pageIdx * this.thumbsImagesPerPage;
    const imageToIdx = Math.min(imageFromIdx + this.thumbsImagesPerPage, this.model.length);

    for (let idx = imageFromIdx; idx < imageToIdx; idx++) {
      let image = this.model[idx];
      this.thumbsImages.push(image);
    }
    this.picCount = this.thumbsImages.length;
  }

  public thumbItemChanged(event) {
    this.thumbsImagesCurrentPage = event.index;
    this.setupThumbsPage(this.thumbsImagesCurrentPage);
  }

  public thumbClicked(thumb: any) {
    //in main scrollview show clicked thumb image
    const pictureIdx = this.model.indexOf(thumb);
    this.scrollview.activeIndex = pictureIdx;
    this.thumbIndex = pictureIdx;
    this.thumbIndex = this.pictureIndex;
  }
  public imageIndex(thumb: any) {
    this.pictureIndex = this.scrollview.activeIndex;
    this.thumbIndex = this.pictureIndex;
    this.picNumber = this.thumbIndex + 1;
  };
  private setupEvents() {
    const self = this;
    let $rootScope = self.ais.$rootScope;

    self.onEvents.push($rootScope.$on('property.images.reindex', function (event, params) {
      let images = params.images || [];
      images.sort((a, b) => a.Index - b.Index);
      images.forEach((item) => {
        const modelImage = self.model.find(_item => _item.Oid === item.Oid);
        if (modelImage) {
          modelImage.Index = item.Index;//apply new Index
        }
      });

      //resort
      self.model = self.model.sort((a, b) => a.Index - b.Index);
    }));
  }


  //#endregion thumbs handling
}
