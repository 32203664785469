(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProjectStdSettingsDialog
   * @description
   * Dialog for project settings
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationProjectStdSettingsDialog", {
      template: require('html-loader!./project.valuation.projectstd.settings.dialog.tmpl.html'),
      bindings: {
        "project": "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$q', 'ProjectsResource', 'ProjectsResourceCustom', 'appStorage', 'angSynapsesDashboardService', '$timeout', 'appUserAvatarService', projectValuationProjectStdSettingsDialogCtrl]
    });

  function projectValuationProjectStdSettingsDialogCtrl($scope, $rootScope, $q, ProjectsResource, ProjectsResourceCustom, appStorage, angSynapsesDashboardService, $timeout, appUserAvatarService) {
    var vm = this;

    //#region variable declaration 
    vm.window = null;

    const openCloseDuration = 500;
    //#endregion


    function reset() {
      vm.downloadDropDownWidget = null;
      vm.project = null;
      vm.internalUsers = '';
      vm.externalUsers = '';
      vm.waitingUsers = '';
      vm.ctModelExportEnabled = 0;
      vm.lastSavedModelExportValue = null;
      vm.archivedPropertiesCount = '';
      vm.archivedJobsCount = '';
      vm.showArchivedProperties = false;
      vm.showArchivedJobs = false;
      vm.archivedJobs = null;
      vm.archivePropertiesIconCssClass = 'svg-icon-green';
      vm.archiveJobsIconCssClass = 'svg-icon-green';
      vm.projectUsers = [];
      vm.showProjectSettingsJobTemplateDialog = false;
      vm.defaultTemplates = [];



      const sidebar = document.getElementById("project-valuation-searchbar");
      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      ///https://docs.telerik.com/kendo-ui/controls/layout/window/how-to/window-as-sliding-pane
      vm.options = {
        title: false,
        //width: "100%",
        //height: "100%",
        //actions: ["close"],
        draggable: false,
        resizable: false,
        modal: false,
        position: {
          top: toolbar.height,
          left: sidebar.clientWidth
        },
        animation: {
          open: {
            effects: "slideIn:left",
            duration: openCloseDuration
          },
          close: {
            effects: "slideIn:left",
            reverse: true,
            duration: openCloseDuration
          }
        },
        visible: false,
        close: function () {
          appStorage.setSelectedProject(null);
          angSynapsesDashboardService.setProjectGuid(null);
          $timeout(function () {
            $rootScope.$broadcast('closeProjectStdSettingsDialog');
          }, openCloseDuration);
        }
      };

      const winW = window.innerWidth;
      const winH = window.innerHeight;
      vm.options.width = winW - sidebar.clientWidth;
      vm.options.height = winH - toolbar.height;
    }

    reset();

    //#region function declaration 
    vm.cancel = cancel;
    vm.internalClicked = internalClicked;
    vm.externalClicked = externalClicked;
    vm.showPropertiesArchiveClicked = showPropertiesArchiveClicked;
    vm.showJobsArchiveClicked = showJobsArchiveClicked;
    vm.getImageForTemplate = getImageForTemplate;
    vm.templateClicked = templateClicked;
    vm.downloadChanged = downloadChanged;
    //#endregion

    //#region function implementation
    /**
     *downloadChanged event handler toggles download on/off and saves it
     */
    function downloadChanged() {
      if (vm.ctModelExportEnabled == 0) {
        //if the value is 0, set it to 1
        vm.ctModelExportEnabled = 1;
      }
      else {
        //in other cases it just is set to 0
        vm.ctModelExportEnabled = 0;
      }
      const data = {
        projectId: vm.project.Oid,
        enabled: vm.ctModelExportEnabled === 1
      };

      kendo.ui.progress(vm.window.element, true);
      ProjectsResourceCustom.setCTModelExportEnabled(data).then((gpr) => {
        kendo.ui.progress(vm.window.element, false);
        if (!(gpr && gpr.success)) {
          alert("Failed to save data!");
        } else {
          vm.lastSavedModelExportValue = vm.ctModelExportEnabled;
        }
      }).catch((err) => {
        kendo.ui.progress(vm.window.element, false);
        alert("Failed to save data!");
      })
    }

    function cancel() {
      vm.window.close();
    }

    function internalClicked() {
      $rootScope.$broadcast("openProjectStdSettingsInternalUsersDialog", { project: vm.project });
    }

    function externalClicked() {
      $rootScope.$broadcast("openProjectStdSettingsExternalUsersDialog", { project: vm.project });
    }

    function formatUsersNumber(num) {
      return num < 10 ? `0${num}` : String(num);
    }

    function preShowArhive() {
      kendo.ui.progress(vm.window.element, true);
      vm.archivePropertiesIconCssClass = 'svg-icon-green';
      vm.archiveJobsIconCssClass = 'svg-icon-green';
      vm.showArchivedProperties = false;
      vm.showArchivedJobs = false;
    }

    function showPropertiesArchiveClicked() {
      if (!vm.archivedPropertiesCount || vm.archivedPropertiesCount === '00') return;

      preShowArhive();

      $q.all([
        ProjectsResourceCustom.loadArchivedPropertiesDetails(vm.project.Oid)
      ]).then(
        function (results) {
          kendo.ui.progress(vm.window.element, false);
          const data = results[0];

          $timeout(function () {
            vm.archivedProperties = JSON.parse(JSON.stringify(data));
            vm.showArchivedProperties = true;
            vm.archivePropertiesIconCssClass = 'svg-icon-red';
          });

        }).catch((err) => {
          alert("Failed to load archived jobs!");
          console.error(err);
          kendo.ui.progress(vm.window.element, false);
        });
    }

    function showJobsArchiveClicked() {
      if (!vm.archivedJobsCount || vm.archivedJobsCount === '00') return;

      preShowArhive();

      $q.all([
        ProjectsResourceCustom.loadArchivedJobsDetails(vm.project.Oid)
      ]).then(
        function (results) {
          kendo.ui.progress(vm.window.element, false);
          const data = results[0].map((job) => {
            job.JobTypeDescription = job.JobTypeId === 1 ? 'CRE' : (job.JobTypeId === 2 ? 'VAL' : 'N/A');
            return job;
          });

          $timeout(function () {
            vm.archivedJobs = JSON.parse(JSON.stringify(data));
            vm.showArchivedJobs = true;
            vm.archiveJobsIconCssClass = 'svg-icon-red';
          });

        }).catch((err) => {
          alert("Failed to load archived jobs!");
          console.error(err);
          kendo.ui.progress(vm.window.element, false);
        });
    }

    function templateClicked(template) {
      const templateType = template.jobTypeId;
      vm.showProjectSettingsJobTemplateDialog = false;
      vm.templateType = templateType;
      $timeout(function () {
        vm.showProjectSettingsJobTemplateDialog = true;
      });
    }
    //#endregion
    //#region "EVENTS"

    $scope.$on('closeProjectStdSettingsJobTemplateDialog', function () {
      vm.showProjectSettingsJobTemplateDialog = false;
    });

    $scope.$on('openProjectStdSettingsDialog', function (event, params) {
      reset();
      const projectId = params.project.Oid;

      setTimeout(() => {
        vm.window.open();
      });

      kendo.ui.progress(vm.window.element, true);
      $q.all([
        ProjectsResource.load(projectId),//always load fresh in case CTModelExportEnabled changed
        ProjectsResourceCustom.loadProjectSettings(projectId)
      ]).then(
        function (results) {
          kendo.ui.progress(vm.window.element, false);

          vm.project = results[0];//set project (ng-if in template)
          vm.ctModelExportEnabled = vm.project.CTModelExportEnabled ? 1 : 0;

          const settings = results[1];

          const settingsData = settings;//.find(r => r.Oid === projectId);
          if (settingsData) {
            const userItems = settingsData.Items.filter(item => item.TileType === 3);

            let item = userItems.find(item => item.ItemType === 3);//InternalUsers
            vm.internalUsers = formatUsersNumber(item ? item.Value : 0);
            item = userItems.find(item => item.ItemType === 4);//ExternalUsers
            vm.externalUsers = formatUsersNumber(item ? item.Value : 0);
            item = userItems.find(item => item.ItemType === 5);//WaitingUsers
            vm.waitingUsers = formatUsersNumber(item ? item.Value : 0);

            const archiveItems = settingsData.Items.filter(item => item.TileType === 5);
            item = archiveItems.find(item => item.ItemType === 1);//Jobs
            vm.archivedJobsCount = formatUsersNumber(item ? item.Value : 0);

            item = archiveItems.find(item => item.ItemType === 6);//Properties
            vm.archivedPropertiesCount = formatUsersNumber(item ? item.Value : 0);

            setupMembers(settingsData.ProjectUsers || [], 'internal');//ProjectUsers are for now "internal" (see API)
            setupTemplates(settingsData.DefaultCalculationModelTemplates || []);
          }
        }).catch(() => {
          kendo.ui.progress(vm.window.element, false);
        });
    });

    function setupMembers(members, usersType) {
      
      switch (usersType) {
        case "internal":
          vm.projectUsers = members;
          vm.internalUsers = formatUsersNumber(members.length);
          break;
        case "external":
          vm.externalUsers = formatUsersNumber(members.length);
          break;
      }
      
      members.forEach((user) => {
        user.initials = appUserAvatarService.getInitials(user.userDescription);

        appUserAvatarService.getUserAvatarUrlAsync(user.userId).then(function (avatarUrl) {
          user.pictureUrl = avatarUrl;
        });

        return user;
      });

      //sort by description ASC
      members.sort((a, b) => (a.userDescription > b.userDescription) ? 1 : ((b.userDescription > a.userDescription) ? -1 : 0));
    }

    function setupTemplates(templates) {
      vm.defaultTemplates = templates;
      vm.defaultTemplates.sort((a, b) => a.jobTypeId >= b.jobTypeId ? 1 : -1);
    }

    function getImageForTemplate(template) {
      var icon = '', cls = '';
      switch (template.jobTypeId) {
        case 1://CRE
          icon = 'flashlight-outline';
          cls = 'svg-icon-green-no-svg-modified';//dummy class => svg was modified to be already green ??
          break;
        case 2://VAL
          icon = 'rocket-outline';
          cls = 'svg-icon-green-no-svg-modified';
          break;
        case 3://RR
          icon = 'glasses-outline';
          cls = 'svg-icon-green';
          break;
      }

      return icon ?
        { src: `../../../../../content/images/ionicons/${icon}.svg`, class: cls }
        : { src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=', class: cls };//1x1 transparent PNG
    }

    $scope.$on('projectMembersChanged', function (event, params) {
      if (params.projectId === vm.project.Oid) {
        let users = JSON.parse(JSON.stringify(params.teamMembers));
        let usersType = params.usersType;//internal or external
        setupMembers(users, usersType);
      }
    });

    $scope.$on('defaultTemplateChange', function (event, params) {
      var changedTemplate = vm.defaultTemplates.find(t => t.jobTypeId === params.jobTypeId);
      if (changedTemplate) {
        changedTemplate.oid = params.oid;
        changedTemplate.name = params.name;
      }
    });

    $scope.$on('closeSidebar', function (event, params) {
      cancel();
    });

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      if (widget === vm.downloadDropDownWidget) {
        vm.downloadDropDownWidget.bind("change", vm.downloadChanged);
      } else if (widget === vm.window) {
        const params = {
          project: vm.project
        };
        $rootScope.$broadcast('openProjectStdSettingsDialog', params);
      }
    });

    //#endregion "EVENTS"
  }
}());

