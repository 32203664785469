(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .directive('dxsFileDropped', function () {
      return {
        scope: {
          dxsFileDropped: "&",
          item: '=item'
        },
        link: function ($scope, $element, $attrs) {

          var allowedExtensions = [];
          if ($attrs.dxsFileDroppedAllowedExtensions) {
            allowedExtensions = $attrs.dxsFileDroppedAllowedExtensions.split(",");
            for (var idx = 0; idx < allowedExtensions.length; idx++) {
              allowedExtensions[idx] = "." + (allowedExtensions[idx].trim()).toLowerCase();
            }
          }

          function endsWith(str, suffix) {
            return str.indexOf(suffix, str.length - suffix.length) !== -1;
          }

          $element.on('dragover', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $element.addClass("infobar-pictures-file-dragenter");
          });

          $element.on('dragenter', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $element.addClass("infobar-pictures-file-dragenter");
          });

          $element.on('dragleave', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $element.removeClass("infobar-pictures-file-dragenter");
          });

          $element.on('drop', function (event) {
            event.preventDefault();
            event.stopPropagation();
            $element.removeClass("infobar-pictures-file-dragenter");
            if (event.originalEvent.dataTransfer) {
              if (event.originalEvent.dataTransfer.files.length > 0) {
                var file = event.originalEvent.dataTransfer.files[0];
                var fileName = file.name.toLowerCase();
                var isAllowed = allowedExtensions.filter(function (ext, idx) {
                  return endsWith(fileName, ext);
                });
                if (allowedExtensions.length === 0 || isAllowed.length) {
                  $scope.dxsFileDropped({ $event: event.originalEvent, item: $scope.item });
                } else {
                  alert("Allowed extensions are: " + $attrs.dxsFileDroppedAllowedExtensions + "!");
                }
              }
            }
            return false;
          });

          $scope.$on("$destroy", function () {
            $element.off();
          });
        }
      };
    });
}());
