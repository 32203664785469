(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxTextField
   * @description
   * This component provides the behavior and visual representation of a single line text field.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Bool} ngMaxLength Specifis the maximum amount of characters that can be entered. If the user enters more characters or pastes a larger string, the overflowing characters will be cut off.
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxTextField", {
      template: require('html-loader!./pollux.text.field.tmpl.html'),
      bindings: {
        "ngMaxLength": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngId": "@",
        "textAreaRows": "="
      },
      controllerAs: "vm",
      controller: ['$timeout', '$element', polluxTextFieldCtrl]
    });

  function polluxTextFieldCtrl($timeout, $element) {
    var vm = this;

    vm.onfocus = onfocus;

    function onfocus(el) {
      var el = $element.find("input");
      if (!el.length) {
        el = $element.find("textarea");
      }

      $timeout(function () {
        //kendo does some formatting, so use $timeout
        el && el.length && el[0].select();
      });
    }

    vm.$onInit = function () {

    }

    vm.onNgChange = function () {
      $timeout(function () {
        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }
  }
}());

