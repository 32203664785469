(function () {

  "use strict";

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:searchService
   * @description
   * This service provides a unified search functionality.
   */
  angular
    .module('DXSPolluxApp')
    .factory('searchService',
      [searchService]);

  function searchService() {
    var self = this;

    /**
     * @ngdoc function
     * @name search
     * @methodOf DXSPolluxApp.service:searchService
     * @description
     * Performs a search.
     * @param {Array} data The array of data where the search is performed in
     * @param {Object} item The search object from a search form or null.
     *
     * @returns {Array} The search results or data if item is null (used for the 'show all' functionality)
     */
    function search(data, item) {
      var filteredData = data;
      if (item && item.values.length) {
        filteredData = [];
        if (item.operator && item.comparisonField && item.operator === 'or') {
          angular.forEach(item.values, function (field, index) {
            var dataForOperator = data.filter(function (entry, index) {
              var compareValue = entry[field.name];
              if (field.mode === "contains") {
                return (compareValue || "").toString().toLowerCase().indexOf((field.value || "").toString().toLowerCase()) >= 0;
              } else if (typeof (compareValue) === 'string' && typeof (field.value) === 'string') {
                return compareValue.toLowerCase() === field.value.toLowerCase() || compareValue.toLowerCase().indexOf(field.value.toLowerCase()) >= 0;
              }
              else if (field.value instanceof Date) {
                var compareDate = new Date(compareValue);
                return compareDate.getYear() === field.value.getYear() && compareDate.getMonth() === field.value.getMonth() && compareDate.getDate() === field.value.getDate();
              }
              else {
                return compareValue == field.value;
              }
            });
            for (var i = 0; i < dataForOperator.length; ++i) {
              var alreadyPresentEntry = filteredData.filter((entry) => { return entry[item.comparisonField] === dataForOperator[item.comparisonField]; });
              if (!alreadyPresentEntry.length) {
                filteredData.push(dataForOperator[i]);
              }
            }


          });

        }
        else {
          angular.forEach(item.values, function (field, index) {
            filteredData = filteredData.filter(function (entry, index) {
              var compareValue = entry[field.name];
              if (field.mode === "contains") {
                return (compareValue || "").toString().toLowerCase().indexOf((field.value || "").toString().toLowerCase()) >= 0;
              } else if (typeof (compareValue) === 'string' && typeof (field.value) === 'string') {
                return compareValue.toLowerCase() === field.value.toLowerCase() || compareValue.toLowerCase().indexOf(field.value.toLowerCase()) >= 0;
              }
              else if (field.value instanceof Date) {
                var compareDate = new Date(compareValue);
                return compareDate.getYear() == field.value.getYear() && compareDate.getMonth() == field.value.getMonth() && compareDate.getDate() == field.value.getDate();
              }
              else {
                return compareValue == field.value;
              }
            });
          });
        }
      }

      return filteredData;
    }

    /**
    * @ngdoc function
    * @name findDataItem
    * @methodOf DXSPolluxApp.service:searchService
    * @description
    * Highlights the item with the specified OID in the grid.
    * 
    * @param {Grid} grid The grid
    * @param {DataSource} dataSource The grid's data source
    * @param {String} itemIdPrefix The item ID prefix for a grid row
    * @param {String} targetField The target field
    * @param {Any} targetFieldValue The desired value in the target field
    */
    function findDataItem(grid, dataSource, itemIdPrefix, targetField, targetFieldValue) {
      //Determine the page and select the item!
      var ds = dataSource;

      var view = kendo.data.Query.process(ds.data(), {
        filter: ds.filter(),
        sort: ds.sort()
      }).data;

      var index = -1;
      for (var x = 0; x < view.length; x++) {
        if (view[x][targetField] == targetFieldValue) {
          index = x;
          break;
        }
      }

      if (index === -1) {
        return;
      }

      var page = Math.floor(index / ds.pageSize());
      var targetIndex = index - (page * ds.pageSize()) + 1;
      //page is 1-based index    
      ds.page(++page);

      grid.select(itemIdPrefix + targetFieldValue);
    }

    /**
     * @ngdoc function
     * @name distinct
     * @methodOf DXSPolluxApp.service:searchService
     * @description
     * Used for DISTINCT filtering
     */
    function distinct(value, index, self) {
      return self.indexOf(value) === index;
    }

    return {
      search: search,
      findDataItem: findDataItem
    };
  }
}());
