(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:appSelectWrapper
   * @description
   * This component encapsulates dropdown fields in ng-repeat or gridlike structures. This is important because multiple Kendo UI elements can't be linked to the same data source as the data source synchronizes all of them. 
   * In such a case, all of the fields always have the same value.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type. Currently, it's used in REM and Stages/Approvals.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngDisabled Sets this field read-only
   * @param {Array} choices An array of elements that can be selected
   * @param {String} fieldName The name of the display field. Must be present in <c>choices</c>.
   * @param {Object} rebind This object will be used to rebind the Kendo UI widget (destroy and re-create).
   * @param {Object} item The object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {Function} filter Filter function. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, the function will be called on every change.
   * @param {Integer} index The row index of the current object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   */
  angular.module("DXSPolluxApp")
    .component("appSelectWrapper", {
      template: require('html-loader!./app.select.wrapper.tmpl.html'),
      bindings: {
        "ngModel": "=",
        "choices": "=",
        "ngChange": "&",
        "ngDisabled": '=',
        "fieldName": "@",
        "rebind": "=",
        "item": "=",
        "filter": "&",
        "index": "="
      },
      controllerAs: "vm",
      controller: ['appLocalization', '$scope', appSelectWrapperCtrl]
    });

  function appSelectWrapperCtrl(appLocalization, $scope) {
    var vm = this;

    //#region variables
    vm.selectDataSource = new kendo.data.DataSource({
      data: []
    });

    vm.selectOptions = null;

    vm.initialized = false;
    //#endregion

    //#region function declaration
    vm.onNgChange = onNgChange;
    //#endregion

    //#region function implementation

    function onNgChange() {
      if (vm.ngChange) {
        vm.ngChange();
      }
    }

    //#endregion

    //#region events

    $scope.$on('localeChanged', function (event, locale) {
      vm.initialized = false;
      $timeout(function () {
        vm.selectOptions = {
          dataTextField: vm.fieldName,
          dataValueField: "oid"
        };
        vm.initialized = true;
      });
    });

    vm.$onInit = function () {
      vm.selectDataSource.data(vm.choices);

      vm.selectOptions = {
        dataTextField: vm.fieldName,
        dataValueField: "oid",
        dataSource: vm.selectDataSource,
        open: function () {
          if (vm.filter) {
            var func = vm.filter();
            if (func) {
              var data = func(vm.item, vm.choices, vm.index);
              vm.selectDataSource.data(data);
            }
          }
        }
      };

      vm.initialized = true;
    }

    //#endregion
  }
}());

