import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'number',
    pure: false
})
export class LocalizedNumber implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    public transform(value: any, digits: string = null): any {
        let ngPipe = new DecimalPipe(this.translate.currentLang || this.translate.defaultLang);
        return ngPipe.transform(value, digits);
    }
}