(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:reflistResource
     * @description
     * This service is responsible for retrieving reflists (ref_... tables) from the service.
     * 
     * The schema of a reflist is as following:
     * 
     *     [
     *         {
     *             "oid": 0,
     *             "description": "",
     *             "parentNo": null
     *         }
     *     ]
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('reflistResource',
        ['$resource', 'appServiceHelper', reflistResource]);

    function reflistResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name loadPollux
             * @methodOf DXSPolluxApp.service:reflistResource
             * @description
             * Loads a reflist from the service (Pollux DB).
             * @param {String} reflistName The reflist's name
             * @param {String} localeOverride Provide a locale string ('de-DE', 'en-US', ...) here to override the locale detection using the X-PolluxLanguage header
             * @param {Bool} withParentNo Include the parentNo for parent->child filtering
             * 
             * @returns {Array} A list of all entries in the desired reflist.
             */
            loadPollux: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux'), { reflistName: '@reflistName', localeOverride: '@localeOverride', withParentNo: '@withParentNo' }),

            /**
             * @ngdoc function
             * @name loadPolluxPostcodeAreas
             * @methodOf DXSPolluxApp.service:reflistResource
             * @description
             * Loads a list of all available post code areas.
             * 
             * <b>Warning</b> Please note that the amount of available post code areas is quite large and that loading the entire list causes 
             * a large amount of data to be transferred as well as a large parsing effort.
             * Therefore, this method shouldn't be used. Instead, please use the same route directly in the Kendo UI DataSource and add the filterId or filterValue parameter. 
             * 
             * Route: api/v2/reflist/pollux/postcodeareas
             * 
             * @returns {Array} A list of all available post code areas.
             */
            loadPolluxPostcodeAreas: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux/postcodeareas'), {}),

            /**
             * @ngdoc function
             * @name loadPolluxDistricts
             * @methodOf DXSPolluxApp.service:reflistResource
             * @description
             * Loads a list of all available districts.
             * 
             * As there are not that many federal states available, this method can be used without any problems.
             * 
             * Route: api/v2/reflist/pollux/districts
             * 
             * @returns {Array} A list of all available districts.
             */
            loadPolluxDistricts: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux/districts'), {}),

            /**
             * @ngdoc function
             * @name loadPolluxRegions
             * @methodOf DXSPolluxApp.service:reflistResource
             * @description
             * Loads a list of all available regions (federal states).
             * 
             * As there are not that many federal states available, this method can be used without any problems.
             * 
             * Route: api/v2/reflist/pollux/regions
             * 
             * @returns {Array} A list of all available federal states.
             */
            loadPolluxRegions: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux/regions'), {}),

            /**
             * @ngdoc function
             * @name loadPolluxMunicipalities
             * @methodOf DXSPolluxApp.service:reflistResource
             * @description
             * Loads a list of all available municipalities.
             * 
             * <b>Warning</b> Please note that the amount of available municipalities is quite large and that loading the entire list causes 
             * a large amount of data to be transferred as well as a large parsing effort.
             * Therefore, this method shouldn't be used. Instead, please use the same route directly in the Kendo UI DataSource and add the filterId or filterValue parameter. 
             * 
             * Route: api/v2/reflist/pollux/municipalities
             * 
             * @returns {Array} A list of all available municipalities.
             */
            loadPolluxMunicipalities: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux/municipalities'), {}),

            /**
             * @ngdoc function
             * @name loadUsers
             * @methodOf DXSPolluxApp.service:reflistResource
             * @description
             * Loads a list of all users (Pollux DB) in the form of a reflist. Useful for user selection dropdowns.
             * 
             * Route: api/v2/reflist/pollux/user
             * 
             * @returns {Array} A list of all available users.
             */
            loadUsers: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux/user'), {})
        }
    }
}());
