(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationRentroll
   * @description
   * This component lists rent rolls in a grid. If an entry is double-clicked, an edit dialog for this entry is shown where the element can be edited and saved.
   * 
   * @requires $translate
   * @requires DXSPolluxApp.service:projectValuationRentrollResource
   * @requires $scope
   * @requires $rootScope
   * @requires $q
   * @requires $timeout
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @requires DXSPolluxApp.component:projectValuationRentroll
   * 
   * @param {Object} tabElement Tab element from the workplace
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationRentroll", {
      template: require('html-loader!./project.valuation.rentroll.tmpl.html'),
      require: {
        "workplace": "^projectValuationWorkplace"
      },
      bindings: {
        "tabElement": "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$timeout', '$q', '$http', 'appLocalization', 'appStorage', 'appServiceHelper'
        , projectValuationRentrollCtrl]
    });

  function projectValuationRentrollCtrl($scope, $rootScope, $timeout, $q, $http, appLocalization, appStorage, appServiceHelper) {
    var vm = this;

    //#region variable declaration
    vm.filterByPropertyId = null;
    vm.availableRentRollDates = [];
    vm.selectedRentRoll = null;
    vm.appLocalization = appLocalization;
    //#endregion

    //#region function declaration
    vm.filter = filter;
    vm.selectRentRollDate = selectRentRollDate;
    vm.importRentrollClick = importRentrollClick;
    //#endregion

    //#region function implementation
    vm.$onInit = function () {
      vm.workplace.onTabCreated(this);

      if (vm.filterByPropertyId <= 0) {
        return;
      }

      loadData();
    };

    function loadData() {
      vm.selectedRentRoll = null;
      vm.availableRentRollDates = [];
      var url = appServiceHelper.getServiceEndpointWithProjectGuid('marvin.pollux.projectValuation.api', `rentroll/property/${vm.filterByPropertyId}/shortinfo/list/paged`, appStorage.getSelectedProject());
      $q.all([
        $http.get(url)
      ]).then(function (results) {
        vm.availableRentRollDates = results[0].data;
      });
    }

    function selectRentRollDate(rentRollDate) {
      vm.selectedRentRoll = null;
      $timeout(function () {
        vm.selectedRentRoll = rentRollDate;

        $rootScope.$broadcast("showRentrollPane.inline", { data: vm.selectedRentRoll });
      });
    }

    function importRentrollClick() {
      $rootScope.$broadcast('showDqs', {
        businessObjectType: 'PropertyRentRoll',
        oid: vm.propertyId,
        dqsVersion: 2
      });
    }

    /**
     * @ngdoc function
     * @name filter
     * @methodOf DXSPolluxApp.component:comparablesValuation
     * @description
     * Called if a BO was selected in any tab of the current area. If it's a property, only the valuations for this property will be displayed.
     * 
     * @param {Object} item The item that was selected
     * @param {Integer} selectedHierarchy The tab hierarchy of the tab where the item was selected
     * @param {Array} tabConfig The current area's tab configuration containing all tabs. selectedHierarchy can be used to filter the current config.
     * @param {Bool} reset true if the 'show all' action was chosen
     */
    function filter(item, selectedHierarchy, tabConfig, reset) {
      vm.filterByPropertyId = null;
      const noFilter = {
        logic: "or",
        filters: []
      };

      if (reset || selectedHierarchy == null || item == null) {
        vm.filterByPropertyId = null;
      } else {
        var filteredTab = tabConfig[selectedHierarchy];
        switch (filteredTab.searchMode) {
          case 'property': {
            vm.filterByPropertyId = item.Oid;

            loadData();

            break;
          }
        }
      }
    }

    //#region events
    $scope.$on('tabRentrollSelect', function (event, params) {
      vm.propertyId = params.id;
      vm.availableRentRollDates.details.length && selectRentRollDate(vm.availableRentRollDates.details[0]); // Select first tab on load
    });

    $rootScope.$on('rentRollImport.property.success', function (event) {
      loadData();
    });
   
    //#endregion events
  }
}());
