import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterPaneComponent } from './filter-pane.component';
import { FilterPaneItemComponent } from './components/filter-pane-item.component';
import { FilterPaneConfigLoaderService } from './services/filter-pane-config-loader.service';
import { FilterPaneService } from './services/filter-pane-service.service';

import { ValueNotInFilterPipe } from './pipes/value-not-in-filter.pipe';
import { ShowMaxItemsPipe } from './pipes/show-max-items.pipe';
import { TranslateModule } from '@ngx-translate/core';

export const translateModule = TranslateModule.forChild();

@NgModule({
  imports: [
    CommonModule,
    translateModule
  ],
  declarations: [
    FilterPaneComponent,
    FilterPaneItemComponent,
    ValueNotInFilterPipe,
    ShowMaxItemsPipe
  ],
  exports: [
    FilterPaneComponent
  ],
  entryComponents: [
    FilterPaneComponent
  ],
  providers: [
    FilterPaneConfigLoaderService,
    FilterPaneService
  ]
})
export class FilterPaneModule { }
