
/*
 * !!!!!!!!!!!!!!!!!!
 * 
 * WARNING: for now there is OLD projectValuationProjectstdResource under this new one (in this file)
 * 
 * keep it for now as it is used on few places !!!
 * 
 * 
 */

(function() {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('ProjectsResource',
            ['$resource', 'appServiceHelper', 'ProjectsResourceCustom', ProjectsResource]);

    function ProjectsResource($resource, appServiceHelper, customResource) {
        var api = "marvin.pollux.projectValuation.api";
        var baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Projects')),
            listResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Projects/list')),
            getResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Projects/get')),
            pagedResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Projects/list/paged'));
        function load(oid) {
          return getResource.get({ oid: oid }).$promise;
        }

        function loadList() {
            return listResource.query().$promise;
        }

        function loadPagedList(take, skip, page, pageSize, filter) {
            return pagedResource.get({
                take: take || 50,
                skip: skip || 0,
                page: page || 1,
                pageSize: pageSize || 50,
                filter: filter || ""
            }).$promise;
        }

        function save(data) {
            return baseResource.save(data).$promise;
        }

        function remove(oid) {
            return baseResource.delete({ oid: oid }).$promise;
        }

        var obj = {
            itemResource: baseResource,
            listResource: listResource,
            pagedListResource: pagedResource,
            load: load,
            loadList: loadList,
            loadPagedList: loadPagedList,
            save: save,
            remove: remove
        };

        for(var prop in customResource) {
            obj[prop] = customResource[prop];
        }

        return obj;

    }
}());


(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationProjectstdResource
     * @description
     * This service is responsible for loading project data.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationProjectstdResource',
        ['$resource', 'appServiceHelper', projectValuationProjectstdResource]);

    function projectValuationProjectstdResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name load
             * @methodOf DXSPolluxApp.service:projectValuationProjectstdResource
             * @description
             * Loads all available project data.
             * 
             * <b>Warning</b> This can be a lot of data.
             * 
             * Route: api/v2/project/valuation/projectstd/details
             * 
             * @returns {Array} All available project data
             */
            load: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/projectstd/details'), {}),

            /**
             * @ngdoc function
             * @name remove
             * @methodOf DXSPolluxApp.service:projectValuationProjectstdResource
             * @description
             * Deletes a project.
             * 
             * Route: api/v2/project/valuation/projectstd
             * 
             * @param {Integer} projectstdId The OID of the project entry that will be deleted
             */
            remove: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/projectstd'), { projectstdId: '@projectstdId' })
        }
    }
}());
