import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MultiDashboardService } from '../multi-dashboard/services/multi-dashboard-service.service';

@Component({
  selector: 'smart-bar',
  templateUrl: './smart-bar.component.html',
  styleUrls: ['./smart-bar.component.css']
})
export class SmartBarComponent implements OnInit, OnDestroy {

  @Input() main: any;

  showSmartBar: boolean = true;

  constructor(private mds: MultiDashboardService) {
  }

  ngOnInit() {
    this.showSmartBar = this.main.searchMode !== 'projectstd' && this.main.searchMode !== 'organization';
    this.initJobsByProperty(true);
  }

  ngOnDestroy(): void {
    this.initJobsByProperty(false);
  }

  //#region COMMON

  private prependZero(n: number): string {
    return (n < 10) ? `0${n}` : `${n}`;
  }

  //#endregion COMMON

  //#region JOBS-BY-PROPERTY

  public jobsByPropertyConfig: any = null;
  private jobsByPropertySub: Subscription;

  private initJobsByProperty(init: boolean) {
    const self = this;
    if (init) {
      this.jobsByPropertySub = this.mds.jobsByProperty$.subscribe((jobsByPropertyCount) => {
        if (jobsByPropertyCount > 0) {
          this.jobsByPropertyConfig = {
            filterItem: { field: 'jobsByProperty', values: [{ oid: 1 }] },
            area: 'multiDashboard',//label: smartBar.multiDashboard.jobsByProperty
            allValues: [{ field: 'jobsByProperty', values: [{ oid: 1, description: this.prependZero(jobsByPropertyCount) }] }],
            itemClicked: function () {
              self.mds.showComparableJobs();
            }
          }
        } else {
          this.jobsByPropertyConfig = null;
        }
      });

    } else {
      this.jobsByPropertyConfig = null;
      this.jobsByPropertySub && this.jobsByPropertySub.unsubscribe();
      this.jobsByPropertySub = null;
    }
  }

  //#endregion JOBS-BY-PROPERTY

}

