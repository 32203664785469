(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationRentrollImportDialog", {
      template: require('html-loader!./project.valuation.rentroll.import.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$timeout', '$translate', '$rootScope', 'appSettings', 'appStorage', 'adalAuthenticationService', 'windowHelper', projectValuationCalcModelDialog]
    });

  function projectValuationCalcModelDialog($scope, $timeout, $translate, $rootScope, appSettings, appStorage, adalAuthenticationService, windowHelper) {
    var vm = this;

    vm.options = {
      actions: ["Full-Screen", "Close"]
    }

    //#region variable declaration
    vm.enableWindow = false;
    vm.dialogWindow = null;
    vm.propertyData = null;
    vm.expanded = false;
    vm.priorPosition = null;
    vm.isImport = true;
    //#endregion

    //#region function declaration
    vm.cancel = cancel;
    //#endregion

    //#region function implementation
    function cancel() {
      if (vm.enableWindow) {
        moveCurrentPropertyOnTop(false);
        vm.enableWindow = false;
        vm.dialogWindow.close();
      }
    }

    function moveCurrentPropertyOnTop(enable) {
      let containerSelector = "div.polluxPane.valuationPortfolioProperty";
      let propertyRowSelector = "#comparablesProperty" + vm.propertyData.Oid;
      if (enable) {
        $(containerSelector).addClass("rowMovedOnTop");
        $(propertyRowSelector).addClass("rowMovedOnTop");
      } else {
        $(containerSelector).removeClass("rowMovedOnTop");
        $(propertyRowSelector).removeClass("rowMovedOnTop");
      }
    }

    //#endregion

    function expandWindow(expand) {
      let $window = vm.dialogWindow.element.parent();
      let pos = vm.priorPosition;

      moveCurrentPropertyOnTop(expand);

      if (expand) {

        $rootScope.$broadcast('minimizeSidebar');

        let top = 120;
        let screenW = $(document).width();
        let screenH = $(document).height();

        let params = {
          callback: function (left) {
            $window.css({
              top: top,
              left: left + 10,
              width: screenW - left - 20,
              height: screenH - top - 10
            });

            //refresh sheet, so it resizes as it should
            $rootScope.$broadcast('dqsValidation.refreshSheet');
          }
        };

        $rootScope.$broadcast('getSmallerSidebarSize', params);

      } else {
        $window.css({
          top: pos.top,
          left: pos.left,
          width: pos.width,
          height: pos.height
        });

        //refresh sheet, so it resizes as it should
        $rootScope.$broadcast('dqsValidation.refreshSheet');
      }
    }

    function openWindow(item) {
      vm.propertyData = item;
      vm.enableWindow = true;
      vm.expanded = false;

      $timeout(function () {
        windowHelper.open(vm.dialogWindow);
      });
    }

    //#region events
    $scope.$on('openRentRollImportDialog', function (event, item) {
      vm.dialogTitle = 'Rent Roll Import';
      vm.isImport = true;
      openWindow(item);
    });

    $scope.$on('openRentRollExportDialog', function (event, item) {
      vm.dialogTitle = 'Rent Roll Export';
      vm.isImport = false;
      openWindow(item);
    });

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      if (widget === vm.dialogWindow) {
        $timeout(function () {
          let window = widget.element.parent();
          let buttonSpan = window.find(".k-i-full-screen");
          let anchor = buttonSpan.parent();

          $(anchor).kendoTooltip({
            position: "top",
            content: function (e) {
              return "Extend to fullscreen";
            }
          });

          anchor.click(function (e) {
            vm.expanded = !vm.expanded;
            if (vm.expanded) {
              let $window = vm.dialogWindow.element.parent();
              vm.priorPosition = $window.position();//top, left
              vm.priorPosition.width = $window[0].clientWidth;
              vm.priorPosition.height = $window[0].clientHeight;
            }

            $timeout(function () {
              expandWindow(vm.expanded);
            });
          });
        });
      }
    });

    //#endregion
  }
}());

