import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Tour } from '../model/tour';
import { JoyrideService } from 'ngx-joyride';
import { TourStep } from '../model/tourStep';

@Component({
  selector: 'tour-unit',
  templateUrl: './tour-unit.component.html',
  styleUrls: ['./tour-unit.component.scss']
})
export class TourUnitComponent implements OnInit {

  @Input() tour: Tour;

  private allTourSteps: TourStep[] = [];
  private visibleTourSteps: TourStep[] = [];

  @Output() closedTour = new EventEmitter<void>();
  @Output() tourStarted = new EventEmitter<void>();

  constructor(private joyrideService: JoyrideService) { }

  ngOnInit() {
    const self = this;
    if (self.tour && self.tour.tourSteps.length !== 0) {

      self.allTourSteps = self.tour.tourSteps;
      self.visibleTourSteps = self.filterOnlyVisible(self.allTourSteps);
    }
  }

  /**
   * Filters for a list of Tour if it is visibile. 
   * @param data
   */
  public filterOnlyVisible(tourSteps: TourStep[]): TourStep[] {
    return tourSteps.filter(tourStep => {
      this.mapElementPosition(tourStep);
      const tourStepElement = document.getElementById(tourStep.tourStepId);
      if (tourStepElement) {
        return window.getComputedStyle(tourStepElement).display !== 'none';
      }
      return false;
    });
  }

  /**
   * Maps the postion from the element to the tourelement
   * @param tourStep
   */
  public mapElementPosition(tourStep: TourStep): void {
    const self = this;
    const rootOverlay = document.getElementById("helpTextRootOverlay");

    const element = document.getElementById(tourStep.tourStepId);
    const values = element ? element.getBoundingClientRect() : null;
    if (values) {
      tourStep.bottom = rootOverlay.clientHeight - values.bottom - self.tour.bottom;
      tourStep.left = values.left - self.tour.left;
      tourStep.right = rootOverlay.clientWidth - values.right - self.tour.right;
      tourStep.top = values.top - self.tour.top;
    }
  }

  started($event: any): void {
    const self = this;
    if (self.visibleTourSteps.length !== 0) {
      const tourStepsIds = self.visibleTourSteps.map(tourStep => tourStep.tourStepId);
      self.joyrideService.startTour(
        { steps: tourStepsIds } // Your steps order
      );
      setTimeout(() => {
        self.tourStarted.emit();
      }, 100);
    }
    
  }
}
