(function() {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('ProjectsResourceCustom',
            ['$resource', 'appServiceHelper', resourceCustom]);

    function resourceCustom($resource, appServiceHelper) {
      const api = 'marvin.pollux.projectValuation.api';
      const projectsSettingsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'projects/detailslist'));
      const projectSettingsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'projects/details'), { projectId: '@projectId' });
      const archivedPropertiesDetailsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties/qv/getarchiveddetails'), { projectId: '@projectId' });
      const archivedJobsDetailsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/qv/getarchiveddetails'), { projectId: '@projectId' });
      const organizationUsersResource = $resource(appServiceHelper.getServiceEndpoint(api, 'usermanagement/getfromorganization'), { projectId: '@projectId' });
      const internalUsersResource = $resource(appServiceHelper.getServiceEndpoint(api, 'usermanagement/getinternal'), { projectId: '@projectId', returnAssigned: '@returnAssigned' });
      const setCTModelExportEnabledResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Projects/ctModelExportEnabled'));
      const addInternalUserResource = $resource(appServiceHelper.getServiceEndpoint(api, 'usermanagement/addinternal'), { projectId: '@projectId' });
      const removeInternalUserResource = $resource(appServiceHelper.getServiceEndpoint(api, 'usermanagement/removeinternal'), { projectId: '@projectId' });
      const createNewProjectResource = $resource(appServiceHelper.getServiceEndpoint(api, 'projects/create'));
      const saveProjectNameResource = $resource(appServiceHelper.getServiceEndpoint(api, 'projects/updateName'));

      const defaultProjectTemplatesResource = $resource(appServiceHelper.getServiceEndpoint(api, 'project/templates/default'), { projectId: '@projectId', jobTypeId: '@jobTypeId', templateId: '@templateId' });
      const availableProjectTemplatesResource = $resource(appServiceHelper.getServiceEndpoint(api, 'project/templates/available'), { projectId: '@projectId', jobTypeId: '@jobTypeId' });
      
      function loadProjectsSettings() {
        return projectsSettingsResource.query().$promise;
      }

      function loadProjectSettings(projectId) {
        return projectSettingsResource.get({ projectId: projectId }).$promise;
      }

      function loadArchivedPropertiesDetails(projectId) {
        return archivedPropertiesDetailsResource.query({ projectId: projectId }).$promise;
      }

      function loadArchivedJobsDetails(projectId) {
        return archivedJobsDetailsResource.query({ projectId: projectId }).$promise;
      }

      function loadOrganizationUsers(projectId) {
        return organizationUsersResource.query({ projectId: projectId }).$promise;
      }

      function loadInternalUsers(projectId) {
        return internalUsersResource.query({ projectId: projectId, returnAssigned: true }).$promise;
      }

      function setCTModelExportEnabled(data) {
        return setCTModelExportEnabledResource.save(data).$promise;
      }

      function addInternalUser(projectId, userId) {
        return addInternalUserResource.save({ projectId: projectId }, userId).$promise;
      }

      function removeInternalUser(projectId, userId) {
        return removeInternalUserResource.save({ projectId: projectId }, userId).$promise;
      }

      function createNewProject() {
        return createNewProjectResource.save({}).$promise;
      }

      function saveProjectName(projectId, projectName) {
        return saveProjectNameResource.save({ projectId: projectId, projectName: projectName}).$promise;
      }

      function getDefaultProjectTemplates(projectId, jobTypeId) {
        return defaultProjectTemplatesResource.query({ projectId: projectId, jobTypeId: jobTypeId }).$promise;
      }

      function getAvailableProjectTemplates(projectId, jobTypeId) {
        return availableProjectTemplatesResource.query({ projectId: projectId, jobTypeId: jobTypeId }).$promise;
      }

      function setAsDefaultTemplate(projectId, jobTypeId, templateId) {
        return defaultProjectTemplatesResource.save({ projectId: projectId, jobTypeId: jobTypeId, templateId }).$promise;
      }

      return {
        loadProjectsSettings: loadProjectsSettings,
        loadProjectSettings: loadProjectSettings,
        loadInternalUsers: loadInternalUsers,
        loadOrganizationUsers: loadOrganizationUsers,
        setCTModelExportEnabled: setCTModelExportEnabled,
        addInternalUser: addInternalUser,
        removeInternalUser: removeInternalUser,
        createNewProject: createNewProject,
        saveProjectName: saveProjectName,
        loadArchivedPropertiesDetails: loadArchivedPropertiesDetails,
        loadArchivedJobsDetails: loadArchivedJobsDetails,
        getDefaultProjectTemplates: getDefaultProjectTemplates,
        getAvailableProjectTemplates: getAvailableProjectTemplates,
        setAsDefaultTemplate: setAsDefaultTemplate
      };
    }
}());
