(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationSettingsDialogValuationPortfolioRoleManagementResource',
        ['$resource', 'appServiceHelper', projectValuationSettingsDialogValuationPortfolioRoleManagementResource]);

    function projectValuationSettingsDialogValuationPortfolioRoleManagementResource($resource, appServiceHelper) {
        return {
            users: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user'), {}),
            roles: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'ValuationPortfolios/roles'), {}),
            roleAssignments: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'ValuationPortfolios/roleAssignments'), { valuationPortfolioId: '@valuationPortfolioId' }),
            deleteRoleAssignment: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'ValuationPortfolios/roleAssignments/delete'), { oid: '@oid' }),
        }
    }
}());
