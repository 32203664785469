(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:projectValuationRentrollResource
   * @description
   * This service is responsible for loading rentroll data.
   * 
   * @requires $resource
   * @requires DXSPolluxApp.object:appServiceHelper
   */
  angular
    .module('DXSPolluxApp')
    .factory('projectValuationRentrollResource',
      ['$resource', 'appServiceHelper', projectValuationRentrollResource]);

  function projectValuationRentrollResource($resource, appServiceHelper) {
    var api = "marvin.pollux.projectValuation.api";

    function loadPropertyRentRoll(propertyId, rentrollDate) {
      var listResource = $resource(appServiceHelper.getServiceEndpoint(api, `rentroll/property/${propertyId}/list`));
      return listResource.query({ rentrollDate: rentrollDate }).$promise;
    }

    function savePropertyRentRoll(propertyId, rentrollDate, data) {
      var listResource = $resource(appServiceHelper.getServiceEndpoint(api, `rentroll/property/${propertyId}`));
      return listResource.save({ propertyId: propertyId, rentrollDate: rentrollDate }, data).$promise;
    }

    function loadJobRentRoll(jobId) {
      var listResource = $resource(appServiceHelper.getServiceEndpoint(api, `rentroll/job/${jobId}/list`));
      return listResource.query({}).$promise;
    }

    function saveJobRentRoll(data) {
      var listResource = $resource(appServiceHelper.getServiceEndpoint(api, `rentroll/job`));
      return listResource.save(data).$promise;
    }

    return {
      loadPropertyRentRoll: loadPropertyRentRoll,
      savePropertyRentRoll: savePropertyRentRoll,
      loadJobRentRoll: loadJobRentRoll,
      saveJobRentRoll: saveJobRentRoll
    }
  }
}());
