
import { SynapsesDqs2ValidationEntry } from './synapses-dqs2-validation-entry';

export class SynapsesDqsValidationResult {
  public isError: boolean;
  public entries: Array<SynapsesDqs2ValidationEntry>;

  public dataRows: number;
  public dataColumnsPerRow: number;
  public emptyColumnsCount: number;

  public additionalValidationInfo: any;

  //used to transfer uploaded file among components
  public rawFile: File;

  constructor() {
    this.isError = false;
    this.entries = [];
    this.dataRows = 0;
    this.dataColumnsPerRow = 0;
    this.emptyColumnsCount = 0;
  }

}
