(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("appRetrieverDialog", {
      template: require('html-loader!./app.retriever.dialog.tmpl.html'),
      transclude: true,//TODO: Why?
      bindings: {
        id: "=",
        description: "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$timeout', '$translate', 'appRetrieverResource', '$q', appRetrieverDialogCtrl]
    });

  function appRetrieverDialogCtrl($scope, $rootScope, $timeout, $translate, appRetrieverResource, $q) {
    var vm = this;

    //#region variable declaration
    vm.retrieverWindow = null;
    vm.config = null;
    vm.searchText = "";
    vm.gridDataLoaded = false;
    vm.gridOptionsDataSource = new kendo.data.DataSource({
      data: [],
      page: 1,
      pageSize: 12
    });

    vm.dxsRetrieverId = "";
    vm.filteredValuationProjectId = -1;
    vm.isMultiselect = false;
    vm.isSingleSelectWithCallback = false;
    vm.actionButtonTitle = "";
    vm.actionButtonTitleCallback = null;
    vm.typeOfSearch = "";
    vm.dxsReturnField = "";
    vm.dxsDescriptionField = "";

    var retrieverLink = "dxsRetriever";

    var dxsTranslations = {
      firstName: "{{ 'dxsRetriever.searchGrid.firstName' | translate }}",
      lastName: "{{ 'dxsRetriever.searchGrid.lastName' | translate }}",
      officeName: "{{ 'dxsRetriever.searchGrid.officeName' | translate }}",
      street: "{{ 'dxsRetriever.searchGrid.street' | translate }}",
      city: "{{ 'dxsRetriever.searchGrid.municipality' | translate }}",
      country: "{{ 'dxsRetriever.searchGrid.country' | translate }}",
      postCode: "{{ 'dxsRetriever.searchGrid.postCode' | translate }}",
      propertyName: "{{ 'dxsRetriever.searchGrid.propertyName' | translate }}",
      streetNumber: "{{ 'dxsRetriever.searchGrid.streetNumber' | translate }}",
      name: "{{ 'dxsRetriever.searchGrid.name' | translate }}",
      projectLeader: "{{ 'dxsRetriever.searchGrid.projectLeader' | translate }}",
      client: "{{ 'dxsRetriever.searchGrid.client' | translate }}",
      clientManual: "{{ 'dxsRetriever.searchGrid.clientManual' | translate }}",
      valuationPortfolioName: "{{ 'dxsRetriever.searchGrid.valuationPortfolioName' | translate }}",
      valuationDate: "{{ 'dxsRetriever.searchGrid.valuationDate' | translate }}",
      remark: "{{ 'dxsRetriever.searchGrid.remark' | translate }}",
      vpUid: "{{ 'dxsRetriever.searchGrid.vpUid' | translate }}",
      puid: "{{ 'dxsRetriever.searchGrid.puid' | translate }}",
      propertyUid: "{{ 'dxsRetriever.searchGrid.propertyUid' | translate }}",
      propertyNumber: "{{ 'dxsRetriever.searchGrid.propertyNumber' | translate }}",
      propertyTypeDescription: "{{ 'dxsRetriever.searchGrid.propertyTypeDescription' | translate }}",
      valuer: "{{ 'dxsRetriever.searchGrid.valuer' | translate }}",
      companyName: "{{ 'dxsRetriever.searchGrid.companyName' | translate }}"
    };

    //#endregion

    //#region function declaration
    vm.search = search;
    vm.cancel = trueCancel;
    //#endregion

    //#region funciton implementation
    vm.modalOpened = function () {
      $timeout(function () {
        var searchInput = document.getElementById('dxsRetrieverInput');
        searchInput.focus();
        searchInput.value = "";
        searchInput.value = vm.searchText;

        vm.search();
      }, 1);

      vm.retrieverWindow.center();
      vm.retrieverWindow.title($translate.instant('dxsRetriever.title'));
    }

    vm.modalOptions = {
      animation: false,
      scrollable: false,
      resizable: false,
      modal: true,
      visible: false
    }

    vm.itemSelected = function (dataItem) {
      if (!vm.isMultiselect && !vm.isSingleSelectWithCallback) {
        $rootScope.$broadcast("setDxsRetreiverInput" + vm.dxsRetrieverId, { inputValue: dataItem[vm.dxsDescriptionField], id: dataItem[vm.dxsReturnField] });
        cancel();
      }

      if (vm.isSingleSelectWithCallback) {
        var items = [];
        vm.retrieverGrid.select().each(function () {
          var currentItem = vm.retrieverGrid.dataItem($(this));
          items.push(currentItem);
        });
        vm.actionButtonTitleCallback(items);
        cancel();
      }
    }

    vm.actionButtonClicked = function () {
      if (vm.actionButtonTitleCallback) {
        var items = vm.retrieverGrid.dataSource.data();
        items = items.filter(function (item) {
          return item._checked;
        });
        vm.actionButtonTitleCallback(items);
      }

      cancel();
    }

    vm.retrieverGridOptions = {
      dataSource: vm.gridOptionsDataSource,
      selectable: "row",
      sortable: true,
      reorderable: true,
      resizable: true,
      groupable: false,
      pageable: {
        pageSize: 10,
        pageSizes: [10, 25],
        numeric: false
      },
      scrollable: true,
      columns: [],
      change: function () {
        var grid = this;
        var currentDataItem = {
          id: "No Item Selected?!"
        };
        if (vm.previouslySelectedItem) {
          vm.previouslySelectedItem._checked = false;
        }
        grid.select().each(function () {
          currentDataItem = grid.dataItem($(this));
        });
        currentDataItem._checked = true;
        vm.previouslySelectedItem = currentDataItem;
        $timeout(function () {
          //Give the browser some time so it doesn't understand the next click as double click
          vm.itemSelected(currentDataItem);
        }, 500);
      }
    };

    function getGridData(searchText, typeOfSearch) {
      vm.loadData(typeOfSearch, searchText, function (queryData) {
        if (queryData) {
          var results = queryData.results;
          var allData = [];
          switch (typeOfSearch) {
            case "User":
              for (var i = 0; i < results.length; i++) {
                var obj = {};
                if (results[i].id) obj.id = results[i].id;
                if (results[i].userFirstName) obj.userFirstName = results[i].userFirstName;
                if (results[i].userLastName) obj.userLastName = results[i].userLastName;
                if (results[i].userEmail) obj.userEmail = results[i].userEmail;
                if (results[i].userTitle) obj.userTitle = results[i].userTitle;
                if (results[i].userInitials) obj.userInitials = results[i].userInitials;

                if (results[i].userDescription) obj.description = results[i].userDescription;
                if (obj.userFirstName && obj.userLastName) obj.description = obj.userLastName + ", " + obj.userFirstName;
                allData.push(obj);
              }
              break;

            case "Property":
              for (var i = 0; i < results.length; i++) {
                var obj = {};
                if (results[i].OID) obj.id = results[i].OID;
                if (results[i].propertyName) obj.propertyName = results[i].propertyName;
                if (results[i].propertyAddress) obj.description = results[i].propertyAddress;
                if (results[i].propertyStreet) obj.propertyStreet = results[i].propertyStreet;
                if (results[i].propertyStreetNumber) obj.propertyStreetNumber = results[i].propertyStreetNumber;
                if (results[i].propertyPostCode) obj.propertyPostCode = results[i].propertyPostCode;
                if (results[i].propertyTown) obj.propertyTown = results[i].propertyTown;
                if (results[i].propertyAddress) obj.propertyAddress = results[i].propertyAddress;
                if (results[i].propertyOwnerDescription) obj.propertyOwnerDescription = results[i].propertyOwnerDescription;
                if (results[i].propertyOfferAssistantDescription) obj.propertyOfferAssistantDescription = results[i].propertyOfferAssistantDescription;
                if (results[i].propertyQuickSearchField) obj.propertyQuickSearchField = results[i].propertyQuickSearchField;
                if (results[i].propertyCountry) obj.propertyCountry = results[i].propertyCountry;
                if (results[i].ownerUserId) obj.ownerUserId = results[i].ownerUserId;
                if (results[i].ownerUserDescription) obj.ownerUserDescription = results[i].ownerUserDescription;
                if (results[i].puid) obj.puid = results[i].puid;
                if (results[i].propertyNumber != null) obj.propertyNumber = results[i].propertyNumber;
                if (results[i].propertyTypeDescription) obj.propertyTypeDescription = results[i].propertyTypeDescription;
                if (results[i].puid) obj.projectstdId = results[i].puid;

                if (results[i].postalCode) obj.postalCode = results[i].postalCode;
                if (results[i].municipality) obj.municipality = results[i].municipality;

                allData.push(obj);
              }
              break;

            case "Project":
              for (var i = 0; i < results.length; i++) {
                var obj = {};
                if (results[i].id) obj.id = results[i].id;
                if (results[i].projectName) obj.projectName = results[i].projectName;
                if (results[i].client) obj.client = results[i].client;
                if (results[i].clientManual) obj.clientManual = results[i].clientManual;
                if (results[i].projectName && results[i].id) obj.description = results[i].projectName;


                allData.push(obj);
              }
              break;

            case "ValuationPortfolio":
            case "ValuationPortfolioParentCandidates":
              for (var i = 0; i < results.length; i++) {
                var obj = {};
                if (results[i].id) obj.id = results[i].id;
                if (results[i].projectName) obj.projectName = results[i].projectName;
                if (results[i].ValuationPortfolioName && results[i].id) obj.description = results[i].ValuationPortfolioName + ", " + results[i].id;
                if (results[i].projectHeadDescription) obj.projectHeadDescription = results[i].projectHeadDescription;
                if (results[i].ValuationClientDescription) obj.ValuationClientDescription = results[i].ValuationClientDescription;
                if (results[i].ClientManual) obj.ClientManual = results[i].ClientManual;
                if (results[i].ValuationPortfolioName) obj.ValuationPortfolioName = results[i].ValuationPortfolioName;
                if (results[i].ValuationDate) obj.ValuationDate = results[i].ValuationDate;
                if (results[i].Remark) obj.Remark = results[i].Remark;
                if (results[i].puid) obj.puid = results[i].puid;


                allData.push(obj);
              }
              break;
          }

          if (vm.dxsFilters != null && vm.applyDxsFilters) {
            var filteredData = [];
            angular.forEach(allData, function (element) {
              var matchesFilter = true;
              angular.forEach(vm.dxsFilters, function (filter) {
                if ((typeOfSearch == 'Property') && (filter.name == 'valuationProjectId' || filter.name == 'ProjectStdID')) {
                  matchesFilter &= true; //This is filtered in the service
                }
                else {
                  matchesFilter &= (element[filter.name] == filter.value);
                }
              });
              if (matchesFilter) {
                filteredData.push(element);
              }
            });


            allData = filteredData;
          }


          vm.gridOptionsDataSource.data(allData);
          if (allData.length < 1) {
            vm.gridDataLoaded = false;
            $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, "dxsRetriever.noResultsMessage", 'simpleMessage.titleSearch');
          }
          else {
            vm.gridDataLoaded = true;
            vm.retrieverGrid.pager.page(1);
          }
        }
      });
    }

    function search() {
      if (vm.searchText != "" && (vm.searchText.length >= 3 || vm.typeOfSearch == 'Report')) {
        //minimum length of 3 for the search to start - except for reports
        getGridData(vm.searchText, vm.config.type);
      }
    }

    function trueCancel() {
      $rootScope.$broadcast("setDxsRetreiverInput" + vm.dxsRetrieverId, { inputValue: vm.searchText });
      cancel();
    }

    function cancel() {
      vm.gridOptionsDataSource.data([]);
      vm.retrieverGridOptions.columns = [];
      vm.gridDataLoaded = false;
      vm.retrieverWindow.close();
    }

    vm.loadData = function (type, searchTerm, callback) {
      if (appRetrieverResource[type]) {
        if (type === 'Property') {
          //As there are lots of filtered properties, filtering is done on the server. The other BOs might be migrated later.
          return appRetrieverResource.Property.query(
            { searchTerm: searchTerm, valuationProjectId: vm.filteredValuationProjectId }, vm.dxsFilters,
            function (data) {
              if (callback) callback(data);
              return data;
            },
            function (error) {
              vm.err = error;
              alert(error.data.ExceptionMessage);
            }
          );
        }
        else if (type === 'ValuationPortfolio') {
          vm.applyDxsFilters = false;//skip client side filtering
          return appRetrieverResource.ValuationPortfolio.get(
            { searchTerm: searchTerm, propertyId: -1 }, vm.dxsFilters,
            function (data) {
              if (callback) callback(data);
              return data;
            },
            function (error) {
              vm.err = error;
              alert(error.data.ExceptionMessage);
            }
          );
        }
        else if (type === 'ValuationPortfolioParentCandidates') {
          const jobTypeId = vm.dxsFilters.find(f => f.name === 'jobTypeId');
          const propertyId = vm.dxsFilters.find(f => f.name === 'propertyId');
          vm.applyDxsFilters = false;//skip client side filtering
          return appRetrieverResource.ValuationPortfolioParentCandidates.get(
            { searchTerm: searchTerm, jobTypeId: jobTypeId ? jobTypeId.value : -1, propertyId: propertyId ? propertyId.value : -1 }, vm.dxsFilters,
            function (data) {
              if (callback) callback(data);
              return data;
            },
            function (error) {
              vm.err = error;
              alert(error.data.ExceptionMessage);
            }
          );
        }
        else {
          return appRetrieverResource[type].get(
            { searchTerm: searchTerm },
            function (data) {
              if (callback) callback(data);
              return data;
            },
            function (error) {
              vm.err = error;
              alert(error.data.ExceptionMessage);
            }
          );
        }
      } else {
        console.error('DxsReceiver: Element has wrong attribute.');
        if (callback) callback({ results: [] });
      }
    };

    $scope.safeApply = function (fn) {
      var phase = this.$root.$$phase;
      if (phase === '$apply' || phase === '$digest') {
        if (fn && (typeof (fn) === 'function')) {
          fn();
        }
      } else {
        this.$apply(fn);
      }
    };

    //#endregion

    //#region events
    $scope.$on('openDxsRetreiverDialog', function (event, config) {
      vm.enableGrid = false;
      $timeout(function () {
        vm.gridOptionsDataSource.page(1);
        vm.gridDataLoaded = false;
        vm.config = config;
        vm.searchText = config.inputValue;
        vm.dxsRetrieverId = config.dxsRetrieverId;
        vm.dxsFilters = config.dxsFilters;
        vm.applyDxsFilters = true;
        vm.filteredValuationProjectId = -1;

        if (vm.dxsFilters && (config.type === 'Property' || config.type === 'PropertyWithoutPortfolio' || config.type === 'PropertyWithoutReport')) {
          var filteredPortfolioId = vm.dxsFilters.filter(function (element) {
            return element.name === 'valuationProjectId';
          });
          if (filteredPortfolioId.length) {
            vm.filteredValuationProjectId = filteredPortfolioId[0].value;
          }
        }

        if (config.dxsReturnField) {
          vm.dxsReturnField = config.dxsReturnField;
        }
        else {
          vm.dxsReturnField = "id";
        }
        if (config.dxsDescriptionField) {
          vm.dxsDescriptionField = config.dxsDescriptionField;
        }
        else {
          vm.dxsDescriptionField = "description";
        }

        vm.isSingleSelectWithCallback = typeof (config.isSingleSelectWithCallback) === "undefined" ? false : config.isSingleSelectWithCallback;
        vm.isMultiselect = typeof (config.isMultiselect) === "undefined" ? false : config.isMultiselect;
        vm.actionButtonTitle = typeof (config.isMultiselect) === "undefined" ? "" : config.actionButtonTitle;
        vm.actionButtonTitleCallback = typeof (config.isMultiselect) === "undefined" ? null : config.actionButtonTitleCallback;
        vm.typeOfSearch = config.type;

        //#region "columnStandard"
        var columns = [];
        switch (vm.typeOfSearch) {
          case "User":
            columns = [
              {
                field: "id",
                title: "Id",
                width: "80px",
                index: 0
              },
              {
                field: "userFirstName",
                title: dxsTranslations.firstName,
                width: "163px",
                index: 1
              },
              {
                field: "userLastName",
                title: dxsTranslations.lastName,
                width: "820px",
                index: 2
              }
            ];
            break;

          case "Property":
            columns = [
              {
                field: "propertyName",
                title: dxsTranslations.propertyName,
                index: 0,
                width: "150px"
              },
              {
                field: "propertyStreet",
                title: dxsTranslations.street,
                index: 1,
                width: "150px"
              },
              {
                field: "propertyStreetNumber",
                title: dxsTranslations.streetNumber,
                index: 2,
                width: "179px"
              },
              {
                field: "municipality",
                title: dxsTranslations.city,
                index: 3,
                width: "176px"
              },
              {
                field: "postalCode",
                title: dxsTranslations.postCode,
                index: 4,
                width: "176px"
              },
              {
                field: "propertyCountry",
                title: dxsTranslations.country,
                index: 5,
                width: "195px"
              },
              {
                field: "id",
                title: 'OID',
                index: 8,
                width: "243px"
              }
            ];
            break;

          case "ValuationPortfolio":
          case "ValuationPortfolioParentCandidates":
            columns = [
              {
                field: "puid",
                title: "PUID",
                width: "74px",
                index: 1
              },
              {
                field: "projectName",
                title: dxsTranslations.name,
                index: 2,
                width: "145px"
              },
              {
                field: "ValuationPortfolioName",
                title: dxsTranslations.valuationPortfolioName,
                index: 3,
                width: "404px"
              },
              {
                field: "ValuationDate",
                title: dxsTranslations.valuationDate,
                template: function (context) { return context.ValuationDate ? kendo.toString(new Date(context.ValuationDate), 'dd.MM.yyyy') : ""; },
                index: 4,
                width: "195px"
              },
              {
                field: "id",
                title: dxsTranslations.vpUid,
                index: 5,
                width: "65px"
              }
            ];
            break;
        }

        //#endregion "columnStandard"

        vm.retrieverGridOptions.columns = columns;

        if (vm.isMultiselect) {
          var checkBox = {
            headerTemplate: '<input type="checkbox" id="checkAll" ng-click="vm.selectOrDeselectAllCheckbox()"/>',
            title: "",
            field: "",
            width: 40,
            template: '<input type="checkbox" class="gridCheckbox" ng-model="dataItem._checked" />'
          };

          columns.unshift(checkBox);
        }

        vm.retrieverWindow.center();
        vm.retrieverWindow.open();
        vm.retrieverWindow.center();

        vm.enableGrid = true;
      }, 10);
    });

    vm.selectOrDeselectAllCheckbox = function () {

      var checkbox = angular.element('.gridCheckbox');
      var checkAll = angular.element('#checkAll')[0];
      if (checkAll.checked == true) {
        for (var i = 0; i < checkbox.length; i++) {
          checkbox[i].checked = true;
        }
      } else {
        for (var i = 0; i < checkbox.length; i++) {
          checkbox[i].checked = false;
        }
      }
    };

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      // the event is emitted for every widget; if we have multiple
      // widgets in this controller, we need to check that the event
      // is for the one we're interested in.
      if (widget === vm.retrieverGrid) {
        //grid is created
        $timeout(function () {
          if (vm.typeOfSearch == 'Report') {
            getGridData(vm.searchText, vm.config.type);
          }
        }, 10);
      }
    });

    //#endregion "EVENTS"
  }
}());
