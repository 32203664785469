import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SynapsesDqsService } from '../_services/synapses-dqs-service.service';

@Component({
  selector: 'synapses-dqs-reflist-mapper',
  templateUrl: './synapses-dqs-reflist-mapper.component.html',
  styleUrls: ['./synapses-dqs-reflist-mapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SynapsesDqsReflistMapperComponent implements OnInit {
  public vm = this;

  @Input() type: string = 'dialog';
  @Input() entry: any;
  @Input() values: any[];
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  

  public reflistValues: any[] = null;

  public currentMappings: any[];

  public doubleMapping = false;
  public missingMapping = false;
  public sameTarget = false;

  constructor(private service: SynapsesDqsService, @Inject('appBusyIndicatorService') private busyIndicator: any, @Inject('appConfirmDialogService') private confirmDialog: any) { }

  ngOnInit() {
    const self = this;
    self.busyIndicator.setVisible(true);
    self.service.getReflist(self.entry.reflistName).then((reflistValues) => {
      self.reflistValues = reflistValues;

      let mappings: any[] = [];
      if (self.entry.reflistMappings) {
        mappings = JSON.parse(JSON.stringify(self.entry.reflistMappings)) as any[];
      }

      for (let i = 0; i < self.values.length; ++i) {
        const existingMapping = mappings.filter((en) => { return en.key === self.values[i]; });
        if (!existingMapping.length) {
          let value = null;

          const existingTargetMapping = self.reflistValues.filter((ref) => {
            return self.values[i] !== '' && ref.description.toLowerCase() === self.values[i].toLowerCase();
          });

          if (existingTargetMapping.length) {
            value = existingTargetMapping[0].description;
          }
          mappings.push({
            key: self.values[i],
            value: value
          });
        }
      }

      self.currentMappings = mappings;
      self.validateMappings();
      self.busyIndicator.setVisible(false);
    });
  }

  public addRow(): void {
    const self = this;

    self.currentMappings.push({
      key: '',
      value: null
    });

    self.validateMappings();
  }

  public getValidationClass(item: any): string {
    if (item.invalid) {
      return 'invalid';
    }
    if (item.warning) {
      return 'warning';
    }
    return 'valid';
  }

  public validateMappings(): boolean {
    const self = this;

    self.missingMapping = false;
    self.doubleMapping = false;
    self.sameTarget = false;


    for (let i = 0; i < self.currentMappings.length; ++i) {
      const matchingId = self.reflistValues.filter((entry) => {
        return entry.description === self.currentMappings[i].value;
      });
      if (matchingId.length) {
        self.currentMappings[i].valueId = matchingId[0].oid;
      }
    }

    for (let i = 0; i < self.currentMappings.length; ++i) {
      const currentMapping = self.currentMappings[i];
      currentMapping.invalid = false;
      currentMapping.warning = false;

      if (currentMapping.key && !currentMapping.value || !currentMapping.key && currentMapping.value) {
        //Error: One of the fields is empty - not both, which will be ignored at saving
        self.missingMapping = true;
        currentMapping.invalid = true;
        currentMapping.warning = false;
      }

      const otherMappingsWithSameKey = self.currentMappings.filter((entry, index) => {
        return entry.key === currentMapping.key && index !== i;
      });
      if (otherMappingsWithSameKey.length) {
        //Error: Duplicate key
        self.doubleMapping = true;
        currentMapping.invalid = true;
        currentMapping.warning = false;
      }

      if (!currentMapping.invalid) {
        const otherMappingsWithSameValue = self.currentMappings.filter((entry, index) => {
          return entry.value === currentMapping.value && index !== i;
        });
        if (otherMappingsWithSameValue.length) {
          //Warning: Same target
          self.sameTarget = true;
          currentMapping.warning = true;
        }
      }
    }


    return !self.doubleMapping && !self.missingMapping;
  }

  public doClose(state: string): void {
    const self = this;

    if (state === 'yes' && self.validateMappings()) {
      self.entry.reflistMappings = self.currentMappings;
    }
    this.close.emit(state);
  }
}
