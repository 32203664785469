(function() {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('PropertiesResourceCustom',
            ['$resource', 'appServiceHelper', resourceCustom]);

    function resourceCustom($resource, appServiceHelper) {
        var api = "marvin.pollux.comparables.api";
        var recreateImagesResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Properties/recreateImages'), null, { 'update': { method:'PUT' } });

        function recreateImages(idsArray) {
            return recreateImagesResource.save(idsArray).$promise;
        }

        //nothing to enhance
        return {
            recreateImages: recreateImages
        };
    }
}());
