(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProjectStdSettingsExternalUsersDialog
   * @description
   * Dialog for project settings external user selection
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationProjectStdSettingsExternalUsersDialog", {
      template: require('html-loader!./project.valuation.projectstd.settings.external.users.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$compile', '$q', '$timeout', 'appStorage', 'ProjectsResourceCustom', projectValuationProjectStdSettingsExternalUsersDialogCtrl]
    });

  function projectValuationProjectStdSettingsExternalUsersDialogCtrl($scope, $compile, $q, $timeout, appStorage, ProjectsResourceCustom) {
    var vm = this;
    vm.window = null;

    vm.selectedUsersDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectOptionsSelectedUsers = {
      dataTextField: "descriptionEnglish",
      dataValueField: "userId",
      dataSource: vm.selectedUsersDataSource,
      template: selectedUsersTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };
    
    function reset() {
      vm.project = null;
      vm.loaded = false;
      vm.selectedUsersDataSource.data([]);
      vm.email = "";

      const userDetails = appStorage.getCurrentUserDetails() || { User_Firstname: '', User_Lastname: '' };
      vm.userDescription = `${userDetails.User_Firstname} ${userDetails.User_Lastname}`;

      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      const windowWidth = 400;
      vm.options = {
        title: false,
        width: windowWidth + "px",
        draggable: false,
        resizable: false,
        modal: true,
        position: {
          top: toolbar.height,
          left: 0
        },
        animation: {
          open: {
            effects: "slideIn:left",
            duration: 200
          },
          close: {
            effects: "slideIn:left",
            reverse: true,
            duration: 200
          }
        },
        visible: false,
        activate: function () {
          // Close window when clicked outside of window
          angular.element(".k-overlay").on("click",
            function () {
              if (vm.window) {
                $timeout(function () {
                  vm.cancel();
                });
              }
            });
        }
      };

      const winW = window.innerWidth;
      const winH = window.innerHeight;
      vm.options.position.left = winW - windowWidth;
      vm.options.height = winH - toolbar.height;
    }

    reset();

    vm.cancel = cancel;
    vm.inviteClicked = inviteClicked;
    vm.minusButtonClicked = minusButtonClicked;

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationProjectStdSettingsExternalUsersDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      vm.window.close();
    }

    function inviteClicked() {
      //TODO
      alert("Not implemented yet!");
    }


    function minusButtonClicked(id) {
      $q.all([
        ProjectsResourceCustom.removeInternalUser(vm.project.Oid, id)
      ]).then(function (results) {
        vm.selectedUsersDataSource.data(vm.selectedUsersDataSource.data().filter((item) => { return item.userId !== id; }));
      }).catch(() => {
      });
    }

    function compileTemplate(e) {
      var listbox = this;
      $timeout(function () {
        var rows = listbox.items();
        rows.each(function (index, row) {
          if (!$(row).hasClass("ng-scope")) {
            $compile(row)($.extend($scope.$new(), listbox.dataItem(row)));
          }
        });
      });
    }

    function selectedUsersTemplate(dataItem) {
      return `
<div class="item-row">
  <div class="item-text item-text-cyan">
    ${dataItem.userDescription}
  </div>
  <span class="item-button k-icon k-i-minus" ng-click="vm.minusButtonClicked(${dataItem.userId})"></span>
</div> 
`;
    }

    //#region "EVENTS"

    $scope.$on('openProjectStdSettingsExternalUsersDialog', function (event, params) {
      reset();

      vm.project = params.project;
      const projectId = vm.project.Oid;

      setTimeout(() => {
        $q.all([
          ProjectsResourceCustom.loadInternalUsers(projectId),
        ]).then(function (results) {
          kendo.ui.progress(vm.window.element, false);

          //TODO any desired order?
          const selectedUsers = results[0];
          vm.selectedUsersDataSource.data(
            selectedUsers
          );
          vm.selectedUsersDataSource.fetch();

          vm.loaded = true;
        }).catch(() => {
          kendo.ui.progress(vm.window.element, false);
        });

        vm.window.open();
      });
    });
      
    //#endregion "EVENTS"
  }
}());

