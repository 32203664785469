import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({ selector: 'project-valuation-rentroll-distribution-pane' })
export class Ng1ProjectValuationRentrollDistributionPaneComponentWrapper extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() propertyOid: number;
  @Input() rentRollDate: number;

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('projectValuationRentrollDistributionPane', elementRef, injector);
  }
}
