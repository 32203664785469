import { Component, Input, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import * as zc from '@dvsl/zoomcharts';
import { WindowRefService } from "../window-ref";
import { CashflowChartsService } from './_services/cashflow-charts-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cashflowcharts',
  templateUrl: './cashflow-charts.component.html',
  styleUrls: ['./cashflow-charts.component.scss']
})

export class CashflowchartsComponent implements AfterViewChecked, OnDestroy {
  @Input() id: string;
  @Input() job: any;

  @ViewChild('chart') chartDiv: ElementRef;

  // for optimal operation: ref to zc instance
  private zc: any = zc;
  public _chartID: string;
  private _data: any;
  private chartInstance: any;
  private width = 0;
  private height = 0;

  constructor(winRef: WindowRefService, private cashflowChartsService: CashflowChartsService, private translate: TranslateService) {
    winRef.nativeWindow.ZoomChartsLicense = "ZCP-he5p70237:  ZoomCharts SDK license for 3 Chart Developers";
    winRef.nativeWindow.ZoomChartsLicenseKey = "33e5694b13267f9859017a44420f5959ee8cde847c63f7794e" +
      "8998a4a218d3dd4ba67e84474c0dedd90210668d98e97ef350b3dedfc4a64e466c6254d15db88" +
      "6b92f31adda725e1019af6570ea8d3b432ec7d28a7f5fff781a735fe72c5d336557b2308e8d22" +
      "1fa9f40043758cfab6acce1109c7d30868d31001add291c9fbb8f0fe8342d3fd8c9b2d02c3798" +
      "68a039756d173548950fc5986a320630a942fad209c8531b7749ffda661a87dad0ce621a6e357" +
      "e8a51763a256c35a1fa324fb6e5e190708234cf9d8de32d22f2f8020f4cd34677661d66aa0e7a" +
      "17cb79d53d09fb1b181bdfa81543569321a4cc20156c6955e30f6b935c576421524e3f9122100";
  }

  ngOnDestroy() {
    const self = this;

    if (self.chartInstance) {
      self.chartInstance.remove();
      self.chartInstance = null;
    }
  }

  ngAfterViewChecked() {
    const self = this;

    if (!self.chartInstance && self._data && self._data.subvalues && self._data.subvalues.length) {
      self.width = self.chartDiv.nativeElement.clientWidth;
      self.height = self.chartDiv.nativeElement.clientHeight;

      self.createChart();
    } else if (self.chartInstance && (self.chartDiv.nativeElement.clientWidth !== self.width || self.chartDiv.nativeElement.clientHeight !== self.height)) {
      self.width = self.chartDiv.nativeElement.clientWidth;
      self.height = self.chartDiv.nativeElement.clientHeight;
      self.chartInstance = null;

      self.createChart();
    }
  }

  ngOnInit() {
    const self = this;

    self._chartID = 'chart' + self.id;

    const values = {
      subvalues: []
    };

    self._data = values;

    const jobId = parseInt(self.id);
    self.cashflowChartsService.load(jobId).then((data: any[]) => {
      const values = {
        subvalues: []
      };

      for (let i = 0; i < data.length; ++i) {
        if (data[i].year !== null) {
          values.subvalues.push({
            name: data[i].year,
            noi: data[i].noi
          });
        }
      }

      if (values.subvalues && values.subvalues[values.subvalues.length - 1] && !values.subvalues[values.subvalues.length - 1].noi) {
        values.subvalues.splice(values.subvalues.length - 1, 1);
      }

      self._data = values;
    });
  }

  public createChart() {
    const self = this;

    if (self._data && self._data.subvalues && self._data.subvalues.length) {
      const FacetChart = self.zc.FacetChart;

      let chartSettings = {
        container: document.getElementById(self._chartID),
        interaction: {
          resizing: { enabled: true },
          scrolling: { enabled: false },
          zooming: { enabled: false}
        },
        area: {
          style: { fillColor: "#ffffff" },
          height: 325,
          width: self.width
        },
        labels: { enabled: true },
        data: {
          preloaded: self._data
        },
        series: [{
          type: "columns",
          data: { field: "noi" },
          style: { fillColor: "#007288" }
        }],
        info: {
          enabled: false
        },
        title: {
          enabled: true,
          text: self.translate.instant(self.job.JobTypeId === 4 ? 'projectValuation.noiCustom': 'projectValuation.noi'),
          margin: 5,
          style: {
            fillColor: "#007288",
            font: '15px Arial'
          }
        },
        toolbar: {
          enabled: false
        },
        facetAxis: {
          enabled: true,
          defaultUnitWidth: 50,/*DEFAULT*/
          labels: {
            textStyle: {
              fillColor: "#007288"
            }
          }
        },
        valueAxisDefault: {
          enabled: true,
          style: {
            valueLabel: {
              textStyle: {
                fillColor: "#007288"
              }
            }
          }
        }
      };

      const MAGIC_LEFT_AXIS_WIDTH = 80;
      let singleWidth: any = Math.round((chartSettings.area.width - MAGIC_LEFT_AXIS_WIDTH) / self._data.subvalues.length); 
      chartSettings.facetAxis.defaultUnitWidth = singleWidth;

      self.chartInstance = new FacetChart(chartSettings);
      self.chartInstance.updateSize();
    }
    else {
      self.chartInstance = null;
    }
  }
}
