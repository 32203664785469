(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:windowHelper
   * @requires $window
   */
  angular
    .module('DXSPolluxApp')
    .factory('windowHelper',
      ['$window', windowHelper]);

  function windowHelper($window) {
    function open(window, className) {
      centerInternal(window);
      if (className) {
        window.element[0].classList.add(className);
      }
      window.open();
    };

    function center(window, options) {
      if (window && window.setOptions) {
        window.setOptions(options);
        centerInternal(window);
      }
    }

    function centerInternal(kendoWindow) {

      //Bug 10247 - for smaller screens, adjust height and width in order for window to be fully visible and scrollable
      if($window.innerHeight < kendoWindow.options.height){
        kendoWindow.options.height = 0.9 * $window.innerHeight;
      }
      if($window.innerWidth < kendoWindow.options.width){
        kendoWindow.options.width = 0.9 * $window.innerWidth;
      }
      var top = ($window.innerHeight - kendoWindow.options.height) / 2;
      var left = ($window.innerWidth - kendoWindow.options.width) / 2;
      var options = {
        position: {
          top: top,
          left: left
        }
      };
      kendoWindow.setOptions(options);
    }

    return {
      open: open,
      center: center
    };
  };
}());
