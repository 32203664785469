(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('ReportsResource',
            ['$resource', 'appServiceHelper', 'ReportsResourceCustom', ReportsResource]);

    function ReportsResource($resource, appServiceHelper, customResource) {
        var api = "marvin.pollux.projectValuation.api";
        var baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports')),
            listResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/list')),
            pagedResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/list/paged')),
            attachmentsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/attachments'));
        function load(oid) {
            return baseResource.get({ oid: oid }).$promise;
        }

        function loadPagedList(take, skip, page, pageSize, filter) {
            return pagedResource.get({
                take: take || 50,
                skip: skip || 0,
                page: page || 1,
                pageSize: pageSize || 50,
                filter: filter || ""
            }).$promise;
        }

        function save(data) {
            return baseResource.save(data).$promise;
        }

        function remove(oid) {
            return baseResource.delete({ oid: oid }).$promise;
        }

        function loadAttachments(propertyId, valuationPortfolioId) {
            return attachmentsResource.get({ propertyId: propertyId, valuationPortfolioId: valuationPortfolioId }).$promise;
        }

        var obj = {
            itemResource: baseResource,
            pagedListResource: pagedResource,
            load: load,
            loadPagedList: loadPagedList,
            save: save,
            remove: remove,
            loadAttachments: loadAttachments
        };

        for (var prop in customResource) {
            obj[prop] = customResource[prop];
        }

        return obj;

    }
}());


(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationReportResource
     * @description
     * This service is responsible for loading report data.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationReportResource',
            ['$resource', 'appServiceHelper', projectValuationReportResource]);

    function projectValuationReportResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name load
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Loads all available report data.
             * 
             * <b>Warning</b> This can be a lot of data, especially if the report JSON is included. This might cause performance issues and shouldn't be used if it can be avoided. Better use <c>filter</c> or <c>loadNoJson</c> instead.
             * 
             * Route: api/v2/report/details
             * 
             * @returns {Array} All available report data
             */
            load: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'report/details'), { valuationProjectId: '@valuationProjectId' }),

            /**
             * @ngdoc function
             * @name loadNoJson
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Loads all available report data but <b>WITHOUT</b> the report JSON to save bandwidth.
             * 
             * Route: api/v2/report/details/noJson
             * 
             * @returns {Array} All available report data <b>WITHOUT</b> the report JSON
             */
            loadNoJson: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'report/details/noJson'), { valuationProjectId: '@valuationProjectId' }),

            /**
             * @ngdoc function
             * @name copy
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Copies a report
             * 
             * Route: api/v2/report/copy
             * 
             * @param {Integer} reportId The OID of the report that will be copied
             */
            copy: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'Reports/copy'), { reportId: '@reportId' }),

            /**
             * @ngdoc function
             * @name remove
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Deletes a report.
             * 
             * Route: api/v2/report
             * 
             * @param {Integer} reportId The OID of the report entry that will be deleted
             */
            remove: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'report'), { reportId: '@reportId' }),

            /**
             * @ngdoc function
             * @name reportcount
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Determines the amount of reports that are available for the given propertyId and the given portfolioId
             * 
             * Route: api/v2/report/reportcount
             * 
             * @param {Integer} propertyId The OID of the property
             * @param {Integer} portfolioId The OID of the valuation portfolio
             * 
             * @returns {Integer} The amount of reports that are available for the given propertyId and the given portfolioId
             */
            reportcount: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'Reports/reportcount'), { propertyId: '@propertyId', portfolioId: '@portfolioId' }),

            /**
             * @ngdoc function
             * @name calculate
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Starts the database calculations for the RD widgets in the specified report
             * 
             * Route: api/v2/report/calculate
             * 
             * @param {Integer} reportId The OID of the report where the calculations should be started
             */
            calculate: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'report/calculate'), { reportId: '@reportId' }),

            /**
             * @ngdoc function
             * @name filter
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Loads all available report data that match the given criteria.
             * 
             * Route: api/v2/report/details/filter
             * 
             * @param {Integer} valuationProjectId The valuation portfolio OID
             * 
             * @returns {Array} All available report data that match the given filter criteria
             */
            filter: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'report/details/filter'), { valuationProjectId: '@valuationProjectId' }),

            /**
             * @ngdoc function
             * @name createAutomatically
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Checks if there are properties in the given valuation portfolio that don't have a report assigned to them.
             * After that, the reports for these properties are created automatically.
             * 
             * <b>Hint</b> This might take a while. Use some kind of busy indicator.
             * 
             * Route: api/v2/report/bulkCreate
             * 
             * @param {Integer} valuationProjectId The OID of the valuation portfolio where the reports should be created
             */
            createAutomatically: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'report/bulkCreate'), { valuationProjectId: '@valuationProjectId' }),

            /**
             * @ngdoc function
             * @name bulkFinalize
             * @methodOf DXSPolluxApp.service:projectValuationReportResource
             * @description
             * Performs finalization for a list of reports
             * 
             * <b>Hint</b> This might take a while. Use some kind of busy indicator.
             * 
             * Route: api/v2/report/bulkFinalize
             */
            bulkFinalize: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'Reports/bulkFinalize'), {}, { 'start': { method: 'POST', isArray: false } })
        }
    }
}());
