(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationJobRentrollPane", {
      template: require('html-loader!./project.valuation.job.rentroll.pane.tmpl.html'),
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$q', 'appLocalization', 'projectValuationRentrollResource', 'appSidebarActionsService', 'reflistResource', 'dateHelper', 'notificationsService', projectValuationJobRentrollPaneCtrl]
    });

  function projectValuationJobRentrollPaneCtrl($rootScope, $scope, $q, appLocalization, projectValuationRentrollResource, sidebarActionsService, reflistResource, dateHelper, notificationsService) {
    var vm = this;

    vm.appLocalization = appLocalization;
    vm.updateSidebarActions = updateSidebarActions;
    vm.sidebarActionsService = sidebarActionsService;
    vm.editMode = false;
    vm.choicesForUnitStatus = [];
    vm.choicesForAreaType = [];
    vm.choicesForAdaptionFrequency = [];
    vm.datePickerOptions = {
      max: new Date(9999, 11, 31),
      dateInput: true,
      format: appLocalization.getLocale() === 'en-US' ? 'dd/MM/yyyy' : 'dd.MM.yyyy'
    };

    function reset() {
      vm.dataItem = null;
      vm.jobId = 0;
      vm.tenants = null;
      vm.tenantsClone = null;
      vm.choicesForUnitStatus = [];
      vm.choicesForAreaType = [];
      vm.choicesForAdaptionFrequency = [];
      vm.editMode = false;
      vm.sidebarActionsService.clear();
    }

    reset();

    /**
   * @ngdoc function
   * @name updateSidebarActions
   * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
   * @description
   * Updates the sidebar actions.
   *
   */
    function updateSidebarActions() {
      var sidebarActions = [];

      sidebarActions.push({
        imgUrl: '/assets/images/ionicons/create-outline.svg', translationKey: 'rentrolltable.edit', callback: function () {
          vm.editTable();
        }
      });
      sidebarActions.push({
        imgUrl: '/assets/images/ionicons/save-outline.svg', translationKey: 'rentrolltable.save', callback: function () {
          vm.save();
        }
      })
      sidebarActions.push({
        imgUrl: '/assets/images/ionicons/close-circle-outline.svg', translationKey: 'rentrolltable.close', callback: function () {
          vm.closeEditMode();
        }
      })
      vm.sidebarActionsService.setActions(sidebarActions);
    }

    /**
   * @ngdoc function
   * @name $onDestroy
   * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
   * @description
   * Cleanup
   */
    vm.$onDestroy = function () {
      vm.sidebarActionsService.clear();
    }

    /**
  * @ngdoc function
  * @name editTable
  * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
  * @description
  * Edits table
  */
    vm.editTable = function () {
      for (let i = 0; i < vm.tenants.length; ++i) {
        vm.tenants[i].leaseStart = vm.tenants[i].leaseStart ? new Date(vm.tenants[i].leaseStart) : null;
        vm.tenants[i].leaseEnd = vm.tenants[i].leaseEnd ? new Date(vm.tenants[i].leaseEnd) : null;
        vm.tenants[i].lastIndexationDate = vm.tenants[i].lastIndexationDate ? new Date(vm.tenants[i].lastIndexationDate) : null;
      }
      vm.editMode = true;
      vm.updateSidebarActions();
    }

    /**
  * @ngdoc function
  * @name closeEditMode
  * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
  * @description
  * Closes edit mode
  */
    vm.closeEditMode = function () {
      vm.editMode = false;
      vm.tenants = vm.tenantsClone;
      vm.updateSidebarActions();
    }

    /**
  * @ngdoc function
  * @name changeUnitStatusDescription
  * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
  * @description
  * Changes unit status description
  */
    vm.changeUnitStatusDescription = function (tenant) {
      var selectedItem = vm.choicesForUnitStatus.filter(function (item) {
        return item.oid == tenant.unitStatusId;//!! == because number vs string comparision
      });
      tenant.unitStatusDescription = selectedItem[0].description;
    }

    /**
   * @ngdoc function
   * @name changeAreaTypeDescription
   * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
   * @description
   * Changes area type description
   */
    vm.changeAreaTypeDescription = function (tenant) {
      var selectedItem = vm.choicesForAreaType.filter(function (item) {
        return item.oid == tenant.areaTypeId;//!! == because number vs string comparision
      });
      tenant.areaTypeDescription = selectedItem[0].description;
    }

    /**
   * @ngdoc function
   * @name changeAdaptionFrequencyDescription
   * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
   * @description
   * Changes unit status description
   */
    vm.changeAdaptionFrequencyDescription = function (tenant) {
      var selectedItem = vm.choicesForAdaptionFrequency.filter(function (item) {
        return item.oid == tenant.adaptionFrequencyId;//!! == because number vs string comparision
      });
      tenant.adaptionFrequencyDescription = selectedItem[0].description;
    }

    function getDateSaveValue(value) {
      if (!value) return value;

      value = dateHelper.toISOString(dateHelper.fixTimeZoneOffsetForSave(value));
      return value;
    }

    /**
  * @ngdoc function
  * @name save
  * @methodOf DXSPolluxApp.component:projectValuationJobRentrollPane
  * @description
  * Saves table
  */
    vm.save = function () {
      var data = JSON.parse(JSON.stringify(vm.tenants));
      for (let i = 0; i < data.length; ++i) {
        data[i].leaseStart = getDateSaveValue(data[i].leaseStart);
        data[i].leaseEnd = getDateSaveValue(data[i].leaseEnd);
        data[i].lastIndexationDate = getDateSaveValue(data[i].lastIndexationDate);
      }

      $rootScope.$broadcast('loadingIndicator', true);
      projectValuationRentrollResource.saveJobRentRoll(data).then(function (result) {
        $rootScope.$broadcast('loadingIndicator', false);
        if (result && result.success) {
          notificationsService.notifyJobAssumptionsChange({ jobId: vm.jobId, messageId: 304/*JOB_ASSUMPTIONS_CHANGE_JOB_RENTROL_CHANGE*/ });
          alert("Saved successfully");
          vm.editMode = false;
          vm.updateSidebarActions();
        } else {
          alert("Saving error!");
        }
      },
        function (error) {
          $rootScope.$broadcast('loadingIndicator', false);
          //Error callback
          vm.err = error;
          alert("An error occurred: " + JSON.stringify(error)); //Add error handling
        });
    }

    /* close handling*/
    $scope.$on("toggleAreaFilters", function (event, params) {
      params.forceClose && reset();
    });

    vm.close = function () {
      reset();
    }

    /* show handling */
    $rootScope.$on("showJobRentrollPane.inline", function (event, params) {
      reset();
      if (params.data) {
        vm.dataItem = params.data;
        vm.jobId = vm.dataItem.jobId;

        $q.all([
          reflistResource.loadPollux.query({ reflistName: 'ref_report_occupancylevel', localeOverride: vm.appLocalization.getLocale() }).$promise,
          reflistResource.loadPollux.query({ reflistName: 'ref_unit_type', localeOverride: vm.appLocalization.getLocale() }).$promise,
          reflistResource.loadPollux.query({ reflistName: 'ref_cpi_adaption_frequency', localeOverride: vm.appLocalization.getLocale() }).$promise,
          projectValuationRentrollResource.loadJobRentRoll(vm.jobId)
        ]).then(function (results) {
          vm.choicesForUnitStatus = results[0];
          vm.choicesForAreaType = results[1];
          vm.choicesForAdaptionFrequency = results[2];
          vm.tenants = results[3];

          for (let i = 0; i < vm.tenants.length; ++i) {
            vm.tenants[i].leaseStart = vm.tenants[i].leaseStart ? new Date(vm.tenants[i].leaseStart) : null;
            vm.tenants[i].leaseEnd = vm.tenants[i].leaseEnd ? new Date(vm.tenants[i].leaseEnd) : null;
            vm.tenants[i].lastIndexationDate = vm.tenants[i].lastIndexationDate ? new Date(vm.tenants[i].lastIndexationDate) : null;
          }
          vm.tenantsClone = JSON.parse(JSON.stringify(vm.tenants));//clone tenants
          vm.updateSidebarActions();
        });
      }
    });

    $rootScope.$on('localeChanged', function (_event, locale) {
      vm.datePickerOptions.format = locale === 'en-US' ? 'dd/MM/yyyy' : 'dd.MM.yyyy';
    });

  }
}());
