(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationJobRentroll
   * @description
   * This component lists job rent rolls in a grid. If an entry is double-clicked, an edit dialog for this entry is shown where the element can be edited and saved.
   * 
   * @requires $translate
   * @requires DXSPolluxApp.service:projectValuationRentrollResource
   * @requires $scope
   * @requires $rootScope
   * @requires $q
   * @requires $timeout
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @requires DXSPolluxApp.component:projectValuationJobRentroll
   * 
   * @param {Object} tabElement Tab element from the workplace
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationJobRentroll", {
      template: require('html-loader!./project.valuation.job.rentroll.tmpl.html'),
      require: {
        "workplace": "^projectValuationWorkplace"
      },
      bindings: {
        "tabElement": "="
      },
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', '$timeout', '$q', '$http', 'appLocalization', 'appStorage', 'appServiceHelper', projectValuationJobRentrollCtrl]
    });

  function projectValuationJobRentrollCtrl($scope, $rootScope, $timeout, $q, $http, appLocalization, appStorage, appServiceHelper) {
    var vm = this;

    //#region variable declaration
    vm.filterByJobId = null;
    vm.availableRentRollDate = null;
    vm.appLocalization = appLocalization;

    //#endregion

    //#region function declaration
    vm.filter = filter;
    //#endregion

    //#region function implementation

    /**
  * @ngdoc function
  * @name $onInit
  * @methodOf DXSPolluxApp.component:projectValuationJobRentroll
  * @description
  * Initializes the grid, loads data
  */
    vm.$onInit = function () {
      vm.workplace.onTabCreated(this);

      if (vm.filterByJobId <= 0) {
        return;
      }

      loadData();
    };

    function loadData() {
      vm.availableRentRollDate = null;
      var url = appServiceHelper.getServiceEndpointWithProjectGuid('marvin.pollux.projectValuation.api', `rentroll/job/${vm.filterByJobId}/shortinfo/list/paged`, appStorage.getSelectedProject());
      $q.all([
        $http.get(url)
      ]).then(function (results) {
        vm.availableRentRollDate = results[0].data.details[0];
      });
    }

    /**
     * @ngdoc function
     * @name filter
     * @methodOf DXSPolluxApp.component:projectValuationJobRentroll
     * @description
     * Called if a BO was selected in any tab of the current area. If it's a property, only the valuations for this property will be displayed.
     * 
     * @param {Object} item The item that was selected
     * @param {Integer} selectedHierarchy The tab hierarchy of the tab where the item was selected
     * @param {Array} tabConfig The current area's tab configuration containing all tabs. selectedHierarchy can be used to filter the current config.
     * @param {Bool} reset true if the 'show all' action was chosen
     */
    function filter(item, selectedHierarchy, tabConfig, reset) {
      vm.filterByJobId = null;
      const noFilter = {
        logic: "or",
        filters: []
      };

      if (reset || selectedHierarchy == null || item == null) {
        vm.filterByJobId = null;
      } else {
        var filteredTab = tabConfig[selectedHierarchy];
        switch (filteredTab.searchMode) {
          case 'valuationPortfolio': {
            vm.filterByJobId = item.Oid;

            loadData();

            break;
          }
        }
      }
    }

    //#region events
    $scope.$on('tabJobRentrollSelect', function (event) {
      $timeout(function () {
        $rootScope.$broadcast("showJobRentrollPane.inline", { data: vm.availableRentRollDate });
      });
    });

    //#endregion events
  }
}());
