(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationSettingsDialogResource',
        ['$resource', 'appServiceHelper', projectValuationSettingsDialogResource]);

    function projectValuationSettingsDialogResource($resource, appServiceHelper) {
        return {
            loadHurdles: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/hurdles/loadData'), {}),
            saveHurdles: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/hurdles/saveData'), {}),
            loadInflationMarketRentalGrowth: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/inflationrate/loadData'), { importId: '@importId' }),
            loadInflationMarketRentalGrowthImports: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/inflationrate/imports'), {}),
            loadPurchasersCosts: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/purchaserscosts/loadData'), { importId: '@importId' }),
            loadPurchasersCostsImports: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/purchaserscosts/imports'), {}),
            savePurchasersCosts: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/purchaserscosts/saveData'), { importId: '@importId' }),
            loadRounding: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/rounding/loadData'), { importId: '@importId' }),
            loadRoundingImports: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/rounding/imports'), {}),
            saveRounding: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.settings.api', 'project/valuation/settings/rounding/saveData'), { importId: '@importId' })
        }
    }
}());
