(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxRetrieverField
   * @description
   * This component provides the behavior and visual representation of a retriever field. 
   * 
   * The user can search for the desired value by typing into the visible field. 
   * Once he typed at least one character, a dialog opens that displays matching data in a grid. The user can then choose the desired entry from this grid.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Bool} isNullable Specifis if this field can contain null values. In such a case, an empty field will be saved as null. Otherwise, the field will be saved as 0.
   * @param {Float} ngDisplayedModel Binds the displayed value to the outside component.
   * @param {Array} filters List of filters that can be used to restrict the choosable values (for example, only the reports for a specific project can be chosen)
   * @param {String} returnField The name of the field that is returned from the retriever's data transfer object. 
   * @param {String} descriptionField The name of the description field that is displayed from the retriever's data transfer object. 
   * @param {String} retrieverType The retriever type (e.g. 'Project'). Must be present in app.retriever.resource
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxRetrieverField", {
      template: require('html-loader!./pollux.retriever.field.tmpl.html'),
      bindings: {
        "isNullable": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngDisplayedModel": '=',
        "filters": '=',
        "returnField": '@',
        "descriptionField": '@',
        "retrieverType": '@',
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxRetrieverFieldCtrl]
    });

  function polluxRetrieverFieldCtrl($timeout) {
    var vm = this;

    //#region variable declaration
    vm.displayModel = null;
    //#endregion

    //#region function declaration
    vm.onNgChange = onNgChange;
    //#endregion

    //#region function implementation
    function onNgChange() {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.onNgChange();
    }
    //#endregion
  }
}());

