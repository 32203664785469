(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("appImportDialog", {
      template: require('html-loader!./app.import.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$timeout', '$translate', 'appImportExportResource', '$rootScope', 'appSettings', 'adalAuthenticationService', appImportDialogCtrl]
    });

  function appImportDialogCtrl($scope, $timeout, $translate, appImportExportResource, $rootScope, appSettings, adalAuthenticationService) {
    var vm = this;

    vm.importArea = null;
    vm.templateName = null;
    vm.appImportDialogWindow = null;
    vm.upload = null;
    vm.valuationType = 0;
    vm.callback = null;

    vm.additionalData = "";

    vm.enableWindow = false;

    vm.cancel = cancel;

    vm.availableValuationTypesDataSource = new kendo.data.DataSource({
      data: [{
        "Oid": 0,
        "Name": $translate.instant('import.valuation')
      },
      {
        "Oid": 1,
        "Name": $translate.instant('import.valuationvpv')
      }]
    });

    vm.valuationTypesOptions = {
      dataTextField: "Name",
      dataValueField: "Oid"
    };

    vm.uploadOptions = {
      multiple: true,
      async: {
        saveUrl: appSettings.polluxApiUrl + '/api/v2/import',
        autoUpload: false
      },
      validation: {
        allowedExtensions: ['.xlsx']
      },
      dropZone: '.dropZoneElement',
      localization: {
        select: $translate.instant('import.select'),
        cancel: $translate.instant('import.cancel'),
        clearSelectedFiles: $translate.instant('import.clearSelectedFiles'),
        dropFilesHere: $translate.instant('import.dropFilesHere'),
        headerStatusUploaded: $translate.instant('import.headerStatusUploaded'),
        headerStatusUploading: $translate.instant('import.headerStatusUploading'),
        invalidFileExtension: $translate.instant('import.invalidFileExtension'),
        invalidFiles: $translate.instant('import.invalidFiles'),
        invalidMaxFileSize: $translate.instant('import.invalidMaxFileSize'),
        invalidMinFileSize: $translate.instant('import.invalidMinFileSize'),
        remove: $translate.instant('import.remove'),
        retry: $translate.instant('import.retry'),
        statusFailed: $translate.instant('import.statusFailed'),
        statusUploaded: $translate.instant('import.statusUploaded'),
        uploadSelectedFiles: $translate.instant('import.uploadSelectedFiles')
      },
      upload: function (e) {
        var files = e.files;
        if (vm.valuationType == 1) {
          vm.importArea += 'Vpv';
        }

        var uploadUrl = appSettings.polluxApiUrl + '/api/v2/import/' + (vm.templateName || vm.importArea);
        e.sender.options.async.saveUrl = uploadUrl;

        if (adalAuthenticationService.isAzure) {
          var xhr = e.XMLHttpRequest;
          xhr.addEventListener("readystatechange", function (e) {
            if (xhr.readyState == 1 /* OPENED */) {
              var resource = adalAuthenticationService.getResourceForEndpoint(uploadUrl);
              if (resource) {
                xhr.withCredentials = true;
                xhr.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
              }
            }
          });
        }

        e.data = { additionalData: vm.additionalData ? JSON.stringify(vm.additionalData) : "{}" };
      },
      error: function (e) {
        alert(e.XMLHttpRequest.responseText);
      },
      success: function (e) {
        if (!e.response.success) {
          alert(e.response.message);
          e.preventDefault();
          $timeout(function () {
            angular.element('.k-upload-status-total').text($translate.instant('import.invalidFiles'));
          }, 10);
        }
        else {
          $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, "import.statusUploaded", 'simpleMessage.titleUpload');
          vm.appImportDialogWindow.close();
          vm.enableWindow = false;
          vm.valuationType = 0;
          $scope.$apply();
          $timeout(function () {
            angular.element('.k-upload-status-total').text($translate.instant('import.headerStatusUploaded'));
            switch (vm.importArea) {
              case 'ReportRentRoll': {
                $rootScope.$broadcast('TenantLoadData');
                vm.importArea = null;
                break;
              }
              case 'ReportValuation': {
                $rootScope.$broadcast('ValuationLoadData');
                vm.importArea = null;
                break;
              }
              case 'ReportCompetitorOverview': {
                $rootScope.$broadcast('CompetitorLoadData');
                vm.importArea = null;
                break;
              }
              case 'Objects': {
                $rootScope.$broadcast('comparables.property.saved');
                vm.importArea = null;
                break;
              }
              case 'Lease': {
                $rootScope.$broadcast('comparables.leases.saved');
                vm.importArea = null;
                break;
              }
              case 'Valuation': {
                $rootScope.$broadcast('comparables.valuation.saved');
                vm.importArea = null;
                break;
              }
              case 'Sales':
                var broadcastMessage = 'nothing';
                switch (vm.templateName) {
                  case 'SalesTransaction':
                    broadcastMessage = 'comparables.sale.saved';
                    break;
                  case 'SalesTransactionHealthcare':
                    broadcastMessage = 'comparables.sale.healthcare.saved';
                    break;
                  case 'SalesTransactionIndustrial':
                    broadcastMessage = 'comparables.sale.industrial.saved';
                    break;
                  case 'SalesTransactionLogistic':
                    broadcastMessage = 'comparables.sale.logistic.saved';
                    break;
                  case 'SalesTransactionParking':
                    broadcastMessage = 'comparables.sale.parking.saved';
                    break;
                  case 'SalesTransactionResidential':
                    broadcastMessage = 'comparables.sale.residential.saved';
                    break;
                  case 'SalesTransactionRetail':
                    broadcastMessage = 'comparables.sale.retail.saved';
                    break;

                }
                $rootScope.$broadcast(broadcastMessage);
                vm.importArea = null;
                break;

            }
            if (vm.callback) {
              vm.callback();
              vm.callback = null;
            }
          }, 10);
        }

      }
    }

    function cancel() {
      vm.importArea = null;
      vm.templateName = null;
      vm.additionalData = null;
      vm.appImportDialogWindow.close();
      vm.valuationType = 0;
    }

    //#region "EVENTS"

    $scope.$on('startAppImport', function (event, item) {
      vm.importArea = item.importArea;
      vm.templateName = item.templateName || null;
      vm.additionalData = item.additionalData || null;
      vm.callback = item.callback;
      vm.enableWindow = true;
      $timeout(function () {
        vm.appImportDialogWindow.title($translate.instant('import.valuationPortfolioImport' + (vm.importArea || '')));
        vm.appImportDialogWindow.center();
        vm.appImportDialogWindow.open();
      }, 10);
    });
    //#endregion "EVENTS"
  }
}());

