(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProjectstd
   * @description
   * This component lists Projects in a grid. If an entry is double-clicked, an edit dialog for this entry is shown where the element can be edited and saved.
   * 
   * <b>Note</b> This component loads all available report data in one batch
   * 
   * @requires $translate
   * @requires $rootScope
   * @requires $scope
   * @requires $timeout
   * @requires $q
   * @requires DXSPolluxApp.service:appStorage
   * @requires DXSPolluxApp.service:dateHelper
   * @requires DXSPolluxApp.service:appLocalization
   * @requires DXSPolluxApp.service:ProjectsResource
   * 
   * @requires DXSPolluxApp.component:projectValuationWorkplace
   * 
   * @param {Object} tabElement Tab element from the workplace
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationProjectstd", {
      template: require('html-loader!./project.valuation.projectstd.tmpl.html'),
      require: {
        "workplace": "^projectValuationWorkplace"
      },
      bindings: {
        "tabElement": "="
      },
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$timeout', '$q', 'appStorage', 'ProjectsResource', 'ProjectsResourceCustom', 'angSynapsesDashboardService', 'appSettings', 'filterPaneService', 'appUserAvatarService', 'projectValuationGridKpiSettingsService', projectValuationProjectstdCtrl]
    });

  function projectValuationProjectstdCtrl($rootScope, $scope, $timeout, $q, appStorage, ProjectsResource, ProjectsResourceCustom, angSynapsesDashboardService, appSettings, filterPaneService, appUserAvatarService, projectValuationGridKpiSettingsService) {
    var vm = this;

    // #region variables
    vm.allData = [];
    vm.environmentCT = appSettings.environment.COME_TOGETHER_URL + '/kanban';
    vm.showProjectSettingsDialog = false;
    vm.initialized = false;

    // #endregion variables

    //#region function declaration
    vm.loadData = loadData;
    vm.filter = filter;
    vm.navigateTo = navigateTo;
    vm.openProjectSettingsClicked = openProjectSettingsClicked;
    vm.getUiNumber = getUiNumber;
    vm.goToCometogetherProject = goToCometogetherProject;
    vm.manualEnableProjectEdit = manualEnableProjectEdit;
    //#endregion

    //region function implementation

    function initDashboard(project) {
      angSynapsesDashboardService.setOrganizationGuid && angSynapsesDashboardService.setOrganizationGuid(appStorage.getOrganizationGuid());
      angSynapsesDashboardService.setProjectGuid(project.ProjectGuid);
    }

    function navigateTo(project) {
      $q.all([
        projectValuationGridKpiSettingsService.load(true) //Reload KPI settings when a project i selected
      ]).then(function () {
        appStorage.setSelectedProject(project);
        initDashboard(project);
        vm.workplace.onElementSelected(vm.tabElement, project);
        $rootScope.$broadcast('selectedProjectChanged', 'tabProperty');
        $rootScope.$broadcast('valuationsidebar.activateArea', 'tabProperty');

        var paramsProperty = {
          area: 'property',
          name: 'Default',
          filters: [],
        };

        var paramsValuationPortfolio = {
          area: 'valuationPortfolio',
          name: 'Default',
          filters: [],
        };

        $q.all([
          filterPaneService.saveFilterSet(paramsProperty, true)/*GenericPolluxResult*/
        ]).then(function (result) {
          if (result[0].success) {
            filterPaneService.filtersChanged(paramsProperty);
            $rootScope.$broadcast('resetFiltersByProject');
          } else {
            alert("Error: " + result.message);
          }
        });

        $q.all([
          filterPaneService.saveFilterSet(paramsValuationPortfolio, true)/*GenericPolluxResult*/
        ]).then(function (result) {
          if (result[0].success) {
            filterPaneService.filtersChanged(paramsValuationPortfolio);
            $rootScope.$broadcast('resetFiltersByProject');
          } else {
            alert("Error: " + result.message);
          }
        });
      });
    }


    function initialize(postInitializeFn) {
      vm.initialized = true;

      vm.allData = null;
      vm.loadData();
      vm.enableGrid = true;

      postInitializeFn && postInitializeFn();
    }

    /**
    * @ngdoc function
    * @name filter
    * @methodOf DXSPolluxApp.component:projectValuationPortfolio
    * @description
    * Called if a BO was selected in any tab of the current area. If it's a project, only the portfolios for this project will be displayed.
    * 
    * @param {Object} item The item that was selected
    * @param {Integer} selectedHierarchy The tab hierarchy of the tab where the item was selected
    * @param {Array} tabConfig The current area's tab configuration containing all tabs. selectedHierarchy can be used to filter the current config.
    * @param {Bool} reset true if the 'show all' action was chosen
    */
    function filter(item, selectedHierarchy, tabConfig, reset) {
      vm.filterByPropertyId = null;
      const noFilter = {
        logic: "and",
        filters: []
      };
      //if (reset || selectedHierarchy == null) {
      //  vm.gridOptionsDataSource.filter(noFilter);
      //} else {
      var filteredTab = tabConfig[selectedHierarchy];
      switch (filteredTab.searchMode) {
        case 'organization': {
          //const projectSelectedFn = () => {
          //  vm.gridOptionsDataSource.data([]);
          //  vm.gridOptionsDataSource.filter(noFilter);
          //};
          initialize();
          //else {
          //  projectSelectedFn();
          //}
          break;
          //}
        }
      }
    }

    /**
     * @ngdoc function
     * @name loadData
     * @methodOf DXSPolluxApp.component:projectValuationProjectstd
     * @description
     * Loads the data
     * 
     * @param {Function} callback Callback function that is called once everything is loaded
     */
    function loadData(callback, params) {
      var tabElement = angular.element('#tabProjectstd-component');

      kendo.ui.progress(tabElement, true);
      $q.all([
        ProjectsResource.loadList(),
        ProjectsResourceCustom.loadProjectsSettings()
      ]).then(
        function (results) {
          const data = results[0].filter(item => item.Oid > 0);
          const settings = results[1];
          data.forEach(item => {
            const setting = settings.find(s => s.Oid === item.Oid) || { Items: [], ProjectUsers: [] }
            item.TileItems = setting.Items || [];
            setupMembers(item, setting.ProjectUsers || []);
          });

          data.sort(function (a, b) {
            //desc
            if (a.Oid < b.Oid) {
              return 1;
            }
            if (a.Oid > b.Oid) {
              return -1;
            }
            return 0;
          });

          //dev: params = { newProjectId: 10032296 }

          if (params && params.newProjectId) {
            //if loaded after creation, mark new so its name can be edited
            const projectId = params.newProjectId;
            const projects = data.filter(item => item.Oid === projectId);
            if (projects.length) {
              projects[0].editable = true;
              $rootScope.$broadcast('ProjectEditMode', { enable: true });
            }
          }

          vm.allData = data;

          kendo.ui.progress(tabElement, false);
          $timeout(function () {
            if (callback) {
              callback();
            }
          });
        },
        function (error) {
          vm.err = error;
          alert(error.data.ExceptionMessage);
        }
      );
    }

    function setupMembers(item, members) {
      item.ProjectUsers = members;
      members.forEach((user) => {
        user.initials = appUserAvatarService.getInitials(user.userDescription);

        appUserAvatarService.getUserAvatarUrlAsync(user.userId).then(function (avatarUrl) {
          user.pictureUrl = avatarUrl;
        });

        return user;
      });

      //sort by description ASC
      members.sort((a, b) => (a.userDescription > b.userDescription) ? 1 : ((b.userDescription > a.userDescription) ? -1 : 0));
    }

    //#region ENUMS
    const TileType = {
      ComeTogether: 0,
      QuickViewSynapses: 1,
      Cases: 2,
      User: 3
    };

    const ItemType = {
      Deadline: 0,
      Jobs: 1,
      Messages: 2,
      InternalUsers: 3,
      ExternalUsers: 4,
      WaitingUsers: 5,
      Properties: 6
    };

    //#endregion

    function getUiNumber(project, section, type) {
      const emptyObj = {
        Value: 0,
        ActiveValue: 0
      };

      switch (section.toLowerCase()) {
        case 'qv':
          if (type === 'jobs') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.QuickViewSynapses && ti.ItemType === ItemType.Jobs) || emptyObj).Value);
          if (type === 'jobs_red') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.QuickViewSynapses && ti.ItemType === ItemType.Jobs) || emptyObj).ActiveValue);
          if (type === 'deadline') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.QuickViewSynapses && ti.ItemType === ItemType.Deadline) || emptyObj).Value);
          if (type === 'deadline_red') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.QuickViewSynapses && ti.ItemType === ItemType.Deadline) || emptyObj).ActiveValue);
          if (type === 'properties') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.QuickViewSynapses && ti.ItemType === ItemType.Properties) || emptyObj).Value);
          break;
        case 'ct':
          if (type === 'messages') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.ComeTogether && ti.ItemType === ItemType.Messages) || emptyObj).Value);
          if (type === 'messages_red') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.ComeTogether && ti.ItemType === ItemType.Messages) || emptyObj).ActiveValue);
          if (type === 'jobs') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.ComeTogether && ti.ItemType === ItemType.Jobs) || emptyObj).Value);
          if (type === 'jobs_red') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.ComeTogether && ti.ItemType === ItemType.Jobs) || emptyObj).ActiveValue);
          if (type === 'properties') return uiFormatNumber((project.TileItems.find(ti => ti.TileType === TileType.ComeTogether && ti.ItemType === ItemType.Properties) || emptyObj).Value);
          break;
        case 'cases':
          if (type === 'cases') return uiFormatNumber(27);
          if (type === 'cases_red') return uiFormatNumber(3);
          if (type === 'to_approve') return uiFormatNumber(4);
          if (type === 'to_approve_red') return uiFormatNumber(2);
          break;
        default:
          return uiFormatNumber(0);
      }

      return uiFormatNumber(0);
    }

    function uiFormatNumber(_num) {
      const num = _num || 0;
      return num < 10 ? `0${num}` : String(num);
    }

    function goToCometogetherProject() {
      window.open(vm.environmentCT);
    }

    function openProjectSettingsClicked($event, project) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      appStorage.setSelectedProject(project);
      initDashboard(project);

      const params = {
        project: project
      };

      vm.showProjectSettingsDialog = true;
      vm.project = project;

      //$rootScope.$broadcast('openProjectStdSettingsDialog', params);
    }

    function manualEnableProjectEdit(selectedOid) {
      const editableProjects = vm.allData.filter(item => item.editable === true);
      if (editableProjects.length) {
        editableProjects[0].editable = false;
      }

      const projects = vm.allData.filter(item => item.Oid === selectedOid);
      if (projects.length) {
        projects[0].editable = true;
        $rootScope.$broadcast('ProjectEditMode', { enable: true });
      }
    }

    $scope.$on('closeProjectStdSettingsDialog', function () {
      vm.showProjectSettingsDialog = false;
      vm.project = null;
    });

    //#endregion

    //#region events

    /**
     * @ngdoc function
     * @name $onInit
     * @methodOf DXSPolluxApp.component:projectValuationProjectstd
     * @description
     * Initializes the grid, loads data
     */
    vm.$onInit = function () {
      vm.workplace.onTabCreated(this);
    };

    $scope.$on('PortfolioArchived', function (event, params) {
      //params = { jobId }
      vm.loadData();
    });

    $scope.$on('createNewProject', function () {
      var tabElement = angular.element('#tabProjectstd-component');
      kendo.ui.progress(tabElement, true);
      $q.all([
        ProjectsResourceCustom.createNewProject()
      ]).then(
        function (results) {
          kendo.ui.progress(tabElement, false);
          const ipr = results[0];//IntPolluxResult, IntValue holds projectId
          if (ipr.success) {
            vm.loadData(null, { newProjectId: ipr.intValue });
          } else {
            alert('Failed to create project!');
          }
        },
        function (error) {
          kendo.ui.progress(tabElement, false);
          alert('Failed to create project!');
        });
    });

    $scope.$on('saveProjectName', function () {

      const projects = vm.allData.filter(p => p.editable);
      const project = projects[0];
      const projectId = project.Oid;
      const projectName = project.Name;

      var tabElement = angular.element('#tabProjectstd-component');
      kendo.ui.progress(tabElement, true);
      $q.all([
        ProjectsResourceCustom.saveProjectName(projectId, projectName)
      ]).then(
        function (results) {
          kendo.ui.progress(tabElement, false);
          const gpr = results[0];//GenericPolluxResult
          if (gpr.success) {
            vm.loadData();
            $rootScope.$broadcast('ProjectEditMode', { enable: false });
          } else {
            alert('Failed to save project!');
          }
        },
        function () {
          kendo.ui.progress(tabElement, false);
          alert('Failed to save project!');
        });
    });

    $scope.$on('cancelSaveProjectName', function () {
      const projects = vm.allData.filter(p => p.editable);
      if (projects.length) {
        projects[0].editable = false;
      }

      $rootScope.$broadcast('ProjectEditMode', { enable: false });
    });
    
    $scope.$on('projectMembersChanged', function (event, params) {
      const projectData = vm.allData.find(project => project.Oid === params.projectId);
      if (projectData) {
        setupMembers(projectData, JSON.parse(JSON.stringify(params.teamMembers)));
      }
    });

    //#endregion
  }
}());
