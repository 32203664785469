(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:appCurrencyResource
   * @description
   * Resource for handling currencies
   * 
   * @requires $resource
   * @requires DXSPolluxApp.object:appServiceHelper
   */
  angular
    .module('DXSPolluxApp')
    .factory('appCurrencyResource',
      ['$resource', 'appServiceHelper', appCurrencyResource]);

  function appCurrencyResource($resource, appServiceHelper) {
    let self = this;
    var currencyData = null;

    function setCurrencyData(data) {
      self.currencyData = data;
    }

    function getCurrencyData() {
      return self.currencyData;
    }

    return {
      /**
      * @ngdoc function
      * @name locale
      * @methodOf DXSPolluxApp.service:appCurrencyResource
      * @description
      * Loads currencies
      * 
      * Route: api/v2/currency
      */
      load: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'currency'), { locale: '@locale' }),

      setCurrencyData: setCurrencyData,
      getCurrencyData: getCurrencyData
    };
  }


  angular
    .module('DXSPolluxApp')
    .filter('currency', ['appCurrencyResource', function (appCurrencyResource) {
      return function (value, prefix) {
        var data = appCurrencyResource.getCurrencyData();
        if (!data) {
          return value;
        }
        if (prefix) {
          return ('&#' + data.CurrencySymbolUnicode + '; ' + value).trim();
        }
        else {
          return (value + ' &#' + data.CurrencySymbolUnicode + ';').trim();
        }        
      };
    }]);
}());
