(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxDateFieldEdit
   * @description
   * This component provides the behavior and visual representation of a date picker field. The field is rendered as a Kendo UI kendo-date-picker.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * Internally, it handles a pollux-date-field, but it only displays it's edit field if the content has been clicked.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires $timeout
   * @requires DXSPolluxApp.service:dateHelper
   * 
   * @param {String} ngModel The property that is bound to this field. Please note that this is a string because this eliminates the date time offset in different time zones. Only the date itself is saved, no time.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Bool} isNullable Specifis if this field can contain null values
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxDateFieldEdit", {
      template: require('html-loader!./pollux.date.field.edit.tmpl.html'),
      bindings: {
        "isNullable": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngId": "@",
        "format": '@'
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxDateFieldCtrl]
    });

  function polluxDateFieldCtrl($timeout) {
    var vm = this;

    //#region variable declaration
    vm.editMode = false;
    vm.guid = null;
    //#endregion

    //#region function declaration
    vm.edit = edit;
    vm.isValidDate = isValidDate;
    //#endregion

    //#region function implementation
    function edit(event) {
      event.stopPropagation();
      vm.editMode = true;
      focusEditableField();
    }

    function focusEditableField() {
      $timeout(function () {
        var input = angular.element('.' + vm.guid).find('input');
        if (input && input[0]) {
          input.focus();
          input[0].onblur = disableEdit;
        } else {
          focusEditableField(); //without this the field might not exist yet
        }
      });
    }

    function disableEdit() {
      vm.editMode = false;
    }

    function onNgChange(e) {
      if (vm.ngChange) {
        vm.ngChange();
      }
    }

    function isValidDate() {
      var date = vm.ngModel;
      if (date == null) {
        return false;
      }
      return date.toString().indexOf('1900') < 0;
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.guid = kendo.guid();
      if (!vm.format) {
        vm.format = 'dd.MM.yyyy';
      }
      if (!vm.ngId) {
        vm.ngId = kendo.guid();
      }
    }
    //#endregion    
  }
}());

