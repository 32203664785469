(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationRentrollDistributionPane", {
      template: require('html-loader!./project.valuation.rentroll.distribution.pane.tmpl.html'),
      controllerAs: "vm",
      bindings: {
        "propertyOid": "<",
        "rentRollDate": "<"
      },
      controller: ['$rootScope', '$q', 'ValuationPortfoliosResourceCustom', projectValuationRentrollDistributionPaneCtrl]
    });

  function projectValuationRentrollDistributionPaneCtrl($rootScope, $q, ValuationPortfoliosResourceCustom) {
    var vm = this;

    vm.result = [];
    vm.availableJobs = null;
    vm.selectedJobs = null;

    vm.resultChanged = resultChanged;
    vm.apply = apply;
    vm.cancel = cancel;

    vm.$onInit = function () {
      ValuationPortfoliosResourceCustom.getPropertyJobs(vm.propertyOid, vm.rentRollDate).then((result) => {
        if (!result.length) {
          $rootScope.$broadcast('closeRentRollDistribution');
          return;
        }
        vm.availableJobs = result;
        vm.selectedJobs = [];
      });
    }

    function resultChanged(result) {
      vm.result = result || [];
    }

    function apply() {
      kendo.ui.progress(angular.element('body'), true);
      $q.all([ValuationPortfoliosResourceCustom.updateRentRoll(vm.result, vm.propertyOid, vm.rentRollDate)]).then((result) => {
        kendo.ui.progress(angular.element('body'), false);
        if (result[0].success) {
          $rootScope.$broadcast('closeRentRollDistribution');
        }
        else {
          alert('Error while applying the RR!');
        }

        vm.availableJobs = null;
      }, (error) => {
        kendo.ui.progress(angular.element('body'), false);
        alert('Error while applying the RR!');
      });
    }

    function cancel() {
      vm.availableJobs = null;
      $rootScope.$broadcast('closeRentRollDistribution');
    }
  }
}());
