(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationConfirmDeletePortfolioDialog
   * @description
   * Dialog that, after confirmation, deletes portfolio
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires DXSPolluxApp.service:ValuationPortfoliosResource
   * @requires DXSPolluxApp.service:applicationUserInfoResource
   * @requires $translate
   * @requires DXSPolluxApp.service:appStorage
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationConfirmDeletePortfolioDialog", {
      template: require('html-loader!./project.valuation.confirm.delete.portfolio.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', 'ValuationPortfoliosResource', 'applicationUserInfoResource', '$translate', 'appStorage', '$q', '$timeout', projectValuationConfirmDeletePortfolioDialogCtrl]
    });

  function projectValuationConfirmDeletePortfolioDialogCtrl($scope, $rootScope, ValuationPortfoliosResource, applicationUserInfoResource, $translate, appStorage, $q, $timeout) {
    var vm = this;

    //#region variable declarations
    vm.appStorage = appStorage;
    vm.confirmWindow = null;
    vm.linkedFeesNoDelete = false;
    vm.linkedData = false;
    vm.archive = false;
    vm.linkedGroupList = [];
    //#endregion variable declarations

    //#region function declarations

    function reset() {
      vm.linkedFeesNoDelete = false;
      vm.linkedData = false;
      vm.archive = false;
      vm.linkedGroupList = [];
    }


    //#endregion function declarations    
    vm.confirm = confirm;
    vm.cancel = cancel;
    //#region function implementation

    /**
     * @ngdoc function
     * @name $onInit
     * @methodOf DXSPolluxApp.component:projectValuationConfirmDeletePortfolioDialog
     * @description
     * Initializes everything
     */
    vm.$onInit = function () {

    };

    /**
    * @ngdoc function
    * @name confirm
    * @methodOf DXSPolluxApp.component:projectValuationConfirmDeletePortfolioDialog
    * @description
    * Deletes portfolio and closes a dialog
    */
    function confirm() {
      vm.confirmWindow.close();
      if (vm.archive) {
        archiveAction();
      } else {
        deleteAction();
      }
    }

    function archiveAction() {
      kendo.ui.progress(angular.element('body'), true);
      ValuationPortfoliosResource.archive(vm.portfolioId).then(function (gpr/*GenericPolluxResult*/) {
        kendo.ui.progress(angular.element('body'), false);
        if (gpr.success) {
          $rootScope.$broadcast('PortfolioArchived', { jobId: vm.portfolioId });
          $rootScope.$broadcast('PortfolioLoadData');
          vm.confirmWindow.close();
        } else {
          alert(gpr.message);
        }
      }).catch(function (error) {
        kendo.ui.progress(angular.element('body'), false);
        if (error && error.data && error.data.ExceptionMessage) {
          alert(error.data.ExceptionMessage);
        } else {
          alert("Failed to archive job(s)!");
        }
        
      });
    }

    function deleteAction() {
      kendo.ui.progress(angular.element('body'), true);
      ValuationPortfoliosResource.remove(vm.portfolioId).then(function (result) {
        kendo.ui.progress(angular.element('body'), false);
        if (result.m_Item1) {
          $rootScope.$broadcast('PortfolioLoadData');
          $rootScope.$broadcast('ReportLoadData');
          $rootScope.$broadcast('project.valuation.report.showAll');
        } else {
          alert(result.m_Item2);
        }
      }).catch(function (error) {
        kendo.ui.progress(angular.element('body'), false);
        vm.err = error;
        alert(error.data.ExceptionMessage);
      });
    }


    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationConfirmDeletePortfolioDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      vm.confirmWindow.close();
    }

    //#endregion

    //#region events

    $scope.$on('deletePortfolio', function (event, selectedItem) {
      reset();
      vm.portfolioId = selectedItem.Oid;

      vm.confirmWindow.title($translate.instant('projectValuationConfirmDeletePortfolioDialog.title'));
      kendo.ui.progress(angular.element('body'), true);
      $q.all([
        ValuationPortfoliosResource.canRemove(vm.portfolioId)
      ]).then(function (result) {
        if (result[0].hasError) {
          alert(result[0].message);
        }
        else {
          vm.linkedFeesNoDelete = result[0].cannotDeleteLinkedFeeData;
          vm.linkedData = result[0].hasLinkedData;

          vm.confirmWindow.center();
          vm.confirmWindow.open();
        }
        kendo.ui.progress(angular.element('body'), false);
      }).catch(function (err) {
        alert("Error loading portfolio delete info:" + JSON.stringify(err));
      });
    });

    $scope.$on('archivePortfolio', function (event, selectedItem) {
      reset();
      vm.archive = true;
      vm.portfolioId = selectedItem.Oid;

      $q.all([
        ValuationPortfoliosResource.loadLinkedGroupList(vm.portfolioId)
      ]).then(function (results) {
        vm.linkedGroupList = results[0];
        vm.linkedGroupList.forEach((job) => {
          job.archiveMessage = (job.Status || 'N/A') + ' - ' + job.Name;
        });

        $timeout(function () {
          vm.confirmWindow.title($translate.instant('projectValuationConfirmDeletePortfolioDialog.archiveTitle'));
          vm.confirmWindow.center();
          vm.confirmWindow.open();
        }, 50);

      }).catch(function (err) {
        alert("Error loading portfolio delete info:" + JSON.stringify(err));
      });

      
    });

    //#endregion
  }
}());

