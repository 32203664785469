(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationPortfolioDialogResource
     * @description
     * This service is responsible for loading valuation portfolio data.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */

    angular
        .module('DXSPolluxApp')
        .factory('projectValuationPortfolioDialogResource',
        ['$resource', 'appServiceHelper', projectValuationPortfolioDialogResource]);

    function projectValuationPortfolioDialogResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name details
             * @methodOf DXSPolluxApp.service:projectValuationPortfolioDialogResource
             * @description
             * Loads valuation portfolio data.
             * 
             * Route: api/v2/project/valuation/portfolio/details/single
             * 
             * @param {Integer} oid Valuation portfolio OID
             * 
             * @returns {Object} Valuation portfolio
             */
            details: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/details/single'), { oid: '@oid' }),
            /**
             * @ngdoc function
             * @name duplicateCheck
             * @methodOf DXSPolluxApp.service:projectValuationPortfolioDialogResource
             * @description
             * Checks if the valuation portfolio name already exists.
             * 
             * Route: api/v2/project/valuation/portfolio/details/single/duplicate
             * 
             * @param {String} valuationPortfolioName The name of the valuation portfolio
             * @param {Integer} oid Valuation portfolio OID
             * 
             * @returns {Array} Array of portfolios with same name or null if there is no duplicates
             */
            duplicateCheck: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/details/single/duplicate'), { valuationPortfolioName: '@valuationPortfolioName', oid: '@oid' })
        }
    }
}());
