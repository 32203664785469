(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationConfirmDeleteProjectstdDialog
   * @description
   * Dialog that, after confirmation, deletes project
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires DXSPolluxApp.service:ProjectsResource
   * @requires DXSPolluxApp.service:applicationUserInfoResource
   * @requires $translate
   * @requires DXSPolluxApp.service:appStorage
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationConfirmDeleteProjectstdDialog", {
      template: require('html-loader!./project.valuation.confirm.delete.projectstd.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$rootScope', 'ProjectsResource', 'applicationUserInfoResource', '$translate', 'appStorage', projectValuationConfirmDeleteProjectstdDialogCtrl]
    });

  function projectValuationConfirmDeleteProjectstdDialogCtrl($scope, $rootScope, ProjectsResource, applicationUserInfoResource, $translate, appStorage) {
    var vm = this;
    vm.appStorage = appStorage;
    vm.confirmWindow = null;

    vm.confirm = confirm;
    vm.cancel = cancel;

    vm.userInfo = {};
    applicationUserInfoResource.userinfo.get({},
      function (data) { vm.userInfo = data; },
      function (err) { vm.err = err; alert("Error loading UserInfo:" + JSON.stringify(err)); });

    /**
    * @ngdoc function
    * @name confirm
    * @methodOf DXSPolluxApp.component:projectValuationConfirmDeleteProjectstdDialog
    * @description
    * Deletes project and closes a dialog
    */
    function confirm() {
      vm.confirmWindow.close();

      kendo.ui.progress(angular.element('body'), true);
      ProjectsResource.remove(vm.projectstdId)
        .then(function (result) {
          kendo.ui.progress(angular.element('body'), false);
          if (result.m_Item1) {
            $rootScope.$broadcast('ProjectstdLoadData', vm);
            $rootScope.$broadcast('project.valuation.showAll', vm);
            $rootScope.$broadcast('PortfolioLoadData', vm);
          }
          else
            alert(result.m_Item2);
        },
          function (error) {
            kendo.ui.progress(angular.element('body'), false);
            vm.err = error;
            alert(error.data.ExceptionMessage);
          });
    }

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationConfirmDeleteProjectstdDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      vm.confirmWindow.close();

    }

    //#region "EVENTS"

    $scope.$on('deleteProjectstd', function (event, selectedItem) {
      vm.projectstdId = selectedItem.Oid;
      vm.confirmWindow.title($translate.instant('projectValuationConfirmDeleteProjectstdDialog.title'));
      $scope.$apply(function () {
        vm.confirmWindow.center();
        vm.confirmWindow.open();
      });
    });

    //#endregion "EVENTS"
  }
}());

