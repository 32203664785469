import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { AngularIntegrationService } from '../../../../services/AngularIntegrationService';
import { ValuationPortfoliosList } from '../models/valuation-portofolio-list.model';

@Injectable()
export class MultiDashboardService {

  readonly emptyJob: ValuationPortfoliosList = {
    BusinessLineNrId: -1,
    CalculationModelId: -1,
    ConstructionCosts: -1,
    DateCreated: new Date(),
    DueDate: new Date(),
    JobStateId: -1,
    JobStatusId: -1,
    JobTypeId: -1,
    LinkedObjectId: -1,
    LinkedPropertyAddress: '',
    LinkedPropertyMarketAnalysis: '',
    LinkedPropertyOid: -1,
    LinkedPropertyOidDescription: '',
    Oid: -1,
    OwnerBuID: -1,
    OwnerBuIDDescription: '',
    OwnerUserID: -1,
    OwnerUserIDDescription: '',
    PortfolioStatusDescription: '',
    PortfolioStatusId: -1,
    ProjectID: -1,
    ProjectName: '',
    ProjectStatus: -1,
    PropertyPictures: [],
    RecordStatusId: -1,
    RecordStatusIdDescription: '',
    ReportId: -1,
    StrategyDescription: '',
    SwimlaneId: -1,
    ValuationDate: new Date(),
    ValuationPortfolioName: '',
    ValuationAssumptions: '',
    CTIsPublished: true,
    PlaygroundJobId: 0,
  };

  private mainJob: ValuationPortfoliosList = null;
  private comparasionJobs: ValuationPortfoliosList[] = [];
  private comparableJobs: ValuationPortfoliosList[] = [];
  private showDashboardAssumptions: boolean = false;

  private multiDashboardActiveSubject = new BehaviorSubject<boolean>(false);
  public multiDashboardActive$ = this.multiDashboardActiveSubject.asObservable();

  private jobsByPropertySubject = new BehaviorSubject<number>(0);
  public jobsByProperty$ = this.jobsByPropertySubject.asObservable();

  private comparisionJobsSubject = new BehaviorSubject<ValuationPortfoliosList[]>(null);
  public comparisionJobs$ = this.comparisionJobsSubject.asObservable();

  private comparableJobsSubject = new BehaviorSubject<ValuationPortfoliosList[]>(null);
  public comparableJobs$ = this.comparableJobsSubject.asObservable();

  private showDashboardAssumptionsSubject = new BehaviorSubject<boolean>(this.showDashboardAssumptions);
  public showDashboardAssumptions$ = this.showDashboardAssumptionsSubject.asObservable();

  constructor(private ais: AngularIntegrationService, private http: HttpClient) {
  }

  public reset() {
    this.mainJob = null;
    this.comparableJobs = [];
    this.comparasionJobs = [];
    this.jobsByPropertySubject.next(0);
    this.comparisionJobsSubject.next(null);
    this.comparableJobsSubject.next(null);
    this.multiDashboardActiveSubject.next(false);
  }

  public initWithJob(job: ValuationPortfoliosList) {

    const filter: string = `{"logic":"and","filters":[{"field":"LinkedPropertyOid","operator":"contains","value":"intInList:${job.LinkedPropertyOid}"},{"field":"QVAppVisible","operator":"eq","value":1},{"field":"IsArchived","operator":"eq","value":0}]}`;
    const encodedFilter: string = encodeURI(filter);
    const apiUrl: string = `ValuationPortfolios/list/paged?take=9999&skip=0&page=1&pageSize=9999&filter=${encodedFilter}`;

    let calls = [
      this.http.get(this.ais.getServiceEndpoint("marvin.pollux.projectValuation.api", apiUrl))
    ];

    forkJoin(calls)
      .subscribe((results: any) => {
        const data = results[0]?.details || [];
        this.jobsByPropertySubject.next(data.length);

        this.mainJob = data.find((j) => j.Oid === job.Oid);
        this.comparasionJobs = [this.mainJob, Object.assign({}, this.emptyJob), Object.assign({}, this.emptyJob)];
        this.comparisionJobsSubject.next(this.comparasionJobs);

        this.comparableJobs = data.filter((j) => j.Oid !== job.Oid);
        this.comparableJobs.sort((a, b) => b.Oid - a.Oid);
        this.comparableJobsSubject.next(this.comparableJobs);

        this.multiDashboardActiveSubject.next(true);
      },
        error => {
          console.log("loadComparableJobs: Failed to load data!", error);
          this.jobsByPropertySubject.next(1);
          this.comparisionJobsSubject.next([job, Object.assign({}, this.emptyJob), Object.assign({}, this.emptyJob)]);
          this.comparableJobsSubject.next([]);
        });
  }

  public addByJobId(jobId: number) {

    if (this.comparasionJobs.find((job) => job.Oid === +jobId)) return;//already there

    let job = this.comparableJobs.find((job) => job.Oid === +jobId);
    if (!job) {//load if not already there
      const filter: string = `{"logic":"and","filters":[{"field":"Oid","operator":"eq","value":${jobId}},{"field":"QVAppVisible","operator":"eq","value":1},{"field":"IsArchived","operator":"eq","value":0}]}`;
      const encodedFilter: string = encodeURI(filter);
      const apiUrl: string = `ValuationPortfolios/list/paged?take=9999&skip=0&page=1&pageSize=9999&filter=${encodedFilter}`;

      let calls = [
        this.http.get(this.ais.getServiceEndpoint("marvin.pollux.projectValuation.api", apiUrl))
      ];

      forkJoin(calls)
        .subscribe((results: any) => {
          const data = results[0]?.details || [];
          if (data.length) {
            let job = data[0];
            this.comparableJobs.push(job);
            this.comparableJobs.sort((a, b) => b.Oid - a.Oid);
            this.addJob(job);
            this.jobsByPropertySubject.next(this.comparableJobs.length + this.comparasionJobs.filter((job) => job.Oid > 0).length);
          }
        },
          error => {
            console.log("addByJobId: Failed to load data!", error);
          });
    } else {
      this.addJob(job);
    }
  }

  public addJob(job: ValuationPortfoliosList) {
      const emptySlotIndex = this.comparasionJobs.findIndex((j) => j.Oid === -1);
      if (emptySlotIndex > -1) {
        this.comparasionJobs[emptySlotIndex] = Object.assign({}, job);
        this.comparableJobs = this.comparableJobs.filter((j) => j.Oid !== job.Oid);
        this.comparisionJobsSubject.next(this.comparasionJobs);
        this.comparableJobsSubject.next(this.comparableJobs);
      }
  }

  public removeJob(job: ValuationPortfoliosList) {
    const jobIndex = this.comparasionJobs.findIndex((j) => j.Oid === job.Oid);
    if (jobIndex > -1) {
      this.comparasionJobs[jobIndex] = Object.assign({}, this.emptyJob);
      this.comparableJobs.push(job);
      this.comparableJobs.sort((a, b) => b.Oid - a.Oid);
      this.comparisionJobsSubject.next(this.comparasionJobs);
      this.comparableJobsSubject.next(this.comparableJobs);
    }
  }

  public syncJobName(jobId: number, name: string) {
    const self = this;
    const job: ValuationPortfoliosList = self.getJob(jobId);
    if (job) {
      job.ValuationPortfolioName = name;
    }
  }

  public getJob(jobId: number): ValuationPortfoliosList {
    let job = this.comparasionJobs.find((job) => job.Oid === +jobId);
    if (job) {
      return job;
    } else {
      //maybe user already closed the job.. search in comparable
      job = this.comparableJobs.find((job) => job.Oid === +jobId);
      if (job) {
        return job;
      }
    }

    return null;
  }

  public showComparableJobs() {
    //const job = jobsByProperty[0];
    //job && this.addJob(job);

    this.ais.$rootScope.$broadcast('openProjectValuationComparisonJobsDialog');
  }

  public toggleDashboardAssumptions() {
    this.showDashboardAssumptions = !this.showDashboardAssumptions;
    this.showDashboardAssumptionsSubject.next(this.showDashboardAssumptions);
  }

  public comparasionJobsExists(){
    return this.comparasionJobs?.length > 0;
  }

  public getMainJob(): ValuationPortfoliosList | null {
    return this.mainJob;
  }
}
