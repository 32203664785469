import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AngularIntegrationService {

  public $rootScope: any;
  public appStorage: any;
  public appSettings: any;
  public appLocalization: any;
  public appServiceHelper: any;
  public locale: any;

  constructor(private http: HttpClient) { }

  public initWithNg1($rootScope, appStorage, appSettings, appLocalization, appServiceHelper, locale) {
    this.$rootScope = $rootScope;
    this.appStorage = appStorage;
    this.appSettings = appSettings;
    this.appLocalization = appLocalization;
    this.appServiceHelper = appServiceHelper;
    this.locale = locale;
  }

  public getServiceEndpoint(api: string, url: string) {
    return this.appServiceHelper.getServiceEndpoint(api, url);
  }

}
