(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationSettingsDialogAlertsResource',
        ['$resource', 'appServiceHelper', projectValuationSettingsDialogAlertsResource]);

    function projectValuationSettingsDialogAlertsResource($resource, appServiceHelper) {
        return {
            alerts: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/alerts/subscriptions'), {})
        }
    }
}());
