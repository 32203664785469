//#####################################
//#####################################
/* for LOCAL DEV for now use both false (local API / WinAuth) */
const useMsal: boolean = true;
//#####################################
//#####################################

//ISPINIT B2C
const tenantId: string = 'bc486fc7-f3a9-41be-a150-bb2b0a991687';
const clientId: string = '0dd0f9c5-1e88-4fc2-96cc-e2026e352f70';//B2C_V2_ComeTogetherRC
const userFlow: string = 'B2C_1_applications_signin';
const azureAuthority: string = `https://ispinitsynapsesrc.b2clogin.com/ispinitsynapsesrc.onmicrosoft.com/${userFlow}`;
const azureAuthorityDomain: string = 'ispinitsynapsesrc.b2clogin.com';
const azureScopes = [`${clientId}`, 'openid', 'profile'];
const azureProtectedResourceMap: Map<string, Array<string>> = new Map([
  ['https://polluxapi.synapsesrc.ispinit.de', azureScopes],
  ['https://comparablesapi.synapsesrc.ispinit.de', azureScopes],
  ['https://projectvaluationapi.synapsesrc.ispinit.de', azureScopes],
  ['https://settingsapi.synapsesrc.ispinit.de', azureScopes],
  ['https://dqsapi.synapsesrc.ispinit.de', azureScopes],
  ['https://ioapi.synapsesrc.ispinit.de', azureScopes],
  ['https://notificationsapi.synapsesrc.ispinit.de', azureScopes]
]);
const azureRedirectUri: string = 'https://synapsesrc.ispinit.de';
const azurePostLogoutRedirectUri: string = '';

export const environment = {
  production: false,
  UseMsal: useMsal,

  POLLUX_API_URL: 'https://polluxapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  POLLUX_COMPARABLES_API_URL: 'https://comparablesapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  POLLUX_PROJECTVALUATION_API_URL: 'https://projectvaluationapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DQS_API_URL: 'https://dqsapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DMS_IO_URL: 'https://ioapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DMS_HELPTEXT_API_URL: 'https://helptextsapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  DMS_NOTIFICATION_API_URL: 'https://notificationsapi.synapsesrc.ispinit.de/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000',
  COME_TOGETHER_URL: 'https://cometogetherrc.ispinit.de',


  /* AZURE */
  AzureTenentId: tenantId,
  AzureClientId: clientId,
  AzureAuthority: azureAuthority,
  AzureAuthorityDomain: azureAuthorityDomain,
  AzureScopes: azureScopes,
  AzureRedirectUri: azureRedirectUri,
  AzurePostLogoutRedirectUri: azurePostLogoutRedirectUri,
  AzureProtectedResourceMap: azureProtectedResourceMap,
  /*COMMON*/
  GOOGLE_MAPS_API_URL: 'https://maps.google.com/maps/api/js?v=3&key=AIzaSyCEAvh-RyDaU-9ctZLznQYdUpmt86TFQ0E',
  SpreadJsLicenseKey: 'synapsesrc.ispinit.de,726417593713533#B0ReIFndY3WbUpVQ5YXRTNVe7RUR03WZJVFSStiSqNFeShFck5EVLRmTrMka4V6bWZmNsRFarJ6TYVUVLlTN7klNYpmS4UFOEFnSJ3iS7lXeTB5LkhVcqdUMkRHTplGbT9EWv3UM84Gbsd6KRtWQm3EOwplarNFTMRTa9omTRFUQ9kWdolHW8hGThhTUzUUSR9WRwlURUxmUxZnSEJ5ZINzR5czMW5maCh7ZwJzQvkUWhdVRqREN0dzSGtGb4FWZqREWvNXeDh7SLpGaXR7UrdDNhd7ZM9EeMh4NUFDUttGNrhWRWJTTuNVamZ4MKNmI0IyUiwiI8ADR4gTO7cjI0ICSiwSM5kDN8IjN6MTM0IicfJye35XX3JSVPNjNiojIDJCLiMTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI6ITMxETMgkjMxETMyAjMiojI4J7QiwiIlRmL4lmbpB7cp9yYyNXZzBXYul7ciojIz5GRiwiIIJWbHBCVJ9USQNVSiojIh94QiwiIzMTNzEzNzkTN7EDN6IzNiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQFJHZ74GWwl6QidXcvIXYxEzVw9mcpRWREJTd7V7LtVDWiJnbxZWYG5GO9cUQyNkMPVDTHJWWlFzdrk7LPh7apFWOlFkWsNWSXpXZ4NnR6N5drYVVRVHT83SZmBDZY5kVPNERMRJVZ9',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
