import { Component, Input, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import * as zc from '@dvsl/zoomcharts';


@Component({
  selector: 'dqs-validation-rules-chart',
  templateUrl: './dqs-validation-rules-chart.component.html',
  styleUrls: ['./dqs-validation-rules-chart.component.scss']
})

export class DqsValidationRulesChartComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() id: string;
  @Input() chartData: any;

  @Output() onChartClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChartFullscreenModeChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('chart') chartDiv: ElementRef;

  public zc: any = zc;
  public _chartID: string;
  public _data: any;
  public chartInstance: any;
  public lastFullscreenMode: boolean = false;
  public width: number = 0;
  public height: number = 0;

  private get winRef(): any {
    return {
      nativeWindow: window
    };
  }

  constructor(/*winRef: WindowRefService, */private elRef: ElementRef) {
    this.winRef.nativeWindow.ZoomChartsLicense = "ZCP-he5p70237:  ZoomCharts SDK license for 3 Chart Developers";
    this.winRef.nativeWindow.ZoomChartsLicenseKey = "33e5694b13267f9859017a44420f5959ee8cde847c63f7794e" +
      "8998a4a218d3dd4ba67e84474c0dedd90210668d98e97ef350b3dedfc4a64e466c6254d15db88" +
      "6b92f31adda725e1019af6570ea8d3b432ec7d28a7f5fff781a735fe72c5d336557b2308e8d22" +
      "1fa9f40043758cfab6acce1109c7d30868d31001add291c9fbb8f0fe8342d3fd8c9b2d02c3798" +
      "68a039756d173548950fc5986a320630a942fad209c8531b7749ffda661a87dad0ce621a6e357" +
      "e8a51763a256c35a1fa324fb6e5e190708234cf9d8de32d22f2f8020f4cd34677661d66aa0e7a" +
      "17cb79d53d09fb1b181bdfa81543569321a4cc20156c6955e30f6b935c576421524e3f9122100";
  }

  ngOnInit() {
    this.elRef.nativeElement.addEventListener('wheel', (e) => this.onWheel(e), true);
    this._chartID = this.id;
    this.setupData();
  }

  ngOnDestroy() {
    this.elRef.nativeElement.removeEventListener('wheel', this.onWheel);
    this.chartInstance.remove();
    this.chartInstance = null;
  }

  ngAfterViewChecked() {
    if (this.chartDiv.nativeElement.clientWidth != this.width || this.chartDiv.nativeElement.clientHeight != this.height) {
      this.width = this.chartDiv.nativeElement.clientWidth;
      this.height = this.chartDiv.nativeElement.clientHeight;
      this.chartInstance.updateSize();
    }
  }

  private setupData() {
    let rulesSummaryData = {};
    for (let sheet of this.chartData.Sheets) {
      let sheetResult = this.chartData.SheetResult[sheet].Result;
      for (let i = 0; i < sheetResult.length; ++i) {
        let resultObj = sheetResult[i];

        //process data for rules summary chart
        let ruleName = resultObj.RuleName;
        if (!rulesSummaryData[ruleName]) {
          rulesSummaryData[ruleName] = {
            name: ruleName,
            errorRowsArr: [],
            warningRowsArr: [],
            errorRows: 0,
            warningRows: 0
          };
        }

        let rowIndex = resultObj.CellName.replace(/\D/g, '');//remove all but numbers
        let ruleObj = rulesSummaryData[ruleName];
        if (resultObj.ResultType === "WARNING") {
          if (ruleObj.warningRowsArr.indexOf(rowIndex) === -1) {
            ruleObj.warningRowsArr.push(rowIndex);
            ruleObj.warningRows++;
          }
        } else if (resultObj.ResultType === "ERROR") {
          if (ruleObj.errorRowsArr.indexOf(rowIndex) === -1) {
            ruleObj.errorRowsArr.push(rowIndex);
            ruleObj.errorRows++;
          }
        }
      }
    }

    //convert object properties values to array
    let values = Object.entries<any>(rulesSummaryData).map(([key, value]) => value);

    //sort by errors DESC, warnings DESC
    values.sort((a: any, b: any) => {
      if (a.errorRows == b.errorRows) {
        return b.warningRows - a.warningRows;
      } else {
        return b.errorRows - a.errorRows;
      }
    });

    this._data = { subvalues: values };
  }

  ngAfterViewInit() {
    var self = this;
    const FacetChart = this.zc.FacetChart;
    let config = {
      container: this.chartDiv.nativeElement,
      area: {
        style: { fillColor: "#ffffff" }
      },
      data: {
        preloaded: self._data
      },
      series: [
        {
          name: "Errors",
          data: {
            field: "errorRows"
          },
          style: {
            fillColor: "#ef796f"
          },
          type: "columns"
        },
        {
          name: "Warnings",
          data: {
            "field": "warningRows"
          },
          style: {
            "fillColor": "#ffe8b1"
          },
          type: "columns"
        }
      ],
      toolbar: {
        enabled: true, export: false, logScale: false, showLabels: false, fullscreen: true, side: "right", align: "left", location: "outside",
        items: [{
          item: "fullscreen",
          onClick: function (event) {
            if (self.chartInstance.fullscreen() !== self.lastFullscreenMode) {
              self.lastFullscreenMode = self.chartInstance.fullscreen();
              self.onChartFullscreenModeChanged.emit(self.lastFullscreenMode);
            }
          }
        }, {
          item: "back"
        }, {
          item: "zoomOut"
        }
        ]
      },
      interaction: { resizing: { enabled: false }, scrolling: { enabled: false }, selection: { enabled: true }, zooming: { enabled: true }, mode: "drilldown" },
      events: {
        onClick: function (event, args) {
          self.onChartClicked.emit(self.id);
        }
      }
    };

    self.chartInstance = new FacetChart(config);
  }

  private onWheel(e) {
    var ctrlKeyPressed = e.ctrlKey;
    if (!ctrlKeyPressed) {
      e.stopPropagation();
    }
  }
}
