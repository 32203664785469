import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SynapsesDqsValidationResult } from '../_data/synapses-dqs-validation-result';
import * as Excel from '@grapecity/spread-excelio';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SynapsesDqsService {

  constructor(private http: HttpClient) { }

  public getTechnicalValidationUrl(templateId: number): string {
    return `${environment.DQS_API_URL}/import/v2/validate?templateId=${templateId}`;
  }

  public downloadDefaultTemplate(businessObjectType: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.DMS_IO_URL}/import/template/synapses?mask=${businessObjectType}`, {
        responseType: 'arraybuffer', observe: 'response'
      }).subscribe((response: HttpResponse<ArrayBuffer>) => {
        const headers = response.headers;
        const contentType = headers.get('content-type');
        const blob = new Blob([response.body], { type: contentType });
        const url = window.URL.createObjectURL(blob);

        const a: any = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = 'Rent Roll Template.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
        error => {
          reject(error);
        },
        () => {
          resolve(null);
        }
      );
    });
  }

  public setupBlankTemplate(businessObjectType: string): Promise<any> {

    //TODO: get blank template for V2 import
    return new Promise<any>((resolve, reject) => {

      this.http.get(`${environment.DQS_API_URL}/import/v2/blanktemplate?templateType=${businessObjectType}`, {
        responseType: 'arraybuffer'
      }
      ).subscribe(data => {
        if (data) {
          let blob = new Blob([data], { type: "application/ms-excel" });
          resolve(this.blobToFile(blob, 'dqs_validate.xslx'));
        } else {
          resolve(null);
        }
      });
    });
  }

  public getAvailableDates(businessObjectType: string, oid: number) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.POLLUX_COMPARABLES_API_URL}/Properties/${businessObjectType}/dates?oid=${oid}`).subscribe((availableDates: any[]) => {
        resolve(availableDates);
      });
    });
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    let blob: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    blob.lastModifiedDate = new Date();
    blob.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }

  public validate(jsonData: any, templateId: number, transformationNeeded: boolean): Promise<SynapsesDqsValidationResult> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      const excelIO = new Excel.IO();
      excelIO.save(jsonData, function (blob: Blob) {
        const file = self.blobToFile(blob, "dqs_validate.xslx");

        const formData = new FormData();
        formData.append('file', file, file.name);

        const actionUrl = `${environment.DQS_API_URL}/import/v2/validate?templateId=${templateId}&transformationNeeded=${transformationNeeded}`;
        self.http.post(actionUrl, formData)
          .pipe(take(1))
          .subscribe(response => {
            resolve(response as SynapsesDqsValidationResult);
          },
            error => {
              console.log(JSON.stringify(error));
              //something wrong happened
              reject(error);
            }
          );
      }, function (err) {
          console.log(err);
          //probably error parsing dates in sheet
          reject(err);
      });
    });
  }

  public import(jsonData: any, templateId: number, referenceBoOid: number, availableDates: any[], selectedAvailableDate: Date, ignoreErrors: boolean) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const excelIO = new Excel.IO();
      excelIO.save(jsonData, function (blob: Blob) {
        const file = self.blobToFile(blob, "dqs_import.xslx");

        let formData = new FormData();
        formData.append('file', file, file.name);

        const additionalData = {
          RentRollDate: self.fixTimeZoneOffsetForSave(selectedAvailableDate)
        };
        formData.append('additionalData', JSON.stringify(additionalData));

        const actionUrl = `${environment.DQS_API_URL}/import/v2/import?templateId=${templateId}&referenceBoOid=${referenceBoOid}&autoFixErrors=${ignoreErrors}`;

        self.http.post(actionUrl, formData)
          .pipe(take(1))
          .subscribe(response => {
            const res: any = response;
            if (res.success) {
              if (res.validationResult) {
                //remember parameters used for import (for further processing)
                res.validationResult.additionalValidationInfo = { RentRollDate: additionalData.RentRollDate };
              }
              
              resolve(res);
            } else {
              reject(res);
            }
          },
            error => {
              reject(error);
            }
          );
      }, function (e) {
        if (e.errorCode === 1) {
          reject(e);
        }
      });
    });
  }

  public fixTimeZoneOffsetForSave(date) {
    try {
      if (date) {
        if (!(date instanceof Date)) {
          date = new Date(date);
        }

        if (date instanceof Date) {
          const n = date.getTimezoneOffset();
          date.setHours(date.getHours() - n / 60);
          const dateString = date.toISOString();
          date = dateString.substring(0, dateString.indexOf('T'));
        }
      }
      return date;
    } catch (e) {
      return null;
    }
  }

  public getImportInfo(businessObjectType: string): Promise<any> {
    const url = `${environment.DQS_API_URL}/import/v2/importinfo?templateType=${businessObjectType}`;
    return this.genericHttpGet(url);
  }

  public getTemplates(businessObjectType: string): Promise<any> {
    if (businessObjectType !== 'PropertyRentRoll') {
      return Promise.resolve([{ id: 0, description: "ISPINIT" }]);
    } else {
      const url = `${environment.DQS_API_URL}/import/v2/templates?templateType=${businessObjectType}`;
      return this.genericHttpGet(url);
    }
  }

  private genericHttpGet(url: string): Promise<any> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      self.http.get(url)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public getPropertyJobs(propertyId: number, active: boolean) {
    const url = `${environment.POLLUX_PROJECTVALUATION_API_URL}/ValuationPortfolios/getPropertyJobs?propertyId=${propertyId}&active=${active ? 1 : 0}`;
    return this.genericHttpGet(url);
  }

  public copyPropertyRentRollToJob(propertyId: number, jobIds: Array<number>, rentrollDate: any) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/ValuationPortfolios/copyPropertyRentRollToJob`;

      let payload = {
        propertyId: propertyId,
        jobIds: jobIds,
        rentrollDate: rentrollDate
      };

      self.http.post(actionUrl, payload)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public getReflist(reflistName: string): Promise<any[]> {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.POLLUX_API_URL}/reflist/pollux?reflistName=${reflistName}`;

      self.http.get(actionUrl)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public getMappableFields(importId: number): Promise<any[]> {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.DQS_API_URL}/import/v2/fields?importId=${importId}`;

      self.http.get(actionUrl)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public saveMappings(importId: number, sheetName: string, templateName: string, mappings: any[], validated: boolean) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.DQS_API_URL}/import/v2/template?importId=${importId}&sheetName=${sheetName}&templateName=${encodeURIComponent(templateName)}&validated=${validated}`;

      self.http.post(actionUrl, mappings)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }



  public loadMappingFile(templateId: number) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.DQS_API_URL}/import/v2/template/sheet?templateId=${templateId}`;

      self.http.get(actionUrl, {
        responseType: 'arraybuffer', observe: 'response'
      })
        .pipe(take(1))
        .subscribe((response: HttpResponse<ArrayBuffer>) => {
          const headers = response.headers;
          const contentType = headers.get('content-type');
          const blob = new Blob([response.body], { type: contentType });
          const excelIO = new Excel.IO();
          excelIO.open(blob, ((json: any) => {
            resolve(json);
          }), function (e) {
              reject(e);
          });
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public uploadSheet(templateId: number, jsonData: any) {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      const excelIO = new Excel.IO();
      excelIO.save(jsonData, function (blob: Blob) {
        const file = self.blobToFile(blob, "mapping_file.xslx");

        let formData = new FormData();
        formData.append('file', file, file.name);

        const actionUrl = `${environment.DQS_API_URL}/import/v2/template/sheet?templateId=${templateId}`;

        self.http.post(actionUrl, formData)
          .pipe(take(1))
          .subscribe(response => {
            const res: any = response;
            if (res.success) {
              resolve(res);
            } else {
              reject(res);
            }
          },
            error => {
              reject(error);
            }
          );
      }, function (e) {
        if (e.errorCode === 1) {
          reject(e);
        }
      });
    });
  }


  public loadMappings(templateId: number) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.DQS_API_URL}/import/v2/template/mapping?templateId=${templateId}`;

      self.http.get(actionUrl)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          resolve(res);
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public deleteTemplate(templateId: number): Promise<any> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      const url = `${environment.DQS_API_URL}/import/v2/template?templateId=${templateId}`;
      self.http.delete(url)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

  public saveSingleReflistMapping(templateId: number, mapping: any): Promise<any> {
    const self = this;
    return new Promise<any>((resolve, reject) => {
      const url = `${environment.DQS_API_URL}/import/v2/template/mapping/single/reflist?templateId=${templateId}`;
      self.http.post(url, mapping)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }
}
