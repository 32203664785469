(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxMultiSelectField
   * @description
   * This component provides the behavior and visual representation of a multi select field. It is rendered as a Kendo UI kendo-multi-select.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Array} choices An array of elements that can be selected
   * @param {String} valueFieldName The name of the value field. Must be present in <c>choices</c>.
   * @param {String} fieldName The name of the display field. Must be present in <c>choices</c>.
   * @param {Object} item The object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {Function} filter Filter function. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, the function will be called on every change.
   * @param {Integer} index The row index of the current object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {String} tagMode Please refer to https://demos.telerik.com/kendo-ui/multiselect/tag-mode for further details
   * @param {String} ngId Unique ID used to identify this control.
   *
   */
  angular.module("DXSPolluxApp")
    .component("polluxMultiSelectField", {
      template: require('html-loader!./pollux.multi.select.field.tmpl.html'),
      bindings: {
        "choices": "=",
        "valueFieldName": "@",
        "fieldName": "@",
        "item": "=",
        "filter": "&",
        "index": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "tagMode": "@",
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$scope', '$timeout', polluxMultiSelectFieldCtrl]
    });

  function polluxMultiSelectFieldCtrl($scope, $timeout) {
    var vm = this;

    //#region variable declaration
    vm.initialCall = true;

    vm.selectDataSource = null;

    vm.options = {};
    //#endregion

    //#region function declaration
    vm.onNgChange = onNgChange;
    //#endregion

    //#region function implementation
    function onNgChange() {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.initialCall && vm.filter && vm.filter()) {
          vm.initialCall = false;
          return;
        }
        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      if (vm.choices) {
        if (vm.choices instanceof Array) {
          //Array - wrap it into a DataSource
          vm.selectDataSource = new kendo.data.DataSource({
            data: vm.choices
          });
        }
        else if (vm.choices instanceof kendo.data.DataSource) {
          //DataSource - use it directly
          vm.selectDataSource = vm.choices;
        }
      }
      else {
        //No data source - create a new one
        vm.selectDataSource = new kendo.data.DataSource({
          data: []
        });
        vm.ngReadonly = true; //if there is no data -> readonly (prevent a bug that happens when typing with empty datasource)
      }

      if (vm.tagMode == "single") {
        vm.options = {
          dataTextField: vm.fieldName || "description",
          dataValueField: vm.valueFieldName || "oid",
          dataSource: vm.selectDataSource,
          enabled: !vm.ngReadonly,
          valuePrimitive: true,
          tagMode: "single",
          tagTemplate: function (context) {
            return "{{ 'selectedValuesCount' | translate }}: " + context.values.length;
          },
          open: function () {
            if (vm.filter) {
              var func = vm.filter();
              if (func) {
                var data = func(vm.item, vm.choices, vm.index);
                vm.selectDataSource.data(data);
              }
            }
          },
          change: vm.onNgChange
        };
      }
      else {
        vm.options = {
          dataTextField: vm.fieldName || "description",
          dataValueField: vm.valueFieldName || "oid",
          dataSource: vm.selectDataSource,
          enabled: !vm.ngReadonly,
          valuePrimitive: true,
          tagMode: "multiple",
          open: function () {
            if (vm.filter) {
              var func = vm.filter();
              if (func) {
                var data = func(vm.item, vm.choices, vm.index);
                vm.selectDataSource.data(data);
              }
            }
          },
          change: vm.onNgChange
        };
      }
    }

    $scope.$on('localeChanged', function (event, locale) {
      $timeout(function () {
        vm.options.dataTextField = vm.fieldName || "description";
      });
    });
  }
}());

