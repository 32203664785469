import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PropertyRentrollDistributionComponent } from './property-rentroll-distribution.component';

export const routerModule = RouterModule.forChild([{
  path: '',
  component: PropertyRentrollDistributionComponent
}]);

@NgModule({
  imports: [
    CommonModule,
    routerModule
  ],
  declarations: [PropertyRentrollDistributionComponent]
})
export class PropertyRentrollDistributionModule { }
