import { Component, OnInit, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as GC from '@grapecity/spread-sheets';
import * as Excel from '@grapecity/spread-excelio';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AngularIntegrationService } from '../../../../services/AngularIntegrationService';

@Component({
  selector: 'rentroll-export-pane',
  templateUrl: './rentroll-export-pane.component.html',
  styleUrls: ['./rentroll-export-pane.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RentrollExportPane implements OnInit, OnDestroy {

  @Input() property: any;

  public rentRollMonth: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  public showWorkbook: boolean = false;

  public showExportButton: boolean = false;
  /**
   * Loading indicator
   */
  public loading: boolean = false;

  /**
   * The SpreadJS license key
   */
  private spreadJsLicenseKey = environment.SpreadJsLicenseKey;

  /*
   * The spreadsheet
   */
  private spreadsheet: GC.Spread.Sheets.Workbook;

  /**
   * Host style for the spreadsheet
   */
  public hostStyle = {
    width: '100%',
    height: '100%'
  };

  /**
   * The displayed data
   */
  public data: any = null;

  public rentRollDates: any[] = null;

  private onEvents: Array<any> = [];

  constructor(private http: HttpClient, private ais: AngularIntegrationService) {
    GC.Spread.Sheets.LicenseKey = this.spreadJsLicenseKey;
    (Excel as any).LicenseKey = this.spreadJsLicenseKey; //See last post from https://www.grapecity.com/forums/spread-sheets/export-to-excel---license-
  }

  ngOnDestroy() {
    if (this.spreadsheet) {
      this.spreadsheet.destroy();
    }

    this.onEvents.forEach(unregister => {
      unregister();
    });
  }

  ngOnInit(): void {
    this.data = null;
    this.setupEvents();

    this.http.get(environment.POLLUX_COMPARABLES_API_URL + '/Properties/rentroll/dates?oid=' + this.property.Oid).subscribe((rentRollDates: any[]) => {
      if (rentRollDates.length) {
        const date = new Date(rentRollDates[rentRollDates.length - 1]);
        this.rentRollMonth = new Date(date.getFullYear(), date.getMonth(), 1);;
      }
      this.rentRollDates = rentRollDates;
      this.rentRollDateChanged();
    });
  }

  public disabledDates = (date: Date): boolean => {

    let matching = this.rentRollDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === date.getFullYear() && entryDate.getMonth() === date.getMonth();
    });

    return matching.length === 0;
  }

  public rentRollDateChanged() {
    this.showWorkbook = false;
    this.showExportButton = this.hasRentRollInMonth(this.rentRollMonth);
    if (this.showExportButton) {
      this.loadRentRoll();
    }
  }

  /**
   * Callback: The SpreadJS spreadsheet has been initialized
   * @param args
   */
  public workbookInit(args) {
    this.spreadsheet = args.spread;
    this.showWorkbook = false;
  }

  private loadRentRoll() {
    var self = this;
    self.loading = true;

    const offset = this.rentRollMonth.getTimezoneOffset();
    var date = new Date(this.rentRollMonth.getTime() - (offset*60*1000));
    var dateAsString = date.toISOString().split('T')[0];

    this.http.get(environment.DMS_IO_URL + '/export/synapses/property/rentroll?propertyId=' + this.property.Oid + '&date=' + dateAsString + '&preview=true', {
      responseType: 'arraybuffer', observe: 'response'
    }).subscribe((response: HttpResponse<ArrayBuffer>) => {
      let headers = response.headers;
      let contentType = headers.get('content-type');
      let blob = new Blob([response.body], { type: contentType });

      self.loading = false;
      self.showWorkbook = true;

      var excelIO = new Excel.IO();
      excelIO.open(blob, loadSpreadsheet, function (message) {  
          console.log(message);  
      });

      function loadSpreadsheet(json) {

      let removedCount = 0; for (var p in json.sheets) p.indexOf("Evaluation") !== -1 && ++removedCount && delete json.sheets[p];
      if (removedCount > 0) {
        json.sheetCount = 0; for (var p in json.sheets) ++json.sheetCount;

        var arr = [];
        for (var p in json.sheets) arr.push({ name: p, index: json.sheets[p].index });
        arr.sort(function(a, b) { return a.index - b.index});
        arr.forEach((item, index) => { json.sheets[item.name].index = index; });
        json.activeSheetIndex = 0;
      }

      self.data = json;
      self.loading = false;

      self.spreadsheet.fromJSON(self.data);

      self.spreadsheet.options.tabStripVisible = false;
      self.spreadsheet.options.allowUserZoom = false;

      //Disable some extended user interactions so it's not possible to change the spreadsheet structure
      self.spreadsheet.options.allowUserEditFormula = false;
      self.spreadsheet.options.allowSheetReorder = false;
      self.spreadsheet.options.newTabVisible = false;
      self.spreadsheet.options.allowContextMenu = false;

      //autofit all sheets
      var sheetCount = self.spreadsheet.getSheetCount();
      for (var i = 0; i < sheetCount; i++) {
        let sheet = self.spreadsheet.getSheet(i);
        var columnCount = sheet.getColumnCount();
        for (var j = 0; j < columnCount; j++) {
          sheet.autoFitColumn(j);
        }
      }

      self.spreadsheet.refresh();
    }

    });
  }

  public downloadRentRoll() {

    const offset = this.rentRollMonth.getTimezoneOffset();
    var date = new Date(this.rentRollMonth.getTime() - (offset*60*1000));
    var dateAsString = date.toISOString().split('T')[0];

    this.http.get(environment.DMS_IO_URL + '/export/synapses/property/rentroll?propertyId=' + this.property.Oid + '&date=' + dateAsString, {
      responseType: 'arraybuffer', observe: 'response'
    }).subscribe((response: HttpResponse<ArrayBuffer>) => {
      let headers = response.headers;
      let contentType = headers.get('content-type');
      let blob = new Blob([response.body], { type: contentType });
      let url = window.URL.createObjectURL(blob);

      let filename = headers.get('content-disposition');
      if (filename && filename.indexOf('filename=') >= 0) {
        filename = filename.substring(filename.indexOf('filename=') + 'filename='.length);
        filename = decodeURIComponent(filename.replace(/\+/g, '%20'));
      }

      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = filename || 'RentRollExport.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        console.log(JSON.stringify(error));
        //something wrong happened, back to first step
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }

  public hasRentRollInMonth(date: Date) {
    let matchingRentRollDate = this.rentRollDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === date.getFullYear() && entryDate.getMonth() === date.getMonth() && entryDate.getDate() === date.getDate();
    });

    return matchingRentRollDate.length > 0;
  }

  public hasRentRollInMonthClass(date: Date) {
    return this.hasRentRollInMonth(date)? 'hasRentRoll' : '';
  }

  public hasRentRollInYearClass(year: any) {
    let matchingRentRollDate = this.rentRollDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === parseInt(year);
    });

    return matchingRentRollDate.length ? 'hasRentRoll' : '';
  }

  private setupEvents() {
    let unregister: any;
    let $rootScope = this.ais.$rootScope;
    const that = this;

    unregister = $rootScope.$on('dqsValidation.refreshSheet', function (event, params) {
      that.spreadsheet && that.spreadsheet.refresh();
    });

    this.onEvents.push(unregister);
  }
}
