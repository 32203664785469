import { Component, OnInit, ViewChild, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs/operators';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from 'src/environments/environment';
import * as GC from '@grapecity/spread-sheets';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { PageChangeEvent } from "@progress/kendo-angular-dropdowns/dist/es2015/common/page-change-event";
import * as Excel from '@grapecity/spread-excelio';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AngularIntegrationService } from '../../../services/AngularIntegrationService';

@Component({
  selector: 'dqs-validation',
  templateUrl: './dqs-validation.component.html',
  styleUrls: ['./dqs-validation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DqsValidation implements OnInit, OnDestroy {
  /**
   * The Kendo upload component for uploading the file to be examined
   */
  @ViewChild("kendoUpload", { static: true }) kendoUpload: any;

  @Input() property: any;

  /**
   * File upload restricttions: Only allow XLSX!
   */
  public fileUploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx'],
    maxFileSize: 8388608 //8MB
  };

  public showUpload: boolean = true;

  public rentRollMonth: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  /**
   * Loading indicator
   */
  public loading: boolean = false;

  /**
   * The SpreadJS license key
   */
  private spreadJsLicenseKey = environment.SpreadJsLicenseKey;

  /*
   * The spreadsheet
   */
  private spreadsheet: GC.Spread.Sheets.Workbook;

  /**
   * Host style for the spreadsheet
   */
  public hostStyle = {
    width: '100%',
    height: '100%'
  };

  public currentSelectedDqsProject: number;

  /**
   * The displayed data
   */
  public data: any = null;

  public sheetEdited: boolean = false;

  public uploadSuccessful: boolean = false;

  public rentRollDates: any[] = [];

  public hasData: boolean = false;

  /**
   * The current calculation model
   */
  public calcModelId: number = null;

  /**
   * Subscription for unsubscribing in ngOnDestroy
   */
  //private serviceSubscriptiopn: Subscription;

  /**
   * The validation results for the RESULTS table
   */
  public validationResult: any[] = null;

  /**
  * The duplicate check flag to show or hide the duplicate check table
  */
  public displayDuplicateCheckTable: boolean = false;

  /**
   * The page size for the RESULTS table
   */
  public PAGE_SIZE: number = 10;

  /**
   * The grid options for the RESULTS table
   */
  public gridOptions: State = {
    skip: 0,
    take: this.PAGE_SIZE,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public dataQualityIndex: number = 0;

  public gridOptionsDuplicateCheck: State = {
  };

  public displayChart: boolean = false;

  public gridData: GridDataResult = null;

  public duplicateCheckData: any[] = null;

  public selectedTab: string = 'dqs-validation';

  private onEvents: Array<any> = [];

  constructor(private http: HttpClient, private ais: AngularIntegrationService) {
    GC.Spread.Sheets.LicenseKey = this.spreadJsLicenseKey;
    (Excel as any).LicenseKey = this.spreadJsLicenseKey; //See last post from https://www.grapecity.com/forums/spread-sheets/export-to-excel---license-
  }

  ngOnDestroy() {
    //this.serviceSubscriptiopn.unsubscribe();

    if (this.spreadsheet) {
      this.spreadsheet.destroy();
    }

    this.onEvents.forEach(unregister => {
      unregister();
    });
  }

  ngOnInit(): void {
    this.data = null;
    this.sheetEdited = false;
    this.setupEvents();

    this.http.get(environment.POLLUX_COMPARABLES_API_URL + '/Properties/rentroll/dates?oid=' + this.property.Oid).subscribe((rentRollDates: any[]) => {
      this.rentRollDates = rentRollDates;
      this.setupBlankRentRoll();
    });
  }

  public selectTab(tab: string) {
    this.selectedTab = tab;

    if (tab === "dqs-validation-results") {
      this.displayDuplicateCheckTable = false;
    }
  }

  /**
   * Callback: The SpreadJS spreadsheet has been initialized
   * @param args
   */
  public workbookInit(args) {
    this.spreadsheet = args.spread;
    this.dataQualityIndex = 0;

    this.spreadsheet.options.tabStripVisible = false;
    this.spreadsheet.options.allowUserZoom = false;

    for (let i = 0; i < this.spreadsheet.sheets.length; ++i) {
      //Process all spreadsheets
      let sheet = this.spreadsheet.sheets[i];
      let sheetName = sheet.name();

      //Suspend events and paint
      sheet.suspendPaint();
      sheet.suspendEvent();

      //Freeze the 1st row so it's always visible
      sheet.frozenRowCount(1);

      //Set the colors for the headers (1st row)
      let headerRange = sheet.getRange(0, 0, 1, sheet.getColumnCount());
      headerRange.locked(true);
      headerRange.backColor("#008AAC");
      headerRange.foreColor("#FFFFFF");

      //Unlock the data cells
      let cellRange = sheet.getRange(1, 0, sheet.getRowCount() - 1, sheet.getColumnCount());
      cellRange.locked(false);

      //Set a different color if a cell was changed by the user
      sheet.bind(GC.Spread.Sheets.Events.CellChanged, function (e, info) {
        info.sheet.getCell(info.row, info.col).backColor('#FA7598');
      });

      //Set the protection options
      sheet.options.isProtected = true;
      sheet.options.protectionOptions.allowDeleteRows = false;
      sheet.options.protectionOptions.allowDeleteColumns = false;
      sheet.options.protectionOptions.allowInsertRows = false;
      sheet.options.protectionOptions.allowInsertColumns = false;
      sheet.options.protectionOptions.allowDragInsertColumns = false;
      sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.valuesAndFormatting;

      let totalRows = sheet.getRowCount() - 1; //-1 for header
      let emptyRows = 0;
      let failedRows = [];
      for (let row = 1; row < sheet.getRowCount(); ++row) {
        let rowIsEmpty: boolean = true;
        for (let column = 0; column < sheet.getColumnCount(); ++column) {
          let cell = sheet.getCell(row, column);
          let value = cell.value();
          if (value != null && value !== '') {
            rowIsEmpty = false;
          }
        }
        if (rowIsEmpty) {
          ++emptyRows;
        }
      }
      //Set colors and formats
      for (let column = 0; column < sheet.getColumnCount(); ++column) {
        //Process all columns and auto-fit
        sheet.autoFitColumn(column);
        for (let row = 1; row < sheet.getRowCount(); ++row) {
          //Processall rows
          let cell = sheet.getCell(row, column);

          //See if there are warnings or errors for this cell
          let resultsWarning = this.data.SheetResult[sheetName].Result.filter((entry) => { return entry.CellName === sheet.getDataColumnName(column) + (row + 1) && entry.ResultType === 'WARNING' });//Index + 1 because data starts in A2 and rowindex is 0-based
          let resultsError = this.data.SheetResult[sheetName].Result.filter((entry) => { return entry.CellName === sheet.getDataColumnName(column) + (row + 1) && entry.ResultType === 'ERROR' });//Index + 1 because data starts in A2 and rowindex is 0-based

          if (resultsError.length !== 0) {
            //Error -> red
            cell.backColor('#ef796f');
            if (failedRows.indexOf(row) < 0) {
              failedRows.push(row);
            }
          }
          else if (resultsWarning.length !== 0) {
            //Warning -> yellow
            cell.backColor('#ffe8b1');
          } else {
            //OK -> green
          }
          try {
            let style = this.data.SheetResult[sheetName].Formatting[0][sheet.getDataColumnName(column)];
            if (style) {
              sheet.setFormatter(row, column, new GC.Spread.Formatter.FormatterBase(style, "en-gb"), GC.Spread.Sheets.SheetArea.viewport);
            }
          } catch (e) {
            //Nothing
          }
        }
      }
      if (totalRows !== 0 && totalRows !== emptyRows) {
        this.dataQualityIndex = 1.0 - failedRows.length / (totalRows - emptyRows);
      }
      else {
        this.dataQualityIndex = 1.0;
      }

      //Resume events and paint - never forget!
      sheet.resumePaint();
      sheet.resumeEvent();

      //display chart
      let self = this;
      setTimeout(() => {
        self.displayChart = true;
      });
    }

    //Disable some extended user interactions soit's not possible to change the spreadsheet structure
    this.spreadsheet.options.allowUserEditFormula = false;
    this.spreadsheet.options.allowSheetReorder = false;
    this.spreadsheet.options.newTabVisible = false;
    this.spreadsheet.options.allowContextMenu = false;
  }

  /**
   * Success handler: The file was successfully uploaded and validated (this doesn't mean that the validation yielded good results though)
   * @param e
   */
  public successEventHandler(e) {
    if (e.response.ok) {
      let data = e.response.body;
      this.data = this.addRows(data);
      this.calcModelId = data.CalculationModelId;
      this.prepareValidationResultLog();
      this.loading = false;
      this.showUpload = false;
      setTimeout(() => {
        this.showUpload = true;
        this.sheetEdited = true;
      });
    }
  }

  /**
   * An error occurred during validation
   * @param e
   */
  public errorEventHandler(e) {
    this.loading = false;
    alert("Error in validation!");
  }

  /**
   * Progress handler - not used
   * @param e
   */
  public uploadProgressEventHandler(e) {
    //Nothing
  }

  /**
   * File selection handler - not used
   * @param e
   */
  public onFileSelectOrDropped(e) {
    if (e.files.length === 1 && e.files[0] && e.files[0].validationErrors) {
      if (e.files[0].validationErrors.length) {
        alert("Invalid file!");
      }
    }
  }

  /**
   * A file should be uploaded for validation
   * @param e
   */
  public onUploadEvent(e) {
    this.displayChart = false;
    const upload = this.kendoUpload;
    if (upload.fileList.files.length !== 1) {
      e.preventDefault();
      return;//no files for upload
    }
    this.loading = true;
    upload.saveUrl = environment.DQS_API_URL + '/validation/TechnicalValidation/RentRoll?propertyId=' + this.property.Oid;
  }

  public setupBlankRentRoll() {
    this.http.post(environment.DQS_API_URL + '/validation/TechnicalValidation/RentRoll?propertyId=' + this.property.Oid, null).subscribe((data: any) => {
      this.data = this.addRows(data);
      this.calcModelId = data.CalculationModelId;
      this.prepareValidationResultLog();
      this.loading = false;
    });
  }

  public downloadRentRollTemplate() {
    this.loading = true;
    this.http.get(environment.DMS_IO_URL + '/import/template/synapses?mask=v_qv_synapses_rentroll', {
      responseType: 'arraybuffer', observe: 'response'
    }).subscribe((response: HttpResponse<ArrayBuffer>) => {
      let headers = response.headers;
      let contentType = headers.get('content-type');
      let blob = new Blob([response.body], { type: contentType });
      let url = window.URL.createObjectURL(blob);

      var a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = 'Rent Roll Template.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        console.log(JSON.stringify(error));
        //something wrong happened, back to first step
      },
      () => {
        this.loading = false;
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }

  /**
   * Reads the validation result for all sheets, so it can be displayed in the RESULTS tab
   */
  private prepareValidationResultLog() {
    this.validationResult = [];

    let result = [];
    for (let sheet of this.data.Sheets) {
      let sheetResult = this.data.SheetResult[sheet].Result;
      for (let i = 0; i < sheetResult.length; ++i) {
        result.push(sheetResult[i]);
      }
    }

    this.validationResult = result;
    this.gridData = process(this.validationResult, this.gridOptions);
    this.gridOptions.skip = 0;//Set page to 1
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }

  /**
   * Called whenever the grid's page was changed
   * @param event
   */
  public pageChange(event: PageChangeEvent): void {
    if (!this.validationResult) {
      return;
    }
    this.gridOptions.skip = event.skip;
    this.gridData = process(this.validationResult, this.gridOptions);
  }

  /**
   * Called whenever the grid's data state was changed
   * @param gridOptions
   */
  public dataStateChange(gridOptions: DataStateChangeEvent): void {
    if (!this.validationResult) {
      return;
    }
    this.gridOptions = gridOptions;
    this.gridData = process(this.validationResult, this.gridOptions);
  }

  private refreshWithValidationResponse(): void {
    this.workbookInit({ spread: this.spreadsheet });
    this.prepareValidationResultLog();
  }

  private updateDataWithResult(newData: any, keepValidationResults: boolean) {
    let oldResults = {};
    if (keepValidationResults) {
      for (let sheet of this.data.Sheets) {
        oldResults[sheet] = this.data.SheetResult[sheet].Result;
      }
    }


    this.data = this.addRows(newData);

    if (keepValidationResults) {
      for (let sheet of this.data.Sheets) {
        this.data.SheetResult[sheet].Result = oldResults[sheet];
      }
    }
  }

  private addRows(data: any) {
    this.hasData = false;
    if (data.SheetResult.v_qv_synapses_rentroll.Data.length === 0) {
      return data;
    }

    this.hasData = data.SheetResult.v_qv_synapses_rentroll.Data.length > 1;
    let firstRowClone = JSON.parse(JSON.stringify(data.SheetResult.v_qv_synapses_rentroll.Data[0]));
    let initialLength = data.SheetResult.v_qv_synapses_rentroll.Data.length;

    for (let key of Object.keys(firstRowClone)) {
      firstRowClone[key] = '';
    }

    for (let i = 0; i < 101 - initialLength; ++i) {
      data.SheetResult.v_qv_synapses_rentroll.Data.push(JSON.parse(JSON.stringify(firstRowClone)));
    }

    return data;
  }

  /**
   * Performs a transformation (rules) with the data visible in the spreadsheet
   */
  public transformation() {
    let url: string = environment.DQS_API_URL + '/validation/Transformation?calcModelOid=' + this.calcModelId.toString();
    this.revalidate(url, true, "Error in transformation!");
  }

  /**
   * Performs a new validation with the data visible in the spreadsheet
   */
  public revalidate(_actionUrl: string, _keepValidationResults: boolean, _errorMessage: string) {
    this.displayChart = false;
    this.displayDuplicateCheckTable = false;
    var that = this;

    var dto = JSON.stringify(this.spreadsheet.toJSON({
      includeBindingSource: true
    }));

    let actionUrl = _actionUrl ? _actionUrl : environment.DQS_API_URL + '/validation/TechnicalValidation?calcModelOid=' + that.calcModelId.toString();
    let keepValidationResults = typeof _keepValidationResults === 'undefined' ? false : _keepValidationResults;
    let errorMessage = _errorMessage ? _errorMessage : "Error in validation!"

    this.loading = true;
    let excelIO = new Excel.IO();
    excelIO.save(dto, function (blob: Blob) {
      let file = that.blobToFile(blob, "dqs_validate.xslx");

      let formData = new FormData();
      formData.append('file', file, file.name);

      that.http.post(actionUrl, formData)
        .pipe(take(1))
        .subscribe(response => {
          that.updateDataWithResult(response, keepValidationResults);
          setTimeout(() => {
            that.loading = false;
            that.refreshWithValidationResponse();
          });
        },
          error => {
            console.log(JSON.stringify(error));
            //something wrong happened
            alert(errorMessage);
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here
          }
        );
    }, function (e) {
      if (e.errorCode === 1) {
        alert(e.errorMessage);
      }
    });
  }

  private fixTimeZoneOffsetForSave(date) {
    try {
      if (date) {
        if (!(date instanceof Date)) {
          date = new Date(date);
        }

        if (date instanceof Date) {
          var n = date.getTimezoneOffset();
          date.setHours(date.getHours() - n / 60);
          var dateString = date.toISOString();
          date = dateString.substring(0, dateString.indexOf('T'));
        }
      }
      return date;
    } catch (e) {
      return null;
    }
  }

  /**
   * Performs an import with the data visible in the import
   */
  public importAndDuplicateCheck() {
    let matchingRentRollDate = this.rentRollDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === this.rentRollMonth.getFullYear() && entryDate.getMonth() === this.rentRollMonth.getMonth() && entryDate.getDate() === this.rentRollMonth.getDate();
    });

    if (matchingRentRollDate.length) {
      if (!confirm('Do you want to replace the existing rent - roll data for ' + (this.rentRollMonth.getMonth() + 1) + ' / ' + this.rentRollMonth.getFullYear() + '?')) {
        return;
      }
    }

    let self = this;

    this.loading = true;
    this.displayChart = false;
    this.displayDuplicateCheckTable = false;
    var that = this;
    var dto = JSON.stringify(this.spreadsheet.toJSON({
      includeBindingSource: true
    }));
    let excelIO = new Excel.IO();
    excelIO.save(dto, function (blob: Blob) {
      let file = that.blobToFile(blob, "dqs_import.xslx");

      let formData = new FormData();
      formData.append('file', file, file.name);

      let additionalData = {
        AdditionalValues: {
          RentRollDate: self.fixTimeZoneOffsetForSave(self.rentRollMonth)
        }
      };
      formData.append('additionalData', JSON.stringify(additionalData));

      that.http.post(environment.DMS_IO_URL + '/import/calcviews?calcModelOid=' + that.calcModelId + '&importId=' + that.calcModelId, formData)
        .pipe(take(1))
        .subscribe(response => {
          let res: any = response;
          if (res.success) {
            self.http.get(environment.POLLUX_COMPARABLES_API_URL + '/Properties/rentroll/dates?oid=' + self.property.Oid).subscribe((rentRollDates: any[]) => {
              self.rentRollDates = rentRollDates;
              self.loading = false;
              self.uploadSuccessful = true;
              alert('Data imported successfully!');
            });
          } else {
            alert(res.message);
          }
        },
          error => {
            console.log(JSON.stringify(error));
            //something wrong happened
            alert("Unknown import error!")
          },
          () => {
            // 'onCompleted' callback.
            // No errors, route to new page here
          }
        );
    }, function (e) {
      if (e.errorCode === 1) {
        alert(e.errorMessage);
      }
    });
  }

  /**
  * Duplicate check
  */
  private duplicateCheck(res) {
    let self = this;
    self.http.get(environment.DQS_API_URL + '/duplicate/check?viewId=31&dqsProjectId=' + self.currentSelectedDqsProject)
      .pipe(take(1))
      .subscribe(response => {
        self.evaluateDuplicateCheck(response, res);

        self.loading = false;
      });
  }
  private evaluateDuplicateCheck(data, res) {
    let transformedData = [];
    for (let i = 0; i < data.Results.length; ++i) {
      let entry = data.Results[i];

      for (let j = 0; j < entry.Duplicates.length; ++j) {
        let duplicate = entry.Duplicates[j];
        if (duplicate.Mandatory >= 0.95) {
          if (!entry.DuplicateId) {
            entry.DuplicateId = duplicate.Oid;
            duplicate.isChosen = true;
          }
        }
        else {
          duplicate.isChosen = false;
        }
      }

      let matches = entry.Duplicates.filter((entry) => { return entry.Mandatory >= 0.95; });
      let newEntry = {
        "Oid": entry.Oid,
        "CheckedEntry": entry.Description,
        "CheckResult": matches.length > 0 ? "MATCH" : "NO MATCH",
        "DuplicatesCount": entry.Duplicates.length,
        "DuplicatesObjects": entry.Duplicates,
        "DuplicateId": entry.DuplicateId,
        "Import": matches.length > 0 ? false : true
      };

      transformedData.push(newEntry);
    }
    this.duplicateCheckData = transformedData;
    this.displayDuplicateCheckTable = true;
    if (res && res.validationSummary) {
      this.updateDataWithResult(res.validationSummary, false);
      setTimeout(() => {
        this.refreshWithValidationResponse();
      });
    }
  }

  public hasDuplicates(dataItem: any, index: number): boolean {
    return dataItem.DuplicatesObjects.length !== 0;
  }

  public duplicateChosen(dataItem, duplicate) {
    if (duplicate.isChosen) {
      for (let i = 0; i < dataItem.DuplicatesObjects.length; ++i) {
        if (dataItem.DuplicatesObjects[i].Oid !== duplicate.Oid) {
          dataItem.DuplicatesObjects[i].isChosen = false;
        }
      }
      dataItem.DuplicateId = duplicate.Oid;
      dataItem.Import = false;
    }
  }

  /**
  * Final import
  */
  public finalImport() {
    let self = this;
    let notImportableEntries = {};
    let duplicateEntries = {};
    this.loading = true;

    for (let sheetName of this.data.Sheets) {
      notImportableEntries[sheetName] = [];
      if (sheetName === 'v_dqs_al') {
        notImportableEntries[sheetName] = this.duplicateCheckData.filter((entry) => { return !entry.Import; }).map((entry) => { return entry.Oid; });
        duplicateEntries[sheetName] = this.duplicateCheckData.filter((entry) => { return !entry.Import; }).map((entry) => { return entry.DuplicateId; });
      }
    }

    this.http.post(environment.DQS_API_URL + '/datastore/apply?importId=' + this.currentSelectedDqsProject, { NotImported: notImportableEntries, Duplicates: duplicateEntries })
      .pipe(take(1))
      .subscribe(response => {
        self.loading = false;
        this.displayDuplicateCheckTable = false;
      });
  }

  public hasRentRollInMonth(date: Date) {
    let matchingRentRollDate = this.rentRollDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === date.getFullYear() && entryDate.getMonth() === date.getMonth() && entryDate.getDate() === date.getDate();
    });

    return matchingRentRollDate.length ? 'hasRentRoll' : '';
  }

  public hasRentRollInYear(year: any) {
    let matchingRentRollDate = this.rentRollDates.filter((entry) => {
      let entryDate = new Date(entry);
      return entryDate.getFullYear() === parseInt(year);
    });

    return matchingRentRollDate.length ? 'hasRentRoll' : '';
  }

  public selectAndUploadFile() {
    this.hasData = false;
    this.kendoUpload.clearFiles();
    setTimeout(() => {
      this.kendoUpload.fileSelect.nativeElement.click();
    });
  }

  public getDataQualityClass(dataQuality) {
    if (dataQuality === 100) {
      return '';
    }
    return 'invalid';
  }

  public getProgressStatusClass(item: string) {
    switch (item) {
      case "input":
        if (this.hasData && this.dataQualityIndex == 1.0) return "done-status";
        else if (this.sheetEdited) return "active-status";
        break;
      case "validation":
        if (this.uploadSuccessful) return "done-status";
        else if (this.hasData && this.dataQualityIndex == 1.0) return "active-status";
        break;
      case "upload":
        if (this.uploadSuccessful) return "active-status";
        break;
    }

    return '';
  }

  private setupEvents() {
    let unregister: any;
    let $rootScope = this.ais.$rootScope;
    const that = this;

    unregister = $rootScope.$on('dqsValidation.refreshSheet', function (event, params) {
      that.spreadsheet && that.spreadsheet.refresh();
    });

    this.onEvents.push(unregister);
  }
}
