import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

//ZoomCharts
import { GeochartsComponent } from './geo-charts/geo-charts.component';
import { CashflowchartsComponent } from './cashflow-charts/cashflow-charts.component';
import { WindowRefService } from "./window-ref";
import { ZoomchartsComponent } from './zoom-charts/zoom-charts.component';
import { CashflowChartsService } from 'src/app/components/_qv/zoom-charts/cashflow-charts/_services/cashflow-charts-service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    ZoomchartsComponent,
    GeochartsComponent,
    CashflowchartsComponent
  ],
  exports: [
    ZoomchartsComponent,
    GeochartsComponent,
    CashflowchartsComponent
  ],
  providers: [WindowRefService, DecimalPipe, CashflowChartsService],
  entryComponents: [GeochartsComponent, CashflowchartsComponent]
})
export class ZoomchartsModule { }
