(function () {
  'use strict';

  angular
    .module('DXSPolluxApp')
    .factory('appSidebarActionsService', [appSidebarActionsService]);

  function appSidebarActionsService() {

    var callbacks = [];

    function register(callback, clearCallback) {
      callbacks.push({
        callback: callback,
        clearCallback: clearCallback
        });
      return callback;
    }

    function setActions(actions) {
      callbacks.forEach(callbacksObj => callbacksObj.callback(actions));
    }

    function clear() {
      callbacks.forEach(callbacksObj => callbacksObj.clearCallback());
    }

    return {
      register: register,
      setActions: setActions,
      clear: clear
    };
  }
}());
