(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationSettingsDialogRoleManagementResource',
        ['$resource', 'appServiceHelper', projectValuationSettingsDialogRoleManagementResource]);

    function projectValuationSettingsDialogRoleManagementResource($resource, appServiceHelper) {
        return {
            users: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user'), {}),
            roles: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/roles'), {}),
            groups: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/groups'), {}),
            roleAssignments: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/roleAssignments'), { projectstdId: '@projectstdId', useCurrentUser: '@useCurrentUser' }),
            roleAssignmentsList: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/roleAssignments/list'), { projectstdId: '@projectstdId', useCurrentUser: '@useCurrentUser' }),
            deleteRoleAssignment: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/roleAssignments/delete'), { oid: '@oid' }),
            deleteRoleAssignmentList: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/roleAssignments/list/delete'), { userId: '@userId' })
        }
    }
}());
