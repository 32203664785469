(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationPortfolioDialogWrapper", {
      template: require('html-loader!./project.valuation.portfolio.dialog.wrapper.tmpl.html'),
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$timeout', 'ValuationPortfoliosResource', '$translate', projectValuationPortfolioDialogWrapperCtrl]
    });

  function projectValuationPortfolioDialogWrapperCtrl($rootScope, $scope, $timeout, ValuationPortfoliosResource, $translate) {
    var vm = this;
    vm.states = getStates();

    function reset() {
      vm.oid = null;
      vm.dataItem = null;
      vm.status = null;
    }

    reset();

    function getStates() {
      return [{
        "oid": 0,
        "parentNo": null,
        "description": ""
      }, {
        "oid": 1,
        "parentNo": null,
        "description": $translate.instant('projectValuation.draft')
      }, {
        "oid": 2,
        "parentNo": null,
        "description": $translate.instant('projectValuation.toBeApproved')
      }, {
        "oid": 3,
        "parentNo": null,
        "description": $translate.instant('projectValuation.approved')
      }, {
        "oid": 4,
        "parentNo": null,
        "description": $translate.instant('projectValuation.final')
      }
      ];
    }

    /* close handling*/
    $scope.$on("valuationPortfoliosDataSaved", function (event, params) {
      reset();
    });

    $scope.$on("valuationPortfoliosData.canceled", function (event, params) {
      reset();
    });

    $scope.$on("PortfolioArchived", function (event, params) {
      reset();
    });

    $scope.$on("toggleAreaFilters", function (event, params) {
      params.forceClose && reset();
    });

    vm.close = function () {
      reset();
    }

    /* show handling */
    $scope.$on("editValuationPortfoliosData.inline", function (event, params) {
      reset();
      if (params.data) {
        vm.oid = params.id;
        vm.dataItem = params.data;
        vm.status = vm.states.filter((entry) => { return entry.oid === vm.dataItem.JobStatusId; })[0].description;
      } else {
        ValuationPortfoliosResource.load(params.id).then(function (dataItem) {
          vm.oid = params.id;
          vm.dataItem = dataItem;
          vm.status = vm.states.filter((entry) => { return entry.oid === vm.dataItem.JobStatusId; })[0].description;
        });
      }
    });


  }
}());
