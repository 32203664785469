(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('projectValuationPortfolioTypeCustomDialogRuntime',
      ['ValuationPortfoliosResource', '$timeout', 'angSynapsesDashboardService', 'ValuationPortfoliosResourceCustom', '$q', runtime]);

  function runtime(ValuationPortfoliosResource, $timeout, angSynapsesDashboardService, ValuationPortfoliosResourceCustom, $q) {
    this.getDialogWidth = function () {
      return 1600;
    };

    this.postProcessData = function (deferred, params) {
      //Perform any post-processing operations, including async. Resolve the promise once they are done or immediately if no async operations are required.
      //params = { vm: vm, data: data, parent: parent }
      if (params.data.Oid < 0) {
        params.data.LanguageId = 2;
        params.data.PeriodOfValuationID = 2;
      }

      params.vm.parentJobFilters = [{ name: 'jobTypeId', value: params.data.JobTypeId }, { name: 'propertyId', value: params.data.LinkedPropertyOid }];

      if (params.parent) {
        params.data.ProjectID = params.parent.ProjectStdID;
        params.data.ProjectIDDescription = params.parent.ProjectStdIDDescription;

        params.data.Client = 0;
        params.data.ClientIdDescription = '';

        params.data.CurrencyId = 1;
        params.data.CurrencyIdDescription = "Euro";
        params.data.CurrencySymbolUnicode = "8364";

        params.data.ValuationPortfolioName = 'New job for property ' + params.parent.Oid;

        params.data.LinkedPropertyOid = params.parent.Oid;
        params.data.LinkedPropertyOidDescription = params.parent.PropertyAddress;


        params.data.AcquisitionDate = params.parent.acquisitionDate;
        params.data.Opex = params.parent.opex;


        params.data.PropertyManagementFee = params.parent.pmFee;
        params.data.AssetManagementFee = params.parent.amFee;
      }

      if (params.data.Oid > 0) {
        const subscriber = angSynapsesDashboardService.load(params.data, null).subscribe((data) => {
          subscriber.unsubscribe();

          const calcModelData = data.calcModel;

          const IMPORT_CUSTOM = 'IMPORT CUSTOM';
          const PERCENTAGE_FACTOR = 100.0;

          params.data.modelData = {
            holdingPeriod: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'EV3', calcModelData) || 0),
            rentIncreasePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'B3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            purchasePrice: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'AZ3', calcModelData) || 0),
            shareOfPropertyPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'C3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            ancillaryPurchaseCostsPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'D3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            brokerFeePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'E3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            costIncreasePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'J3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            propertyManagementFeePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'F3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            nonRecsPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'G3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            maintenanceReservePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'H3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            rentLossRiskPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'I3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            renovationCostsPerSqm: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'BA3', calcModelData) || 0),
            vacancyCostsPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'BB3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            relettingCostsPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'BC3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            equityPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'K3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            loanPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'L3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            interestRatePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'M3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            repaymentPercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'N3', calcModelData) || 0) * PERCENTAGE_FACTOR,
            salesPrice: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'CV3', calcModelData) || 0),
            //properties starting with _ will not be writeBack !! needed just for calculation
            _grossRent1: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'BI3', calcModelData) || 0),
            _totalLettableArea: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'CX3', calcModelData) || 0)
          };

          deferred.resolve();
        });
      }
      else {
        params.data.modelData = {
          holdingPeriod: 0,
          rentIncreasePercentage: 0,
          purchasePrice: 0,
          shareOfPropertyPercentage: 0,
          ancillaryPurchaseCostsPercentage: 0,
          brokerFeePercentage: 0,
          costIncreasePercentage: 0,
          propertyManagementFeePercentage: 0,
          nonRecsPercentage: 0,
          maintenanceReservePercentage: 0,
          rentLossRiskPercentage: 0,
          renovationCostsPerSqm: 0,
          vacancyCostsPercentage: 0,
          relettingCostsPercentage: 0,
          equityPercentage: 0,
          loanPercentage: 100,
          interestRatePercentage: 0,
          repaymentPercentage: 0,
          salesPrice: 0,
          //properties starting with _ will not be writeBack !! needed just for calculation
          _grossRent1: 0,
          _totalLettableArea: 0
        };

        deferred.resolve();
      }

      params.vm.loanAndEquityFacade = {
        //we use equityPercentageEx & loanPercentageEx as VM for UI numeric-text-boxes
        set equityPercentageEx(value) {
          params.data.modelData.equityPercentage = value;
          params.data.modelData.loanPercentage = 100 - (params.data.modelData.equityPercentage || 0);
        },
        get equityPercentageEx() {
          return params.data.modelData.equityPercentage;
        },
        set loanPercentageEx(value) {
          params.data.modelData.loanPercentage = value;
          params.data.modelData.equityPercentage = 100 - (params.data.modelData.loanPercentage || 0);
        },
        get loanPercentageEx() {
          return params.data.modelData.loanPercentage;
        }
      }
    };

    this.preProcessDataBeforeSave = function (deferred, params) {
      //params = { vm: vm, data: data, parent: parent }
      var vm = params.vm, data = params.data, parent = params.parent;

      //TODO: Perform any pre-save-processing operations, including async. Resolve the promise once they are done or immediately if no async operations are required.
      if (!vm.data.LinkedPropertyOid) {
        vm.formInvalid = true;
        vm.suppressFormInvalidMessage = false; //Use this variable to suppress the 'invalid' message if you wish to display your own messages
      }
      else {
        vm.formInvalid = false;
        vm.suppressFormInvalidMessage = false; //Use this variable to suppress the 'invalid' message if you wish to display your own messages
      }
      //TODO: Ferform form validation & required fields check. Please use vm.formInvalid


      deferred.resolve();
    };

    this.onDataSaved = function (deferred, params) {
      if (params.result.m_Item1) {
        const originalOid = params.vm.data.Oid;
        const oid = params.result.m_Item2;

        const calcModelWriteBack = [];
        for (const property in params.data.modelData) {
          if (property.indexOf('_') === 0) {
            //properties starting with _ will not be writeBack
            continue;
          }

          const cellName = this.getValueChangedCell(property);

          const uiValue = params.data.modelData[property];
          const divideFactor = property.endsWith("Percentage") ? 100.0 : 1;

          calcModelWriteBack.push({
            sheet: 'Summary',
            cellName: cellName,
            value: uiValue / divideFactor
          });
        }

        //based on price we have to calculate multipliers and prices (per sqm)
        this.calculateMultipliers(params.data.modelData, calcModelWriteBack);
        this.calculatePrices(params.data.modelData, calcModelWriteBack);

        $q.all([ValuationPortfoliosResourceCustom.saveCalcModelData(oid, calcModelWriteBack)]).then((result) => {

          if (originalOid > 0) {
            deferred.resolve();//It was update => nothing to do
            return;
          }

          //It was insert => we need some calculated values that are not present of form, but calculated in model

          const jobData = JSON.parse(JSON.stringify(params.data));
          jobData.Oid = oid;

          const subscriber = angSynapsesDashboardService.load(jobData, null).subscribe((data) => {
            subscriber.unsubscribe();
            const calcModelData = data.calcModel;
            const IMPORT_CUSTOM = 'IMPORT CUSTOM';
            const PERCENTAGE_FACTOR = 100.0;

            const modelData = {
              rentIncreasePercentage: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'B3', calcModelData) || 0) * PERCENTAGE_FACTOR,
              salesPrice: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'CV3', calcModelData) || 0),
              _grossRent1: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'BI3', calcModelData) || 0),
              _totalLettableArea: parseFloat(angSynapsesDashboardService.getCalulatedModelData(IMPORT_CUSTOM, 'CX3', calcModelData) || 0)
            };

            const calcModelWriteBack = [];
            //based on price we have to calculate multipliers and prices (per sqm)
            this.calculateMultipliers(modelData, calcModelWriteBack);
            this.calculatePrices(modelData, calcModelWriteBack);

            //save multipliers and prices
            $q.all([ValuationPortfoliosResourceCustom.saveCalcModelData(oid, calcModelWriteBack)]).then((result) => {
              deferred.resolve();
            });
            
          });
        });
      }
      else {
        deferred.resolve();
      }
    }

    this.getValueChangedCell = function (fieldName) {
      //return cell name on sheet "Summary" for field
      switch (fieldName) {
        case "holdingPeriod": return 'D24';
        case "rentIncreasePercentage": return 'D25';
        case "purchasePrice": return 'D26';
        case "shareOfPropertyPercentage": return 'D27';
        case "ancillaryPurchaseCostsPercentage": return 'D28';
        case "brokerFeePercentage": return 'D29';
        case "propertyManagementFeePercentage": return 'D31';
        case "nonRecsPercentage": return 'D32';
        case "maintenanceReservePercentage": return 'D33';
        case "rentLossRiskPercentage": return 'D34';
        case "renovationCostsPerSqm": return 'D35';
        case "vacancyCostsPercentage": return 'D36';
        case "relettingCostsPercentage": return 'D37';
        case "costIncreasePercentage": return 'D38';
        case "equityPercentage": return 'D40';
        case "loanPercentage": return 'D41';
        case "interestRatePercentage": return 'D42';
        case "repaymentPercentage": return 'D43';
        case "salesPrice": return 'D44';
        case "multiplier1": return 'C51';
        case "multiplier2": return 'C52';
        case "multiplier3": return 'C53';
        case "price1": return 'C58';
        case "price2": return 'C59';
        case "price3": return 'C60';
        default: return null;
      }
    }

    this.calculateMultipliers = function (modelData, calcModelWriteBack) {
      //based on DashboardLib: dashboard-widget-custom-scenario-analysis-multiplier2.component.ts
      let multiplier1, multiplier2, multiplier3;

      const salesProceeds = modelData['salesPrice'] || 0;

      //rentalIncomeExit: parseFloat(self.getCalcModelData('IMPORT CUSTOM', 'BI3') || 0) * (1 + parseFloat(self.getCalcModelData('IMPORT CUSTOM', 'B3') || 0)), //decimal
      //rentalIncomeExit = grossRent1 * (1 + rentIncreasePercentage)
      const grossRent1 = modelData['_grossRent1'] || 0;
      const rentIncreasePercentage = modelData['rentIncreasePercentage'] / 100;
      const rentalIncomeExit = grossRent1 * (1 + rentIncreasePercentage);

      multiplier2 = Math.max(0, rentalIncomeExit ? salesProceeds / rentalIncomeExit : 0, 0);
      multiplier1 = multiplier2 < 1 ? 0 : multiplier2 - 1;
      multiplier3 = multiplier2 + 1;

      calcModelWriteBack.push({ sheet: 'Summary', cellName: this.getValueChangedCell('multiplier1'), value: multiplier1 });
      calcModelWriteBack.push({ sheet: 'Summary', cellName: this.getValueChangedCell('multiplier2'), value: multiplier2 });
      calcModelWriteBack.push({ sheet: 'Summary', cellName: this.getValueChangedCell('multiplier3'), value: multiplier3 });
    }

    this.calculatePrices = function (modelData, calcModelWriteBack) {
      //based on DashboardLib: dashboard-widget-custom-scenario-analysis-price2.component.ts
      let price1, price2, price3;

      const salesPrice = modelData['salesPrice'] || 0;
      const totalLettableArea = modelData['_totalLettableArea'] || 0;

      price2 = Math.max(0, totalLettableArea ? salesPrice / totalLettableArea : 0);
      price1 = price2 < 1 ? 0 : price2 - 100;
      price3 = price2 + 100;

      calcModelWriteBack.push({ sheet: 'Summary', cellName: this.getValueChangedCell('price1'), value: price1 });
      calcModelWriteBack.push({ sheet: 'Summary', cellName: this.getValueChangedCell('price2'), value: price2 });
      calcModelWriteBack.push({ sheet: 'Summary', cellName: this.getValueChangedCell('price3'), value: price3 });
    }

    this.loanChanged = function () {
      //usage of change event is not good because if fire onFocus/onBlur for pollux-numeric-field
      //so we are using getter/setter: see params.vm.loanAndEquityFacade up the code
    }

    this.equityChanged = function () {
      //usage of change event is not good because if fire onFocus/onBlur for pollux-numeric-field
      //so we are using getter/setter: see params.vm.loanAndEquityFacade up the code
    }

    this.applyProjectName = function (vm, field) {
      if (!vm.dataLoadedTimeout && field != 'ApplyFieldsFromProjectstd') {
        //Don't react to the initial change events
        return;
      }

      var projectNameWithId = String(vm.data.ProjectIDDescription);
      if (projectNameWithId.lastIndexOf(',') >= 0) {
        vm.data.ProjectName = projectNameWithId.substring(0, projectNameWithId.lastIndexOf(',')).trim();
      }
      else {
        vm.data.ProjectName = projectNameWithId;
      }
      this.buildValuationPortfolioName(vm, 'ApplyFieldsFromProjectstd');
    };

    this.buildValuationPortfolioName = function (vm, field) {
      if (!vm.dataLoadedTimeout && field != 'ApplyFieldsFromProjectstd') {
        //Don't react to the initial change events
        return;
      }

      if (vm.data.Oid) {
        var name = '';
        if (vm.data.ValuationDate && vm.data.ValuationDate != '1900-01-01') {
          if (vm.data.ValuationDate instanceof Date) {
            name = kendo.toString(new Date(vm.data.ValuationDate), "yyyyMMdd");
          }
          else {
            var dateString = String(vm.data.ValuationDate);
            if (dateString.indexOf('T') >= 0) {
              dateString = dateString.substring(0, dateString.indexOf('T'))
            }
            if (dateString) {
              var valuationDate = Date(dateString);
              name = kendo.toString(new Date(valuationDate), "yyyyMMdd");
            }
          }
        }
      }

      if (vm.data.Client) {
        name += '_';
        name += vm.data.ClientIdDescription;
      }

      if (vm.data.ProjectName) {
        name += '_';
        name += vm.data.ProjectName;
      }

      if (vm.data.Remark) {
        name += '_';
        name += vm.data.Remark;
      }

      vm.data.ValuationPortfolioName = name;
    };

    this.datasetStatusUpdated = function (vm) {
      vm.formReadOnly = vm.data.RecordStatusId == 2;
    };

    this.getMarketRentsAndUsages = function (vm) {
      ValuationPortfoliosResource.loadMarketRentsAndUsages(vm.data.LinkedPropertyOid, vm.data.RentRollDate).then(function (result) {
        vm.data.MarketRentUsageType1 = result.MarketRentUsageType1;
        vm.data.MarketRentUsageType2 = result.MarketRentUsageType2;
        vm.data.MarketRentUsageType3 = result.MarketRentUsageType3;
        vm.data.MarketRentUsageType4 = result.MarketRentUsageType4;
        vm.data.MarketRentUsageType5 = result.MarketRentUsageType5;

        vm.data.MarketRentUsage1 = result.MarketRentUsage1;
        vm.data.MarketRentUsage2 = result.MarketRentUsage2;
        vm.data.MarketRentUsage3 = result.MarketRentUsage3;
        vm.data.MarketRentUsage4 = result.MarketRentUsage4;
        vm.data.MarketRentUsage5 = result.MarketRentUsage5;
      });
    }

    this.rentRollDateChanged = function (vm) {
      const self = this;
      if (vm.data.Oid > 0) {
        return;//no RR date change on existing jobs
      }

      self.getMarketRentsAndUsages(vm);
    }

    this.linkedPropertyChanged = function (vm) {

      const self = this;
      if (vm.data.Oid > 0) {
        return;//no property change on existing jobs
      }

      //reset parent job (can be just job with same property)
      vm.data.ParentJobOid = 0;
      vm.data.ParentJobOidDescription = "";

      vm.parentJobFilters = [{ name: 'jobTypeId', value: vm.data.JobTypeId }, { name: 'propertyId', value: vm.data.LinkedPropertyOid }];

      if (vm.data.LinkedPropertyOid) {
        vm.rentrollsDates = null;//force dropdown update
        ValuationPortfoliosResource.loadRentRollsDates(vm.data.LinkedPropertyOid).then(function (dates) {
          vm.rentrollsDates = dates.sort().reverse();
          if (vm.rentrollsDates.length) {
            vm.data.RentRollDate = vm.rentrollsDates[0];
            self.getMarketRentsAndUsages(vm);
          }
        });
      } else {
        vm.rentrollsDates = null;//force dropdown update
        $timeout(function () {
          vm.rentrollsDates = [];
          vm.data.RentRollDate = null;
        });
      }
    };

    this.parentJobChanged = function (vm) {
      if (vm.data.ParentJobOid) {
        ValuationPortfoliosResource.load(vm.data.ParentJobOid).then((result) => {
          vm.data.MarketRentUsage1 = result.MarketRentUsage1;
          vm.data.MarketRentUsage2 = result.MarketRentUsage2;
          vm.data.MarketRentUsage3 = result.MarketRentUsage3;
          vm.data.MarketRentUsage4 = result.MarketRentUsage4;
          vm.data.MarketRentUsage5 = result.MarketRentUsage5;
        });
      }
    };

    this.updateRentrollFromParentClicked = function (vm) {
      const self = this;
      if (vm.data.UpdateRentrollFromParent) {
        vm.rentrollsDates = null;//force dropdown update
        ValuationPortfoliosResource.load(vm.data.ParentJobOid).then(function (result) {
          if (result) {
            vm.rentrollsDates = [result.RentRollDate];
            vm.data.RentRollDate = vm.rentrollsDates[0];
            vm.disableRentRollDate = true;
          }
        });
      }
      else {
        vm.rentrollsDates = null;//force dropdown update
        vm.disableRentRollDate = false;
        $timeout(function () {
          if (vm.data.LinkedPropertyOid) {
            vm.rentrollsDates = null;//force dropdown update
            ValuationPortfoliosResource.loadRentRollsDates(vm.data.LinkedPropertyOid).then(function (dates) {
              vm.rentrollsDates = [];
              vm.rentrollsDates = dates.sort().reverse();
              vm.data.RentRollDate = null;
              if (vm.rentrollsDates.length) {
                vm.data.RentRollDate = vm.rentrollsDates[0];
                self.getMarketRentsAndUsages(vm);
              }
            });
          } else {
            vm.rentrollsDates = null;//force dropdown update
            $timeout(function () {
              vm.rentrollsDates = [];
              vm.data.RentRollDate = null;
            });
          }
        });
      }
    };

    return this;
  }
}());
