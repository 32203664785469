(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("projectValuationCalcModelDialog", {
      template: require('html-loader!./project.valuation.calc.model.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$scope', '$timeout', '$translate', '$rootScope', 'appSettings', 'appStorage', 'adalAuthenticationService', 'windowHelper', 'appConfirmDialogService', 'notificationsService', projectValuationCalcModelDialog]
    });

  function projectValuationCalcModelDialog($scope, $timeout, $translate, $rootScope, appSettings, appStorage, adalAuthenticationService, windowHelper, appConfirmDialogService, notificationsService) {
    var vm = this;

    //#region variable declaration
    vm.enableWindow = false;
    vm.appImportDialogWindow = null;

    function reset() {
      vm.callback = null;
      vm.job = null;
      vm.jobOid = -1;
    }

    reset();

    vm.uploadOptions = {
      multiple: true,
      async: {
        saveUrl: appSettings.DQS_API_URL + '/import/calcmodel/v2/import',
        autoUpload: false
      },
      validation: {
        allowedExtensions: ['.xlsx']
      },
      dropZone: '.dropZoneElement',
      localization: {
        select: $translate.instant('import.select'),
        cancel: $translate.instant('import.cancel'),
        clearSelectedFiles: $translate.instant('import.clearSelectedFiles'),
        dropFilesHere: $translate.instant('import.dropFilesHere'),
        headerStatusUploaded: $translate.instant('import.headerStatusUploaded'),
        headerStatusUploading: $translate.instant('import.headerStatusUploading'),
        invalidFileExtension: $translate.instant('import.invalidFileExtension'),
        invalidFiles: $translate.instant('import.invalidFiles'),
        invalidMaxFileSize: $translate.instant('import.invalidMaxFileSize'),
        invalidMinFileSize: $translate.instant('import.invalidMinFileSize'),
        remove: $translate.instant('import.remove'),
        retry: $translate.instant('import.retry'),
        statusFailed: $translate.instant('import.statusFailed'),
        statusUploaded: $translate.instant('import.statusUploaded'),
        uploadSelectedFiles: $translate.instant('import.uploadSelectedFiles')
      },
      upload: function (e) {
        var xhr = e.XMLHttpRequest;
        xhr.addEventListener("readystatechange", function (e) {
          if (xhr.readyState == 1 /* OPENED */) {
            try {
              var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
              if (resource) {
                xhr.withCredentials = true;
                xhr.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
              }
            } catch (e) {
              //Nothing, appears outside Azure only
            }
          }
        });

        const zeroGuidPattern = '/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/';
        const selectedProject = appStorage.getSelectedProject();
        const organizationGuid = appStorage.getOrganizationGuid();

        let url = appSettings.environment.DQS_API_URL + '/import/calcmodel/v2/import?jobOid=' + vm.jobOid;
        if (selectedProject) {
          url = url.replace(zeroGuidPattern, `/api/v2/${organizationGuid}/${selectedProject.ProjectGuid}/`);
        }
        e.sender.options.async.saveUrl = url;

        e.data = {};
      },
      error: function (e) {
        alert(e.XMLHttpRequest.responseText);
      },
      success: function (e) {
        if (!e.response.success) {
          alert(e.response.message);
          e.preventDefault();
          $timeout(function () {
            angular.element('.k-upload-status-total').text($translate.instant('import.invalidFiles'));
          }, 10);
        }
        else {
          notificationsService.notifyJobAssumptionsChange({ jobId: vm.jobOid, messageId: 303/*JOB_ASSUMPTIONS_CHANGE_CALC_MODEL_UPLOAD*/ });
          $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, "import.statusUploaded", 'simpleMessage.titleUpload');
          vm.appImportDialogWindow.close();
          vm.enableWindow = false;
          $scope.$apply();
          $timeout(function () {
            angular.element('.k-upload-status-total').text($translate.instant('import.headerStatusUploaded'));

            if (vm.callback) {
              vm.callback();
              vm.callback = null;
            }
          }, 10);
        }
      }
    };
    //#endregion

    //#region function declaration
    vm.callback = null;
    vm.cancel = cancel;
    vm.upload = null;
    //#endregion

    //#region function implementation
    function cancel() {
      vm.appImportDialogWindow.close();
    }
    //#endregion

    //#region events
    $scope.$on('openProjectValuationCalcModelDialog', function (event, item) {
      reset();

      vm.enableWindow = true;
      vm.job = item.job;
      vm.jobOid = item.job.Oid;
      vm.callback = item.callback;

      function openDialog() {
        $timeout(function () {
          vm.appImportDialogWindow.title($translate.instant('import.valuationPortfolioImport'));
          windowHelper.open(vm.appImportDialogWindow);
        });
      }

      //Task 8508: Synching of jobs
      //If a user imports a model in the QV application into a synched job a message is shown" Do you want to keep the synchronization on?"  On yes the synchronization keeps turned on, else this is turned off.

      if (vm.job.PublishedCopyJobId && vm.job.IsCTShared && vm.job.IsCTSyncronizationOn) {
        const confirmConfig = {
          success: function () {
            openDialog();//YES => keep synced, just open import dialog
          },
          cancel: function () {
            //NO => turn syncronization off
            const jobEntity = vm.job;
            const syncParams = {
              jobEntity: jobEntity,
              syncronizationOn: false,
              successCallback: function () {
                jobEntity.IsCTSyncronizationOn = false;
                openDialog();//SUCCESS => open dialog
              },
              failCallback: function (msg) {
                //FAIL => display message and DON'T open dialog
                appConfirmDialogService.open({ simpleMessage: true }, msg, 'Information');
              }
            };

            $rootScope.$broadcast('CTShareJobSyncronization', syncParams);
          }
        };

        appConfirmDialogService.open(confirmConfig, 'keepSyncronizationOnDialog', '');

      } else {
        openDialog();//not synced, just open import dialog
      }
    });
    //#endregion
  }
}());

