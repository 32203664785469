import { Injectable } from '@angular/core';
import { MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import * as msalConfig from './../config/msal.config';

@Injectable({
    providedIn: "root"
})
export class CustomMsalInterceptor {

  /*
  Interceptor used by AngularJS
  Based on interceptor used in @azure/msal-angular (2.0.0-beta.3)
  It also cached acquired token (cashedAccessToken) used by adalAuthenticationService (required for kendo grids that need token sync)
  */

  publicClientApplication: IPublicClientApplication;
  msalInterceptorConfig: MsalInterceptorConfiguration;
  cashedAccessToken: string = '';//used by adalAuthenticationService (kendo grids etc that need token sync)

  constructor() {
    this.publicClientApplication = msalConfig.MSALInstanceFactory();
    this.msalInterceptorConfig = msalConfig.MSALInterceptorConfigFactory();
	}

  handle($q: any) {
    const self = this;
    const publicClientApplication = this.publicClientApplication;
    const msalInterceptorConfig = this.msalInterceptorConfig;

    return {
      request: function (config) {

        function getScopesForEndpoint(endpoint) {
          //taken from from azure-msal-angular to decide if enddpoint is protected
          publicClientApplication.getLogger().verbose("ANGJS - Interceptor - getting scopes for endpoint");
          const normalizedEndpoint = endpoint.toLowerCase().trim();//simplified "normalize" from azure-msal-angular
          const protectedResourcesArray = Array.from(msalInterceptorConfig.protectedResourceMap.keys());
          const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
            const normalizedKey = key.toLowerCase().trim();
            return normalizedEndpoint.indexOf(normalizedKey) === 0;
          });
          // Process all protected resources and send the first matched resource
          if (keyMatchesEndpointArray.length > 0) {
            const keyForEndpoint = keyMatchesEndpointArray[0];
            if (keyForEndpoint) {
              return msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
            }
          }

          return null;
        }

        const scopes = getScopesForEndpoint(config.url);
        if (!scopes || scopes.length === 0) {
          return config;
        }

        let account;
        if (!!publicClientApplication.getActiveAccount()) {
          publicClientApplication.getLogger().verbose("ANGJS - Interceptor - active account selected");
          account = this.authService.instance.getActiveAccount();
        }
        else {
          publicClientApplication.getLogger().verbose("ANGJS - Interceptor - no active account, fallback to first account");
          const accounts = publicClientApplication.getAllAccounts();
          if (accounts.length === 0) return config;
          account = accounts[0];
        }

        const accessTokenRequest = {
          scopes: scopes,
          account: account
        };

        var deferred = $q.defer();

        publicClientApplication.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
          self.cashedAccessToken = accessTokenResponse.accessToken;//used by adalAuthenticationService (kendo grids etc that need token sync)
          let token = `Bearer ${accessTokenResponse.accessToken}`;
          config.headers['Authorization'] = token;
          deferred.resolve(config);
        }).catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          console.log(error);

          if (error instanceof InteractionRequiredAuthError) {
            publicClientApplication.acquireTokenRedirect(accessTokenRequest).then(function () {
              publicClientApplication.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
                self.cashedAccessToken = accessTokenResponse.accessToken;//used by adalAuthenticationService (kendo grids etc that need token sync)
                let token = `Bearer ${accessTokenResponse.accessToken}`;
                config.headers['Authorization'] = token;
                deferred.resolve(config);
              }).catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
            }).catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
            });
          }
        });

        return deferred.promise;
      }
    };
  }

}
