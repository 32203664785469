(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:appStorage
   * @description
   * App storage that can temporarily save the application state
   * 
   * @requires $rootScope
   * @requires $q
   * @requires $timeout
   * @requires DXSPolluxApp.object:appSettings
   * @requires projectValuationSettingsDialogRoleManagementResource
   * @requires appUserResource
   */
  angular
    .module('DXSPolluxApp')
    .factory('appStorage',
      ['$rootScope', '$q', '$timeout', 'appSettings', 'projectValuationSettingsDialogRoleManagementResource', 'appUserResource', '$window', 'angularIntegrationService'
        , 'appLocalization', 'appServiceHelper', 'locale'
        , appStorage]);

  function appStorage($rootScope, $q, $timeout, appSettings, projectValuationSettingsDialogRoleManagementResource, appUserResource, $window, angularIntegrationService
        , appLocalization, appServiceHelper, locale) {
    var self = this;

    //set rootscope to GEO hostApp so it can handle events
    if ($window.polluxValApp) {
      $window.polluxValApp.setRootScope($rootScope);
    }

    var appEditMode = false;
    var appWidgetHeaderMode = false;
    var appReportGridRasterMode = true;
    var appWidgetInfoMode = true;
    var PVInfoMode = false;
    var PTDInfoMode = true;
    var appActivePortfolio = null;
    var appActiveProperty = null;
    var appActivePeriod = null;
    var appActivePlanProcess = null;
    var template = null;
    var report = null;
    var valuationPortfolio = null;
    var appFooterReceiverData = null;

    var widgetTargetDropMode = false;
    var widgetTargetDropModeOptions = null;

    var appActiveDocument = null;
    var roleAssignments = {};
    var roleAssignmentsCurrentUser = {};
    var hasUserRoles = false;
    var roleLoadingWaiters = [];

    var userDetails = null;

    var appPrintMode = false;
    var selectedPortfolio = null;
    var selectedProject = null;
    var selectedProperty = null;
    var dashboardPortfolioMyReports = null;
    var dashboardProjectMyProjectsAndPortfolios = null;

    var stagesIdToOpen = null;
    var taskIdToOpen = null;

    var organizationGuid = '00000000-0000-0000-0000-000000000000';

    //#region Report data

    /**
     * @ngdoc function
     * @name getActivePortfolio
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current RAM portfolio. RAM only.
     * 
     * @returns {Object} The current RAM portfolio
     */
    function getActivePortfolio() {
      return appActivePortfolio;
    }

    /**
     * @ngdoc function
     * @name setActivePortfolio
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current RAM portfolio. RAM only.
     * 
     * @param {Object} portfolio The new current RAM portfolio
     * 
     * @returns {Object} The current RAM portfolio
     */
    function setActivePortfolio(portfolio) {
      appActivePortfolio = portfolio;
      return appActivePortfolio;
    }

    /**
     * @ngdoc function
     * @name getActiveProperty
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current property
     * 
     * @returns {Object} The current property
     */
    function getActiveProperty() {
      return appActiveProperty;
    }

    /**
     * @ngdoc function
     * @name setActiveProperty
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current property
     * 
     * @param {Object} property The new current property
     * 
     * @returns {Object} The current property
     */
    function setActiveProperty(property) {
      appActiveProperty = property;
      return appActiveProperty;
    }

    /**
     * @ngdoc function
     * @name getActivePeriod
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current period. RAM only!
     * 
     * @returns {Object} The current period
     */
    function getActivePeriod() {
      return appActivePeriod;
    }

    /**
     * @ngdoc function
     * @name setActivePeriod
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current period. RAM only!
     * 
     * @param {Object} period The new current period
     * 
     * @returns {Object} The current period
     */
    function setActivePeriod(period) {
      appActivePeriod = period;
      return appActivePeriod;
    }

    /**
     * @ngdoc function
     * @name getActivePlanProcess
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current plan period. RAM only!
     * 
     * @returns {Object} The current plan period
     */
    function getActivePlanProcess() {
      return appActivePlanProcess;
    }

    /**
     * @ngdoc function
     * @name setActivePlanProcess
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current plan process. RAM only!
     * 
     * @param {Object} process The new current plan process
     * 
     * @returns {Object} The current plan process
     */
    function setActivePlanProcess(process) {
      appActivePlanProcess = process;
      return appActivePlanProcess;
    }

    //#endregion


    //#region active document 

    /**
     * @ngdoc function
     * @name getActiveDocument
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current Report Designer document object.
     * 
     * @returns {Object} The current Report Designer document object
     */
    function getActiveDocument() {
      return appActiveDocument;
    }

    /**
     * @ngdoc function
     * @name setActiveDocument
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current Report Designer document object.
     * 
     * @param {Object} document The new current Report Designer document object
     * 
     * @returns {Object} The current Report Designer document object
     */
    function setActiveDocument(document) {
      appActiveDocument = document;
      return appActiveDocument;
    }

    //#region template

    /**
     * @ngdoc function
     * @name setTemplate
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current Report Designer template object.
     * 
     * @param {Object} newTemplate The new current Report Designer template object
     * 
     * @returns {Object} The current Report Designer template object
     */
    function setTemplate(newTemplate) {
      template = newTemplate;
    }

    /**
     * @ngdoc function
     * @name getTemplate
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current Report Designer template object.
     * 
     * @returns {Object} The current Report Designer template object
     */
    function getTemplate() {
      return template;
    }

    //#endregion

    //#region reports

    /**
     * @ngdoc function
     * @name setReport
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current report.
     * 
     * @param {Object} newReport The new current report
     * 
     * @returns {Object} The current report
     */
    function setReport(newReport) {
      report = newReport;
    }

    /**
     * @ngdoc function
     * @name getReport
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current report.
     * 
     * @returns {Object} The current report
     */
    function getReport() {
      return report;
    }

    //#endregion

    //#region application mode

    /**
     * @ngdoc function
     * @name setAppEditMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Turns the edit mode on or off. Only used in the Report Designer at the moment because the other areas have dedicated edit dialogs.
     * 
     * @param {Bool} mode The new edit mode state. true turns the edit mode on, false turns it off.
     * 
     * @returns {Bool} The current edit mode state
     */
    function setAppEditMode(mode) {
      appEditMode = mode;
      $rootScope.$broadcast("editModeToggled");
      return appEditMode;
    }

    /**
     * @ngdoc function
     * @name getAppEditMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current edit mode state. true indicates that the edit mode is turned on, false means that it is turned off.
     * Only used in the Report Designer at the moment because the other areas have dedicated edit dialogs.
     * 
     * @returns {Bool} The current edit mode state
     */
    function getAppEditMode() {
      return appEditMode;
    }

    /**
     * @ngdoc function
     * @name setAppWidgetHeaderMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current Report Designer Widget Header mode state. true indicates that the editReport Designer Widget Header mode is turned on, false means that it is turned off.
     * If it is turned on, a red utility bar is rendered above every widget.
     * 
     * @param {Bool} mode The new Report Designer Widget Header mode state. true turns the Report Designer Widget Header mode on, false turns it off.
     * 
     * @returns {Bool} The current Report Designer Widget Header mode state
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function setAppWidgetHeaderMode(mode) {
      appWidgetHeaderMode = mode;
      if (appActiveDocument && appActiveDocument.Data && appActiveDocument.Data.pages) {
        for (var i = 0; i < appActiveDocument.Data.pages.length; ++i) {
          var options = appActiveDocument.Data.pages[i].options;

          options.draggable.enabled = mode;
          options.resizable.enabled = mode;
        }
      }

      return appWidgetHeaderMode;
    }

    /**
     * @ngdoc function
     * @name getAppWidgetHeaderMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current Report Designer Widget Header mode state. true indicates that the editReport Designer Widget Header mode is turned on, false means that it is turned off.
     * If it is turned on, a red utility bar is rendered above every widget.
     * Only used in the Report Designer.
     * 
     * @returns {Bool} The current Report Designer Widget Header mode state
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function getAppWidgetHeaderMode() {
      return appWidgetHeaderMode;
    }

    /**
     * @ngdoc function
     * @name setAppReportGridRasterMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current Report Designer Grid Raster state. true indicates that the raster mode is turned on, false means that it is turned off.
     * 
     * @param {Bool} mode The new Report Designer Grid Raster mode state. true turns the Report Designer Grid Raster mode on, false turns it off.
     * 
     * @returns {Bool} The current Report Designer Grid Raster mode state
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function setAppReportGridRasterMode(mode) {
      appReportGridRasterMode = mode;
    }

    /**
     * @ngdoc function
     * @name getAppReportGridRasterMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current Report Designer Grid Raster state. true indicates that the raster mode is turned on, false means that it is turned off.
     * If it is turned on, the grid in which the widgets can be moved is shown.
     * Only used in the Report Designer. 
     * 
     * @returns {Bool} The current Report Designer Grid Raster state
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function getAppReportGridRasterMode() {
      return appReportGridRasterMode;
    }

    /**
     * @ngdoc function
     * @name setAppWidgetInfoMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current Report Designer Widget Info mode state. true indicates that the Report Designer Widget Info mode is turned on, false means that it is turned off.
     * If it is turned on, the Report Designer section's infobar will show more info and configuration possibilities for the currently active widget.
     * Only used in the Report Designer. 
     * 
     * @param {Bool} mode The new current Report Designer Widget Info mode state
     * 
     * @returns {Bool} The current Report Designer Widget Info mode state
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function setAppWidgetInfoMode(mode) {
      appWidgetInfoMode = mode;
      return appWidgetInfoMode;
    }

    /**
     * @ngdoc function
     * @name getAppWidgetInfoMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current Report Designer Widget Info mode state. true indicates that the Report Designer Widget Info mode is turned on, false means that it is turned off.
     * If it is turned on, the Report Designer section's infobar will show more info and configuration possibilities for the currently active widget.
     * Only used in the Report Designer. 
     * 
     * @returns {Bool} The current Report Designer Widget Info mode state
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function getAppWidgetInfoMode() {
      return appWidgetInfoMode;
    }

    /**
     * @ngdoc function
     * @name setPTDInfoMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Shows or hides the infobar in the Project Tasks Dashboard. RAM only.
     */
    //TODO: Move this into the PTD module, we don't need this globally!
    function setPTDInfoMode(mode) {
      PTDInfoMode = mode;
      return PTDInfoMode;
    }

    /**
     * @ngdoc function
     * @name getPTDInfoMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Indicates whether the infobar in the Project Tasks Dashboard is shown. RAM only.
     */
    //TODO: Move this into the PTD module, we don't need this globally!
    function getPTDInfoMode() {
      return PTDInfoMode;
    }
    //#endregion

    /**
     * @ngdoc function
     * @name setActiveValuationPortfolio
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the current valuation portfolio.
     * 
     * @param {Object} portfolio The new current valuation portfolio
     * 
     * @returns {Object} The current valuation portfolio
     */
    function setActiveValuationPortfolio(portfolio) {
      valuationPortfolio = portfolio;
      return valuationPortfolio;
    }

    /**
     * @ngdoc function
     * @name getActiveValuationPortfolio
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the current valuation portfolio.
     * 
     * @returns {Object} The current valuation portfolio
     */
    function getActiveValuationPortfolio() {
      return valuationPortfolio;
    }

    /**
     * @ngdoc function
     * @name getRoleAssignments
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets a list of all role assignments for a portfolio.
     * 
     * @param {Integer} portfolioId The portfolio OID
     * @returns {Array} A list of all role assignments for a portfolio.
     */
    function getRoleAssignments(portfolioId) {
      return roleAssignments[portfolioId];
    }

    /**
     * @ngdoc function
     * @name reloadRoleAssignments
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Reloads all role assignments
     */
    function reloadRoleAssignments() {
      roleAssignments = {};
      roleAssignmentsCurrentUser = {};
      roleAssignmentsQuery();
    }

    /**
     * @ngdoc function
     * @name setRoleAssignments
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the role assignments for a portfolio.
     * 
     * @param {Integer} portfolioId The portfolio OID
     * @param {Array} value The role assignments
     */
    function setRoleAssignments(portfolioId, value) {
      roleAssignments[portfolioId] = value;
    }

    /**
     * @ngdoc function
     * @name getRoleAssignmentsCurrentUser
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets a list of the current user's role assignments for a portfolio.
     * 
     * @param {Integer} portfolioId The portfolio OID
     * @returns {Array} A list of the current user's role assignments for a portfolio.
     */
    function getRoleAssignmentsCurrentUser(portfolioId) {
      return roleAssignmentsCurrentUser[portfolioId];
    }

    function roleAssignmentsQuery() {
      hasUserRoles = false;
      $q.all([
        projectValuationSettingsDialogRoleManagementResource.roleAssignments.query({ projectstdId: 0, useCurrentUser: true }).$promise,
        projectValuationSettingsDialogRoleManagementResource.roleAssignments.query({ projectstdId: 0, useCurrentUser: false }).$promise
      ]).then(function (result) {
        angular.forEach(result[0], function (element) {
          if (!roleAssignmentsCurrentUser[element.ProjectstdId]) {
            roleAssignmentsCurrentUser[element.ProjectstdId] = [];
          }
          roleAssignmentsCurrentUser[element.ProjectstdId].push(element);
        });
        angular.forEach(result[1], function (element) {
          if (!roleAssignments[element.ProjectstdId]) {
            roleAssignments[element.ProjectstdId] = [];
          }
          roleAssignments[element.ProjectstdId].push(element);
        });
        hasUserRoles = true;
        // notify waiters
        while (roleLoadingWaiters.length) {
          roleLoadingWaiters[0]();
          roleLoadingWaiters.splice(0, 1);
        }
      });
    }

    /**
     * @ngdoc function
     * @name userIsInRole
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Checks if the user has a specific role or not.
     * <b>Warning</b> Only use this function if you are 100% sure that the role loading process has finished! Otherwise, please use userIsInRoleAsync
     * 
     * @param {Array} roles The role names to be checked for
     * @returns {Bool} True if the user has any of the given roles
     */
    function userIsInRole(roles) {
      var userRoles = roleAssignmentsCurrentUser[0];
      if (userRoles) {
        for (var i = 0; i < userRoles.length; ++i) {
          if (roles.indexOf(userRoles[i].roleDescription) >= 0) {
            return true;
          }
        }
      }
      return false;
    }

    /**
      * @ngdoc function
      * @name isRolesLoaded
      * @methodOf DXSPolluxApp.service:appStorage
      * @description
      * Checks if the user roles are loaded
      * 
      * @returns {Bool} True if the user roles are loaded
      */
    function isRolesLoaded() {
        return typeof roleAssignmentsCurrentUser[0] !== 'undefined';
    }

    /**
     * @ngdoc function
     * @name userIsInRole
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Checks if the user has a specific role or not. The roles are loaded from the database before querying them.
     * <b>Warning</b> This function is asynchronous and returns a promise.
     * 
     * @param {Array} roles The role names to be checked for
     * @returns {Promise} True if the user has any of the given roles
     */
    function userIsInRoleAsync(roles) {
      var deferred = $q.defer();
      if (hasUserRoles) {
        deferred.resolve(userIsInRole(roles));
      } else {
        roleLoadingWaiters.push(function () {
          deferred.resolve(userIsInRole(roles));
        });
      }

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name setWidgetTargetDropMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the Report Designer widget target drop mode (can the user drop a widget into the page?)
     * 
     * @param {Bool} isEnabled Enable or disable the drop mode
     * @param {Object} options The drop mode options
     * @returns {Object} Report Designer widget target drop mode
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function setWidgetTargetDropMode(isEnabled, options) {
      widgetTargetDropMode = isEnabled;
      widgetTargetDropModeOptions = isEnabled ? options : null;
    }

    /**
     * @ngdoc function
     * @name getWidgetTargetDropMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the Report Designer widget target drop mode (can the user drop a widget into the page?)
     * 
     * @returns {Object} Report Designer widget target drop mode
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function getWidgetTargetDropMode() {
      return widgetTargetDropMode;
    }

    /**
     * @ngdoc function
     * @name getWidgetTargetDropModeOptions
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the Report Designer widget target drop mode options
     * 
     * @returns {Object} Report Designer widget target drop mode options
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function getWidgetTargetDropModeOptions() {
      return widgetTargetDropModeOptions;
    }

    /**
     * @ngdoc function
     * @name loadCurrentUserDetails
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Loads the user details for the current user
     * <b>Warning</b> This is an asynchronous operation!
     */
    function loadCurrentUserDetails() {
      $q.all([
        appUserResource.details.get({}).$promise
      ]).then(function (result) {
        userDetails = result[0];
      });
    }

    /**
     * @ngdoc function
     * @name getCurrentUserDetails
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the user details for the current user
     * <b>Warning</b> The user details need to be loaded before. This load operation needs to finished before calling the function.
     * 
     * @returns {Object} The user details for the current user
     */
    function getCurrentUserDetails() {
      return userDetails;
    }

    /**
     * @ngdoc function
     * @name setAppPrintMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets whether the application is run in print or normal mode
     * 
     * @param {Bool} mode if set to true, the application is run in print mode. Set it to false for normal mode
     */
    function setAppPrintMode(mode) {
      appPrintMode = mode;
    }

    /**
     * @ngdoc function
     * @name getAppPrintMode
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Checks whether the application is run in print or normal mode
     * 
     * @returns {Bool} true if the application is run in print mode. false for normal mode
     */
    function getAppPrintMode() {
      return appPrintMode;
    }

    /**
     * @ngdoc function
     * @name getFooterReceiverData
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the receiver data (customer, address, country) for a Report Designer page
     * 
     * @returns {Object} The receiver data
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function getFooterReceiverData() {
      return appFooterReceiverData;
    }

    /**
     * @ngdoc function
     * @name setFooterReceiverData
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the receiver data (customer, address, country) for a Report Designer page
     * 
     * @param {Object} data The new footer receiver data
     * @returns {Object} The receiver data
     */
    //TODO: Move this into the RD module, we don't need this globally!
    function setFooterReceiverData(data) {
      appFooterReceiverData = data;
    }

    /**
     * @ngdoc function
     * @name getGuid
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Creates a Guid.
     * <b>Warning</b> This is not a real Guid, it just looks like one! If you have very high requirements about it being unique, don't use this function but create the Guid on the server side.
     * <b>Note</b> Should not be used any more. Instead, please use kendo.guid()
     * 
     * @returns {String} A pseudo-Guid
     */
    function getGuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
    }

    /**
     * @ngdoc function
     * @name setSelectedPortfolio
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the selected valuation portfolio. Used to synchronize the selection across the areas.
     * 
     * @param {Object} item The new currently selected valuation portfolio
     */
    function setSelectedPortfolio(item) {
      selectedPortfolio = item;
      $rootScope.$broadcast('appStorage.setSelectedPortfolio', item);
    }

    /**
     * @ngdoc function
     * @name getSelectedPortfolio
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the selected valuation portfolio. Used to synchronize the selection across the areas.
     * 
     * @returns {Object} The currently selected valuation portfolio
     */
    function getSelectedPortfolio() {
      return selectedPortfolio;
    }

    /**
     * @ngdoc function
     * @name setSelectedProject
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the selected project. Used to synchronize the selection across the areas.
     * 
     * @param {Object} item The new currently selected project
     */
    function setSelectedProject(item) {
      selectedProject = item;
      $rootScope.$broadcast('appStorage.setSelectedProject', item);
    }

    /**
     * @ngdoc function
     * @name getSelectedProject
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the selected project. Used to synchronize the selection across the areas.
     * 
     * @returns {Object} The currently selected project
     */
    function getSelectedProject() {
      return selectedProject;
    }

    function setSelectedProperty(item) {
      selectedProperty = item;
      $rootScope.$broadcast('appStorage.setSelectedProperty', item);
    }

    function getSelectedProperty() {
      return selectedProperty;
    }

    /**
      * @ngdoc function
      * @name setDashboardPortfolioMyReports
      * @methodOf DXSPolluxApp.service:appStorage
      * @description
      * Sets the selected valuation portfolio id from dashboard.
      * 
      * @param {Object} item The new currently selected valuation portfolio id
      */
    function setDashboardPortfolioMyReports(item) {
      dashboardPortfolioMyReports = item;
    }

    /**
     * @ngdoc function
     * @name getDashboardPortfolioMyReports
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the selected valuation portfolio id from dashboard.
     * 
     * @returns {Object} The currently selected valuation portfolio id
     */
    function getDashboardPortfolioMyReports() {
      return dashboardPortfolioMyReports;
    }

    /**
     * @ngdoc function
     * @name setValuationPortfolioMyProjectsAndPortfolios
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Sets the selected valuation project id from dashboard.
     * 
     * @param {Object} item The new currently selected valuation portfolio id
    */
    function setDashboardProjectMyProjectsAndPortfolios(item) {
      dashboardProjectMyProjectsAndPortfolios = item;
    }

    /**
     * @ngdoc function
     * @name getDashboardProjectMyProjectsAndPortfolios
     * @methodOf DXSPolluxApp.service:appStorage
     * @description
     * Gets the selected valuation portfolio id from dashboard.
     * 
     * @returns {Object} The currently selected valuation portfolio id
     */
    function getDashboardProjectMyProjectsAndPortfolios() {
      return dashboardProjectMyProjectsAndPortfolios;
    }

    function setStagesIdToOpen(stagesId) {
      stagesIdToOpen = stagesId;
    }

    function getStagesIdToOpen() {
      return stagesIdToOpen;
    }

    function setTaskIdToOpen(taskId) {
      taskIdToOpen = taskId;
    }

    function getTaskIdToOpen() {
      return taskIdToOpen;
    }

    function setSidebarLocked(locked) {
      localStorage.setItem('sidebarLocked', locked ? "true" : "false");
    }

    function getSidebarLocked() {
      return (localStorage.getItem('sidebarLocked') || "true") === "true";
    }

    function setOrganizationGuid(guid) {
      organizationGuid = guid;
      appServiceHelper.setOrganizationGuid(guid);
    }

    function getOrganizationGuid() {
      return organizationGuid;
    }

    var appStorageObj = {
      setActiveDocument: setActiveDocument,
      getActiveDocument: getActiveDocument,

      setAppEditMode: setAppEditMode,
      getAppEditMode: getAppEditMode,

      setAppWidgetHeaderMode: setAppWidgetHeaderMode,
      getAppWidgetHeaderMode: getAppWidgetHeaderMode,

      setAppReportGridRasterMode: setAppReportGridRasterMode,
      getAppReportGridRasterMode: getAppReportGridRasterMode,

      setAppWidgetInfoMode: setAppWidgetInfoMode,
      getAppWidgetInfoMode: getAppWidgetInfoMode,

      getActivePortfolio: getActivePortfolio,
      setActivePortfolio: setActivePortfolio,

      getActiveProperty: getActiveProperty,
      setActiveProperty: setActiveProperty,

      getActivePeriod: getActivePeriod,
      setActivePeriod: setActivePeriod,

      getActivePlanProcess: getActivePlanProcess,
      setActivePlanProcess: setActivePlanProcess,

      setTemplate: setTemplate,
      getTemplate: getTemplate,

      getReport: getReport,
      setReport: setReport,

      setActiveValuationPortfolio: setActiveValuationPortfolio,
      getActiveValuationPortfolio: getActiveValuationPortfolio,

      setPTDInfoMode: setPTDInfoMode,
      getPTDInfoMode: getPTDInfoMode,

      getRoleAssignments: getRoleAssignments,
      reloadRoleAssignments: reloadRoleAssignments,
      setRoleAssignments: setRoleAssignments,
      getRoleAssignmentsCurrentUser: getRoleAssignmentsCurrentUser,
      userIsInRole: userIsInRole,
      isRolesLoaded: isRolesLoaded,
      userIsInRoleAsync: userIsInRoleAsync,

      setWidgetTargetDropMode: setWidgetTargetDropMode,
      getWidgetTargetDropMode: getWidgetTargetDropMode,
      getWidgetTargetDropModeOptions: getWidgetTargetDropModeOptions,

      loadCurrentUserDetails: loadCurrentUserDetails,
      getCurrentUserDetails: getCurrentUserDetails,

      setAppPrintMode: setAppPrintMode,
      getAppPrintMode: getAppPrintMode,

      getFooterReceiverData: getFooterReceiverData,
      setFooterReceiverData: setFooterReceiverData,

      setSelectedPortfolio: setSelectedPortfolio,
      getSelectedPortfolio: getSelectedPortfolio,

      setSelectedProject: setSelectedProject,
      getSelectedProject: getSelectedProject,

      setSelectedProperty: setSelectedProperty,
      getSelectedProperty: getSelectedProperty,

      setDashboardPortfolioMyReports: setDashboardPortfolioMyReports,
      getDashboardPortfolioMyReports: getDashboardPortfolioMyReports,

      setDashboardProjectMyProjectsAndPortfolios: setDashboardProjectMyProjectsAndPortfolios,
      getDashboardProjectMyProjectsAndPortfolios: getDashboardProjectMyProjectsAndPortfolios,

      getGuid: getGuid,

      getStagesIdToOpen: getStagesIdToOpen,
      setStagesIdToOpen: setStagesIdToOpen,

      getTaskIdToOpen: getTaskIdToOpen,
      setTaskIdToOpen: setTaskIdToOpen,

      setSidebarLocked: setSidebarLocked,
      getSidebarLocked: getSidebarLocked,

      setOrganizationGuid: setOrganizationGuid,
      getOrganizationGuid: getOrganizationGuid
    };

    angularIntegrationService.initWithNg1($rootScope, appStorageObj, appSettings, appLocalization, appServiceHelper, locale);

    return appStorageObj;
  }
}());
