(function () {

  "use strict";

  /**
   * @ngdoc directive
   * @name DXSPolluxApp.directive:appSelectWrapper
   * @description
   * This directive can be used to dynamically load any desired component. Currently used to load Quicks.
   * 
   * @requires $compile
   * @requires $timeout
   * 
   * @param {Object} context The view model context
   * @param {String} template Angular template that can be used to load the desired component. Example: <quick-macroeconomic-indicators widget-config='vm.context.quickConfig' department=\"'valuation'\" mode=\"'percentage'\"></quick-macroeconomic-indicators>
   */
  angular.module("DXSPolluxApp")
    .directive('appComponentBinder', ['$compile', '$timeout',
      function ($compile, $timeout) {
        return {
          template: require('html-loader!./app.component.binder.tmpl.html'),
          link: function (scope, element, attrs, ngModelCtrl) {
            // wait for data from the ng-model, particulary if you are loading the data from an http request
            // create a new angular element from the resource in the
            // inherited scope object so it can compile the element 
            // the item element represents the custom widgets
            var newElement = angular.element(scope.template);

            //newElement.css('display', 'block');
            newElement.css('width', '100%');
            newElement.css('height', '100%');

            scope.guid = kendo.guid();

            $timeout(function () {
              // using jQuery after new element creation, to append element
              var wrapperElement = element.find('.componentBinder-' + scope.guid);

              wrapperElement.append(newElement);
              scope.vm = {
                context: scope.context,
              };

              // returns a function that is looking for scope
              // use angular compile service to instanitate a new widget element
              $compile(newElement)(scope);
            });
          },
          scope: {
            template: '=template',
            context: '=context'
          }
        }
      }
    ]);
}());

