import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularIntegrationService } from './AngularIntegrationService';

@Injectable({
    providedIn: "root"
})
export class ProjectGuidInterceptor implements HttpInterceptor {

  private zeroGuidPattern = '/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/';

  private get projectGuidPattern(): string {
    const selectedProject = this.ais.appStorage.getSelectedProject();
    const organizationGuid = this.ais.appStorage.getOrganizationGuid();
    return selectedProject ? `/api/v2/${organizationGuid}/${selectedProject.ProjectGuid}/` : `/api/v2/${organizationGuid}/00000000-0000-0000-0000-000000000000/`;
  }

  constructor(private ais: AngularIntegrationService) {
	}
  
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.ais.appStorage) {
      return next.handle(req);
    }

    if (req.url.indexOf(this.zeroGuidPattern) != -1) {
        req = req.clone({ url: req.url.replace(this.zeroGuidPattern, this.projectGuidPattern) });
    }

    return next.handle(req);
	}
}
