import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SynapsesDqsValidationResult } from '../_data/synapses-dqs-validation-result';

@Component({
  selector: 'synapses-dqs-summary',
  templateUrl: './synapses-dqs-summary.component.html',
  styleUrls: ['./synapses-dqs-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SynapsesDqsSummaryComponent {
  @Input() businessObjectType: string;
  @Input() oid: number;

  public vm = this;

  private _result: SynapsesDqsValidationResult;

  get result(): SynapsesDqsValidationResult {
    return this._result;
  }

  @Input() set result(value: SynapsesDqsValidationResult) {
    const self = this;

    self._result = value;
    self.refresh();
    self.shouldShowConnectedJobs = self.businessObjectType === 'PropertyRentRoll' && self._result.additionalValidationInfo && self._result.additionalValidationInfo.RentRollDate;
    self.rentrollDate = self.shouldShowConnectedJobs ? self._result.additionalValidationInfo.RentRollDate : '';
  }

  public fillRate = 0;
  public errorRate = 0;
  public numberOfRows = 0;

  public shouldShowConnectedJobs = false;
  public rentrollDate: string;//as string in format 2020-09-01

  public refresh() {
    const self = this;

    self.numberOfRows = self.result.dataRows;
    const numberOfFields = self.result.dataRows * self.result.dataColumnsPerRow;

    const filledFields = numberOfFields - self.result.emptyColumnsCount;
    self.fillRate = filledFields / numberOfFields * 100.0;

    const distinctErrors = self._result.entries.filter((entry, i, arr) => {
      return arr.indexOf(arr.find(t => t.rowIndex === entry.rowIndex && t.colIndex === entry.colIndex)) === i;
    });

    self.errorRate = distinctErrors.length / numberOfFields * 100.0;
  }
}
