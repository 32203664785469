(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:applicationUserInfoResource
     * @description
     * This service is responsible for retrieving user information for the currently logged-in user.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('applicationUserInfoResource',
        ['$resource', 'appServiceHelper', applicationUserInfoResource]);

    function applicationUserInfoResource($resource, appServiceHelper) {
        return {
            userinfo: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'userinfo/get'), {})
        }
    }
}());
