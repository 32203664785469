(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:comparablesPropertyImageManagement
   * @description
   * Image Management component that allows users to add and order property images
   * 
   * @requires $scope
   * @requires $timeout
   * @requires $translate
   */
  angular.module("DXSPolluxApp")
    .component("comparablesPropertyImageManagement", {
      //template: require('html-loader!./comparables.property.imageManagement.tmpl.html'),
      templateUrl: 'modules/comparables/components/dialog/comparables.property.imageManagement.tmpl.html',
      bindings: {
        "oid": "=",
        "isDialog": "="
      },
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$timeout', '$translate', comparablesPropertyImageManagement]
    });

  function comparablesPropertyImageManagement($rootScope, $scope, $timeout, $translate) {
    var vm = this;
    
  }
}());
