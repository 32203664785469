(function () {

  "use strict";
  
  /**
   * @ngdoc directive
   * @name DXSPolluxApp.directive:appDefaultFocus
   * @description
   * Focuses an element once it's rendered. Use this directive to place a default focus once
   * 
   * @requires $timeout
   */
    angular.module("DXSPolluxApp")
      .directive('defaultFocus', function ($timeout) {
    return {
      scope: { defaultFocus: '@defaultFocus' },
      link: function (scope, element) {
        $timeout(function () {
          var elements = element.find(scope.defaultFocus);
          if (elements && elements.length) {
            elements[0].focus();
          }
          if (element.attr('id') === scope.defaultFocus || !scope.defaultFocus) {
            element.focus();
          }
        });
      }
    };
  });
}());

