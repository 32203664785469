(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxResizingTextFieldEdit
   * @description
   * This component provides the behavior and visual representation of a text field. The field is rendered as a Kendo UI input field.
   *
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * Internally, it handles a pollux-text-field, but it only displays it's edit field if the content has been clicked.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   */
  angular.module("DXSPolluxApp")
    .component("polluxResizingTextFieldEdit", {
      template: require('html-loader!./pollux.resizing.text.field.edit.tmpl.html'),
      bindings: {
        "ngMaxLength": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxResizingTextFieldEditCtrl]
    });

  function polluxResizingTextFieldEditCtrl($timeout) {
    var vm = this;

    //#region variable declaration
    vm.editMode = false;
    vm.guid = null;
    //#endregion

    //#region function declaration
    vm.edit = edit;
    //#endregion

    vm.$onInit = function () {
      vm.guid = kendo.guid();
      if (!vm.ngId) {
        vm.ngId = kendo.guid();
      }

    }

    vm.onNgChange = function () {
      if (vm.ngChange) {
        vm.ngChange();
      }
    }

    function edit(event) {
      event.stopPropagation();
      vm.editMode = true;
      focusEditableField();
    }

    function focusEditableField() {
      $timeout(function () {
        var input = angular.element('.' + vm.guid).find('textarea');
        if (input && input[0]) {
          input.focus();
          input[0].onblur = disableEdit;
        } else {
          focusEditableField(); //without this the field might not exist yet
        }
      });
    }

    function disableEdit() {
      vm.editMode = false;
    }
  }
}());

