(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxPostcodeField
   * @description
   * This component provides the behavior and visual representation of a single select field. It is rendered as a Kendo UI kendo-drop-down-list.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxPostcodeField", {
      template: require('html-loader!./pollux.postcode.field.tmpl.html'),
      bindings: {
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "countryId": '<',
        "ngId": "@",
        "updateCity": "<",
        "cityTarget": "="
      },
      controllerAs: "vm",
      controller: ['$timeout', 'appServiceHelper', 'adalAuthenticationService', 'appStorage', 'appLocalization', polluxPostcodeFieldCtrl]
    });

  function polluxPostcodeFieldCtrl($timeout, appServiceHelper, adalAuthenticationService, appStorage, appLocalization) {
    var vm = this;

    //#region variable declaration
    vm.initialCall = true;

    vm.dataSource = new kendo.data.DataSource({
      serverFiltering: true,
      transport: {
        read: {
          url: function (context) {
            const selectedProject = appStorage.getSelectedProject();
            const organizationGuid = appStorage.getOrganizationGuid();
            const zeroGuid = '00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000';
            let projectGuid;
            if (selectedProject) {
              projectGuid = selectedProject.ProjectGuid;
            }
            else {
              projectGuid = zeroGuid;
            }

            var api = "marvin.pollux.api";
            let ret;

            if (!vm.ngModel && (!context.filter || !context.filter.filters || !context.filter.filters.length || !context.filter.filters[0].value)) {
              ret = appServiceHelper.getServiceEndpoint(api, 'location/autocomplete/postalCode');
            }
            if (context.filter.filters.length > 0) {
              ret = appServiceHelper.getServiceEndpoint(api, 'location/autocomplete/postalCode?searchTerm=' + context.filter.filters[0].value + '&countryId=' + (vm.countryId || 0));
            }
            if (ret) {
              ret = ret.split(zeroGuid).join(`${organizationGuid}/${projectGuid}`);
            }
            return ret;
          }, beforeSend: function (request, xhr) {
            var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
            if (resource !== null) {
              xhr.withCredentials = true;
              request.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
            }
            request.setRequestHeader('X-PolluxLanguage', appLocalization.getLocale());
          },
          xhrFields: {
            withCredentials: true
          },
          parameterMap: function (data, type) {
            return "";
          }
        },
        parameterMap: function (data, type) {
          return "";
        }
      }
    });

    vm.options = {
      dataTextField: "postalCode",
      dataValueField: "postalCode",
      filter: "contains",
      valuePrimitive: true,
      delay: 500,
      autoBind: false,
      minLength: 3,
      change: onNgChange,
      template: '<span class="k-state-default"><h3>#: data.postalCode #</h3><p>#: data.city #, #: data.country #</p></span>',
      select: function (e) {
        if (vm.updateCity && e.dataItem && e.dataItem.city) {
          vm.cityTarget = e.dataItem.city;
        }
      }
    };

    //#endregion

    //#region function declaration

    //#endregion

    //#region function implementation
    function onNgChange() {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.initialCall && vm.filter && vm.filter()) {
          vm.initialCall = false;
          return;
        }

        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.options.enabled = !vm.ngReadonly;

      if (vm.ngModel) {
        vm.dataSource.read().then(function () {
          vm.element.value(vm.ngModel);
          vm.element.refresh();
          onNgChange();
        });
      }
      else {
        onNgChange();
      }
    }
    //#endregion
  }
}());

