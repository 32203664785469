/*
 * W A R N I N G !!!
 *
 * KEEP THIS FILE IN SYNC BETWEEN QV, CT AND NOTIFICATIONS API !!!
*/

export enum MessageId {
  UNDEFINED = 0,
  SYNCRONIZATION_ON = 101,
  SYNCRONIZATION_OFF = 102,
  JOB_PUBLISHED = 201,
  JOB_UNPUBLISHED = 202,
  JOB_ASSUMPTIONS_CHANGE_JOB_MASK = 301,
  JOB_ASSUMPTIONS_CHANGE_DASHBOARD = 302,//TODO
  JOB_ASSUMPTIONS_CHANGE_CALC_MODEL_UPLOAD = 303,
  JOB_ASSUMPTIONS_CHANGE_JOB_RENTROL_CHANGE = 304,
  JOB_ASSUMPTIONS_CHANGE_PROPERTY_RENTROL_CHANGE = 305,//TODO
  DASHBOARD_SHARED = 401,//TODO
  DASHBOARD_UNSHARED = 402//TODO
}

export enum SourceAreaId {
  UNDEFINED = 0,
  QV_JOB = 101,
  QV_PROPERTY = 102,
  QV_DASHBOARD = 103,

  CT_JOB = 1101,
  CT_PROPERTY = 1102,
  CT_DASHBOARD = 1103
}

export enum AppVisible {
  ALL = 0,
  QV = 1,
  CT = 2
}
