(function () {

  "use strict";

  /*
   *  Dummy component for calculating default redirect route.
   *  Can be used in $routeConfig like this:
   *      { path: "/**", component: "appRouterDefaultRedirector" }
   */
  angular.module("DXSPolluxApp")
    .component("appRouterDefaultRedirector",
      {
        controller: ['appStorage', '$q', 'appRouting', function (appStorage, $q, appRouting) {
          $q.all([
            appStorage.userIsInRoleAsync(['Management Overview'])
          ]).then(function (isInRoles) {
            if (isInRoles[0]) {
              appRouting.navigate(['AnalysisDashboard']);
            } else {
              appRouting.navigate(['ProjectMain']);
            }
          });
        }]
      });

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:appRouter
   * @description
   * This component is responsible for determining which component should be displayed dependent of the current URL.
   * The base URL ends with the pollux.html file (e.g. http://localhost/polluxApp/polluxValuation/app/pollux.html).
   * Then, a route to a special area within Pollux can be added to the base URL by adding #/xyz
   * This addition is specified in the <c>path</c> property below. 
   * 
   * Depending on which <c>path</c> is chosen, the router will then look up which <c>component</c> is assigned to this path.
   * Example: http://localhost/polluxApp/polluxValuation/app/pollux.html#/pd will load the <c>projectValuationMain</c> component.
   * 
   * If an invalid route is provided (for example http://localhost/polluxApp/polluxValuation/app/pollux.html#/polluxIsGreat), the <c>/**</c> route is chosen,
   * which then redirects to the <c>ProjectValuation</c> route.
   * 
   * The <c>name</c> property is relevant for changing the route/area programmatically.
   * For example, <c>appRouting.navigate(["ProjectValuation"]);</c> will load the <c>projectValuationMain</c> component.
   */
  angular.module("DXSPolluxApp").component("appRouter", {
    template: require('html-loader!./app.router.tmpl.html'),
    $routeConfig: [
      //The routes are configured in app.js. Please leave this place empty because the routes are added and loaded once the initial translations are loaded
    ]
  });
}());
