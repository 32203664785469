(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('ValuationPortfoliosResourceCustom',
      ['$resource', 'appServiceHelper', '$q', resourceCustom]);

  function resourceCustom($resource, appServiceHelper, $q) {
    var api = 'marvin.pollux.projectValuation.api';
    var comps_api = "marvin.pollux.comparables.api";
    var dqs_api = "marvin.dqs.api";

    var baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios')),
      usersResource = $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'reflist/pollux/user')),
      rolesResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/roles')),
      roleAssignmentsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/roleAssignments')),
      deleteRoleAssignmentsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/roleAssignments/delete')),
      workloadResouce = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/workload')),
      copyResouce = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/copy/v2'), { oid: '@oid', withModel: '@withModel' }),
      propertyRentRollsDatesResource = $resource(appServiceHelper.getServiceEndpoint(comps_api, 'Properties/rentroll/dates')),
      propertyRentsAndUsagesResource = $resource(appServiceHelper.getServiceEndpoint(comps_api, 'Properties/rentroll/analysis')),
      shareJobResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/ct/share')),
      unshareJobResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/ct/unshare')),
      setJobSyncronizationStateResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/setJobSyncronizationState')),
      currentTeamMembersResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/job/team/current'), { jobId: '@oid' }),
      possibleTeamMembersResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/job/team/possible'), { jobId: '@oid' }),
      jobLinkingDataResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/links'), { jobId: '@jobId' }),
      updateRentRollResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/copyPropertyRentRollToJob'), {}),
      propertyJobsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/getPropertyJobs'), { propertyId: '@propertyId' }),
      archiveJobResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/archive')),
      linkedGroupListResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/linkedgrouplist'), { jobId: '@jobId' }),
      saveCalcModelDataResource = $resource(appServiceHelper.getServiceEndpoint(dqs_api, 'import/calcmodel/v2/json'), { jobId: '@jobId' });


    function loadUsers() {
      return usersResource.query().$promise;
    }

    function loadRoles() {
      return rolesResource.query().$promise;
    }

    function loadRoleAssignments(valuationPortfolioId) {
      return roleAssignmentsResource.query({ valuationPortfolioId: valuationPortfolioId }).$promise;
    }

    function addRoleAssignment(valuationPortfolioId, entry) {
      return roleAssignmentsResource.save({ valuationPortfolioId: valuationPortfolioId }, entry).$promise;
    }

    function deleteRoleAssignment(oid) {
      return deleteRoleAssignmentsResource.delete({ oid: oid }).$promise;
    }

    function loadUserWorkload(valuationPortfolioId, userId) {
      return workloadResouce.get({ valuationPortfolioId: valuationPortfolioId, withModel: withModel }).$promise;
    }

    function copyJob(oid, withModel) {
      return copyResouce.save({ oid: oid, withModel: withModel }).$promise;
    }

    function loadRentRollsDates(propertyId) {
      return propertyRentRollsDatesResource.query({ oid: propertyId }).$promise;
    }

    function loadMarketRentsAndUsages(propertyId, rentRollDate) {
      return propertyRentsAndUsagesResource.get({ propertyId: propertyId, rentRollDate: rentRollDate }).$promise;
    }

    function shareJob(request) {
      return shareJobResource.save(request).$promise;
    }

    function unshareJob(request) {
      return unshareJobResource.save(request).$promise;
    }

    function setJobSyncronizationState(request) {
      return setJobSyncronizationStateResource.save(request).$promise;
    }

    function loadCurrentTeamMembers(jobId) {
      return currentTeamMembersResource.query({ jobId: jobId }).$promise;
    }

    function loadPossibleTeamMembers(jobId) {
      return possibleTeamMembersResource.query({ jobId: jobId }).$promise;
    }

    function loadLinkingData(jobId) {
      if (jobId) {
        return jobLinkingDataResource.query({ jobId: jobId }).$promise;
      }
      var deferred = $q.defer();
      deferred.resolve(null);
      return deferred.promise;
    }

    function getPropertyJobs(propertyId, rentrollDate) {
      return propertyJobsResource.query({ propertyId: propertyId, active: 1, rentrollDate: rentrollDate }).$promise;
    }

    function updateRentRoll(jobIds, propertyId, rentrollDate) {
      return updateRentRollResource.save({}, { jobIds: jobIds, rentrollDate: rentrollDate, propertyId: propertyId }).$promise;
    }

    function archive(jobId) {
      return archiveJobResource.save({}, { jobId: jobId }).$promise;
    }

    function loadLinkedGroupList(jobId) {
      return linkedGroupListResource.query({ jobId: jobId }).$promise;
    }

    function saveCalcModelData(oid, data) {
      return saveCalcModelDataResource.save({ jobOid: oid }, data).$promise
    }

    return {
      loadUsers: loadUsers,
      loadRoles: loadRoles,
      loadRoleAssignments: loadRoleAssignments,
      addRoleAssignment: addRoleAssignment,
      deleteRoleAssignment: deleteRoleAssignment,
      loadUserWorkload: loadUserWorkload,
      copyJob: copyJob,
      loadRentRollsDates: loadRentRollsDates,
      shareJob: shareJob,
      unshareJob: unshareJob,
      setJobSyncronizationState: setJobSyncronizationState,
      loadCurrentTeamMembers: loadCurrentTeamMembers,
      loadPossibleTeamMembers: loadPossibleTeamMembers,
      loadMarketRentsAndUsages: loadMarketRentsAndUsages,
      loadLinkingData: loadLinkingData,
      getPropertyJobs: getPropertyJobs,
      updateRentRoll: updateRentRoll,
      archive: archive,
      loadLinkedGroupList: loadLinkedGroupList,
      saveCalcModelData: saveCalcModelData
    };
  }
}());
