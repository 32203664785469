(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridKpi
   * @description
   * Displays the KPI for either jobs or properties. The area is defined via the area parameter
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridKpi", {
      template: require('html-loader!./project.valuation.grid.kpi.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "area": "@",
        "gridData": "<"
      },
      controllerAs: "vm",
      controller: ['appLocalization', '$translate', '$q', 'projectValuationGridKpiSettingsService', '$filter', '$scope', projectValuationGridKpiCtrl]
    });

  function projectValuationGridKpiCtrl(appLocalization, $translate, $q, projectValuationGridKpiSettingsService, $filter, $scope) {
    var vm = this;
    //#region variables

    vm.loaded = false;
    vm.showHighlightCategoryName = true;//TODO the picture in the task has only the value in the highlighted KPI (choose one implementation, or use this switch?)
    vm.kpiSettingsService = projectValuationGridKpiSettingsService;
    vm.showTeamMembers = false;
    vm.appLocalization = appLocalization;
    vm.showKpiSettings = false;
    vm.kpiData = null;
    //#endregion

    //#region function declaration
    vm.kpiEditButtonClicked = kpiEditButtonClicked;
    vm.getKpiValue = getKpiValue;
    vm.closeEditKpiSettings = closeEditKpiSettings;
    //#endregion

    //#region function implementation
    vm.$onInit = function () {
      vm.owner = vm.gridData.OwnerUserIDDescription;
      if (vm.owner === "") {
        vm.owner = vm.gridData.ModifiedByUserIdDescription;
      }
      if (!vm.owner) {
        vm.owner = $translate.instant('notAvailable');
      }
      vm.date = vm.gridData.ValuationDate;

      vm.areaTypeId = vm.gridData.JobTypeId || 1;//fallback for f.e. properties

      vm.showTeamMembers = (vm.area === 'job');

      loadData();
    };

    function loadData() {
      $q.all([
        projectValuationGridKpiSettingsService.loadData(vm.area, vm.gridData.Oid)
      ]).then(function (result) {
        vm.kpiData = result[0];
      });
    }

    $scope.$on('reloadKpi', function (event, item) {
      if (item.type === 'property' && item.oid === vm.gridData.Oid) {
        loadData();
      }
    });

    function getKpiValue(kpi) {
      var value;
      var amountSuffix = '';
      var unit = kpi.unit || '';

      if (vm.kpiData) {
        value = vm.kpiData[kpi.key];

        value = (value === '#DIV/0!' ? '' : value);//treat devision by zero as "not avaliable"(will be set to N/A)

        if (value) {
          if (unit === 'date') {
            value = $filter('date')(value, "dd.MM.yyyy");
            unit = '';
          }
          else if (unit === 'string') {
            //no format, keep value as is
            unit = '';
          }
          else if (!isNaN(parseFloat(value))) {
            if (value >= 1000000 || value <= -1000000) {
              value = $filter('number')(value / 1000000.0, 2);
              amountSuffix = 'MIO ';
            }
            else {
              value = $filter('number')(value, 0);
            }
          }
        }
        else {
          if (!(typeof (value) === 'number')) {//Bug 8493: 0 values shown as N/A  => leave numbers as 0
            value = 'N/A';
          }
        }
      }
      else {
        value = 'N/A';
      }

      if (value === 'N/A') {
        unit = '';
      }

      if ((unit === 'currency' && value !== 'N/A') || (unit === 'EUR' && value !== 'N/A')) {
        if (vm.kpiData.CurrencySymbol) {
          unit = '&#' + vm.kpiData.CurrencySymbol + ';';
        }
        else {
          unit = '€';
        }
      }

      if (value && value !== 'N/A') {
        //Task 8509: Changes to Property KPI
        /*
        WAULT: Show 1 decimal: Instead of 4 years, 4.4 years
        NRI per sqm: Show 2 decimals
        Average rent per sqm: Show 2 decimals
        Main use NRI per sqm: Show 2 decimals
        */
        let originalValue = vm.kpiData[kpi.key];
        switch (kpi.key) {
          case 'RrAnalysisWault':
            value = $filter('number')(originalValue, 1);
            break;
          case 'RrAnalysisNriPsqmTotal':
          case 'RrAnalysisAverageRentPerSqm':
          case 'RrAnalysisUsage1NriPsqm':
            value = $filter('number')(originalValue, 2);
            break;
          default:
            break;
        }
      }


      if (unit && unit !== 'date') {
        return value + ' ' + amountSuffix + unit;
      }
      return value;
    }

    function kpiEditButtonClicked() {
      vm.kpiData = { area: vm.area, oid: vm.gridData.Oid, areaTypeId: vm.areaTypeId };
      vm.showKpiSettings = true;
    }

    function closeEditKpiSettings() {
      vm.showKpiSettings = false;
    }

    //#endregion

    //#region events

    //#endregion
  }
}());

