(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:appUserResource
     * @description
     * Resource for loading the user data of the current user
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('appUserResource',
        ['$resource', 'appServiceHelper', appUserResource]);

    function appUserResource($resource, appServiceHelper) {
        return {

            /**
             * @ngdoc function
             * @name lockedWidgets
             * @methodOf DXSPolluxApp.service:appUserResource
             * @description
             * Gets the user data of the current user
             * 
             * Route: api/v2/user/current
             * 
             * @returns {Array} The user data for the currently logged in user
             */
            details: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'user/current'), {})
        }
    }
}());
