(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:appSettingsMain
   * @description
   * This is the main component for the Settings area. Here, all application configuration can be done.
   * 
   * Please note that Admin rights are required to see this area!
   * 
   * It divides the available area into 2 sections:
   * - Toolbar
   * - Workplace
   * 
   * Each of these areas is represented by a dedicated component again.
   * 
   * @requires appRouting
   * @requires DXSPolluxApp.service:appStorage
   * @requires $q
   * 
   * @param {Integer} userid The user id (not used)
   */
  angular.module("DXSPolluxApp")
    .component("appSettingsMain", {
      template: require('html-loader!./app.settings.main.tmpl.html'),
      bindings: {
        userid: "<"
      },
      controllerAs: "vm",
      controller: ['appRouting', 'appStorage', '$q', appSettingsMainCtrl]
    });

  function appSettingsMainCtrl(appRouting, appStorage, $q) {
    var vm = this;

    //#region variables
    vm.WorkPlaceOrientation = "horizontal";
    vm.ApplicationOrientation = "vertical";
    vm.show = true;

    vm.splitter = null;

    vm.splitterOptions = {
      panes: [
        { collapsible: false, resizable: true }],
      orientation: vm.WorkPlaceOrientation
    };
    //#endregion

    //#region function declaration
    vm.onKeyPressed = onKeyPressed;
    //#endregion

    //#region function implementation
    function onKeyPressed(e) {
      if (e.ctrlKey && e.altKey && e.keyCode === 66) { // STRG + ALT + B
        appRouting.navigate(["ProjectValuation"]);
      }
      else if (e.ctrlKey && e.altKey && e.keyCode === 82) { // STRG + ALT + R
        appRouting.navigate(["ReportDesigner"]);
      }
      else if (e.ctrlKey && e.altKey && e.keyCode === 68) { // STRG + ALT + D
        appRouting.navigate(["Comparables"]);
      }
      else if (e.ctrlKey && e.altKey && e.keyCode === 80) { // STRG + ALT + P
        appRouting.navigate(["ProjectMain"]);
      }
    }
    //#endregion

    vm.$onInit = function () {
      var module = document.getElementById('appSettingsMain');
      module.focus();
      vm.show = false;
      $q.all([
        appStorage.userIsInRoleAsync(['Admin'])
      ]).then(function (isInRoles) {
        if (isInRoles[0]) {
          vm.show = true;
        }
      });
    }
  }
}());
