import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';

import { PolluxMonthPickerField } from './extensions/fields/pollux-month-picker-field.component';

@NgModule({
  imports: [
    CommonModule,
    DatePickerModule,
  ],
  declarations: [
    PolluxMonthPickerField
  ],
  entryComponents: [
    PolluxMonthPickerField
  ],
  exports: [
    PolluxMonthPickerField
  ]
})
export class MainModule { }
