import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

import * as msalConfig from './app/config/msal.config';

if (environment.production) {
  enableProdMode();
}

// load all javascript files
declare const require: any;

const context = require.context('./ng1/app', true, /\.js$/);

context.keys().forEach((file: any) => {
  try {
    context(file);
  } catch (err) {
    console.log(err, file);
  }
});

function runBootstrap() {
  /* load google API with correct key for environment */
  const googleApiScript = document.createElement('script');
  googleApiScript.type = 'text/javascript';
  googleApiScript.src = environment.GOOGLE_MAPS_API_URL;
  document.head.appendChild(googleApiScript);

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (!environment.UseMsal) {
  runBootstrap();
} else {

  const isIframe = window !== window.parent && !window.opener;

  if (!isIframe) {

    // Create an instance of PublicClientApplication
    const msalInstance = msalConfig.MSALInstanceFactory();

    // Handle the redirect flows
    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        runBootstrap();
      })
      .catch((error) => {
        // Handle redirect error
      });

  } else {
    console.log("Running in iframe: skip bootstrap...");
  }
}
