(function () {

  "use strict";

  angular.module("DXSPolluxApp")
    .component("comparablesPropertyDialogWrapper", {
      template: require('html-loader!./comparables.property.dialog.wrapper.tmpl.html'),
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$timeout', 'reflistResource', '$q', comparablesPropertyDialogWrapperCtrl]
    });

  function comparablesPropertyDialogWrapperCtrl($rootScope, $scope, $timeout, reflistResource, $q) {
    var vm = this;

    function reset() {
      vm.oid = null;
      vm.dataItem = null;
      vm.propertyType = null;
    }

    reset();

    /* close handling*/
    $scope.$on("propertiesDataSaved", function (event, params) {
      reset();
    });

    $scope.$on("propertiesData.canceled", function (event, params) {
      reset();
    });

    $scope.$on("toggleAreaFilters", function (event, params) {
      params.forceClose && reset();
    });

    vm.close = function () {
      reset();
    };

    /* show handling */
    $scope.$on("editPropertiesData.inline", function (event, params) {
      if (params.data) {
        vm.oid = params.id;
        vm.dataItem = params.data;
        vm.mode = params.mode || 'detail';
        $q.all([
          reflistResource.loadPollux.query({ reflistName: 'ref_property_type_mask' }).$promise
        ]).then(function (result) {
          vm.choicesForPropertyTypeID = result[0];
          vm.propertyType = vm.choicesForPropertyTypeID.filter((entry) => { return entry.oid === vm.dataItem.propertyTypeIdMask; })[0].description;
        }
        )
      } else {
        alert("No data set!");
      }
    });


  }
}());
