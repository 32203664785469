import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { SynapsesDashboardMainComponent, SynapsesDashboardService } from 'qv-dashboard-lib/projects/qv-dashboard-lib/src/public_api';
import { ValuationPortfoliosList } from '../models/valuation-portofolio-list.model';
import { environment } from '../../../../../environments/environment';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MultiDashboardService } from '../services/multi-dashboard-service.service';
import { AngularIntegrationService } from '../../../../services/AngularIntegrationService';

@Component({
    selector: 'ct-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() job: ValuationPortfoliosList;
  @Input() classValue: string;
  @Input() hideRemove: boolean = false;
  @Input() gridsterScrollTop: number | undefined;
  @Input() displayAssumtionWidgets: boolean;
  @Input() sourceJob: ValuationPortfoliosList;
  @Output() gridsterScrollTopChange = new EventEmitter<number>();
  @Output() removeJobId = new EventEmitter<number>();

  propertyName: string = '';
  propertyAddress: string = '';
  jobName: string = '';
  isReadonly = true;
  private onEvents: Array<any> = [];
  
  private dashboardComponent: SynapsesDashboardMainComponent | undefined;
  @ViewChild('dashboardComponent', { static: false }) set content(content: SynapsesDashboardMainComponent) {
    if (content) {
      this.dashboardComponent = content;
    }
  }

  constructor(private dashboardService: SynapsesDashboardService, private http: HttpClient, private mds: MultiDashboardService, private ais: AngularIntegrationService) {
    this.dashboardService.setEnvironment(environment);
  }

  ngOnInit(): void {
    this.setupJobNames();
    this.isReadonly = this.job.Oid === this.sourceJob.Oid;
    this.setupEvents();
  }

  ngOnDestroy() {
    this.onEvents.forEach(unregister => {
      unregister();
    });
  }

  private setupJobNames() {
    if (this.job) {
      const parts = (this.job.LinkedPropertyAddress || '').split(',');
      this.propertyName = parts[0];
      if (parts.length > 1) {
          parts.shift();
          this.propertyAddress = parts.join(' |');
      }

      this.jobName = this.job.ValuationPortfolioName;
    }
  }

  updateJobName() {
    const self = this;
    const sourceJobId = this.sourceJob.Oid;
    const jobId = this.job.Oid;
    const jobName = encodeURIComponent(this.jobName);

    const actionUrl = `${environment.POLLUX_PROJECTVALUATION_API_URL}/playground-jobs/updateName?sourceJobId=${sourceJobId}&jobId=${jobId}&jobName=${jobName}`;

    self.http.post(actionUrl, {})
      .pipe(take(1))
      .subscribe(response => {
        const res: any = response;//job dto
        //update cached
       self.mds.syncJobName(jobId, self.jobName);
        //and reload grid
       self.ais.$rootScope.$broadcast("PortfolioLoadData", () => self.ais.$rootScope.$broadcast("activateDashboardAreaAfterCopy", this.sourceJob));
      },
        error => {
          //ignore
        }
      );
  }

  remove() {
    this.removeJobId.emit(this.job.Oid);
  }

  onScrollTop(scrollTop: number) {
    this.gridsterScrollTopChange.emit(scrollTop);
  }

  navigateToJob(jobId: number) {
    const self = this;
    const job = self.sourceJob.Oid === jobId ? self.sourceJob : self.mds.getJob(jobId);
    if (job) {
      const $rootScope = this.ais.$rootScope;
      $rootScope.$broadcast('showDashboard', {
        canGoForward: false,
        canGoBack: false,
        job: job
      });
    }
  }

  private setupEvents() {
    if (!this.isReadonly) {
      const self = this;
      const $rootScope = this.ais.$rootScope;

      let unregisterSaveDashboard: any = $rootScope.$on('dashboardSaveClicked', function (event, params) {
        if (self.displayAssumtionWidgets && self.dashboardComponent) {
          self.dashboardComponent.saveData();
        }
        
      });

      self.onEvents.push(unregisterSaveDashboard);
    }
  }
}
