import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { QvPropertyImagesSliderComponent } from './qv-property-images-slider.component';
import { TranslationModule } from 'src/app/services/translation/translation.module';
import { QvPropertyImageManagementModule } from '../qv-property-image-management/qv-property-image-management.module';

export const routerModule = RouterModule.forChild([{
  path: '',
  component: QvPropertyImagesSliderComponent
}]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    routerModule,
    TranslationModule,
    ScrollViewModule,
    UploadsModule,
    QvPropertyImageManagementModule
  ],
  declarations: [
    QvPropertyImagesSliderComponent
  ],
  exports: [
    QvPropertyImagesSliderComponent
  ],
  entryComponents: [
    QvPropertyImagesSliderComponent
  ]
})
export class QvPropertyImagesSliderModule { }
