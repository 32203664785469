
(function () {
  'use strict';

  /*
      *****COMPARABLES PROPERTIES
      //required on component declaration
      this.angularModule = 'DXSPolluxApp';
      this.componentName = 'comparablesPropertyDialog';//'PropertiesDialog';
      this.runtimeComponentName = 'comparablesPropertyDialogRuntime';
      this.templateUrl = 'modules/comparables/components/dialog/comparables.property.dialog.tmpl.html';
      this.entityResourceName = 'PropertiesResource';
      
      //used in component function(comparablesPropertyDialogCtrl)
      this.entityName = 'Properties';
      this.editcomparablesPropertyDialogBroadcastMessage = 'editPropertiesData';
      this.newcomparablesPropertyDialogBroadcastMessage = 'createPropertiesData';

      *****VALUATION PROPERTIES
      //required on component declaration
      this.angularModule = 'DXSPolluxApp';
      this.componentName = 'projectValuationPropertyDialog';//'PropertiesDialog';
      this.runtimeComponentName = 'projectValuationPropertyDialogRuntime';
      this.templateUrl = 'modules/project.valuation/components/dialog/project.valuation.property.dialog.tmpl.html';
      this.entityResourceName = 'PropertiesResource';
      
      //used in component function(comparablesPropertyDialogCtrl)
      this.entityName = 'Properties';
      this.editcomparablesPropertyDialogBroadcastMessage = 'editPropertiesData';
      this.newcomparablesPropertyDialogBroadcastMessage = 'createPropertiesData';
  */

  function getConfig() {
    //required on component declaration
    this.angularModule = 'DXSPolluxApp';
    this.componentName = 'comparablesPropertyDialog';//'PropertiesDialog';
    this.runtimeComponentName = 'comparablesPropertyDialogRuntime';
    this.templateUrl = 'modules/comparables/components/dialog/comparables.property.dialog.tmpl.html';
    this.entityResourceName = 'PropertiesResource';

    //used in component function(comparablesPropertyDialogCtrl)
    this.entityName = 'Properties';
    this.editcomparablesPropertyDialogBroadcastMessage = 'editPropertiesData';
    this.newcomparablesPropertyDialogBroadcastMessage = 'createPropertiesData';
    this.comparablesPropertyDialogBroadcastSavedMessage = 'propertiesDataSaved';
    this.comparablesPropertyDialogBroadcastCanceledMessage = 'propertiesData.canceled';
    this.savecomparablesPropertyBroadcastMessage = 'savePropertiesData';
    this.editcomparablesPropertyBroadcastMessage = 'editPropertyData';
    this.editcomparablesPropertyBroadcastMessageFromMask = 'editPropertyDataFromMask'

    this.getDialogTitle = function (vm, $translate) {
      return $translate.instant('formIconLabel.property');
    };

    this.resetSelectFields = function (vm) {
      vm.choicesForprovince = [];
      vm.choicesForsubLocation = [];
      vm.choicesForCountryID = [];
      vm.choicesForPropertyTypeID = [];
      vm.choicesForassetClass = [];
      vm.choicesForenergyPerformanceLabel = [];
      vm.choicesForenergyCertificate = [];
      vm.choicesFortenure = [];
      vm.choicesForcoordinatesQuality = [];
    };
    this.refListsToLoad = ['ref_l2_property_province', 'ref_lease_transaction_sublocation', 'ref_valuation_country', 'ref_property_type_mask', 'ref_l2_assetclass',
      'ref_lease_transaction_building_energy', 'ref_l2_energycertificate', 'ref_l2_tenure', 'ref_statuskoordinate'];

    //////////////////////////////////////////////////////////////////////
    //SOME DEV HELPERS (TODO: remove after testing)
    //////////////////////////////////////////////////////////////////////
    this.fakeResource = function (resource) {
      if (!resource.load && resource.details && resource.details.get) {
        resource.load = function (oid) {
          return resource.details.get({ oid: oid }).$promise;
        };
      }
    };

    return this;
  }

  /****************************************************
  ****************************************************
  
  CODE BELOW SHOULD NOT BE MODIFIED (IN THEORY)
  
  ****************************************************
  ****************************************************/

  var CONFIG = new getConfig();

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:comparablesPropertyDialog
   * @description
   * Dialog that allows user to edit Entity data
   * 
   * @requires $scope
   * @requires $q
   * @requires $timeout
   * @requires $translate
   * @requires DXSPolluxApp.service:reflistResource
   * @requires DXSPolluxApp.service:EntityResource
   */
  angular.module(CONFIG.angularModule)
    .component(CONFIG.componentName, {
      templateUrl: CONFIG.templateUrl,
      bindings: {
        "oid": "=",
        "isDialog": "="
      },
      controllerAs: 'vm',
      controller: ['$scope', '$q', '$timeout', '$translate', 'reflistResource', CONFIG.entityResourceName, CONFIG.runtimeComponentName, '$rootScope', 'projectValuationPropertyResource', 'windowHelper', '$element', comparablesPropertyDialogCtrl]
    });

  function comparablesPropertyDialogCtrl($scope, $q, $timeout, $translate, reflistResource, EntityResource, runtimeComponent, $rootScope, projectValuationPropertyResource, windowHelper, $element) {
    var vm = this;
    CONFIG.fakeResource && CONFIG.fakeResource(EntityResource);

    var RUNTIME = runtimeComponent;

    //#region variable declaration
    vm.window = null;
    vm.width = 1700;
    vm.minWidth = 425;
    vm.height = 900;
    vm.minHeight = 200;
    vm.displayLocationCategory = true;
    vm.displayValPortfolioCategory = true;
    vm.displayProjectCategory = true;
    vm.displayPropertyDescriptionCategory = true;
    vm.displayPropertyCategory = true;
    vm.pictureData = null;
    vm.dialogTitle = CONFIG.getDialogTitle(vm, $translate);
    vm.slideIndex = 1;
    vm.dataLoaded = false;
    vm.dataLoadedTimeout = false;
    vm.countStreet = 1;
    vm.editableFieldsShown = false;

    vm.tabOptions = {
      tabPosition: "top",
      scrollable: false,
      animation: {
        open: {
          effects: "fadeIn"
        }
      }
    };

    vm.windowOptions = {
      animation: false
    };

    vm.rentrollsDates = [];

    //Dropdown possibilities should be declared in the vm
    //Remark: this is done in CONFIG.resetSelectFields, so probably nothing to do

    function resetComponent() {
      vm.formInvalid = true;
      vm.suppressFormInvalidMessage = true;
      vm.isNewMode = false;
      vm.dataLoaded = false;
      vm.dataLoadedTimeout = false;
      vm.formReadOnly = false;
      vm.showWindow = false;
      vm.data = null;
      vm.displayLocationCategory = true;
      vm.displayValPortfolioCategory = true;
      vm.displayProjectCategory = true;
      vm.displayPropertyDescriptionCategory = true;
      vm.displayPropertyCategory = true;
      vm.pictureData = null;
      vm.width = 1700;
      vm.slideIndex = 1;
      vm.countStreet = 1;
      vm.editableFieldsShown = false;

      CONFIG.resetSelectFields && CONFIG.resetSelectFields(vm);
    }

    //init and reset
    resetComponent();

    //#endregion

    //#region function declaration
    vm.initWindow = initWindow;
    vm.loadData = loadData;
    vm.onClose = onClose;
    vm.cancel = cancel;
    vm.save = save;
    vm.displayLocation = displayLocation;
    vm.displayValPortfolio = displayValPortfolio;
    vm.displayProject = displayProject;
    vm.displayPropertyDescription = displayPropertyDescription;
    vm.displayProperty = displayProperty;
    vm.slidePicture = slidePicture;
    vm.addStreet = addStreet;
    vm.showEditableFields = showEditableFields;
    //vm.getWidthSectionFirst = getWidthSectionFirst;
    //#endregion

    //#region function implementation

    function showProgress(show) {
      if (vm.window && vm.window.element) {
        kendo.ui.progress(vm.window.element, show);
      } else {
        kendo.ui.progress($element, show);
      }
    }

    /**
     * @ngdoc function
     * @name initWindow
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Initializes the Kendo UI window that hosts this dialog.
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function initWindow() {
      var deferred = $q.defer();
      $timeout(function () { //Potentially executed from outside of the Angular context
        resetComponent();
        $timeout(function () { //Timeout to destroy the window
          //if ($(document).height() < vm.height) {
          //  vm.height = Math.max(vm.minHeight, $(document).height() - 50);
          //}

          //if ($(document).width() < vm.width) {
          //  vm.width = Math.max(vm.minWidth, $(document).width() - 50);
          //}

          vm.width = 1700;

          vm.window && vm.window.setOptions({ width: vm.width, height: vm.height });

          //Show the window
          vm.showWindow = true;
          $timeout(function () { //Timeout to create the window
            vm.window && windowHelper.open(vm.window, 'generatedHTMLWindow');
            deferred.resolve();
          });
        });
      });
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name loadReflists
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Loads all ref lists that are required in this dialog.
     * @returns {Array<Promise>} An array of promises pointing to the ref list loading operations. If no ref lists are required, please create a dummy promise, resolve it and return an array containing only this promise.
     */
    function loadReflists() {
      //Always return a list of promises.
      var promises = [];

      if (CONFIG.refListsToLoad.length === 0) {
        // If no reflists are required, create a blank promise, resolve it and add it to the list
        var deferred = $q.defer();
        deferred.resolve([]);
        promises.push(deferred.promise);
      }
      else {
        for (var idx = 0, len = CONFIG.refListsToLoad.length; idx < len; idx++) {
          var refListName = CONFIG.refListsToLoad[idx];
          promises.push(reflistResource.loadPollux.query({ reflistName: refListName }).$promise);//TODO: New service!
        }
      }
      return promises;
    }

    /**
     * @ngdoc function
     * @name processReflistResults
     * @param { Array<Array<any>> } result The results for the requests sent in loadReflists (and also in this order).
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description Processes all ref list results (used for std/mstd lists). Leave this method empty if no ref lists are needed.
     */
    function processReflistResults(result) {
      vm.choicesForprovince = result[0];
      vm.choicesForsubLocation = result[1];

      //move germany value to the first place
      result[2].sort(function (a, b) {
        if (a['description'] < b['description'])
          return -1;
        if (a['description'] > b['description'])
          return 1;
        return 0;
      });

      var germanyIndex = null;

      for (let index = 0; index < result[2].length; ++index) {
        const element = result[2][index];
        if (element['oid'] === 1) { // germany oid
          germanyIndex = index;
          break;
        }
      }
      let germanyValue = result[2].splice(germanyIndex, 1);
      result[2].unshift(germanyValue[0]);

      vm.choicesForCountryID = result[2];
      vm.choicesForPropertyTypeID = result[3];
      vm.choicesForassetClass = result[4];
      vm.choicesForenergyPerformanceLabel = result[5];
      vm.choicesForenergyCertificate = result[6];
      vm.choicesFortenure = result[7];
      vm.choicesForcoordinatesQuality = result[8];
    }

    /**
     * @ngdoc function
     * @name postProcessData
     * @param { any } data The data
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Post-processes the data stored in vm.data.
     * Typically, this is about performing some initial calculations and also applying data from the parent element if the dialog is in new mode (vm.isNewMode === true)
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function postProcessData(data, parent) {
      showProgress(true);
      var deferred = $q.defer();

      RUNTIME.postProcessData(deferred, { vm: vm, data: data, parent: parent });

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name postProcessDurabilityData
     * @param { any } data The data
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Post-processes the data stored in vm.data.
     * Typically, this is about performing some initial calculations and also applying data from the parent element if the dialog is in new mode (vm.isNewMode === true)
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function postProcessDurabilityData(data, parent) {
      showProgress(true);
      var deferred = $q.defer();

      deferred.resolve(null);
      //RUNTIME.postProcessDurabilityData(deferred, { vm: vm, data: data, parent: parent });

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name preProcessDataBeforeSave
     * @param { any } data The data
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Pre-processes the data stored in vm.data before saving.
     * Typically, this is about performing some initial calculations and also applying data from the parent element if the dialog is in new mode (vm.isNewMode === true)
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function preProcessDataBeforeSave(data, parent) {
      showProgress(true);
      var deferred = $q.defer();

      RUNTIME.preProcessDataBeforeSave(deferred, { vm: vm, data: data, parent: parent });

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name preProcessDurabilityDataBeforeSave
     * @param { any } data The data
     * @param { any } parent The parent element to fill data from in new mode
     * @param { number } propertyId The property OID
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Pre-processes the data stored in vm.data before saving.
     * Typically, this is about performing some initial calculations and also applying data from the parent element if the dialog is in new mode (vm.isNewMode === true)
     * @returns {Promise} A promise that is resolved once all async operations are completed. If there aren't any async operations please, resolve the default promise immediately.
     */
    function preProcessDurabilityDataBeforeSave(data, parent, propertyId) {
      showProgress(true);
      var deferred = $q.defer();

      deferred.resolve(null);
      //RUNTIME.preProcessDurabilityDataBeforeSave(deferred, { vm: vm, data: data, parent: parent, propertyId: propertyId });

      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name loadData
     * @param { number } oid The oid of the element or -1 for new mode
     * @param { any } parent The parent element to fill data from in new mode
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description Processes all ref list results (used for std/mstd lists). Leave this method empty if no ref lists are needed.
     * @returns {Promise} A promise that is resolved once all async (loading) operations are completed.
     */
    function loadData(oid, parent) {
      showProgress(true);

      //TODO: New service
      //vm.reflistOptions = {
      //    dataTextField: (item == 'en-US' || item == 'en_US') ? 'description_english' : 'description_german',
      //    dataValueField: 'oid'
      //};

      vm.isNewMode = oid <= 0;

      var deferred = $q.defer();
      $q.all(loadReflists())
        .then(function (reflistResult) {
          //Ref lists loading has finished
          processReflistResults(reflistResult);

          return EntityResource.load(oid);
        }).then(function (dataResult) {
          //Data loading has finished
          vm.data = dataResult;
          return postProcessData(vm.data, parent);
        }).then(function () {
          var deferred = $q.defer();

          deferred.resolve(null);

          return deferred.promise;
        }).then(function (dataResult) {
          //Data loading has finished
          vm.durabilityData = dataResult;

          return postProcessDurabilityData(vm.durabilityData, parent);
        }).then(function () {
          //Post-processing has finished
          projectValuationPropertyResource.getpicturesV2.get({ propertyId: vm.data.Oid, width: 300, height: 200 }, function (result) {
            if (result || result.length) {
              vm.pictureData = null;
              if (result.length >= 1) {
                vm.pictureData = result.filter(function (picture) {
                  return picture.Type !== 1; // all but cover image
                });
                var coverImage = result.filter(function (picture) {
                  return picture.Type === 1; // cover image
                });
                if (coverImage.length > 0) {
                  vm.pictureData.unshift(coverImage[0]);
                }
              }
              else {
                vm.pictureData = result;
              }
            }
            else {
              vm.pictureData = null;
            }

            calculateStreetNumber();

            vm.dataLoaded = true;

            $timeout(function () {
              showProgress(false);
              vm.dataLoadedTimeout = true;

              deferred.resolve(); //All done!
            }, 100); //Give the components some time to render and prepare them in the background to prevent the UI from displaying incomplete markup       
          }, function (error) {
            alert("Error when loading pictures!");
          });

        }).catch(function (error) {
          showProgress(false);
          deferred.reject(error);
        });
      return deferred.promise;
    }

    /**
     * @ngdoc function
     * @name onClose
     * @param { Event } e Optional event that triggered the close action. Set it to null/undefined to close the window programmatically. 
     * If it is not null and user triggered, the user is asked if he really wants to close the dialog before it's actually closed (or left open if the user chose not to close the dialog).
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description Closes the dialog. If the event is not null and user triggered, the user is asked if he really wants to close the dialog before it's actually closed (or left open if the user chose not to close the dialog).
     */
    function onClose(e) {
      $timeout(function () { //Timeout to destroy the window
        resetComponent();
        vm.window && vm.window.close();
      });
    }

    /**
     * @ngdoc function
     * @name save
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description Saves the data.
     */
    function save() {
      showProgress(true);

      preProcessDataBeforeSave(vm.data, parent)
        .then(function () {
          if (vm.formInvalid) {
            showProgress(false);
            return $q.reject('The form contains invalid data');
          } else {
            return EntityResource.save(vm.data);
          }
        }).then(function (result) {
          showProgress(false);
          if (result && result.m_Item1) {
            preProcessDurabilityDataBeforeSave(vm.durabilityData, parent, result.m_Item2)
              .then(function () {
                if (vm.formInvalid) {
                  showProgress(false);
                  return $q.reject('The form contains invalid data');
                } else {
                  return $q.resolve(result);
                }
              }).then(function (result) {
                $timeout(function () {
                  showProgress(false);
                  onClose();
                  $rootScope.$broadcast(CONFIG.comparablesPropertyDialogBroadcastSavedMessage, result);
                }, 100); //Give the components some time to render and prepare them in the background to prevent the UI from displaying incomplete markup
              });
          }
          else if (result && !result.m_Item1) {
            alert(result.m_Item2);
          }
          else {
            alert('Unspecified error saving ' + CONFIG.entityName + ' data!');
          }
        }).catch(function (error) {
          showProgress(false);

          if (error === 'The form contains invalid data') {
            if (!vm.suppressFormInvalidMessage) {
              $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'formInvalid', 'simpleMessage.titleSave');
            }
          } else {
            var msg = error.message || JSON.stringify(error);

            console.error(msg);
            alert(msg);
          }
        });
    }

    /**
     * @ngdoc function
     * @name cancel
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description Closes the dialog if the user clicked the 'close' button. The user is asked if he really wants to close the dialog before it's actually closed (or left open if the user chose not to close the dialog).
     */
    function cancel() {
      $rootScope.$broadcast("openConfirmDialog", {
        success: function () {
          save();
        },
        cancel: function () {
          $timeout(function () { //Timeout to destroy the window
            resetComponent();
            vm.window && vm.window.close();
            $rootScope.$broadcast(CONFIG.comparablesPropertyDialogBroadcastCanceledMessage);
          });
        }
      }, 'confirmDialogForm');
    }


    /**
    * @ngdoc function
    * @name slidePicture
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Slides the property picture.
    * @param {number} n The index
    */
    function slidePicture(n) {
      showOrHidePicture(vm.slideIndex += n);
    }

    /**
    * @ngdoc function
    * @name showEditableFields
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Show editable feilds.
    */
    function showEditableFields() {
      vm.editableFieldsShown = true;

      $rootScope.$broadcast(CONFIG.editcomparablesPropertyBroadcastMessageFromMask, vm.editableFieldsShown);
    }

    /**
    * @ngdoc function
    * @name showOrHidePicture
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Shows or hides the property picture.
    * @param {number} n The index
    */
    function showOrHidePicture(n) {
      var i;
      var x = document.getElementsByClassName("pictureSlides");
      if (n > x.length) {
        vm.slideIndex = 1;
      }
      if (n < 1) {
        vm.slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      x[vm.slideIndex - 1].style.display = "block";
    }

    /**
    * @ngdoc function
    * @name calculateDialogSize
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Calculates Dialog Size using vm properties
    * @returns {number} Calculated {width} of Dialog
    */
    function calculateDialogSize() {
      var width = 1700;
      var areasSum = vm.displayPropertyCategory + vm.displayPropertyDescriptionCategory + vm.displayLocationCategory;

      if (areasSum === 3) {
        width = 1700;
      }
      else if (areasSum === 2) {
        width = 1300;
      }
      else if (areasSum === 1) {
        width = 1200;
      }
      else {
        width = 900;
      }

      return { width: width };
    }

    /**
    * @ngdoc function
    * @name displayProperty
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Shows or hides the property category.
    */
    function displayProperty() {
      vm.displayPropertyCategory = !vm.displayPropertyCategory;
      vm.window && windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
     * @ngdoc function
     * @name displayLocation
     * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description Shows or hides the location category.
     */
    function displayLocation() {
      vm.displayLocationCategory = !vm.displayLocationCategory;
      vm.window && windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
    * @ngdoc function
    * @name displayValPortfolio
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Shows or hides the valuation portfolio category.
    */
    function displayValPortfolio() {
      vm.displayValPortfolioCategory = !vm.displayValPortfolioCategory;
      vm.window && windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
    * @ngdoc function
    * @name displayProject
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Shows or hides the project category.
    */
    function displayProject() {
      vm.displayProjectCategory = !vm.displayProjectCategory;
      vm.window && windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
    * @ngdoc function
    * @name displayPropertyDescription
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Shows or hides the property description category.
    */
    function displayPropertyDescription() {
      vm.displayPropertyDescriptionCategory = !vm.displayPropertyDescriptionCategory;
      vm.window && windowHelper.center(vm.window, calculateDialogSize());
    }

    /**
    * @ngdoc function
    * @name addStreet
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Adds street.
    */
    function addStreet() {
      if (vm.countStreet === 4) {
        return;
      }
      vm.countStreet += 1;
    }


    /**
    * @ngdoc function
    * @name calculateStreetNumber
    * @methodOf DXSPolluxApp.component:comparablesPropertyDialog
    * @description Calculates street number.
    */
    function calculateStreetNumber() {
      if (vm.data.Street2 !== "" || vm.data.StreetNo2 !== "") {
        vm.countStreet = 2;
      }
      else if (vm.data.Street3 !== "" || vm.data.StreetNo3 !== "") {
        vm.countStreet = 3;
      }
      else if (vm.data.Street4 !== "" || vm.data.StreetNo4 !== "") {
        vm.countStreet = 4;
      }
      else {
        vm.countStreet = 1;
      }
    }


    //#endregion

    //#region events
    vm.$onInit = function () {
      CONFIG.init && CONFIG.init();
      vm.init && vm.init();
      vm.RUNTIME = RUNTIME;

      if (!vm.isDialog && vm.oid) {
        vm.height = 900;
        vm.loadData(vm.oid, null);
      }
    };

    /**
     * @ngdoc event
     * @name editEntityData
     * @eventOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Use this event to open the DXSPolluxApp.component:comparablesPropertyDialog dialog in edit mode.
     * 
     * @param {Object} event The event - not used
     * @param {number} oid The oid of the Entity that shall be opened
     */
    $scope.$on(CONFIG.editcomparablesPropertyDialogBroadcastMessage, function (event, oid) {
      if (!vm.isDialog) return;

      vm.initWindow().then(function () {
        return vm.loadData(oid, null);
      }).catch(function (error) {
        onClose();
        var msg = error.message || JSON.stringify(error);

        console.error(msg);
        alert(msg);
      });
    });

    /**
     * @ngdoc event
     * @name newEntityData
     * @eventOf DXSPolluxApp.component:comparablesPropertyDialog
     * @description
     * Use this event to open the DXSPolluxApp.component:comparablesPropertyDialog dialog in new mode.
     * 
     * @param {Event} event The event - not used
     * @param {any} parent The parent element to fill data from in new mode
     */
    $scope.$on(CONFIG.newcomparablesPropertyDialogBroadcastMessage, function (event, parent) {
      if (!vm.isDialog) return;

      vm.initWindow().then(function () {
        return vm.loadData(-1, parent);//Load the zero entry DTO from the service and adapt it in the dialog. Pass any parent information to loadData so it can be processed there.
      }).catch(function (error) {
        onClose();
        var msg = error.message || JSON.stringify(error);

        console.error(msg);
        alert(msg);
      });
    });

    /**
 * @ngdoc event
 * @name savetEntityData
 * @eventOf DXSPolluxApp.component:comparablesPropertyDialog
 * @description
 * Use this event to save the DXSPolluxApp.component:comparablesPropertyDialog 
 */
    $scope.$on(CONFIG.savecomparablesPropertyBroadcastMessage, function (event) {
      if (vm.isDialog) return;//dialog (saving new properties, calls directly save() - it has "Save" button in dialog)

      save();
    });

    $scope.$on(CONFIG.editcomparablesPropertyBroadcastMessage, function (event) {
      vm.editableFieldsShown = true;
    });

    //#endregion
  }
}());
