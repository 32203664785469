import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { take } from 'rxjs/operators';
import { AngularIntegrationService } from './AngularIntegrationService';
import { MessageId, SourceAreaId, AppVisible } from './NotificationsEnums';



@Injectable()
export class NotificationsService {

  constructor(private http: HttpClient, private ais: AngularIntegrationService) { }

  public notifySyncronizationChange(request: any) {
    /*DTO schema for request: {
        jobId: vm.jobId,
        state: syncronizationOn
      }*/
    const notificationDto = {
      oid: 0,
      projectId: this.ais.appStorage.getSelectedProject().Oid,
      sourceAreaId: SourceAreaId.QV_JOB,
      sourceObjectId: request.jobId,
      messageId: request.state ? MessageId.SYNCRONIZATION_ON : MessageId.SYNCRONIZATION_OFF,
      important: 1,
      visibleToApp: AppVisible.ALL
    }

    this.insertProjectNotification(notificationDto)
      .then((lastInsertedNotification) => {
        //TODO
      }).catch((e) => {
        //TODO
      });
  }

  public notifyPublishChange(request: any) {
    /*DTO schema for request: {
        jobId: vm.jobId,
        state: publishOn
      }*/
    const notificationDto = {
      oid: 0,
      projectId: this.ais.appStorage.getSelectedProject().Oid,
      sourceAreaId: SourceAreaId.QV_JOB,
      sourceObjectId: request.jobId,
      messageId: request.state ? MessageId.JOB_PUBLISHED : MessageId.JOB_UNPUBLISHED,
      important: 1,
      visibleToApp: AppVisible.ALL
    }

    this.insertProjectNotification(notificationDto)
      .then((lastInsertedNotification) => {
        //TODO
      }).catch((e) => {
        //TODO
      });
  }

  public notifyJobAssumptionsChange(request: any) {
    /*DTO schema for request: {
        jobId: vm.jobId,
        messageId: 301 - 399 (see MessageId enum)
      }*/
    const notificationDto = {
      oid: 0,
      projectId: this.ais.appStorage.getSelectedProject().Oid,
      sourceAreaId: SourceAreaId.QV_JOB,
      sourceObjectId: request.jobId,
      messageId: request.messageId,
      important: 0,
      visibleToApp: AppVisible.ALL
    }

    this.insertProjectNotification(notificationDto)
      .then((lastInsertedNotification) => {
        //TODO
      }).catch((e) => {
        //TODO
      });
  }

  private insertProjectNotification(notificationDto) {
    const self = this;

    return new Promise<any>((resolve, reject) => {
      const actionUrl = `${environment.DMS_NOTIFICATION_API_URL}/Notification/insert`;

      /*DTO schema: {
        "oid": 0,
        //"senderId": 0,
        "receiverId": 0,
        "projectId": 0,
        //"createdAt": "2021-10-04T10:21:15.100Z",
        //"readAt": "2021-10-04T10:21:15.100Z",
        "important": 0,
        "sourceAreaId": 0,
        "messageId": 0,
        "messageCustom": "some message",
        "parentNotificationId": 0,
        "sourceObjectId": 0,
        "visibleToApp": 0
      }*/

      const postObj = JSON.parse(JSON.stringify(notificationDto));
      postObj.oid = 0;

      self.http.post(actionUrl, postObj)
        .pipe(take(1))
        .subscribe(response => {
          const res: any = response;
          if (res.success) {
            resolve(res);
          } else {
            reject(res);
          }
        },
          error => {
            reject(error);
          }
        );
    });
  }

}
