(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxSingleSelectField
   * @description
   * This component provides the behavior and visual representation of a single select field. It is rendered as a Kendo UI kendo-drop-down-list.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appLocalization
   * @requires $timeout
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Array} choices An array of elements that can be selected
   * @param {String} valueFieldName The name of the value field. Must be present in <c>choices</c>.
   * @param {String} germanFieldName The name of the German display field. Must be present in <c>choices</c>.
   * @param {String} englishFieldName The name of the English display field. Must be present in <c>choices</c>.
   * @param {Object} item The object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {Function} filter Filter function. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, the function will be called on every change.
   * @param {Integer} index The row index of the current object that contains this field. Can be used for filtering in ng-repeat or gridlike structures. If <c>filter</c> is specified, this value will be passed as a parameter into the filter function.
   * @param {Bool} useComboBox If <c>true</c>, a Kendo UI kendo-combo-box is used instead of the kendo-drop-down-list. This allows the user to enter a filter string.
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxSingleSelectField", {
      template: require('html-loader!./pollux.single.select.field.tmpl.html'),
      bindings: {
        "choices": "=",
        "valueFieldName": "@",
        "fieldName": "@",
        "item": "=",
        "filter": "&",
        "index": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "useComboBox": '=',
        "ngId": "@",
        "orderBy": "@",
        "orderDescending": "<",
        "fixZero": "<",
        "templateStyle": "@",
        "noSort": "<"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxSingleSelectFieldCtrl]
    });

  function polluxSingleSelectFieldCtrl($timeout) {
    var vm = this;

    //#region variable declaration
    vm.initialCall = true;
    vm.displayModel = null;
    vm.selectDataSource = null;

    var changeSinceOpen = false;

    //#endregion

    //#region function declaration

    //#endregion

    //#region function implementation
    function onNgChange(onInitCall) {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.initialCall && vm.filter && vm.filter()) {
          vm.initialCall = false;
          return;
        }
        if (vm.choices) {
          var choice = vm.choices.filter(function (item) {
            return item.oid == vm.ngModel;
          });
          if (choice && choice.length) {
            vm.displayModel = choice[0].description;
          }
          else {
            vm.displayModel = '';
          }
        }
        else {
          vm.displayModel = '';
        }
        if (vm.ngChange) {
          vm.ngChange();
        }

        if (!onInitCall) {
          changeSinceOpen = true;
        }
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {
      vm.options = {
        dataTextField: vm.fieldName || "description",
        dataValueField: vm.valueFieldName || "oid",
        dataSource: vm.selectDataSource,
        valuePrimitive: true,
        enabled: !vm.ngReadonly,
        template: vm.templateStyle === "radioButton" ? //TODO check if the ids create unexpected behaviour (potential duplicates) //TODO use dataTextField dataValueField
          '<input type="checkbox" class="k-checkbox" ng-checked="vm.ngModel == #: oid #" id="dropdownCheckbox#: oid #"/><label class="k-checkbox-label" for="dropdownCheckbox#: oid#"></label>#:description#'
          : null,
        close: function () {
          if (!changeSinceOpen) return; //fix issue created by [#70168]

          var value = vm.element.value();
          value = parseInt(value);
          if (isNaN(value)) {
            value = 0;
          }

          changeSinceOpen = false;
          vm.ngModel = value;
        },
        open: function (e) {
          if (vm.filter) {
            var func = vm.filter();
            if (func) {
              var existingFilters = vm.selectDataSource.filter();
              var data = func(vm.item, vm.choices, vm.index);

              var filters = [];

              for (var i = 0; i < data.length; ++i) {
                filters.push({ field: vm.valueFieldName, operator: "eq", value: data[i][vm.valueFieldName] });
              }

              vm.selectDataSource.filter({
                logic: "or",
                filters: filters
              });

              if (existingFilters) {
                var existingFilterOids = existingFilters.filters.map(function (filter) {
                  return parseInt(filter.value);
                });

                var newFilterOids = data.map(function (item) {
                  return parseInt(item[vm.valueFieldName]);
                });

                var filtersUpdated = false;
                if (existingFilterOids.length != newFilterOids.length) {
                  //The lengths are different, so the filters must have changed
                  filtersUpdated = true;
                }
                else {
                  //Compare element-wise
                  existingFilterOids.sort();
                  newFilterOids.sort();
                  for (var i = 0; i < existingFilterOids.length; ++i) {
                    if (existingFilterOids[i] != newFilterOids[i]) {
                      filtersUpdated = true;
                      break;
                    }
                  }
                }

                if (filtersUpdated) {
                  //Call open again since the filters have just been changed
                  $timeout(function () {
                    vm.element.open();
                  });
                }
              }
              else {
                //Call open again since the filters have just been set
                $timeout(function () {
                  vm.element.open();
                });
              }
            }
          }
        },
        statusTrackHelper: 0,
        change: onNgChange
      };

      if (vm.choices) { //Potentially executed from outside of the Angular context          
        if (vm.choices instanceof Array) {
          if (vm.orderBy) {
            if (vm.orderDescending) {
              vm.choices.sort(function (a, b) {
                if (a[vm.orderBy] < b[vm.orderBy])
                  return 1;
                if (a[vm.orderBy] > b[vm.orderBy])
                  return -1;
                return 0;
              });
            }
            else {
              vm.choices.sort(function (a, b) {
                if (a[vm.orderBy] < b[vm.orderBy])
                  return -1;
                if (a[vm.orderBy] > b[vm.orderBy])
                  return 1;
                return 0;
              });
            }
          }
          else {
            if (vm.orderDescending) {
              vm.choices.sort(function (a, b) {
                if (a[vm.fieldName] < b[vm.fieldName])
                  return 1;
                if (a[vm.fieldName] > b[vm.fieldName])
                  return -1;
                return 0;
              });
            }
            else {
              if (!vm.noSort) {
                vm.choices.sort(function (a, b) {
                  if (a[vm.fieldName] < b[vm.fieldName])
                    return -1;
                  if (a[vm.fieldName] > b[vm.fieldName])
                    return 1;
                  return 0;
                });
              }
            }
          }

          if (vm.fixZero) {
            var from = -1;
            var to = 0;
            var zeroEntry = vm.choices.filter(function (element, index) {
              if (element[vm.valueFieldName] == 0) {
                from = index;
                return true;
              }
              return false;
            });
            if (zeroEntry && zeroEntry.length && from >= 0) {
              vm.choices.splice(to, 0, vm.choices.splice(from, 1)[0]);
            }
          }

          //Array - wrap it into a DataSource
          vm.selectDataSource = new kendo.data.DataSource({
            data: vm.choices
          });
        }
        else if (vm.choices instanceof kendo.data.DataSource) {
          //DataSource - use it directly
          vm.selectDataSource = vm.choices;
        }
      }
      else {
        //No data source - create a new one
        vm.selectDataSource = new kendo.data.DataSource({
          data: []
        });
        vm.ngReadonly = true; //if there is no data -> readonly (prevent a bug that happens when typing with empty datasource)
      }

      vm.options.enabled = !vm.ngReadonly;
      vm.options.dataSource = vm.selectDataSource;

      //[#70168] Text and list - edit mode: choose an entry and it displays another text for a short time
      //set initial value at start
      vm.options.value = vm.ngModel;

      onNgChange(true);
    }
    //#endregion
  }
}());

