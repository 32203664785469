(function() {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('ReportsResourceCustom',
            ['$resource', 'appServiceHelper', resourceCustom]);

    function resourceCustom($resource, appServiceHelper) {
        var api = "marvin.pollux.projectValuation.api";
        var bulkCreateResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/bulkCreate'));
        var creteNewReportVersionResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/createNewVersion'));
        var getValuationPortfolioPublishInfoResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/valuationPortfolioPublishInfo'));
        var filterResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/filter'));
        var calculationModelResource = $resource(appServiceHelper.getServiceEndpoint(api, 'Reports/calculationModel/getId'));

        function bulkCreate(valuationProjectId) {
            return bulkCreateResource.save({
                valuationProjectId: valuationProjectId || 0
            }, {
            }).$promise;
        }

        function creteNewReportVersion(reportId) {
            return creteNewReportVersionResource.save({
                reportId: reportId || 0
            }, {
            }).$promise;
        }

        function getValuationPortfolioPublishInfo(valuationProjectId) {
            return getValuationPortfolioPublishInfoResource.query({ valuationProjectId: valuationProjectId }).$promise;
        }

        function filter(valuationProjectId) {
            return filterResource.query({ valuationProjectId: valuationProjectId }).$promise;
        }

        function getReportCalculationModelId(reportId) {
            return calculationModelResource.get({ reportId: reportId }).$promise;
        }

        return {
            bulkCreate: bulkCreate,
            creteNewReportVersion: creteNewReportVersion,
            getValuationPortfolioPublishInfo: getValuationPortfolioPublishInfo,
            filter: filter,
            getReportCalculationModelId: getReportCalculationModelId
        };
    }
}());
