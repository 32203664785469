(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxResizingTextField
   * @description
   * This component provides the behavior and visual representation of a multi line text field that is automatically resized .
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {Bool} ngMaxLength Specifis the maximum amount of characters that can be entered. If the user enters more characters or pastes a larger string, the overflowing characters will be cut off.
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxResizingTextField", {
      template: require('html-loader!./pollux.resizing.text.field.tmpl.html'),
      bindings: {
        "ngMaxLength": "=",
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxResizingTextFieldCtrl]
    });

  function polluxResizingTextFieldCtrl($timeout) {
    var vm = this;
    vm.guid = '';

    vm.$onInit = function () {
      vm.guid = kendo.guid();

      $timeout(function () {
        vm.onNgChange();
      });
    };

    vm.onNgChange = function () {
      try {
        var element = angular.element('textarea.' + vm.guid);
        element.height('1px');
        var height = element[0].scrollHeight;
        element.height(height);
      } catch (e) {
        //Nothing
      }
      if (vm.ngChange) {
        vm.ngChange();
      }
    };
  }
}());

