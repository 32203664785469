(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:fileDownloadService
   * @description
   * This service is responsible for initiating and handling filedownloads.
   * @requires $timeout
   */
  angular
    .module('DXSPolluxApp')
    .factory('fileDownloadService',
      ['$timeout', '$http', '$rootScope', filedownloadService]);
  
  function filedownloadService($timeout, $http, $rootScope) {
    function initiateDownload(url, alternateFilename) {
      $http({
        method: 'GET',
        url: url,
        responseType: 'arraybuffer'
      }).then(function (response) {
        var headers = response.headers();

        var contentType = headers['content-type'];
        var filename = headers['content-disposition'];
        if (filename && filename.indexOf('filename=') >= 0) {
          filename = filename.substring(filename.indexOf('filename=') + 'filename='.length);
          filename = decodeURIComponent(filename.replace(/\+/g, '%20'));
        }
        if (!filename) {
          filename = alternateFilename || 'Export.xlsx';
        }
        filename = filename.split('"').join('');

        var blob = new Blob([response.data], { type: contentType });
        var element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(blob));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        setTimeout(function () {
          element.click();
          setTimeout(function () {
            document.body.removeChild(element);
            $rootScope.$broadcast('loadingIndicator', false);
          }, 100);
        }, 150);
        });

    }

    function initiateDownloadPost(url, payload) {
      $http({
        method: 'POST',
        url: url,
        data: payload,
        responseType: 'arraybuffer'
      }).then(function (response) {
        var headers = response.headers();

        var contentType = headers['content-type'];
        var filename = headers['content-disposition'];
        if (filename && filename.indexOf('filename=') >= 0) {
          filename = filename.substring(filename.indexOf('filename=') + 'filename='.length);
          filename = decodeURIComponent(filename.replace(/\+/g, '%20'));
        }
        if (!filename) {
          filename = 'Export.xlsx';
        }
        filename = filename.split('"').join('');

        var blob = new Blob([response.data], { type: contentType });
        var element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(blob));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        setTimeout(function () {
          element.click();
          setTimeout(function () {
            document.body.removeChild(element);
          }, 100);
        }, 150);
      });
    }

    function initiateMultipleDownloads(urls) {
      var length = urls.length;
      for (var i = 0; i < length; ++i) {
        var currentUrl = urls.pop();
        if (currentUrl) {
          window.open(currentUrl);
        }
      }
    }

    function saveBinaryData(blob, fileName) {
      try {
        ////downloading the file depends on the browser
        ////IE handles it differently than chrome/webkit
        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //  window.navigator.msSaveOrOpenBlob(blob, fileName);
        //} else {
        //  var objectUrl = URL.createObjectURL(blob);
        //  window.location = objectUrl;
        //}
          // Create an object URL for the blob object
          const url = URL.createObjectURL(blob);

          // Create a new anchor element
          const a = document.createElement('a');

          // Set the href and download attributes for the anchor element
          // You can optionally set other attributes like `title`, etc
          // Especially, if the anchor element will be attached to the DOM
          a.href = url;
          a.download = fileName;

          // Click handler that releases the object URL after the element has been clicked
          // This is required for one-off downloads of the blob content
          const clickHandler = function() {
            setTimeout(function() {
              URL.revokeObjectURL(url);
              a.removeEventListener('click', clickHandler);
            }, 150);
          };

          // Add the click event listener on the anchor element
          // Comment out this line if you don't want a one-off download of the blob content
          a.addEventListener('click', clickHandler, false);

          // Programmatically trigger a click on the anchor element
          // Useful if you want the download to happen automatically
          // Without attaching the anchor element to the DOM
          // Comment out this line if you don't want an automatic download of the blob content
          a.click();

          // Return the anchor element
          // Useful if you want a reference to the element
          // in order to attach it to the DOM or use it in some other way
          return a;
      } catch (exc) {
        console.log("Save Blob method failed with the following exception.");
        console.log(exc);
      }
    }

    return {
      initiateDownload: initiateDownload,
      initiateMultipleDownloads: initiateMultipleDownloads,
      initiateDownloadPost: initiateDownloadPost,
      saveBinaryData: saveBinaryData
    };
  }
}());
