/*
 * !!!!!!!!!!!!!!!!!!
 * 
 * WARNING: for now there is OLD projectValuationPortfolioResource under this new one (in this file)
 * 
 * keep it for now as it is used on few places !!!
 * 
 * 
 */

(function () {
    'use strict';
    angular
        .module('DXSPolluxApp')
        .factory('ValuationPortfoliosResource',
            ['$resource', 'appServiceHelper', 'ValuationPortfoliosResourceCustom', ValuationPortfoliosResource]);

    function ValuationPortfoliosResource($resource, appServiceHelper, customResource) {
        var api = "marvin.pollux.projectValuation.api";
        var baseResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios')),
            pagedResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/list/paged')),
            attachmentsResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/attachments')),
            canRemoveResource = $resource(appServiceHelper.getServiceEndpoint(api, 'ValuationPortfolios/canDelete'));

        function load(oid) {
            return baseResource.get({ oid: oid }).$promise;
        }

        function loadPagedList(take, skip, page, pageSize, filter) {
            return pagedResource.get({
                take: take || 50,
                skip: skip || 0,
                page: page || 1,
                pageSize: pageSize || 50,
                filter: filter || ""
            }).$promise;
        }


        function canRemove(oid) {
            return canRemoveResource.get({ oid: oid }).$promise;
        }

        function save(data) {
            return baseResource.save(data).$promise;
        }

        function remove(oid) {
            return baseResource.delete({ oid: oid }).$promise;
        }

        function loadAttachments(oid) {
            return attachmentsResource.query({ oid: oid }).$promise;
        }

        var obj = {
            itemResource: baseResource,
            pagedListResource: pagedResource,
            load: load,
            loadPagedList: loadPagedList,
            save: save,
            canRemove: canRemove,
            remove: remove,
            loadAttachments: loadAttachments
        };

        for (var prop in customResource) {
            obj[prop] = customResource[prop];
        }

        return obj;

    }
}());



(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationPortfolioResource
     * @description
     * This service is responsible for loading valuation portfolio data.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */
    angular
        .module('DXSPolluxApp')
        .factory('projectValuationPortfolioResource',
            ['$resource', 'appServiceHelper', projectValuationPortfolioResource]);

    function projectValuationPortfolioResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name load
             * @methodOf DXSPolluxApp.service:projectValuationPortfolioResource
             * @description
             * Loads all available valuation portfolio data.
             * 
             * <b>Warning</b> This can be a lot of data.
             * 
             * Route: api/v2/project/valuation/portfolio/details
             * 
             * @returns {Array} All available valuation portfolio data
             */
            load: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/details'), {}),

            /**
             * @ngdoc function
             * @name remove
             * @methodOf DXSPolluxApp.service:projectValuationPortfolioResource
             * @description
             * Deletes a valuation portfolio.
             * 
             * Route: api/v2/project/valuation/portfolio
             * 
             * @param {Integer} portfolioId The OID of the valuation portfolio entry that will be deleted
             */
            remove: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio'), { portfolioId: '@portfolioId' }),

            canRemove: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/canDelete'), { portfolioId: '@portfolioId' }),

            /**
             * @ngdoc function
             * @name attachments
             * @methodOf DXSPolluxApp.service:projectValuationPortfolioResource
             * @description
             * Gets a list of all file attachments that are attached to the given valuation portfolio
             * 
             * Route: api/v2/project/valuation/portfolio/attachments
             * 
             * @param {Integer} valuationPortfolioId The OID of the valuation portfolio entry that will be checked for attachments
             * 
             * @returns {Array} A list of all file attachments that are attached to the given valuation portfolio
             */
            attachments: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/portfolio/attachments'), { valuationPortfolioId: '@valuationPortfolioId' })
        }
    }
}());
