import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DqsValidation } from 'src/app/components/_dqs/dqs-validation/dqs-validation.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { GridModule } from '@progress/kendo-angular-grid';
import { SpreadSheetsModule } from 'src/app/components/spreadjs/gc.spread.sheets.angular'; //https://www.grapecity.com/forums/spread-sheets/angular-error-on-build-ple#helloyou-can-copy-the-angu_1

import { DqsValidationRulesChartComponent } from 'src/app/components/_dqs/dqs-validation-rules-chart/dqs-validation-rules-chart.component';
import { TranslationModule } from 'src/app/services/translation/translation.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';

import { RentrollExportPane } from 'src/app/components/_dqs/dqs-validation/rentroll-export-pane/rentroll-export-pane.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonsModule,
    TranslationModule,
    DropDownsModule,
    DatePickerModule,
    UploadModule,
    SpreadSheetsModule,
    GridModule
  ],
  declarations: [
    DqsValidation,
    DqsValidationRulesChartComponent,
    RentrollExportPane
  ],
  providers: [
  ],
  exports: [
    DqsValidation,
    DqsValidationRulesChartComponent,
    RentrollExportPane
  ],
  entryComponents: [
    DqsValidation,
    RentrollExportPane
  ]
})
export class DqsValidationModule { }
