(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationPropertyDialogResource
     * @description
     * This service is responsible for loading property data.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */

    angular
        .module('DXSPolluxApp')
        .factory('projectValuationPropertyDialogResource',
        ['$resource', 'appServiceHelper', projectValuationPropertyDialogResource]);

    function projectValuationPropertyDialogResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name details
             * @methodOf DXSPolluxApp.service:projectValuationPropertyDialogResource
             * @description
             * Loads property data.
             * 
             * Route: api/v2/project/valuation/property/details/single
             * 
             * @param {Integer} oid Property OID
             * 
             * @returns {Object} Property
             */
            details: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/property/details/single'), { oid: '@oid' })
        }
    }
}());
