(function () {
    'use strict';

    angular
        .module('DXSPolluxApp')
        .factory('adalAuthenticationService',
          ['appSettings', 'customMsalInterceptor', azureTokenService]);

    function azureTokenService(appSettings, customMsalInterceptor) {
      var isAzure = appSettings.environment.UseMsal;
      var scopes = appSettings.environment.AzureScopes;

      return {
        environment: appSettings.environment,
        isAzure: isAzure,
        getResourceForEndpoint: function (url) {
          return isAzure ? {} : null;
        },
        getCachedToken: function (resource) {
          //we need token sync here, so use last one acquired (cached)
          return customMsalInterceptor.cashedAccessToken;
        },
        getCachedTokenDeferred: function (dfd, resource) {
          const publicClientApplication = customMsalInterceptor.publicClientApplication;
          const accessTokenRequest = {
            scopes: scopes,
            account: publicClientApplication.getAllAccounts()[0]
          };

          publicClientApplication.acquireTokenSilent(accessTokenRequest).then(function (accessTokenResponse) {
            dfd.resolve(accessTokenResponse.accessToken);
          });
        },
        logout: function () {
          const publicClientApplication = customMsalInterceptor.publicClientApplication;
          publicClientApplication.logout();
        }
      }
    }
}());
