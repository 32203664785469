import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { AppRoutingService, PreRoutingDataLoadedResolver } from './app-routing.service';
import { AppAuthGuard } from './app.auth.guard';

export function loadChildrenUnauthorized() {
  const result = import('./modules/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule);
  return result;
}

export function loadChildrenPd() {
  const result = import('./modules/project-valuation/project-valuation.module').then(m => m.ProjectValuationModule);
  return result;
}

const msalGuardArray: Array<any> = environment.UseMsal ? [MsalGuard] : [];

const routes: Routes = [
  {
    //used for MSAL login redirect
    path: 'login',
    redirectTo: ''
  },
  {
    path: 'id_token',
    redirectTo: ''
  },
  {
    path: 'access_token',
    redirectTo: ''
  },
  {
    path: 'unauthorized',
    canActivate: [],
    loadChildren: loadChildrenUnauthorized
  },
  {
    path: 'pd',
    canActivate: msalGuardArray.concat([AppAuthGuard]),
    loadChildren: loadChildrenPd,
    resolve: {
      canRoute: PreRoutingDataLoadedResolver
    }
  },
  {
    path: '**',
    redirectTo: '/pd',
    pathMatch: 'full'
  }
];

export const routerModule = RouterModule.forRoot(routes, { enableTracing: false, useHash: true, relativeLinkResolution: 'legacy' });

@NgModule({
  imports: [routerModule], //Use enableTracing to enable router debugging
  exports: [RouterModule],
  declarations: [],
  providers: [
    AppRoutingService,
    PreRoutingDataLoadedResolver,
    AppAuthGuard
  ]
})
export class AppRoutingModule { }
