(function () {
  'use strict';
  angular
    .module('DXSPolluxApp')
    .factory('comparablesPropertyDialogRuntime',
      ['ValuationPortfoliosResource', runtime]);

  function runtime(ValuationPortfoliosResource) {
    this.postProcessData = function (deferred, params) {
      //params = { vm: vm, data: data, parent: parent }
      if (params.data.Oid) {
        ValuationPortfoliosResource.loadRentRollsDates(params.data.Oid).then(function (dates) {
          params.vm.rentrollsDates = dates.sort().reverse();
          params.data.RentRollDate = params.vm.rentrollsDates[0];
          deferred.resolve();
        });
      } else {
        params.vm.rentrollsDates = [];
        deferred.resolve();
      }
    };

    this.postProcessDurabilityData = function (deferred, params) {
      //params = { vm: vm, data: data, parent: parent }

      //TODO: Perform any post-processing operations, including async. Resolve the promise once they are done or immediately if no async operations are required.
      deferred.resolve();
    };

    this.preProcessDataBeforeSave = function (deferred, params) {
      //params = { vm: vm, data: data, parent: parent }
      var vm = params.vm, data = params.data, parent = params.parent;

      //TODO: Perform any pre-save-processing operations, including async. Resolve the promise once they are done or immediately if no async operations are required.

      //TODO: Ferform form validation & required fields check. Please use vm.formInvalid
      vm.formInvalid = false;
      vm.suppressFormInvalidMessage = false; //Use this variable to suppress the 'invalid' message if you wish to display your own messages

      deferred.resolve();
    };

    this.preProcessDurabilityDataBeforeSave = function (deferred, params) {
      //params = { vm: vm, data: data, parent: parent }
      var vm = params.vm, data = params.data, parent = params.parent;

      //TODO: Perform any pre-save-processing operations, including async. Resolve the promise once they are done or immediately if no async operations are required.
      params.data.Oid = params.propertyId;

      //TODO: Ferform form validation & required fields check. Please use vm.formInvalid
      vm.formInvalid = false;
      vm.suppressFormInvalidMessage = false; //Use this variable to suppress the 'invalid' message if you wish to display your own messages

      deferred.resolve();
    };

    return this;
  }
}());
