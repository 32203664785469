import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { SynapsesDqsService } from '../_services/synapses-dqs-service.service';
import { SynapsesDqsValidationResult } from '../_data/synapses-dqs-validation-result';
import * as GC from '@grapecity/spread-sheets';
import * as Excel from '@grapecity/spread-excelio';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AngularIntegrationService } from '../../../../services/AngularIntegrationService';

@Component({
  selector: 'synapses-dqs-v2-main',
  templateUrl: './synapses-dqs-main.component.html',
  styleUrls: ['./synapses-dqs-main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SynapsesDqsV2MainComponent implements OnInit, OnDestroy {
  public vm = this;

  public upload = false;
  public validationData: SynapsesDqsValidationResult = null;
  public importResult: SynapsesDqsValidationResult = null;

  private _businessObjectType: string;
  private _oid: number;

  private importInfo: any;
  private templateTypes: any[] = [];
  private selectedTemplateTypeId = 0;
  private importTemplate: any;
  public propertyPictures: any = [];
  public propertyPicture: any;

  public showMappingEditor = false;

  public pictureLoaded = false;

  public scrollviewWidth = '100%';
  public scrollviewHeight = '100%';

  get businessObjectType(): string {
    return this._businessObjectType;
  }
  @Input() data: any;
  @Input() set businessObjectType(value: string) {
    this._businessObjectType = value;
    this.reset();
  }

  get oid(): number {
    return this._oid;
  }
  @Input() set oid(value: number) {
    this._oid = value;
    this.reset();
  }

  @Output() close = new EventEmitter<boolean>();

  @ViewChild('container') container: ElementRef;

  constructor(private service: SynapsesDqsService, private ais: AngularIntegrationService, @Inject('appBusyIndicatorService') private busyIndicator: any, private http: HttpClient, @Inject('appSidebarActionsService') private sidebarActionsService: any) {
    GC.Spread.Sheets.LicenseKey = environment.SpreadJsLicenseKey;
    (Excel as any).LicenseKey = environment.SpreadJsLicenseKey; //See last post from https://www.grapecity.com/forums/spread-sheets/export-to-excel---license-
  }

  ngOnInit() {
    const self = this;

    const sidebarActions = [
      {
        imgUrl: '/assets/images/SidebarIcons/window-close-solid.svg', translationKey: 'dqs.close', callback: function () {
          self.doClose();
        }
      }
    ];
    self.sidebarActionsService.setActions(sidebarActions);
    self.busyIndicator.setVisible(true);
    self.service.getImportInfo(self.businessObjectType).then(data => { self.importInfo = data; });
    self.service.getTemplates(self.businessObjectType).then(data => {
      self.templateTypes = data;
      self.selectedTemplateTypeId = self.templateTypes.length > 0 ? self.templateTypes[0].id : 0;
      self.busyIndicator.setVisible(false);
    });
    setTimeout(() => {
      const width = this.container.nativeElement.offsetWidth;
      const height = this.container.nativeElement.offsetHeight;

      this.http
        .get(environment.POLLUX_PROJECTVALUATION_API_URL + '/picturesexport/pictures/dataurl?propertyId=' + this.oid + '&width=' + width + '&height=' + height).subscribe((data: any) => {
          if (data && data.length) {
            this.propertyPictures = data;
            this.propertyPicture = this.propertyPictures[0];
          } else {
            this.propertyPicture = { Data: '/assets/images/demoPropertyImage.jpg' };
          }
          setTimeout(() => {
            this.pictureLoaded = true;
          }, 50);
        });
    });
  }

  ngOnDestroy() {
    this.sidebarActionsService.clear();
  }

  private reset() {
    const self = this;

    self.busyIndicator.setVisible(true);
    self.showMappingEditor = false;
    self.upload = false;
    self.validationData = null;
    self.importResult = null;
    self.service.getTemplates(self.businessObjectType).then(data => {
      self.templateTypes = data;
      self.selectedTemplateTypeId = self.templateTypes.length > 0 ? self.templateTypes[0].id : 0;
      self.busyIndicator.setVisible(false);
    });
  }

  //#region Actions

  public directInput() {
    const self = this;
    if (!self.templateTypes) return;

    self.reset();
    self.importTemplate = self.templateTypes.find(t => t.description === "ISPINIT");
    self.service.setupBlankTemplate(self.businessObjectType).then((data) => {
      if (data) {
        self.validationData = new SynapsesDqsValidationResult();
        self.validationData.rawFile = data;
        self.ais.$rootScope.$broadcast("directInputActivated", { id: self.oid, data: self.data });
      } else {
        alert(`Template not available for ${self.businessObjectType}!`);
      }

    });
  }

  public downloadDefaultTemplate() {
    this.service.downloadDefaultTemplate(this.businessObjectType);
  }

  public uploadTemplate() {
    const self = this;

    self.upload = false;
    setTimeout(() => {
      self.upload = true;
    });
  }

  public map() {
    this.showMappingEditor = true;
  }

  //#endregion

  public fileUploadSucceeded(validationResult: SynapsesDqsValidationResult) {
    const self = this;

    self.reset();
    self.importTemplate = self.templateTypes.find(t => t.id === self.selectedTemplateTypeId);
    setTimeout(() => {
      self.validationData = validationResult;
    });
  }

  public fileUploadFailed(e) {
    this.upload = false;
  }

  public importSuccessful(result: SynapsesDqsValidationResult) {
    this.importResult = result;
    this.ais.$rootScope.$broadcast('rentRollImport.property.success');
  }

  public doClose() {
    this.close.emit(true);
  }

  public getProgressStatusClass(item: string) {
    const self = this;

    switch (item) {
      case 'input':
        if (self.validationData) return 'done-status';
        return 'active-status';
      case 'validation':
        if (self.importResult) return 'done-status';
        if (self.validationData) return 'active-status';
        break;
      case 'summary':
        if (self.importResult) return 'active-status';
        break;
    }

    return '';
  }

  public mappingEditorClosed(e) {
    this.doClose();
  }
}
