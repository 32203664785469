import { Pipe, PipeTransform } from '@angular/core';

import { FilterPaneItemValue } from '../models/filter-pane-item-value';

@Pipe({
    name: 'showMaxItems',
    pure: false
})
export class ShowMaxItemsPipe implements PipeTransform {
    transform(items: FilterPaneItemValue[], showAll: boolean, maxItems: number): any {
        if (!items || showAll) {
            return items;
        }

        return items.slice(0, maxItems);
    }
}
