import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SynapsesDqsService } from '../_services/synapses-dqs-service.service';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'synapses-dqs-update-job-rentroll',
  templateUrl: './synapses-dqs-update-job-rentroll.component.html',
  styleUrls: ['./synapses-dqs-update-job-rentroll.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SynapsesDqsUpdateJobRentrollComponent {
  @Input() businessObjectType: string;
  @Input() oid: number;
  @Input() rentrollDate: string;//as string in format 2020-09-01

  public vm = this;

  public shouldShowConnectedJobs = false;

  constructor(private service: SynapsesDqsService) {
  }

  ngOnInit() {
    const self = this;
    self.selectionToggleCallback = self.selectionToggleCallback.bind(self);//bind to this, so we have this in function
    self.showConnectedJobs();
  }

  public propertyJobs = [];
  public selectedJobsIds = [];
  public updatedJobsCount = 0;

  private showConnectedJobs() {
    const self = this;

    if (self.businessObjectType !== 'PropertyRentRoll') return;
    if (!self.rentrollDate) return;

    self.service.getPropertyJobs(self.oid, true).then((data) => {
      if (data && data.length) {
        self.propertyJobs = data;
        self.shouldShowConnectedJobs = true;
      }
    }).catch(() => {
      alert("Failed to load jobs...");
    });
  }

  public selectionToggleCallback(context: RowClassArgs) {
    const self = this;

    const isDisabled = self.service.fixTimeZoneOffsetForSave(context.dataItem.rentrollDate) !== self.rentrollDate;
    return { 'k-disabled': isDisabled };
  }

  public copyRentRollToJobs() {
    const self = this;

    if (self.selectedJobsIds.length === 0) {
      alert("No jobs selected!");
      return;
    }

    self.service.copyPropertyRentRollToJob(self.oid, self.selectedJobsIds, self.rentrollDate).then((gpr) => {
      if (gpr && gpr.success) {
        self.updatedJobsCount = self.selectedJobsIds.length;
        self.shouldShowConnectedJobs = false;
      } else {
        alert("Failed to update jobs..." + (gpr || { message: '' }).message);
      }
    }).catch((err) => {
      alert("Failed to update jobs...");
    });

  }
}
