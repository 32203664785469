(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:appLanguageSettingsResource
   * @description
   * Resource for persisting user specific locale settings.
   * 
   * @requires $resource
   * @requires DXSPolluxApp.object:appServiceHelper
   */
  angular
    .module('DXSPolluxApp')
    .factory('appLanguageSettingsResource',
      ['$resource', 'appServiceHelper', appLanguageSettingsResource]);

  function appLanguageSettingsResource($resource, appServiceHelper) {
    return {

      /**
      * @ngdoc function
      * @name locale
      * @methodOf DXSPolluxApp.service:appLanguageSettingsResource
      * @description
      * Loads or saves the locale settings for the currently logged in user
      * 
      * Route: api/v2/language
      */
      locale: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.api', 'language'), { locale: '@locale' })
    };
  }
}());
