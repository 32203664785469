(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationPortfolio
   * @description
   * This component lists Valuation Portfolios in a grid. If an entry is double-clicked, an edit dialog for this entry is shown where the element can be edited and saved.
   * 
   * <b>Note</b> This component loads all available report data in one batch
   * 
   * @param {Object} tabElement Tab element from the workplace
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationPortfolio", {
      template: require('html-loader!./project.valuation.portfolio.tmpl.html'),
      require: {
        "workplace": "^projectValuationWorkplace"
      },
      bindings: {
        "tabElement": "="
      },
      controllerAs: "vm",
      controller: ['$translate', '$rootScope', '$scope', '$timeout', '$q', 'appStorage', 'appLocalization', 'adalAuthenticationService', 'appSettings', 'fileDownloadService', 'ValuationPortfoliosResourceCustom', 'filterPaneService', 'appServiceHelper', 'notificationsService', projectValuationPortfolioCtrl]
    });

  function projectValuationPortfolioCtrl($translate, $rootScope, $scope, $timeout, $q, appStorage, appLocalization, adalAuthenticationService, appSettings, fileDownloadService, ValuationPortfoliosResourceCustom, filterPaneService, appServiceHelper, notificationsService) {
    var vm = this;

    //#region variable declarations
    vm.allData = [];
    vm.grid = null;
    vm.showProgressBars = true;
    vm.areaFilterSettings = [];
    vm.filtersActive = false;
    vm.filterPaneFilters = {};
    vm.states = getStates();
    vm.filterConfig = filterPaneService.loadConfig('valuationPortfolio');
    vm.dashboardSelectedJob = null;

    let filtersChangedSub = filterPaneService.filtersChanged$.subscribe((filters) => {
      if (typeof filters == "undefined")
        return;
      if (vm.filterPaneFilters === {}) {
        vm.filterPaneFilters = { area: 'valuationPortfolio', filters: [], name: "Default" };
        return;
      }
      else if (filters && filters.area === 'property' && vm.filterPaneFilters.area === 'valuationPortfolio') {
        return;
      }

      if (filters && filters.area === vm.tabElement.searchMode && filters !== vm.filterPaneFilters) {
        vm.filterPaneFilters = filters;
        loadData();
      }
    });

    function processFilterPaneFilters(data) {
      data.filter = data.filter || {
        logic: "and",
        filters: []
      };

      let filtersAddedCount = filterPaneService.appendFilters(data.filter, vm.filterPaneFilters.filters);
      vm.filtersActive = filtersAddedCount > 0;
      broadcastFiltersActiveState();
    }

    vm.gridOptionsDataSource = new kendo.data.DataSource({
      data: [],
      page: 1,
      pageSize: 5
    });

    vm.isInitialLoad = true;

    vm.gridOptionsDataSource = new kendo.data.DataSource({
      serverPaging: true,
      serverFiltering: true,
      page: 1,
      pageSize: 5,
      transport: {
        read: {
          url: function (context) {
            return appServiceHelper.getServiceEndpointWithProjectGuid('marvin.pollux.projectValuation.api', 'ValuationPortfolios/list/paged', appStorage.getSelectedProject());
          }, beforeSend: function (request, xhr) {
            var resource = adalAuthenticationService.getResourceForEndpoint(xhr.url);
            if (resource !== null) {
              xhr.withCredentials = true;
              request.setRequestHeader('Authorization', "Bearer " + adalAuthenticationService.getCachedToken(resource));
            }
          },
          xhrFields: {
            withCredentials: true
          }
        },
        parameterMap: function (data, type) {
          processFilterPaneFilters(data);

          if (data.filter) {
            var filterClone = JSON.parse(JSON.stringify(data.filter));
            if (vm.isInitialLoad) {
              vm.isInitialLoad = false;
              filterClone.filters = [{ field: "Oid", operator: "lt", value: 0 }];
            } else {
              filterClone.filters.push({ field: "QVAppVisible", operator: "eq", value: 1 });
              filterClone.filters.push({ field: "IsArchived", operator: "eq", value: 0 });
            }

            data.filter = kendo.stringify(filterClone);
          }

          return data;
        }
      },
      requestEnd: function (e) {
        var response = e.response;
        var type = e.type;
        if (type === "read") {
          appStorage.setSelectedPortfolio(null);
          vm.workplace.setCounterValue('valuationPortfolio', response.total);
          response.details = (response.details || []).filter(item => item.QVAppVisible && !item.IsArchived);//double check
        }
      },
      schema: {
        total: "total", // total is returned in the "totalPropertyCount" field of the response
        data: "details" // Grid data is returned in the "details" field of the response
      }
    });

    vm.progressBarOptions = {
      min: 0,
      max: 100,
      animation: false,
      change: function (e) {
        this.progressStatus.text(parseInt(e.value).toFixed(0));

        this.progressWrapper.css({
          "background-color": "#D7D6E9",
          "border-color": "#D7D6E9"
        });
      }
    };

    vm.gridOptions = {
      excel: {
        fileName: "Jobs.xlsx",
        filterable: true
      },
      dataSource: vm.gridOptionsDataSource,
      selectable: "row",
      locale: appLocalization.getLocale(),
      rowTemplate: function (context) {
        return getRowTemplate(context, '');
      },
      altRowTemplate: function (context) {
        return getRowTemplate(context, 'k-alt');
      },
      scrollable: true,
      pageable: {
        refresh: true,
        pageSize: 5,
        pageSizes: [5, 10, 15],
        numeric: false
      },
      columns: getColumns(),
      change: function (e) {
        var grid = e.sender;
        var item = grid.dataItem(grid.select());
        if (item === appStorage.getSelectedPortfolio()) {
          return;
        }
        e.preventDefault();
        angular.forEach(vm.gridOptionsDataSource.data(), function (element) {
          element.checkbox = false;
        });
        $timeout(function () {
          if (item) {
            item.checkbox = true;
          }
          $timeout(function () {
            if (item) {
              appStorage.setSelectedPortfolio(item);
              var taskId = appStorage.getTaskIdToOpen();
              if (taskId) {
                vm.workplace.onElementSelected(vm.tabElement, item, taskId ? [{ tabId: "tabTasks", filter: { specificDmOid: taskId } }] : null);
                appStorage.setTaskIdToOpen(null);
                $timeout(function () {
                  $rootScope.$broadcast('valuationTabSelect', 'tabTasks');
                }, 100);
              } else {
                vm.workplace.onElementSelected(vm.tabElement, item);
              }
            }
            else {
              appStorage.setSelectedPortfolio(null);
              vm.workplace.onElementDeselected(vm.tabElement);
            }
          }, 10);
        }, 10);
      },
      dataBound: onGridDataBound
    };
    //#endregion variable declarations

    //#region function declarations
    vm.updateLocale = updateLocale;
    vm.loadData = loadData;
    vm.filter = filter;
    vm.rowClicked = rowClicked;
    vm.doGridExcelExport = doGridExcelExport;
    //#endregion function declarations

    //#region function implementation

    /**
     * @ngdoc function
     * @name $onInit
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Initializes the grid, loads data
     */
    vm.$onInit = function () {
      vm.workplace.onTabCreated(this);
    };

    function initialize(postInitializeFn) {
      vm.initialized = true;
      $q.all([
        filterPaneService.getActiveFilterSet("valuationPortfolio")
      ]).then(function (results) {
        vm.filterPaneFilters = results[0];

        vm.loadData();
        vm.enableGrid = true;

        postInitializeFn && postInitializeFn();
      });
    }

    /**
    * @ngdoc function
    * @name $onDestroy
    * @methodOf DXSPolluxApp.component:projectValuationPortfolio
    * @description
    * Cleanup
    */
    vm.$onDestroy = function () {
      filtersChangedSub && filtersChangedSub.unsubscribe();
    }

    function getStates() {
      return [{
        "oid": 0,
        "parentNo": null,
        "description": ""
      }, {
        "oid": 1,
        "parentNo": null,
        "description": $translate.instant('projectValuation.draft')
      }, {
        "oid": 2,
        "parentNo": null,
        "description": $translate.instant('projectValuation.toBeApproved')
      }, {
        "oid": 3,
        "parentNo": null,
        "description": $translate.instant('projectValuation.approved')
      }, {
        "oid": 4,
        "parentNo": null,
        "description": $translate.instant('projectValuation.final')
      }
      ];
    }

    function getColumns() {
      var columnIndex = 0;
      return [
        {
          title: '',
          field: '',
          disableDoubleClick: true,
          template: function (context) {
            return vm.states.filter((entry) => { return entry.oid === context.JobStatusId; })[0].description;
          },
          index: columnIndex++
        },
        {
          title: '',
          field: '',
          disableDoubleClick: true,
          template: function () {
            return '<project-valuation-grid-images area="job" grid-data="dataItem"></project-valuation-grid-images>';
          },
          index: columnIndex++
        },
        {
          title: '',
          field: '',
          template: function () {
            return '<project-valuation-grid-job-details grid-data="dataItem"></project-valuation-grid-job-details>';
          },
          index: columnIndex++
        },
        {
          title: '',
          field: '',
          template: function () {
            return '<project-valuation-grid-kpi area="job" grid-data="dataItem"></project-valuation-grid-kpi>';
          },
          index: columnIndex++
        }
      ];
    }

    /**
     * @ngdoc function
     * @name getRowTemplate
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Generates an HTML template for a single row in the grid. Required for double-click to edit
     * 
     * @param {Object} context The project that will be displayed in this row
     * @param {String} cssClass CSS class for the entire row. Used to display even and odd row numbers in different colors.
     * @returns {String} The template HTML
     */
    function getRowTemplate(context, cssClass) {
      var ret = '<tr id="valuationPortfolio' + context.Oid + '" class="' + cssClass + ' portfolioRow">';

      angular.forEach(vm.grid ? vm.grid.columns : vm.gridOptions.columns, function (element, index) {
        var doubleClickHandler = element.disableDoubleClick ? '' : 'ng-dblclick="vm.rowClicked($event)"';

        if (!element.hidden) {
          if (typeof element.template === "function") {
            if (element.index === 0) {
              ret += '<td class="grid-first-column"' + doubleClickHandler + '><div class="grid-first-column-text">' + element.template(context) + '</div></td>';
            }
            else if (element.index === 2) {
              ret += '<td class="col-6"' + doubleClickHandler + '>' + element.template(context) + '</td>';
            }
            else {
              ret += '<td class="col-3"' + doubleClickHandler + '>' + element.template(context) + '</td>';
            }
          }
          else {
            ret += '<td ' + doubleClickHandler + '>' + context[element.field] + '</td>';
          }
        }
      });

      ret += '</tr>';
      return ret;
    }

    /**
     * @ngdoc function
     * @name onGridDataBound
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Called if the grid was bound to it's data source
     * 
     * @param {Object} e The event
     */
    function onGridDataBound(e) {
      $timeout(function () {
        var items = e.sender.items();
        items.each(function () {
          if (vm.grid) {
            var dataItem = vm.grid.dataItem(this);
            if (dataItem.RecordStatusId === 2) {
              this.className += " recordStatusInactive";
            }
          }
        });
      });
    }

    /**
     * @ngdoc function
     * @name rowClicked
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Called if a row was double-clicked. Checks if the user has the required roles to edit a portfolio and then opens an edit dialog.     * 
     */
    function rowClicked() {
      var selectedItem = vm.grid.dataItem(vm.grid.select());
      $rootScope.$broadcast("editValuationPortfoliosData.inline", { id: selectedItem.Oid, data: selectedItem });
    }

    /**
     * @ngdoc function
     * @name filter
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Called if a BO was selected in any tab of the current area. If it's a project, only the portfolios for this project will be displayed.
     * 
     * @param {Object} item The item that was selected
     * @param {Integer} selectedHierarchy The tab hierarchy of the tab where the item was selected
     * @param {Array} tabConfig The current area's tab configuration containing all tabs. selectedHierarchy can be used to filter the current config.
     * @param {Bool} reset true if the 'show all' action was chosen
     */
    function filter(item, selectedHierarchy, tabConfig, reset) {
      vm.filterByPropertyId = null;
      const noFilter = {
        logic: "and",
        filters: []
      };
      if (reset || selectedHierarchy == null) {
        vm.gridOptionsDataSource.filter(noFilter);
      } else {
        var filteredTab = tabConfig[selectedHierarchy];
        switch (filteredTab.searchMode) {
          case 'projectstd': {
            const projectSelectedFn = () => {
              vm.gridOptionsDataSource.data([]);
              vm.gridOptionsDataSource.filter(noFilter);
            };

            if (!vm.initialized) {
              initialize();
            } else {
              projectSelectedFn();
            }
            break;
          }
          case 'property': {
            vm.filterByPropertyId = item.Oid;
            filterPaneService.addAndSaveFilter(vm.filterPaneFilters, { field: "LinkedPropertyOid", operator: "contains_intInList", values: [{ oid: item.Oid }] });
            break;
          }
        }
      }
    }

    /**
     * @ngdoc function
     * @name loadData
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Loads the data
     * 
     * @param {Function} callback Callback function that is called once everything is loaded
     */
    function loadData(callback) {
      if (!appStorage.getSelectedProject()) {
        callback && callback();
        return;
      }

      //var selectedItem = appStorage.getSelectedPortfolio();
      //var selectedOid = selectedItem ? selectedItem.Oid : null;
      vm.gridOptionsDataSource.read().then(
        function (data) {
          //TODO switch to page with selected Item (#10124)
          //if (selectedOid && false) {
          //} else {
            vm.gridOptionsDataSource.page(1);
          //}

          if (callback) {
            callback();
          }
        },
        function (error) {
          vm.err = error;
          alert(JSON.stringify(error));
        }
      );
    }

    //#endregion

    //#region events   

    $scope.$on('valuationProjectProjectstdItemSavedReloadPortfolio', function (e, callback) {
      vm.loadData(callback, true);
    });

    $scope.$on('valuationPortfolioLoadData', function (e, callback) {
      vm.loadData(callback);
    });

    $scope.$on('valuationPortfoliosDataSaved', function (e, item) {
      const jobId = item.m_Item2;
      notificationsService.notifyJobAssumptionsChange({ jobId: jobId, messageId: 301/*JOB_ASSUMPTIONS_CHANGE_JOB_MASK*/ });
      vm.loadData(function () {
        $rootScope.$broadcast("valuationTabSelect", 'tabValuationPortfolio');
        findDataItem(jobId);
      });
    });

    $scope.$on('valuationPortfolioLoadDataFromPublish', function (e, callback) {
      vm.loadData(callback, true);
    });

    $scope.$on('valuationProjectPortfolioItemSaved', function (e, item) {
      var savedPortfolioId = item.m_Item2;
      vm.loadData(function () {
        $timeout(function () {
          findDataItem(savedPortfolioId);
        }, 10);
      });
    });

    $scope.$on('buttonDeletePortfolio', function () {
      var selectedItem = vm.grid.dataItem(vm.grid.select());
      if (selectedItem) {
        $rootScope.$broadcast('deletePortfolio', selectedItem);
      }
      else {
        $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'errorMessage.choosePortfolio', 'simpleMessage.titleDelete');
      }
    });

    $scope.$on('PortfolioLoadData', function (event, callback) {
      vm.loadData(callback);
    });

    $scope.$on('project.valuation.search', function (event, item) {
      vm.gridOptionsDataSource.filter(item);
    });

    $scope.$on('project.valuation.showAll', function (event, item) {
      vm.gridOptionsDataSource.filter(null);
      vm.workplace.onShowAll(vm.tabElement);
    });

    $scope.$on('copyJob', function (e, data) {
      var oid = data.item.Oid;
      var jobTypeId = data.item.JobTypeId;

      var withModel = data.withModel;
      var callback = data.callback;
      var dontOpenDialog = data.dontOpenDialog;
      var setSourceJobId = data.setSourceJobId;
      vm.dashboardSelectedJob = data.item;


      $q.all([
        ValuationPortfoliosResourceCustom.copyJob(oid, withModel)
      ]).then(function (results) {
        var copyResult = results[0];
        if (copyResult.success) {
          if (setSourceJobId) {
            $rootScope.$broadcast('PortfolioLoadData', activateDashboardArea);
          }
          else {
            $rootScope.$broadcast('PortfolioLoadData');
          }

          const jobId = copyResult.message;
          if (!dontOpenDialog) {
            $rootScope.$broadcast("editValuationPortfoliosData:jobType:" + jobTypeId, jobId);
          }

          callback && callback(jobId);
        }
        else {
          alert(copyResult.message);
        }
      });
    });

    function activateDashboardArea() {
      $rootScope.$broadcast("activateDashboardAreaAfterCopy", vm.dashboardSelectedJob);
    }

    /**
     * @ngdoc function
     * @name findDataItem
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Highlights the valuation portfolio with the specified OID in the grid.
     * 
     * @param {Integer} desiredOid The valuation portfolio OID
     */
    function findDataItem(desiredOid) {
      vm.grid.select(angular.element("#valuationPortfolio" + desiredOid)).parent();
    }

    /**
     * @ngdoc function
     * @name updateLocale
     * @methodOf DXSPolluxApp.component:projectValuationPortfolio
     * @description
     * Updates the locale for this area.
     * 
     * @param {String} locale The new locale
     */
    function updateLocale(locale) {
      vm.gridOptions.locale = locale;
    }

    $scope.$on("kendoWidgetCreated", function (event, widget) {
      // the event is emitted for every widget; if we have multiple
      // widgets in this controller, we need to check that the event
      // is for the one we're interested in.
      if (widget === vm.grid) {
        //grid is created
        angular.element("#tabValuationPortfolio-component .k-pager-refresh").on('click', function (e) {
          vm.loadData(null, true);
        });

      }
    });

    $scope.$on('exportISPINITCalculationModelJob', function (event, item) {
      if (!item.CalculationModelId) {
        $rootScope.$broadcast('openConfirmDialog', { simpleMessage: true }, 'report.noCalculationModel', 'simpleMessage.titleImport');
        return;
      }

      $rootScope.$broadcast('loadingIndicator', true);

      var downloadUrl = appSettings.environment.DQS_API_URL + "/import/calcmodel/v2/export?jobOid=" + item.Oid;
      fileDownloadService.initiateDownload(downloadUrl);
    });

    $scope.$on('importCalcModelJob', function (event, params) {
      let item = params.item;
      let callback = params.callback;
      $rootScope.$broadcast("openConfirmDialog", {
        success: function () {
          $rootScope.$broadcast("openProjectValuationCalcModelDialog", {
            job: item,
            callback: callback
          });
        },
        cancel: function () {
        }
      }, 'importCalcModel');
    });

    function doGridExcelExport() {
      vm.grid.saveAsExcel();
    }

    function broadcastFiltersActiveState() {
      $rootScope.$broadcast("filtersActiveState", { area: vm.tabElement.searchMode, active: vm.filtersActive });
    }

    $scope.$on('broadcastFiltersActiveState', function (event, params) {
      if (params.area === vm.tabElement.searchMode) {
        broadcastFiltersActiveState();
      }
    });

    $scope.$on('showDashboardSelect', function () {
      var selectedItem = vm.grid.dataItem(vm.grid.select());
      if (!selectedItem) {
        return;
      }
      var positionInPage = vm.grid.dataSource.data().indexOf(vm.grid.dataItem(vm.grid.select())) + 1; //0 based
      var pageSize = vm.grid.dataSource.pageSize();
      var page = vm.grid.dataSource.page(); //1 based
      var pageCount = vm.grid.dataSource.totalPages();

      $rootScope.$broadcast('showDashboard', {
        canGoForward: positionInPage < pageSize || pageCount > page,
        canGoBack: page > 1 || positionInPage > 1,
        job: selectedItem
      });
    });

    $scope.$on('dashboardSelectNextJob', function (e, dashboardData) {
      var positionInPage = vm.grid.dataSource.data().indexOf(vm.grid.dataItem(vm.grid.select())) + 1; //0 based
      var pageSize = vm.grid.dataSource.pageSize();
      var page = vm.grid.dataSource.page(); //1 based

      function selectNext(pos) {
        var positionInPageAfterSwitch = pos; //0 based
        var pageSizeAfterSwitch = vm.grid.dataSource.pageSize();
        var pageAfterSwitch = vm.grid.dataSource.page(); //1 based
        var pageCountAfterSwitch = vm.grid.dataSource.totalPages();

        vm.grid.select(`tr:eq(${pos})`);
        var selectedItem = vm.grid.dataItem(vm.grid.select());

        $rootScope.$broadcast('showDashboard', {
          canGoForward: positionInPageAfterSwitch < pageSizeAfterSwitch || pageCountAfterSwitch > pageAfterSwitch,
          canGoBack: pageAfterSwitch > 1 || positionInPageAfterSwitch >= 1,
          job: selectedItem
        });
      }

      if (positionInPage === pageSize) {
        vm.grid.dataSource.page(page + 1);
        vm.grid.dataSource.read().then(function () {
          selectNext(0);
        });
        // refreshes the grid
        vm.grid.refresh();
      }
      else {
        selectNext(vm.grid.dataSource.data().indexOf(vm.grid.dataItem(vm.grid.select())) + 1);
      }
    });

    $scope.$on('dashboardSelectPreviousJob', function (e, dashboardData) {
      var positionInPage = vm.grid.dataSource.data().indexOf(vm.grid.dataItem(vm.grid.select())) + 1; //0 based
      var pageSize = vm.grid.dataSource.pageSize();
      var page = vm.grid.dataSource.page(); //1 based

      function selectPrev(pos) {
        var positionInPageAfterSwitch = pos; //0 based
        var pageSizeAfterSwitch = vm.grid.dataSource.pageSize();
        var pageAfterSwitch = vm.grid.dataSource.page(); //1 based
        var pageCountAfterSwitch = vm.grid.dataSource.totalPages();

        vm.grid.select(`tr:eq(${pos})`);
        var selectedItem = vm.grid.dataItem(vm.grid.select());

        $rootScope.$broadcast('showDashboard', {
          canGoForward: positionInPageAfterSwitch < pageSizeAfterSwitch || pageCountAfterSwitch > pageAfterSwitch,
          canGoBack: pageAfterSwitch > 1 || positionInPageAfterSwitch >= 1,
          job: selectedItem
        });
      }

      if (positionInPage === 1) {
        vm.grid.dataSource.page(page - 1);
        vm.grid.dataSource.read().then(function () {
          selectPrev(pageSize - 1);
        });
        // refreshes the grid
        vm.grid.refresh();
      }
      else {
        selectPrev(vm.grid.dataSource.data().indexOf(vm.grid.dataItem(vm.grid.select())) - 1);
      }
    });

    $scope.$on('filterPane:clearGridSelection', function (e) {
      vm.grid.clearSelection();
    });

    $scope.$on('property.archived', function (e, params) {
      const propertyId = params.oid;
      //property archived => clear it from filter(s)
      filterPaneService.resetCache();
      filterPaneService.loadDataForConfig("property").then(_r => {
        filterPaneService.loadDataForConfig("valuationPortfolio").then(_r => {
          filterPaneService.removeAndSaveFilter(vm.filterPaneFilters, { field: "LinkedPropertyOid", operator: "contains_intInList", values: [{ oid: propertyId }] });
        });
      });
    });
  }
}());
