(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:appBar
   * @description
   * This component is not used any more and therefore is deprecated
   * 
   * @param {Integer} moduleno The module number
   */
  angular.module("DXSPolluxApp")
    .component("appBar", {
      template: require('html-loader!./app.bar.tmpl.html'),
      bindings: {
        moduleno: "<"
      },
      controllerAs: "vm",
      controller: [appBarCtrl]
    });

  function appBarCtrl() {
    var vm = this;
    vm.moduleno = 333;
  }
}());
