import { TourStep } from './tourStep';

/**
 * This class represents a tour.
 *
 */
export class Tour {
  public tourId: string; 
  public name: string;
  public description: string;
  public color: string; // as HexCode 
  public tourSteps: TourStep[];

  // For seting the correct postion
  // This is not mapped by the API. This is set dynamically
  public bottom?: number;
  public left?: number;
  public right?: number;
  public top?: number;
}
