(function () {

  "use strict";
  var dxsCounter = 0;

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:dxsRetriever
   * @description
   * This component provides the behavior and visual representation of a retriever field. 
   * 
   * The user can search for the desired value by typing into the visible field. 
   * Once he typed at least one character, a dialog opens that displays matching data in a grid. The user can then choose the desired entry from this grid.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $rootScope
   * 
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngDisabled Sets this field read-only
   * @param {Integer} dxsSelectedOid The currently selected OID
   * @param {Array} dxsFilters List of filters that can be used to restrict the choosable values (for example, only the reports for a specific project can be chosen)
   * @param {String} dxsReturnField The name of the field that is returned from the retriever's data transfer object. 
   * @param {String} dxsDescriptionField The name of the description field that is displayed from the retriever's data transfer object. 
   * @param {String} dxsRetrieverType The retriever type (e.g. 'Project'). Must be present in app.retriever.resource
   */
  angular.module("DXSPolluxApp")
    .component("dxsRetriever", {
      template: require('html-loader!./app.retriever.input.tmpl.html'),
      bindings: {
        "ngModel": "=",
        "ngChange": "&",
        "ngDisabled": '=',
        "dxsSelectedOid": "=",
        "dxsFilters": "=",
        "dxsReturnField": "@",
        "dxsDescriptionField": "@",
        "dxsRetrieverType": "="
      },
      controllerAs: "vm",
      controller: ['$rootScope', dxsRetrieverCtrl]
    });

  function dxsRetrieverCtrl($rootScope) {
    var vm = this;
    vm.initialized = false;
    vm.dxsRetrieverId = dxsCounter++;

    vm.$onInit = function () {
      vm.initialized = true;
    }

    vm.onNgChange = function () {
      if (vm.ngChange) {
        vm.ngChange();
      }
    }

    vm.dxsAllSearch = function () {
      if (!vm.ngDisabled) {
        $rootScope.$broadcast("dxsRetreiverAllSearch" + vm.dxsRetrieverId, {});
      }
    }
  }

  /**
   * @ngdoc directive
   * @name DXSPolluxApp.directive:dxsRetrieverDirective
   * 
   * @restrict 'A'
   * 
   * @description
   * <b>Do not use this directive directly! Instead, use the dxsRetriever component.</b>
   * 
   * This component provides the behavior and visual representation of a retriever field. 
   * 
   * The user can search for the desired value by typing into the visible field. 
   * Once he typed at least one character, a dialog opens that displays matching data in a grid. The user can then choose the desired entry from this grid.
   * 
   * @requires $rootScope
   * @requires $timeout
   * 
   * @param {Integer} dxsRetrieverId The retriever's unique id. Managed by the dxsRetriever component. This id is used to determine which retriever triggered an event.
   * @param {String} ngModel The property that is bound to this field.
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Integer} dxsSelectedOid The currently selected OID
   * @param {Array} dxsFilters List of filters that can be used to restrict the choosable values (for example, only the reports for a specific project can be chosen)
   * @param {String} dxsReturnField The name of the field that is returned from the retriever's data transfer object. 
   * @param {String} dxsDescriptionField The name of the description field that is displayed from the retriever's data transfer object. 
   * @param {String} dxsRetrieverType The retriever type (e.g. 'Project'). Must be present in app.retriever.resource
   */
  angular.module("DXSPolluxApp")
    .directive('dxsRetrieverDirective', ['$rootScope', '$timeout',
      function ($rootScope, $timeout) {
        return {
          restrict: 'A',
          scope: {
            dxsRetrieverId: '=',
            ngModel: '=',
            ngChange: '&',
            dxsSelectedOid: '=',
            dxsFilters: '=',
            dxsReturnField: '=',
            dxsDescriptionField: '=',
            dxsRetrieverType: '='
          },
          link: function (scope, element, attr, ctrl) {

            $timeout(function () {
              scope.$watch('ngModel', function (newVal, oldVal) {
                if ((oldVal !== newVal) && element[0] === document.activeElement) {
                  $rootScope.$broadcast("openDxsRetreiverDialog", { retriever: element, dxsRetrieverId: scope.dxsRetrieverId, type: scope.dxsRetrieverType, inputValue: scope.ngModel, oid: scope.dxsSelectedOid, dxsFilters: scope.dxsFilters, dxsReturnField: scope.dxsReturnField, dxsDescriptionField: scope.dxsDescriptionField });
                }
              });
            }, 50);

            scope.safeApply = function (fn) {
              var phase = this.$root.$$phase;
              if (phase == '$apply' || phase == '$digest') {
                if (fn && (typeof (fn) === 'function')) {
                  fn();
                }
              } else {
                this.$apply(fn);
              }
            };


            //#region "EVENTS"

            scope.$on('setDxsRetreiverInput' + scope.dxsRetrieverId, function (event, config) {
              scope.safeApply(function () {
                if (config.inputValue && config.id) {
                  scope.ngModel = config.inputValue;
                  scope.dxsSelectedOid = config.id;
                }
                else {
                  scope.ngModel = null;
                  scope.dxsSelectedOid = null;
                }
                if (scope.ngChange) {
                  $timeout(scope.ngChange, 0);
                }
              });
            });

            scope.$on('dxsRetreiverAllSearch' + scope.dxsRetrieverId, function (event, config) {
              scope.ngModel = "%%%";
              $rootScope.$broadcast("openDxsRetreiverDialog", { retriever: element, dxsRetrieverId: scope.dxsRetrieverId, type: scope.dxsRetrieverType, inputValue: scope.ngModel, oid: scope.dxsSelectedOid, dxsFilters: scope.dxsFilters, dxsReturnField: scope.dxsReturnField, dxsDescriptionField: scope.dxsDescriptionField });
            });

            //#endregion "EVENTS"
          }
        }
      }]);
}());

