(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name DXSPolluxApp.service:FilledWithPropertyResource
   */
  angular
    .module('DXSPolluxApp')
    .factory('FilledWithPropertyResource',
      ['$resource', 'appServiceHelper', filledWithPropertyResource]);

  function filledWithPropertyResource($resource, appServiceHelper) {
    var api = 'marvin.pollux.comparables.api';

    return {
      /**
       * @ngdoc function
       * @name propertyDetails
       * @methodOf DXSPolluxApp.service:FilledWithPropertyResource
       * @description
       * Loads property data.
       * 
       * Route: api/v2/Properties/specific
       * 
       * @param {Integer} oid Property OID
       * 
       * @returns {Object} Property
       */
      propertyDetails: $resource(appServiceHelper.getServiceEndpoint(api, 'Properties/specific'), { oid: '@oid' })
    }
  }
}());
