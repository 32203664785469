(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name DXSPolluxApp.service:projectValuationProjectstdDialogResource
     * @description
     * This service is responsible for loading project data.
     * 
     * @requires $resource
     * @requires DXSPolluxApp.object:appServiceHelper
     */

    angular
        .module('DXSPolluxApp')
        .factory('projectValuationProjectstdDialogResource',
        ['$resource', 'appServiceHelper', projectValuationProjectstdDialogResource]);

    function projectValuationProjectstdDialogResource($resource, appServiceHelper) {
        return {
            /**
             * @ngdoc function
             * @name details
             * @methodOf DXSPolluxApp.service:projectValuationProjectstdDialogResource
             * @description
             * Loads project data.
             * 
             * Route: api/v2/project/valuation/projectstd/details/single
             * 
             * @param {Integer} oid Project OID
             * 
             * @returns {Object} Project
             */
            details: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/projectstd/details/single'), { oid: '@oid' }),
            /**
             * @ngdoc function
             * @name details
             * @methodOf DXSPolluxApp.service:projectValuationProjectstdDialogResource
             * @description
             * Aborts a project.
             * 
             * Route: api/v2/project/valuation/projectstd/abort
             * 
             * @param {Object} object Info about project to be aborted (OID, Comment,..)
             * 
             * @returns {Object} A Tuple. The 1st (bool) item indicates the success state, the 2nd (string) item provides an error message if Item1 is false
             */
            abort: $resource(appServiceHelper.getServiceEndpoint('marvin.pollux.projectValuation.api', 'project/valuation/projectstd/abort'), {})
        }
    }
}());
