(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationProjectStdSettingsInternalUsersDialog
   * @description
   * Dialog for project settings internal user selection
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationUsersDialog", {
      template: require('html-loader!./project.valuation.portfolio.users.dialog.tmpl.html'),
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$compile', '$q', '$timeout', 'ValuationPortfolioTeamResource', projectValuationUsersDialog]
    });

  function projectValuationUsersDialog($rootScope, $scope, $compile, $q, $timeout, ValuationPortfolioTeamResource) {
    var vm = this;
    vm.window = null;
    vm.loaded = false;

    const windowWidth = 400;
    const openCloseDuration = 200;

    vm.$onInit = function () {
    };

    vm.availableUsersDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectedUsersDataSource = new kendo.data.HierarchicalDataSource({
      data: []
    });

    vm.selectOptionsSelectedUsers = {
      dataTextField: "description",
      dataValueField: "oid",
      dataSource: vm.selectedUsersDataSource,
      template: selectedUsersTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    vm.selectOptionsAvailableUsers = {
      dataTextField: "description",
      dataValueField: "oid",
      dataSource: vm.availableUsersDataSource,
      template: availableUsersTemplate,
      dataBound: compileTemplate, //http://dojo.telerik.com/@bubblemaster/IQatA/2
      add: compileTemplate
    };

    vm.options = {
      title: false,
      width: windowWidth + "px",
      draggable: false,
      resizable: false,
      modal: false,
      position: {
        top: 0,
        left: 0
      },
      animation: {
        open: {
          effects: "slideIn:left",
          duration: openCloseDuration
        },
        close: {
          effects: "slideIn:left",
          reverse: true,
          duration: openCloseDuration
        }
      },
      visible: false
    };

    function reset() {
      vm.projectId = null;
      vm.jobId = null;
      vm.loaded = false;
      vm.selectedUsersDataSource.data([]);
      vm.availableUsersDataSource.data([]);

      const toolbar = document.getElementById("project-valuation-toolbar").getBoundingClientRect();

      const winW = window.innerWidth;
      const winH = window.innerHeight;

      if (vm.window) {
        vm.window.wrapper.css("top", (toolbar.height) + "px");
        vm.window.wrapper.css("left", (winW - windowWidth) + "px");
        vm.window.wrapper.css("height", (winH - toolbar.height) + "px");
      }
    }

    reset();

    vm.cancel = cancel;
    vm.plusButtonClicked = plusButtonClicked;
    vm.minusButtonClicked = minusButtonClicked;

    /**
    * @ngdoc function
    * @name cancel
    * @methodOf DXSPolluxApp.component:projectValuationProjectStdSettingsInternalUsersDialog
    * @description
    * Closes the dialog
    */
    function cancel() {
      vm.window.close();
    }

    function plusButtonClicked(id) {
      var user = vm.availableUsersDataSource.data().filter((item) => { return item.oid === id; });
      if (user && user.length) {
        $q.all([
          ValuationPortfolioTeamResource.addUserToTeam(vm.jobId, id)
        ]).then(function (results) {
          vm.availableUsersDataSource.data(vm.availableUsersDataSource.data().filter((item) => { return item.oid !== id; }));
          vm.selectedUsersDataSource.data().push(user[0]);

          $rootScope.$broadcast('jobTeamMembersChanged', { jobId: vm.jobId, teamMembers: vm.selectedUsersDataSource.data() });
        }).catch(() => {
        });
      }
    }

    function minusButtonClicked(id) {
      var user = vm.selectedUsersDataSource.data().filter((item) => { return item.oid === id; });
      if (user && user.length) {
        $q.all([
          ValuationPortfolioTeamResource.removeUserFromTeam(vm.jobId, id)
        ]).then(function (results) {
          vm.selectedUsersDataSource.data(vm.selectedUsersDataSource.data().filter((item) => { return item.oid !== id; }));
          vm.availableUsersDataSource.data().push(user[0]);

          $rootScope.$broadcast('jobTeamMembersChanged', { jobId: vm.jobId, teamMembers: vm.selectedUsersDataSource.data() });
        }).catch(() => {
        });
      }
    }

    function compileTemplate(e) {
      var listbox = this;
      $timeout(function () {
        var rows = listbox.items();
        rows.each(function (index, row) {
          if (!$(row).hasClass("ng-scope")) {
            $compile(row)($.extend($scope.$new(), listbox.dataItem(row)));
          }
        });
      });
    }

    function availableUsersTemplate(dataItem) {
      return `
<div class="item-row">
  <div class="item-text">
    ${dataItem.description}
  </div>
  <span class="item-button k-icon k-i-plus" ng-click="vm.plusButtonClicked(${dataItem.oid})"></span>
</div> 
`;
    }

    function selectedUsersTemplate(dataItem) {
      return `
<div class="item-row">
  <div class="item-text item-text-cyan">
    ${dataItem.description}
  </div>
  <span class="item-button k-icon k-i-minus" ng-click="vm.minusButtonClicked(${dataItem.oid})"></span>
</div> 
`;
    }

    //#region "EVENTS"

    $scope.$on('openPortfolioUsersDialog', function (event, params) {
      reset();

      vm.loaded = false;
      vm.projectId = params.projectId;
      vm.jobId = params.jobId;

      $timeout(function () {
        vm.window.open();
      });

      kendo.ui.progress(vm.window.element, true);
      $timeout(function () {
        $q.all([
          ValuationPortfolioTeamResource.loadJobCurrentTeamUsers(vm.jobId),
          ValuationPortfolioTeamResource.loadJobNotAssignedTeamUsers(vm.projectId, vm.jobId)
        ]).then(function (results) {
          kendo.ui.progress(vm.window.element, false);

          //TODO any desired order?
          const assignedUsers = results[0];
          const notAssignedUsers = results[1].filter((item) => { return !assignedUsers.some(selectedUser => selectedUser.oid === item.oid); });

          vm.selectedUsersDataSource.data(assignedUsers);
          vm.selectedUsersDataSource.fetch();

          vm.availableUsersDataSource.data(notAssignedUsers);
          vm.availableUsersDataSource.fetch();

          vm.loaded = true;
        }).catch(() => {
          kendo.ui.progress(vm.window.element, false);
        });

        
      });
    });

    $scope.$on('closeSidebar', function (event, params) {
      cancel();
    });
      
    //#endregion "EVENTS"
  }
}());

