import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { SynapsesDqsService } from '../_services/synapses-dqs-service.service';
import { SynapsesDqsValidationResult } from '../_data/synapses-dqs-validation-result';

@Component({
  selector: 'synapses-dqs-file-upload',
  templateUrl: './synapses-dqs-file-upload.component.html'
})
export class SynapsesDqsFileUploadComponent implements OnInit {
  @Output() fileUploadSucceeded: EventEmitter<SynapsesDqsValidationResult> = new EventEmitter<any>();
  @Output() fileUploadFailed: EventEmitter<any> = new EventEmitter<any>();

  @Input() businessObjectType: string;
  @Input() oid: number;
  @Input() importVersion: number;
  @Input() templateId: number;

  /**
   * The Kendo upload component for uploading the file to be examined
   */
  @ViewChild("kendoUpload") kendoUpload: any;

  public fileUploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx'],
    maxFileSize: 8388608 //8MB
  };

  constructor(private dqsService: SynapsesDqsService) { }

  ngOnInit() {
    const self = this;

    self.kendoUpload && self.kendoUpload.clearFiles();
    setTimeout(() => {
      self.kendoUpload.clearFiles();
      self.kendoUpload.fileSelect.nativeElement.click();
    });
  }

  /**
   * Success handler: The file was successfully uploaded and validated (this doesn't mean that the validation yielded good results though)
   * @param e
   */
  public successEventHandler(e) {
    if (e.response.ok) {
      const validationResult = e.response.body as SynapsesDqsValidationResult;
      validationResult.rawFile = e.files[0].rawFile;

      this.fileUploadSucceeded.emit(validationResult);
    }
    else {
      this.fileUploadFailed.next(e);
    }
  }

  /**
   * An error occurred during validation
   * @param e
   */
  public errorEventHandler(e) {
    this.fileUploadFailed.next(e);
  }

  /**
   * Progress handler - not used
   * @param e
   */
  public uploadProgressEventHandler() {
    //Nothing
  }

  /**
   * File selection handler - not used
   * @param e
   */
  public onFileSelectOrDropped(e) {
    if (e.files.length === 1 && e.files[0] && e.files[0].validationErrors) {
      if (e.files[0].validationErrors.length) {
        this.fileUploadFailed.next(e);
      }
    }
  }

  /**
   * A file should be uploaded for validation
   * @param e
   */
  public onUploadEvent(e) {
    const upload = this.kendoUpload;
    if (upload.fileList.files.length !== 1) {
      e.preventDefault();
      return;//no files for upload
    }

    this.kendoUpload.saveUrl = this.dqsService.getTechnicalValidationUrl(this.templateId);
  }
}
