(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridJobTeam
   * @description
   * Dialog for project settings internal user selection
   * 
   * @requires $scope
   * @requires $rootScope
   * @requires $translate
   *
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridJobTeam", {
      template: require('html-loader!./project.valuation.grid.job.team.tmpl.html'),
      bindings: {
        "projectId": "=",
        "jobId": "="
      },
      controllerAs: "vm",
      controller: ['$rootScope', '$scope', '$q', '$timeout', 'ValuationPortfolioTeamResource', 'appUserAvatarService', projectValuationGridJobTeamCtrl]
    });

  function projectValuationGridJobTeamCtrl($rootScope, $scope, $q, $timeout, ValuationPortfolioTeamResource, appUserAvatarService) {
    var vm = this;
    vm.teamMembers = [];

    vm.$onInit = function () {
      $q.all([
        ValuationPortfolioTeamResource.loadJobCurrentTeamUsers(vm.jobId)
      ]).then(function (results) {
        setupMembers(results[0]);
      }).catch(() => {
        //ignore
      });
    };

    //#region function declaration
    vm.openUsersDialogClicked = openUsersDialogClicked;
    //#endregion

    //#region function implementation
    function openUsersDialogClicked(e) {
      e.preventDefault();
      e.stopPropagation();

      const params = {
        projectId: vm.projectId,
        jobId: vm.jobId
      }

      $rootScope.$broadcast('openPortfolioUsersDialog', params);
    }

    function setupMembers(members) {
      vm.teamMembers = members;
      members.forEach((user) => {
        user.initials = appUserAvatarService.getInitials(user.description);

        appUserAvatarService.getUserAvatarUrlAsync(user.oid).then(function (avatarUrl) {
          user.pictureUrl = avatarUrl;
        });

        return user;
      });

      //sort by description ASC
      members.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
    }

    //#endregion

    //#region "EVENTS"

    $scope.$on('jobTeamMembersChanged', function (event, params) {
      if (params.jobId === vm.jobId) {
        setupMembers(JSON.parse(JSON.stringify(params.teamMembers)));
      }
    });

    //#endregion "EVENTS"
  }
}());

