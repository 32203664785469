import { FilterPaneItemValue } from './filter-pane-item-value';

export class FilterPaneItemConfig {
  constructor(
    public field: string
    , public operator: string
    , public translationKey: string
    , public multi: boolean
    , public api: string
    , public valuesUrl: string
    , public values: Array<FilterPaneItemValue>
    , public equivalence?: { [field: string]: string }) {
  }

  public filters: Array<FilterPaneItemValue> = [];
}
