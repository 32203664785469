/**
 * Represents a Tour step.
 *
 * */
export class TourStep {
  public tourStepId: string;
  public header: string;
  public description: string;

  // For seting the correct postion
  // This is not mapped by the API. This is set dynamically
  public bottom?: number;
  public left?: number;
  public right?: number;
  public top?: number;
}
