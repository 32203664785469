import 'hammerjs';
import * as msalConfig from './config/msal.config';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { SynapsesDashboardModule, SynapsesDashboardService } from 'qv-dashboard-lib/projects/qv-dashboard-lib/src/public_api';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UpgradeModule, setAngularJSGlobal } from '@angular/upgrade/static';
import { AngularIntegrationService } from './services/AngularIntegrationService';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DqsModule } from './components/_dqs/DqsModule';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { FilterPaneModule } from './components/filter-pane/filter-pane.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { HelpTextModule } from './components/shared/tour/help-text-module.module';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { JobDataLinkingModule } from './components/_synapses/job-data-linking/job-data-linking.module';
import { JoyrideModule } from 'ngx-joyride';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MainModule } from './main/main.module';
import { MenusModule } from '@progress/kendo-angular-menu';
import { MultiDashboardModule } from './components/_synapses/multi-dashboard/multi-dashboard.module';
import { NgModule } from '@angular/core';
import { ProjectGuidInterceptor } from './services/ProjectGuidInterceptor';
import { PropertyRentrollDistributionModule } from './components/_synapses/property-rentroll-distribution/property-rentroll-distribution.module';
import { QvPropertyImageManagementModule } from './components/_qv/qv-property-image-management/qv-property-image-management.module';
import { QvPropertyImagesSliderModule } from './components/_qv/qv-property-images-slider/qv-property-images-slider.module';
import { SmartBarModule } from './components/_synapses/smart-bar/smart-bar.module';
import { SynapsesDqsV2Module } from './components/_synapses/dqs-v2/synapses-dqs-module.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationModule } from './main/extensions/translation.module';
import { WinAuthInterceptor } from './WinAuthInterceptor';
import { ZoomchartsModule } from './components/_qv/zoom-charts/zoom-charts.module';
import { environment } from 'src/environments/environment';
import { NotificationsService } from './services/NotificationsService';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { CustomMsalInterceptor } from './services/CustomMsalInterceptor';
declare var angular: angular.IAngularStatic;
setAngularJSGlobal(angular);

let authProviders: Array<any> = [];

if (environment.UseMsal) {
  authProviders = authProviders.concat([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalConfig.MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalConfig.MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalConfig.MSALInterceptorConfigFactory,
    },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ]);
} else {
  authProviders = authProviders.concat([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WinAuthInterceptor,
      multi: true,
    },
  ]);
}


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './content/translation/lang-', '.json');
}

export function appBusyIndicatorServiceFactory($injector: any) {
  return $injector.get('appBusyIndicatorService');
}

export function appConfirmDialogServiceFactory($injector: any) {
  return $injector.get('appConfirmDialogService');
}

export function appSidebarActionsServiceFactory($injector: any) {
  return $injector.get('appSidebarActionsService');
}

export function fileDownloadServiceFactory($injector: any) {
  return $injector.get('fileDownloadService');
}

export const translateModule = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
});

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    environment.UseMsal ? MsalModule : [],
    BrowserModule,
    UpgradeModule,
    AppRoutingModule,
    HttpClientModule,
    InputsModule,
    BrowserAnimationsModule,
    LayoutModule,
    ButtonsModule,
    GridModule,
    MenusModule,
    DateInputsModule,
    ChartsModule,
    ZoomchartsModule,
    QvPropertyImagesSliderModule,
    QvPropertyImageManagementModule,
    DqsModule,
    SynapsesDqsV2Module,
    SynapsesDashboardModule,
    translateModule,
    TranslationModule,
    DropDownsModule,
    ExcelExportModule,
    FilterPaneModule,
    MainModule,
    JobDataLinkingModule,
    SmartBarModule,
    PropertyRentrollDistributionModule,
    HelpTextModule,
    JoyrideModule.forRoot(),
    MultiDashboardModule
  ],
  providers: [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ProjectGuidInterceptor,
    multi: true
  },
  { provide: '$scope', useExisting: '$rootScope' },
    AngularIntegrationService,
    SynapsesDashboardService,
    NotificationsService,
    CustomMsalInterceptor,
  {
    provide: 'appBusyIndicatorService',
    useFactory: appBusyIndicatorServiceFactory,
    deps: ['$injector']
  },
  {
    provide: 'appConfirmDialogService',
    useFactory: appConfirmDialogServiceFactory,
    deps: ['$injector']
  },
  {
    provide: 'appSidebarActionsService',
    useFactory: appSidebarActionsServiceFactory,
    deps: ['$injector']
  },
  {
    provide: 'fileDownloadService',
    useFactory: fileDownloadServiceFactory,
    deps: ['$injector']
  }
  ].concat(authProviders),
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule, private http: HttpClient) { }

  ngDoBootstrap() {
    console.log("ngDoBootstrap entry");
  }
}
