(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:projectValuationGridJobDetails
   * @description
   * Displays the job details
   * 
   * @requires DXSPolluxApp.component:projectValuationMain
   */
  angular.module("DXSPolluxApp")
    .component("projectValuationGridJobDetails", {
      template: require('html-loader!./project.valuation.grid.job.details.tmpl.html'),
      require: {
        "main": "^projectValuationMain"
      },
      bindings: {
        "gridData": "<"
      },
      controllerAs: "vm",
      controller: ['$rootScope', '$timeout', '$q', '$translate', 'appConfirmDialogService', '$scope', projectValuationGridJobDetailsCtrl]
    });

  function projectValuationGridJobDetailsCtrl($rootScope, $timeout, $q, $translate, appConfirmDialogService, $scope) {
    var vm = this;
    //#region variables

    vm.states = getStates();

    vm.progressBarOptions = {
      type: "chunk",
      chunkCount: 4,
      min: 0,
      max: 4,
      animation: false
    };
    //#endregion

    //#region function declaration
    vm.isCTSharedClicked = isCTSharedClicked;
    vm.isCTSyncronizationClicked = isCTSyncronizationClicked;
    vm.navigateToComparisonMode = navigateToComparisonMode;
    //#endregion

    //#region function implementation

    function getStates() {
      return [{
        "oid": 0,
        "parentNo": null,
        "description": ""
      }, {
        "oid": 1,
        "parentNo": null,
        "description": $translate.instant('projectValuation.draft')
      }, {
        "oid": 2,
        "parentNo": null,
        "description": $translate.instant('projectValuation.toBeApproved')
      }, {
        "oid": 3,
        "parentNo": null,
        "description": $translate.instant('projectValuation.approved')
      }, {
        "oid": 4,
        "parentNo": null,
        "description": $translate.instant('projectValuation.final')
      }
      ];
    }

    vm.$onInit = function () {
      var deferred = $q.defer();
      vm.states = vm.states.filter((entry) => { return entry.oid > 0; });
      vm.currentState = vm.states.filter((entry) => { return entry.oid === vm.gridData.JobStatusId; });

      $timeout(function () {
        var chunks = angular.element('#progress-bar-project-status' + vm.gridData.Oid + ' .k-item');

        for (var stateCount = 0; stateCount < vm.states.length; ++stateCount) {
          for (var chunkCount = 0; chunkCount < chunks.length; ++chunkCount) {
            if (stateCount === chunkCount) {
              // append states description
              chunks[chunkCount].innerHTML = vm.states[stateCount].description;
              // add class for current status
              if ((vm.currentState.length > 0 && parseInt(vm.currentState[0].oid) - 1) === chunkCount) {
                chunks[chunkCount].classList.add("current-status");
              }
              break;
            }
          }
        }
        deferred.resolve();
      }, 10);

      return deferred.promise;
    };

    function isCTSharedClicked($event) {
      const jobEntity = vm.gridData;
      const publishParams = {
        share: !jobEntity.IsCTShared,
        jobEntity: jobEntity,
        successCallback: function () {
          jobEntity.IsCTShared = !jobEntity.IsCTShared;
          jobEntity.PublishedCalcModelDirty = false;
          jobEntity.IsCTSyncronizationOn = jobEntity.IsCTShared;
        },
        failCallback: function (msg) {
          appConfirmDialogService.open({ simpleMessage: true }, msg, 'Information');
        }
      };

      $rootScope.$broadcast('CTShareJob', publishParams);
    }

    function isCTSyncronizationClicked($event) {
      const jobEntity = vm.gridData;
      const syncParams = {
        jobEntity: jobEntity,
        syncronizationOn: !jobEntity.IsCTSyncronizationOn,
        successCallback: function () {
          jobEntity.IsCTSyncronizationOn = !jobEntity.IsCTSyncronizationOn;
          const onOff = jobEntity.IsCTSyncronizationOn ? 'on' : 'off';
          appConfirmDialogService.open({ simpleMessage: true }, `Syncronization ${onOff}`, 'Syncronization');
        },
        failCallback: function (msg) {
          appConfirmDialogService.open({ simpleMessage: true }, msg, 'Information');
        }
      };

      $rootScope.$broadcast('CTShareJobSyncronization', syncParams);
    }

    function navigateToComparisonMode() {

      //little timeout so project gets selected
      $timeout(function () {
        $rootScope.$broadcast("sidebar:activateDashboard");

        //then switch to comparison mode
        $timeout(function () {
          $rootScope.$broadcast('setDashboardComparisonMode', { mode: true });
        }, 100);

      }, 100);
    }

    //#endregion

    //#region events
    $rootScope.$on('localeChanged', function () {
      var optionsBefore = JSON.parse(JSON.stringify(vm.progressBarOptions));
      vm.progressBarOptions = null;
      vm.states = getStates();
      $timeout(function () {
        vm.progressBarOptions = optionsBefore;
      });
    });
    //#endregion
  }
}());

