import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { HelpTextOverlayComponent } from './help-text-overlay/help-text-overlay-component';
import { TourUnitComponent } from './tour-unit/tour-unit-component';
import { TourStepElementComponent } from './tour-step-element/tour-step-element-component';
import { JoyrideModule } from 'ngx-joyride';
import { SafePipe } from './tour-step-element/safe.pipe';
import { HelpTextService } from './_services/help-text.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    DialogsModule,
    BrowserAnimationsModule,
    LayoutModule,
    ButtonsModule,
    JoyrideModule.forChild()
  ],
  declarations: [
    HelpTextOverlayComponent,
    TourStepElementComponent,
    TourUnitComponent,
    SafePipe
  ],
  exports: [
    HelpTextOverlayComponent,
    TourStepElementComponent,
    TourUnitComponent,
    SafePipe
  ],
  entryComponents: [
    HelpTextOverlayComponent
  ],
  providers: [
    HelpTextService
  ]
})
export class HelpTextModule { }
