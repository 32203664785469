import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';
import { AppRoutingService } from './app-routing.service';
import { environment } from "src/environments/environment";
import { GeochartsComponent } from './components/_qv/zoom-charts/geo-charts/geo-charts.component';
import { CashflowchartsComponent } from './components/_qv/zoom-charts/cashflow-charts/cashflow-charts.component';
import { QvPropertyImagesSliderComponent } from './components/_qv/qv-property-images-slider/qv-property-images-slider.component';
import { QvPropertyImageManagementComponent } from './components/_qv/qv-property-image-management/qv-property-image-management.component';

import { AngularIntegrationService } from './services/AngularIntegrationService';
import { FilterPaneComponent } from './components/filter-pane/filter-pane.component';
import { FilterPaneService } from './components/filter-pane/services/filter-pane-service.service';
import { DqsValidation } from './components/_dqs/dqs-validation/dqs-validation.component';
import { RentrollExportPane } from './components/_dqs/dqs-validation/rentroll-export-pane/rentroll-export-pane.component';

import { PolluxMonthPickerField } from './main/extensions/fields/pollux-month-picker-field.component';
import { TranslateService } from '@ngx-translate/core';
import { SynapsesDqsV2MainComponent } from './components/_synapses/dqs-v2/synapses-dqs-main/synapses-dqs-main.component';
import { SynapsesDashboardMainComponent, SynapsesDashboardEventsService, SynapsesDashboardService } from 'qv-dashboard-lib/projects/qv-dashboard-lib/src/public_api';
import { HelpTextOverlayComponent } from './components/shared/tour/help-text-overlay/help-text-overlay-component';
import { HelpTextService } from './components/shared/tour/_services/help-text.service';
import { JobDataLinkingComponent } from './components/_synapses/job-data-linking/job-data-linking.component';
import { PropertyRentrollDistributionComponent } from './components/_synapses/property-rentroll-distribution/property-rentroll-distribution.component';
import { SmartBarComponent } from './components/_synapses/smart-bar/smart-bar.component';
import { MultiDashboardComponent } from './components/_synapses/multi-dashboard/multi-dashboard.component';
import { MultiDashboardService } from './components/_synapses/multi-dashboard/services/multi-dashboard-service.service';
import { QvPropertyImagesService } from './components/_qv/qv-property-image-management/_services/qv-property-images.service';
import { NotificationsService } from './services/NotificationsService';
import { CustomMsalInterceptor } from './services/CustomMsalInterceptor';

declare var angular: angular.IAngularStatic;
declare var $: any;
declare var kendo: any;

var app = angular.module('DXSPolluxApp', [
  "kendo.directives",
  "gridster",
  'tmh.dynamicLocale',
  'pascalprecht.translate',
  'ngSanitize',
  'ngLocalize',
  'ngLocalize.Config',
  'ngLocalize.InstalledLanguages',
  'dynamicNumber',
  'ngResource'
]);


//#region downgraded Angular components - make them available in AngularJS

app.factory('appRouting', downgradeInjectable(AppRoutingService));

app.factory('angularIntegrationService', downgradeInjectable(AngularIntegrationService));
app.factory('filterPaneService', downgradeInjectable(FilterPaneService));
app.factory('multiDashboardService', downgradeInjectable(MultiDashboardService));
app.factory('qvPropertyImagesService', downgradeInjectable(QvPropertyImagesService));
app.factory('notificationsService', downgradeInjectable(NotificationsService));
app.factory('customMsalInterceptor', downgradeInjectable(CustomMsalInterceptor));

app.directive(
  'geoCharts',
  downgradeComponent({
    component: GeochartsComponent,
    inputs: ['id', 'latitude', 'longitude', 'description', 'area', 'enteredarea']
  }) as angular.IDirectiveFactory
);

app.directive(
  'cashflowCharts',
  downgradeComponent({
    component: CashflowchartsComponent,
    inputs: ['id', 'jobId', 'job']
  }) as angular.IDirectiveFactory
);


app.directive(
  'propertyImagesSlider',
  downgradeComponent({
    component: QvPropertyImagesSliderComponent,
    inputs: ['oid']
  }) as angular.IDirectiveFactory
);


app.directive(
  'propertyImageManagement',
  downgradeComponent({
    component: QvPropertyImageManagementComponent,
    inputs: ['oid']
  }) as angular.IDirectiveFactory
);

app.directive(
  'filterPane',
  downgradeComponent({
    component: FilterPaneComponent,
    inputs: ['area'],
    outputs: ['filtersChanged']
  }) as angular.IDirectiveFactory
);


app.directive(
  'dqsValidation',
  downgradeComponent({
    component: DqsValidation,
    inputs: ['property'],
    outputs: ['']
  }) as angular.IDirectiveFactory
);

app.directive(
  'polluxMonthPickerField',
  downgradeComponent({
    component: PolluxMonthPickerField,
    inputs: ['model', 'fieldName', 'value', 'hasMonthClass', 'availableDates'],
    outputs: ['valueChanged']
  }) as angular.IDirectiveFactory
);

app.directive(
  'rentrollExportPane',
  downgradeComponent({
    component: RentrollExportPane,
    inputs: ['property'],
    outputs: ['']
  }) as angular.IDirectiveFactory
);
app.directive(
  'synapsesDqsV2Main',
  downgradeComponent({
    component: SynapsesDqsV2MainComponent,
    inputs: ['businessObjectType', 'oid'],
    outputs: ['close']
  }) as angular.IDirectiveFactory
);

app.directive(
  'synapsesDashboardMain',
  downgradeComponent({
    component: SynapsesDashboardMainComponent,
    inputs: ['job', 'canGoForward', 'canGoBack'],
    outputs: ['close', 'forward', 'back']
  }) as angular.IDirectiveFactory
);

app.directive(
  'helpTextOverlay',
  downgradeComponent({
    component: HelpTextOverlayComponent,
    inputs: [''],
    outputs: ['closed']
  }) as angular.IDirectiveFactory
);

app.directive(
  'jobDataLinking',
  downgradeComponent({
    component: JobDataLinkingComponent,
    inputs: ['data']
  }) as angular.IDirectiveFactory
);

app.directive(
  'smartBar',
  downgradeComponent({
    component: SmartBarComponent,
    inputs: ['data']
  }) as angular.IDirectiveFactory
);

app.directive(
  'propertyRentrollDistribution',
  downgradeComponent({
    component: PropertyRentrollDistributionComponent,
    inputs: ['data'],
    outputs: ['result']
  }) as angular.IDirectiveFactory
);

app.directive(
  'ctMultiDashboard',
  downgradeComponent({
    component: MultiDashboardComponent,
    inputs: ['job']
  }) as angular.IDirectiveFactory
);

app.factory('angTranslateService', downgradeInjectable(TranslateService) as any);
app.factory('angSynapsesDashboardService', downgradeInjectable(SynapsesDashboardService) as any);
app.factory('angHelpTextService', downgradeInjectable(HelpTextService) as any);
app.factory('angSynapsesDashboardEventsService', downgradeInjectable(SynapsesDashboardEventsService) as any);

//#endregion

/**
 * @ngdoc overview
 * @name index
 * @description 
 * <h1>QV Synapses</h1>
 * This is the index page of the Pollux Valuation documentation.
 * 
 * Please use the list on the left side to navigate through Pollux Valuation's components, directives and services.
 */

//Doc DXSPolluxApp index page
/**
  * @ngdoc overview
  * @name DXSPolluxApp
  * @description Common Services - TODO
  */

/**
   * @ngdoc object
   * @name DXSPolluxApp.object:appSettings
   * @description App settings
   */
app.constant('appSettings',
  {
    /**
     * @ngdoc property
     * @name DXSPolluxApp.object:appSettings.#version
     * @propertyOf DXSPolluxApp.object:appSettings
     * @description The application version string
     */
    version: "",

    /**
    * @ngdoc property
    * @name DXSPolluxApp.object:appSettings.#useMockup
    * @propertyOf DXSPolluxApp.object:appSettings
    * @description Enables the use of mockup data. Disables in productive use.
    */
    useMockup: false,

    /**
    * @ngdoc property
    * @name DXSPolluxApp.object:appSettings.#serverPath
    * @propertyOf DXSPolluxApp.object:appSettings
    * @description Path to the Pollux API service
    */
    serverPath: '',

    polluxApiUrl: '',
    settingsApiUrl: '',
    comparablesApiUrl: '',
    projectMainApiUrl: '',
    projectValuationApiUrl: '',
    dqsApiUrl: '',

    serverApiVersion: '',
    useProjectGuidInUrl: true,

    //hosting APP ANG environment
    environment: environment
  });


app.controller("AppController", function () {
  //Nothing
});

app.config(['$compileProvider', function ($compileProvider) {
  $compileProvider.debugInfoEnabled(false);
}]);

app.config(function ($translateProvider, tmhDynamicLocaleProvider, appSettings, appSettingsOnStartup, dynamicNumberStrategyProvider) {
  //Process all loaded config keys
  const keys = Object.keys(appSettingsOnStartup);
  for (var i = 0; i < keys.length; ++i) {
    appSettings[keys[i]] = appSettingsOnStartup[keys[i]];
  }

  //Set the locale settings
  const localeRegionDesignator = appSettings.locale.split('-').join('_'); //separated by '_', so replace all occurrences of '-' by '_' -> de_DE
  const localeScriptDesignator = appSettings.locale.split('_').join('-'); //separated by '-', so replace all occurrences of '_' by '-' -> de-DE

  tmhDynamicLocaleProvider.localeLocationPattern('../libraries/angular/angular-i18n/angular-locale_{{locale}}.js');

  const translationBasePath = 'content/translation/lang-';

  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.useStaticFilesLoader({
    prefix: translationBasePath,
    suffix: '.json'
  });

  //http://docs.telerik.com/kendo-ui/controls/editors/datepicker/how-to/AngularJS/localization-using-angular-translate
  $translateProvider.preferredLanguage(localeScriptDesignator); //$translateProvider uses the script designator notation
  $.getScript('./libraries/kendoui/message/kendo.messages.' + localeScriptDesignator + '.min.js', function () {
    tmhDynamicLocaleProvider.defaultLocale(localeRegionDesignator); //tmhDynamicLocale uses the region designator location
    kendo.culture(localeScriptDesignator); //Kendo UI uses the script designator notation
  });

  dynamicNumberStrategyProvider.addStrategy('n0-englishNotation', {
    numFract: 0,
    numFixed: true,
    numSep: '.',
    thousandSeparator: ',',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });
  dynamicNumberStrategyProvider.addStrategy('n0', {
    numFract: 0,
    numFixed: true,
    numSep: ',',
    thousandSeparator: '.',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });

  dynamicNumberStrategyProvider.addStrategy('n1-englishNotation', {
    numFract: 1,
    numFixed: true,
    numSep: '.',
    thousandSeparator: ',',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });
  dynamicNumberStrategyProvider.addStrategy('n1', {
    numFract: 1,
    numFixed: true,
    numSep: ',',
    thousandSeparator: '.',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });

  dynamicNumberStrategyProvider.addStrategy('n2-englishNotation', {
    numFract: 2,
    numFixed: true,
    numSep: '.',
    thousandSeparator: ',',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });
  dynamicNumberStrategyProvider.addStrategy('n2', {
    numFract: 2,
    numFixed: true,
    numSep: ',',
    thousandSeparator: '.',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });

  dynamicNumberStrategyProvider.addStrategy('n3-englishNotation', {
    numFract: 3,
    numFixed: true,
    numSep: '.',
    thousandSeparator: ',',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });
  dynamicNumberStrategyProvider.addStrategy('n3', {
    numFract: 3,
    numFixed: true,
    numSep: ',',
    thousandSeparator: '.',
    numPos: true,
    numNeg: true,
    numRound: 'round',
    numThousand: true
  });
});

app.config([
  '$compileProvider',
  function ($compileProvider) {
    //#59542: we need https for linking images to https://www.google.com/maps...
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(data|http|https?):/);
  }
]);

app.config(['$locationProvider', function ($locationProvider) {
  $locationProvider.hashPrefix('');
}]);

//https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/472
/*
  The service provider is different than the service it provides.
  It appears that msalAuthenticationServiceProvider is a magic string that gets generated (by Angular?) instead of existing in the code like msalAuthenticationService.
*/
app.config(['$httpProvider', function ($httpProvider) {
  $httpProvider.defaults.withCredentials = true;

  $httpProvider.interceptors.push(function ($injector) {
    const zeroGuidPattern: string = '/api/v2/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/';

    return {
      request: function (config) {
        const appLocalization = $injector.get('appLocalization');
        const appStorage = $injector.get('appStorage');

        config.headers['X-PolluxLanguage'] = appLocalization.getLocale();

        const selectedProject = appStorage.getSelectedProject();
        const organizationGuid = appStorage.getOrganizationGuid();
        if (config.url.toLowerCase().startsWith('http')) {
          const projectGuid = selectedProject ? selectedProject.ProjectGuid : '00000000-0000-0000-0000-000000000000';
          const newUrl = config.url.replace(zeroGuidPattern, `/api/v2/${organizationGuid}/${projectGuid}/`);
          config.url = newUrl;
        }

        return config;
      }
    };
  });

  if (environment.UseMsal) {
    $httpProvider.interceptors.push('customMsalInterceptor', '$q', function (customMsalInterceptor, $q) {
      return customMsalInterceptor.handle($q);
    });
  }

  //!!! HAS TO BE HERE AT THE END!!!!
  $httpProvider.defaults.withCredentials = true;

}]);

app.run(['appStorage', 'appLocalization', 'appSettings', 'appRouting', function (appStorage, appLocalization, appSettings, appRouting) {
  // Set the default locale, but don't store it for the current user
  //console.log("app.run");
  appLocalization.setLocale(appSettings.locale, true).then(function () {
    appRouting.notifyPreRoutingDataLoaded();
  });

  //appStorage.reloadRoleAssignments();
  appStorage.loadCurrentUserDetails();
  if (window.location.hash === "#/") {
    window.location.hash = "#/pad";
  }
}]);

app.filter('trusted', ['$sce', function ($sce) {
  return function (url) {
    return $sce.trustAsResourceUrl(url);
  };
}]);

app.filter('trustedHtml', ['$sce', function ($sce) {
  return function (url) {
    return $sce.trustAsHtml(url);
  };
}]);

export function SetupPolluxBeforeBootstrap(appConfigResult, startupInfo, callback) {
  SetupPolluxBeforeBootstrapInternal(appConfigResult, startupInfo, callback);
}

function SetupPolluxBeforeBootstrapInternal(appConfigResult, startupInfo, callback) {
  //Set the available locales for the Report Designer. This can't be done inside a config or run block.
  const localeFallbacks = {};
  const localeSupported = [];
  angular.forEach(appConfigResult.availableLocales, function (localeConfig) {
    const scriptDesignatorNotation = localeConfig.name.split('_').join('-'); //separated by '-', so replace all occurrences of '_' by '-' -> de-DE
    localeSupported.push(scriptDesignatorNotation);

    const shortNotation = scriptDesignatorNotation.substring(0, 2);

    localeFallbacks[shortNotation] = scriptDesignatorNotation;
  });

  app.constant('localeSupported', localeSupported);
  app.constant('localeFallbacks', localeFallbacks);

  //Widget translations have to be configured here because we set an app value
  const translationBasePath = 'content/translation/lang-';

  app.value('localeConf', {
    basePath: translationBasePath,
    defaultLocale: 'en-US',
    sharedDictionary: 'common',
    fileExtension: '.json',
    persistSelection: false,
    cookieName: 'COOKIE_LOCALE_LANG',
    observableAttrs: new RegExp('^data-(?!ng-|i18n)'),
    delimiter: '::',
    validTokens: new RegExp('^[\\w\\.-]+\\.[\\w\\s\\.-]+\\w(:.*)?$') //Safe because it's only used for app-controlled localization. The user can't mess around with this Regex
  });

  //Set the configured locale to the user's locale
  appConfigResult.locale = startupInfo.language && startupInfo.language.success ? startupInfo.language.message : 'en-US';

  //Set application startup info
  appConfigResult.startupInfo = startupInfo;

  angular.module('DXSPolluxApp').constant('appSettingsOnStartup', appConfigResult);
  callback();
}
