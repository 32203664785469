(function () {

  "use strict";

  /**
   * @ngdoc component
   * @name DXSPolluxApp.component:polluxBoolField
   * @description
   * This component provides the behavior and visual representation of a bool field. The field is rendered as a Kendo UI checkbox.
   * 
   * It can be used throughout the entire application and therefore simplifies and unifies the use of this field type.
   * 
   * @requires $scope
   * @requires DXSPolluxApp.service:appStorage
   * @requires $timeout
   * 
   * @param {Bool} ngModel The property that is bound to this field
   * @param {Function} ngChange Callback function that is called on each change
   * @param {Bool} ngReadonly Sets this field read-only
   * @param {String} translationKey The translation key that is used to translate the label
   * @param {String} ngId Unique ID used to identify this control.
   */
  angular.module("DXSPolluxApp")
    .component("polluxBoolField", {
      template: require('html-loader!./pollux.bool.field.tmpl.html'),
      bindings: {
        "ngModel": "=",
        "ngChange": "&",
        "ngReadonly": '<',
        "translationKey": '@',
        "ngId": "@"
      },
      controllerAs: "vm",
      controller: ['$timeout', polluxBoolFieldCtrl]
    });

  function polluxBoolFieldCtrl($timeout) {
    var vm = this;

    //#region variable declaration

    //#endregion

    //#region function declaration
    vm.onNgChange = onNgChange;
    //#endregion

    //#region function implementation
    function onNgChange() {
      $timeout(function () { //Potentially executed from outside of the Angular context
        if (vm.ngChange) {
          vm.ngChange();
        }
      });
    }
    //#endregion

    //#region events
    vm.$onInit = function () {

      if (vm.ngTrueValue === undefined) {
        vm.ngTrueValue = true;
      }
      if (vm.ngFalseValue === undefined) {
        vm.ngFalseValue = true;
      }

      vm.onNgChange();
    }
    //#endregion
  }
}());

